import { Column } from '../../utils/interfaces';
import { getColunmsPOS } from './pos/pos-colums';
import { getColunmsWeeklySales } from './weeklysales-colums/weeklysales-columns';
import { getColunmsPreWeeklySales } from './pre-weeklysales-colums/pre-weeklysales-columns';
import { getColumnsProducts } from './products/products-columns';
import { getColunmsMarketShare } from './market-share/market-share-columns';
export function getColunmsByFile(file: any, onClickPOS: Function): Column[] {
    let columns: Column[] = [];
    switch (file) {
        case 'pos':
            columns = getColunmsPOS(onClickPOS);
            break;
        case 'weeklysales':
            columns = getColunmsWeeklySales();
            break;
        case 'completeweeklysales':
            columns = getColunmsPreWeeklySales();
            break;
        case 'products':
            columns = getColumnsProducts();
            break;
        case 'market_share':
            columns = getColunmsMarketShare();
            break;
        default:
            break;
    }
    // return columns.map((obj, i) => {
    //     return { ...obj, id: i };
    // });
    return columns;
}
