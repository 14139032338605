import styles from './table-row.module.scss';
import { TableCell } from '../cell/cell';
import { Column } from '../../utils/interfaces';
export interface RowProps {
    data?: any;
    columns?: Column[];
    selectId?: (id: number) => void | undefined;
    allColumnsStyle?: string;
    idsArray?: number[] | undefined;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const TableRow = ({ data, columns = [], allColumnsStyle, selectId, idsArray }: RowProps) => {
    const addClass = allColumnsStyle === 'w-full' ? ' flex w-full' : ' flex w-[max-content]';
    return (
        <div className={styles.root + addClass}>
            {columns.map(
                (column: any, index: any) =>
                    column.show && (
                        <TableCell
                            idsArray={idsArray}
                            isCheckbox={column.id === 'id'}
                            onClick={column.onClick}
                            row={data}
                            value={data[column.prop]}
                            key={column.prop + index.toString()}
                            style={
                                column.id === 'id'
                                    ? { minWidth: '50px', paddingLeft: '10px' }
                                    : column.style
                            }
                            formatting={column.formatting}
                            cellType={column.cellType}
                            selectId={selectId}
                            allColumnsStyle={allColumnsStyle}
                        />
                    )
            )}
        </div>
    );
};
