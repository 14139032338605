import styles from './multi-tab-child.module.scss';
import Notiflix from 'notiflix';
import React, { useState } from 'react';
import { MockUp } from '../mock-up/mock-up';
import {
    ChevronDownIcon,
    ChevronUpIcon,
    EyeIcon,
    EyeSlashIcon,
    PencilIcon,
    ShareIcon,
} from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { getChildUrl, getEnvironment, getParentUrl, getQueryParams } from '../../utils/functions';
import { showStatus, updatePowerBI } from '../../services/python-services';

export interface MultiTabChildProps {
    children?: React.ReactNode;
    currentTab?: string;
    data?: any;
    canEdit?: any;
    onClickIFrame?: any;
    itemUpdate?: any;
    updateData?: any;
    currentViewData?: any;
    setUpdatedData?: any;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const MultiTabChild = ({
    data,
    canEdit,
    onClickIFrame,
    itemUpdate,
    currentViewData,
    setUpdatedData,
}: MultiTabChildProps) => {
    const URL = window.location.href;
    const env = getEnvironment(URL);
    const [open, setOpen] = useState(true);
    const token = localStorage.getItem('tokenkey');
    const queries: any = getQueryParams(window.location.search);
    const handleHideChild = async (report: any) => {
        // You need to implement your service call using async/await or promises
        try {
            //handleViewReport(report);
            const payload = {
                name: report.name,
                type: report.cover_id,
                sub_view_id: report.parent_id,
                division_id: report.division_id !== 0 ? report.division_id : 1,
                workspace_id: report.workspace_id,
                report_id: report.report_id,
                url: report.url,
                person_in_charge_id:
                    report.person_in_charge_id !== -1 ? report.person_in_charge_id : null,
                show: !report.show,
            };
            const response = await updatePowerBI(report.id, payload); // Modify yourService to your actual service
            if (response.message === 'View updated successfully') {
                //updateData("Applied");
                Notiflix.Notify.success('Applied');
                setUpdatedData(true);
            }
        } catch (error) {
            console.error('Error hiding child:', error);
        }
    };

    const handleClipboard = (name: any) => {
        // const parent = getParentUrl(name);
        //get child data
        if(currentViewData.child === undefined) {
            currentViewData.child = getChildUrl(name);
        }
        // console.log('handleClipboard', currentViewData);
        if (queries.t === undefined) {
            navigator.clipboard.writeText(
                `${window.location.origin}/${currentViewData.parent}/${currentViewData.child}/${name}${(name.includes('glp_dashboard') && !name.includes('sela'))?('?finalctry='+name.split('_').slice(-2)[0]):''}`
            );
        } else {
            navigator.clipboard.writeText(
                `https://${env === 'dev' ? 'dev' : 'rm'}.cheilbidx.io/${
                    currentViewData.parent
                }?r=${name}${(name.includes('glp_dashboard') && !name.includes('sela'))?('?finalctry='+name.split('_').slice(-2)[0]):''}`
            );
        }
        Notiflix.Notify.success(`Report URL in clipboard`, { timeout: 5000 });
    };

    return (
        <div className={styles['row']}>
            <div className={styles['items']}>
                {data?.children?.length > 0 ? (
                    data?.children?.map((item: any) =>
                        canEdit || item.show ? (
                            <div className={styles['item-box']} key={item.id}>
                                <div
                                    className={`${styles['img-fixed']} ${
                                        item.show ? '' : styles.reportNotShowed
                                    }`}
                                    onClick={() => {
                                        if (onClickIFrame) onClickIFrame(item);
                                    }}
                                >
                                    <Link
                                        to={`/${currentViewData.parent}/${getChildUrl(item.name)}/${
                                            item.name
                                        }${(item.name.includes('glp_dashboard') && !item.name.includes('sela'))?('?finalctry='+item.name.split('_').slice(-2)[0]):''}`}
                                    >
                                        <MockUp data={item} />
                                    </Link>
                                </div>

                                <div className={styles['icons-flex']}>
                                    <ShareIcon
                                        className={styles['lni'] + ' ' + styles['blue']}
                                        style={{ padding: 0 }}
                                        onClick={() => handleClipboard(item.name)}
                                        title="Copy report URL to share"
                                    />
                                    {canEdit && (
                                        <>
                                            <div
                                            // Link
                                            // to={`/${currentViewData.parent}/${currentViewData.child}/${item.name}`}
                                            >
                                                {item.show === 1 ? (
                                                    <EyeIcon
                                                        className={
                                                            styles['lni'] + ' ' + styles['blue']
                                                        }
                                                        onClick={() => handleHideChild(item)}
                                                        title="Hide report"
                                                    />
                                                ) : (
                                                    <EyeSlashIcon
                                                        className={
                                                            styles['lni'] + ' ' + styles['blue']
                                                        }
                                                        onClick={() => handleHideChild(item)}
                                                        title="Show report"
                                                    />
                                                )}
                                            </div>

                                            <PencilIcon
                                                className={styles['lni'] + ' ' + styles['green']}
                                                onClick={() => itemUpdate(item)}
                                                title="Edit report"
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <></>
                        )
                    )
                ) : (
                    <>No data Found</>
                )}
            </div>
        </div>
    );
};
