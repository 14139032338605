import {
    ChevronLeftIcon,
    ChevronRightIcon,
    EyeIcon,
    LinkIcon,
    ArrowLongUpIcon,
    ArrowLongDownIcon,
    EyeSlashIcon
} from '@heroicons/react/24/outline';
import styles from './dynamic-menu.module.scss';
import { isLastParentWithChildren } from '../../utils/functions';
import { useEffect, useState, useContext } from 'react';
import { updateOrderOfViews, unlinkView, linkView, renameView, changeShowProperty } from '../../services/python-services';
import { DoubleClickInputForm } from '../double-click-input-form/double-click-input-form';
import { SearchBoxForChildrenViews } from '../search-box-for-children-views/search-box-for-children-views';
import Notiflix from 'notiflix';
import { addSiteMapToContext } from '../../utils/functions';
import { RMContext } from '../../context/RMContext';

export interface TreeNodeProps {
    node: any;
    level: any;
    onSelectNode: any;
    isSelected: boolean;
    isExpanded: boolean;
    onToggleExpand: any;
    lastParent?: boolean;
    nodes: any;
    index: any;
    allChildrenViews: any;
    setAllChildrenViews: any;
    options: any;
    levels: any;
    setLevels: any;
    grandpaID: any;
    returnIDs: any;
    currentNode?:any;
    setCurrentNode?:any;
}

export const TreeNode = ({
    node,
    level,
    onSelectNode,
    isSelected,
    isExpanded,
    onToggleExpand,
    lastParent,
    nodes,
    index,
    setAllChildrenViews,
    options,
    levels,
    setLevels,
    grandpaID,
    returnIDs,
    currentNode,
    setCurrentNode
}: TreeNodeProps) => {
    const [forceReRenderLocal, setForceReRenderLocal] = useState(false);
    const [updatedName, setUpdatedName] = useState("");
    const [updatedIndex, setUpdatedIndex] = useState("");
    // const [currentNode, setCurrentNode] = useState(-1);
    const [showSearchBox, setShowSearchBox] = useState(false);

    const { setUserData, userData } = useContext(RMContext);


    const handleClick = () => {
        
        setCurrentNode(node.id);
        setShowSearchBox(true);
        // if (node.children && node.children.length > 0) {
            const lastParent = isLastParentWithChildren(node);
            
            onSelectNode(level, node.children, node.id, (lastParent ));
            onToggleExpand(node.id);
        // }
    };

    const isLeaf = !node.children || node.children.length === 0;
    const hasLeafChildren =
        node.children &&
        node.children.every((child: any) => !child.children || child.children.length === 0);




    



    function leftSwap() {
        if (level === 0) {
            let levelsHolder = [...levels[0]]
            let storageOfCurrent = levelsHolder[index]
            let storageOfFormer = levelsHolder[index-1]
            let idOfCurrent = storageOfCurrent.id
            let idOfFormer = storageOfFormer.id
            let orderOfCurrent = storageOfCurrent.order_field
            let orderOfFormer = storageOfFormer.order_field
            
            const body = {views: [{"view_id":idOfCurrent, "order":orderOfFormer},{"view_id":idOfFormer, "order":orderOfCurrent}]}

            
            updateOrderOfViews(body)

            levelsHolder[index] = storageOfFormer
            levelsHolder[index-1] = storageOfCurrent

            let totalSpread = [...levels]
            totalSpread[0] = levelsHolder
            setLevels(totalSpread)
            //update the newtree data in the context
        }
        else if (level === 1) {
            
            let levelsHolder = [...levels[1]]
            let storageOfCurrent = levelsHolder[index]
            let storageOfFormer = levelsHolder[index-1]
            let idOfCurrent = storageOfCurrent.id
            let idOfFormer = storageOfFormer.id
            let orderOfCurrent = storageOfCurrent.order_field
            let orderOfFormer = storageOfFormer.order_field
            
            const body = {views: [{"view_id":idOfCurrent, "order":orderOfFormer},{"view_id":idOfFormer, "order":orderOfCurrent}]}

            

            updateOrderOfViews(body)

            levelsHolder[index] = storageOfFormer
            levelsHolder[index-1] = storageOfCurrent

            let totalSpread = [...levels]
            totalSpread[1] = levelsHolder
            setLevels(totalSpread)
        }
    }

    function rightSwap() {

        if (level === 0) {
            let levelsHolder = [...levels[0]]
            let storageOfCurrent = levelsHolder[index]
            let storageOfLatter = levelsHolder[index+1]
            let idOfCurrent = storageOfCurrent.id
            let idOfLatter = storageOfLatter.id
            let orderOfCurrent = storageOfCurrent.order_field
            let orderOfLatter = storageOfLatter.order_field
            
            const body = {views: [{"view_id":idOfCurrent, "order":orderOfLatter},{"view_id":idOfLatter, "order":orderOfCurrent}]}

            

            updateOrderOfViews(body)

            levelsHolder[index] = storageOfLatter
            levelsHolder[index+1] = storageOfCurrent

            let totalSpread = [...levels]
            totalSpread[0] = levelsHolder
            setLevels(totalSpread)
        }
        else if (level === 1) {
            let levelsHolder = [...levels[1]]
            let storageOfCurrent = levelsHolder[index]
            let storageOfLatter = levelsHolder[index+1]
            let idOfCurrent = storageOfCurrent.id
            let idOfLatter = storageOfLatter.id
            let orderOfCurrent = storageOfCurrent.order_field
            let orderOfLatter = storageOfLatter.order_field
            
            const body = {views: [{"view_id":idOfCurrent, "order":orderOfLatter},{"view_id":idOfLatter, "order":orderOfCurrent}]}

            

            updateOrderOfViews(body)

            levelsHolder[index] = storageOfLatter
            levelsHolder[index+1] = storageOfCurrent

            let totalSpread = [...levels]
            totalSpread[1] = levelsHolder
            setLevels(totalSpread)
        }
    }

    function upSwap(index:any) {  
        const storageOfCurrent = node.children[index]
        const storageOfFormer = node.children[index-1]
        node.children[index-1] = storageOfCurrent
        node.children[index] = storageOfFormer

        const body = {views: [{"view_id":storageOfCurrent.id, "order":storageOfFormer.order_field},{"view_id":storageOfFormer.id, "order":storageOfCurrent.order_field}]}


            updateOrderOfViews(body)

        setForceReRenderLocal((x:any)=>!x)
    }

    function downSwap(index:any) {
        const storageOfCurrent = node.children[index]
        const storageOfLatter = node.children[index+1]
        node.children[index+1] = storageOfCurrent
        node.children[index] = storageOfLatter

        const body = {views: [{"view_id":storageOfCurrent.id, "order":storageOfLatter.order_field},{"view_id":storageOfLatter.id, "order":storageOfCurrent.order_field}]}


            updateOrderOfViews(body)


        setForceReRenderLocal((x:any)=>!x)
    }


    function swapToOff(index:any) {
        node.children[index].show = 1
        const body = {"show":1}
        const id = node.children[index].id
        changeShowProperty(body, id)
        setForceReRenderLocal((x:any)=>!x)
    }

    function swapToOn(index:any) {
        node.children[index].show = 0
        const body = {"show":0}
        const id = node.children[index].id
        changeShowProperty(body, id)
        setForceReRenderLocal((x:any)=>!x)
    }

    /* {'parent_id': 174, 'child_id':211/210 (need to reassign both of these on)} + FOR NAME ALTER ONLY ExecuTIVE Store Activity ID: 180
                        Executive NCA SELA ID: 622 show: 1    
        */

    async function removeFromArray(index:any) {
        const body = {'parent_id': node.children[index].parent_id, 'child_id':node.children[index].id}
        unlinkView(body)
        node.children.splice(index, 1)
        setForceReRenderLocal((x:any)=>!x)
    }

    function renameChild(index:any) {
        node.children[index].description = updatedName
        const body = {"description": updatedName}
        const id = node.children[index].id
        renameView(body, id)
    }

    useEffect(() => {
        
        if (updatedName !== "") {
        renameChild(updatedIndex)
        setForceReRenderLocal((x:any)=>!x)
    }
        
    }, [updatedName]);

    
    useEffect(() => {
        if (lastParent) {
          setAllChildrenViews((prevChildrenViews:any) => [
            ...prevChildrenViews,
            ...node.children
          ]);
        }
      }, [lastParent, node.children/* trigger should be when the entry is entered */]);       


    
    






    return (
        <div className={styles.nodeContainer}>
            <div className={styles.optionBox}>
                {index!==0 && <button onClick={leftSwap}><ChevronLeftIcon className="w-5 h-5" /></button>}
                {index===0 && <ChevronLeftIcon className="w-5 h-5 opacity-15" />}
                <div
                    className={isSelected ? styles.optionClicked : styles.option}
                    onClick={handleClick}
                >
                    {node.description}
                </div>
                {node.children && !isLeaf && nodes.length-1 !== index && <button onClick={rightSwap}><ChevronRightIcon className="w-5 h-5" /></button>}
                {node.children && nodes.length-1 === index && <ChevronRightIcon className="w-5 h-5 opacity-15" />}
            </div>
            {isExpanded && lastParent && (
                <div
                    className={
                        hasLeafChildren ? styles.leafChildrenContainer : styles.childrenContainer
                    }
                >
                    {node.children.map((child: any, index:any) => (
                        <div key={child.id} className={`${styles.leafDescription} ${child.show === 1 ? styles.greyedOut : ''}`}>
                            {child.show === 1 ? <button onClick={()=>swapToOn(index)}>
                                <EyeSlashIcon className='w-4 h-4' />
                            </button> : <button onClick={()=>swapToOff(index)}><EyeIcon className="w-4 h-4" /></button>}
                            
                                <button onClick={()=>removeFromArray(index)}><LinkIcon className="w-4 h-4" /></button>
                            
                            <div className={styles.arrowsDiv}>
                                {index !== 0 &&  <button onClick={()=>upSwap(index)}><ArrowLongUpIcon className="w-5 h-5" /></button>}
                                {index == 0 &&  <ArrowLongUpIcon className="w-5 h-5 opacity-15" />}
                                {index !== node.children.length-1 && <button onClick={()=>downSwap(index)}><ArrowLongDownIcon className="w-5 h-5" /></button>}
                                {index === node.children.length-1 && <ArrowLongDownIcon className="w-5 h-5 opacity-15" />}
                            </div>
                            <div className={styles.description} title={child.description}>
                                <DoubleClickInputForm setUpdatedIndex={setUpdatedIndex} index={index} setUpdatedName = {setUpdatedName} description={child.description} />
                            </div>
                        </div>
                    ))}
                    {/* <SearchBoxForChildrenViews grandpaID={grandpaID} returnIDs={returnIDs} newParentID={node.id} options={options} node={node}/> */}
                </div>
            )}
            <div className={styles.dropdownContainer}>
            {showSearchBox && currentNode===node.id && level!==0 &&
                   <SearchBoxForChildrenViews grandpaID={grandpaID} returnIDs={returnIDs} newParentID={node.id} options={options} node={node}/> 
            }
            </div>
        </div>
    );
};
