export interface ItemProps {
    name: string;
    icon?: string;
}

export interface ItemsMenuProps {
    className?: string;
    props: any;
    onClick: (e: any) => void;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const ItemsMenu = ({ props, onClick }: ItemsMenuProps) => {
    return (
        <div
            className="block rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
            onClick={() => onClick(props)}
        >
            <div className="flex gap-2">
                {props?.icon ? props?.icon : ''}
                {props.name}
            </div>
        </div>
    );
};
