import { Button } from 'flowbite-react';
import { ComparisonFilter } from '../comparison-filter/comparison-filter';
import { useState } from 'react';
import { TrashIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import styles from './multi-comparison-filters.module.scss';

export interface MultiComparisonFiltersProps {
    className?: string;
    columns: object[];
    exportFilters?: Function;
    setComponents?: Function;
    components?: any[];
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const MultiComparisonFilters = ({
    columns,
    components = [],
    setComponents = () => {},
}: MultiComparisonFiltersProps) => {
    const [iterControl, setIterControl] = useState(1);
    const addFilter = () => {
        const id = iterControl + 1;
        setIterControl(id);
        setComponents([...components, [id, '', '', '', '', '', '']]);
    };

    const deleteFilter = (filter: any) => {
        const updateComponents: any[] = components.filter((c: any) => c[0] !== filter[0]);
        setComponents(updateComponents);
    };

    const adapterFilters = (e: any, id: number) => {
        const updateComponent: any = components.map((c: any) => {
            if (c[0] === id) {
                return [c[0], e[1], e[2], e[3], e[4], e[5], e[6]];
            }
            return c;
        });
        setComponents(updateComponent);
    };

    return (
        <div className="flex flex-col gap-1">
            <div className="flex flex-col gap-1">
                {components.map((c: any) => (
                    <div className="flex gap-1 w-full justify-between" key={'f' + c[0]}>
                        <ComparisonFilter
                            columns={columns}
                            updatedAttributes={(u: any) => adapterFilters(u, c[0])}
                            key={'multi_' + c[0]}
                            values={c}
                        />
                        <Button
                            onClick={() => deleteFilter(c)}
                            size="sm"
                            color="light"
                            style={{ borderColor: '#d1cbcb' }}
                        >
                            <TrashIcon className="h-4" style={{ color: '#2E323F' }}></TrashIcon>
                        </Button>
                    </div>
                ))}
            </div>
            <Button
                className={classNames(styles.button)}
                size="xs"
                color="light"
                pill
                onClick={addFilter}
                style={{ backgroundColor: 'white', borderColor: '#d1cbcb', color: '#2E323F' }}
            >
                + Add filter rule
            </Button>
        </div>
    );
};
