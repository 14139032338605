import styles from './roles.module.scss';
import classNames from 'classnames';
import { useContext, useEffect } from 'react';
import { Outlet } from 'react-router';
import { RMContext } from '../../context/RMContext';
import { viewInArrayViews } from '../../utils/functions';
import { postActionLogs } from '../../services/python-services';

export interface RolesProps {
    className?: string;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const Roles = ({ className }: RolesProps) => {
    const currentView = window.location.href.split("/").slice(-1)[0];
    const {sitemap}= useContext(RMContext).userData;

    useEffect(() => {
        const viewD =viewInArrayViews(currentView, sitemap);
        if(viewD?.hasOwnProperty("id")) {
            postActionLogs(viewD.id)
        }
    },[sitemap]);
    return <div className={classNames(styles.root, className)}><Outlet/></div>;
};
