import { Column } from '../../../utils/interfaces';

export const getColumnsProducts: Function = (): Column[] => [
    {
        name: 'PRODUCT ID',
        id: 'PRODUCT ID',
        prop: 'product_id',
        open: false,
        show: true,
        valueType: 'number',
        style: { minWidth: '120px', maxWidth: '120px' },
    },
    {
        name: 'MODEL',
        id: 'MODEL',
        prop: 'marketing_name',
        open: false,
        show: true,
        style: { minWidth: '150px', maxWidth: '150px' },
    },
    {
        name: 'SKU',
        id: 'SKU',
        prop: 'sku',
        open: false,
        show: true,
        style: { minWidth: '150px', maxWidth: '150px' },
    },
    {
        name: 'DIVISION',
        id: 'DIVISION',
        prop: 'division__value',
        open: false,
        show: true,
        style: { minWidth: '100px', maxWidth: '100px' },
    },
    {
        name: 'PRODUCT GROUP',
        id: 'PRODUCT GROUP',
        prop: 'group__value',
        open: false,
        show: true,
        style: { minWidth: '150px', maxWidth: '150px' },
    },
    {
        name: 'PRODUCT RANGE',
        id: 'PRODUCT RANGE',
        prop: 'product_range__value',
        open: false,
        show: true,
        style: { minWidth: '150px', maxWidth: '150px' },
    },
    {
        name: 'SEGMENT',
        id: 'SEGMENT',
        prop: 'segment__value',
        open: false,
        show: true,
        style: { minWidth: '120px', maxWidth: '120px' },
    },
];
