import styles from './double-click-input-form.module.scss';
import classNames from 'classnames';
import { useState } from 'react';

export interface DoubleClickInputFormProps {
    className?: string;
    description: any;
    setUpdatedName: any;
    index: any;
    setUpdatedIndex:any;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const DoubleClickInputForm = ({ className, description, setUpdatedName, index, setUpdatedIndex }: DoubleClickInputFormProps) => {

        const [text, setText] = useState('Double-click to edit');
        const [isEditing, setIsEditing] = useState(false);
        const [inputValue, setInputValue] = useState(description);

        // Handle double-click to start editing
        const handleDoubleClick = () => {
            setIsEditing(true);
            console.log("firing")
        };
    
        // Handle input changes
        const handleInputChange = (e:any) => {
            setInputValue(e.target.value);
        };
    
        // Handle input blur (when user clicks outside)
        const handleBlur = () => {
            setText(inputValue);
            setIsEditing(false);
            setUpdatedName(inputValue)
            setUpdatedIndex(index)
        };
    
        // Handle Enter key to save changes
        const handleKeyDown = (e:any) => {
            if (e.key === 'Enter') {
            handleBlur();
            }
        };



    return <div className={classNames(styles.root, className)}>

        {isEditing ? (
            <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                className={styles.renameInput}
            />
        ) : (
            <span onDoubleClick={handleDoubleClick}>{description}</span>
        )}


    </div>;
};
