import axios, { AxiosResponse } from 'axios';
import { encryptText, serializeObject } from './security';
import { GroupDataProps } from '../utils/interfaces';
import { axiosInstance } from '../interceptors/private.interceptor';
import { multipleUniquesColumns } from '../utils/constants';

const ENV_API_URLS: any = {
    dev: {
        api: `https://lrm-api-dev.cheilbidx.io`,
        //api: `http://localhost:8000`,
        frontend: `https://dev.cheilbidx.io`,
    },
    prod: {
        api: `https://lrm-api-prod.cheilbidx.io`,
        frontend: `https://rm.cheilbidx.io`,
    },
    local: {
        api: `http://localhost:8000`,
        frontend: `http://localhost:4000`,
    },
};

function getEnv() {
    const search: string = window.location.search;

    //Get env and token queries
    const queries = new URLSearchParams(search);
    const env: any = queries.get('e') ? queries.get('e') : 'dev';
    return ENV_API_URLS[env];
}

export function getPythonData(file: string, queries?: object): object {
    if (queries) {
        return axiosInstance
            .get(`/file/${file}`, {
                params: queries,
            })
            .then((data: AxiosResponse) => data?.data);
    } else {
        return axiosInstance.get(`/file/${file}`).then((data: AxiosResponse) => data?.data);
    }
}

export async function getPivotService(group: string, params?: object) {
    if (params) {
        return axiosInstance
            .get(`/storePivot/${group}`, {
                params: params,
            })
            .then((data: AxiosResponse) => data?.data);
    } else {
        return axiosInstance.get(`/storePivot/${group}`).then((data: AxiosResponse) => data?.data);
    }
}

export async function getPowerBiEmbeddedData(id: string, workspaceId: string) {
    try {
        const response = await axiosInstance.get(
            `/reports/get_embed_info/${id}?workspace_id=${workspaceId}`
        );
        if (!response.data) {
            throw new Error('No data received from the server');
        }
        return response.data;
    } catch (error) {
        console.error('Error fetching PowerBi data:', error);
        throw error; // You may want to handle the error further up in the component hierarchy
    }
}

export async function getBiData(groups: any) {
    try {
        const response = await axiosInstance.get(`/reports/bidata/${groups}`);
        if (!response.data) {
            throw new Error('No data received from the server');
        }
        return response.data;
    } catch (error) {
        console.error('Error fetching PowerBi data:', error);
        throw error; // You may want to handle the error further up in the component hierarchy
    }
}

export async function getAllRoles(id: any = '', params?: object) {
    if (params) {
        return axiosInstance
            .get(`/administration/roles${id}`, {
                params: params,
            })
            .then((data: AxiosResponse) => data?.data);
    } else {
        if (!id) {
            return axiosInstance
                .get(`/administration/roles`)
                .then((data: AxiosResponse) => data?.data);
        } else {
            return axiosInstance
                .get(`/administration/roles/${id}`)
                .then((data: AxiosResponse) => data?.data);
        }
    }
}

export async function getTreeViews(id: number) {
    if (id) {
        const response = await axiosInstance.get(`/administration/roles/${id}/treeView`);
        return response.data;
    }
    return [];
}

export async function deleteRoles(id: any = '') {
    return axiosInstance.delete(`/administration/roles/${id}`);
}

export async function updateRoles(id: any = '', body: any) {
    return axiosInstance
        .patch(`/administration/roles/${id}`, body)
        .then((data: AxiosResponse) => data?.data);
}

export async function createUser(body: any) {
    return axiosInstance
        .post(`/administration/roles`, body)
        .then((data: AxiosResponse) => data?.data);
}

export async function updateUser(id: any, params: any) {
    try {
        return axiosInstance
            .patch(`/administration/users/${id}`, { ...params })
            .then((data: AxiosResponse) => data?.data);
    } catch (e) {
        console.error(e);
    }
}

export async function createNewUser(body: any) {
    try {
        return axiosInstance
            .post(`/administration/users`, { ...body })
            .then((data: AxiosResponse) => data?.data);
    } catch (e) {
        console.error(e);
    }
}

export async function getUserById(id: any) {
    return axiosInstance.get(`/api/v1/users/${id}/`).then((data: AxiosResponse) => data?.data);
}
export async function disableUser(users_ids: any) {
    return axiosInstance
        .post(`/administration/users/disable`, users_ids)
        .then((data) => data?.data);
}

export async function getAllCountries() {
    return axiosInstance.get(`/administration/countries`).then((data: AxiosResponse) => data?.data);
}

export async function getAllViewTypesByType(type: string) {
    return axiosInstance
        .get(`/administration/viewsType/${type}`)
        .then((data: AxiosResponse) => data?.data);
}

export async function getAllViews() {
    return axiosInstance.get(`/administration/views`).then((data: AxiosResponse) => data?.data);
}

export async function getAllDivisions() {
    return axiosInstance.get(`/products/divisions`).then((data: AxiosResponse) => data?.data);
}

export async function getAllGroups() {
    return axiosInstance.get(`/products/group`).then((data: AxiosResponse) => data?.data);
}

export async function getAllUsers(params?: object) {
    if (params) {
        let query_string = serializeObject(params);
        return axiosInstance
            .get(`/administration/users?${query_string}`)
            .then((data) => data?.data);
    } else {
        return axiosInstance.get(`/administration/users?`).then((data) => data?.data);
    }
}
//get uniques user_country, status, company for user table
export async function getOrmUniquesValues(table?: string, column?: string, queries?: any) {
    if (queries) {
        return axiosInstance
            .get(`/administration/ormUniques/${table}/${column}`, { params: queries })
            .then((data) => data?.data);
    } else {
        return axiosInstance
            .get(`/administration/ormUniques/${table}/${column}`)
            .then((data) => data?.data);
    }
}

export async function getAllCompanies() {
    return axiosInstance.get(`/administration/companies`).then((data: AxiosResponse) => data?.data);
}

export async function getAllDepartments() {
    return axiosInstance
        .get(`/administration/departments`)
        .then((data: AxiosResponse) => data?.data);
}

export async function getAllAuthorizedEmails() {
    return axiosInstance
        .get(`/administration/authorizedEmails`)
        .then((data: AxiosResponse) => data?.data);
}

export async function getAllJobPositions() {
    return axiosInstance
        .get(`/administration/jobPositions`)
        .then((data: AxiosResponse) => data?.data);
}

export function getUniques(
    fileName: string,
    fields: string,
    queries?: any,
    by: string = 'parquet'
) {
    const config = {
        headers: {
            'data-source': by,
        },
    };
    //replace symbol , by %2C
    /*    if (typeof queries === 'object' && queries !== null) {
        for (const key in queries) {
            if (typeof queries[key] === 'string') queries[key] = queries[key];
        }
    } */
    if (typeof queries === 'object' && queries !== null) {
        for (const key in queries) {
            if (
                typeof queries[key] === 'string' &&
                !multipleUniquesColumns.some((x: any) => {
                    return x === key;
                })
            )
                queries[key] = queries[key].replace(/,/g, '%2C');
        }
    }
    if (queries) {
        return axiosInstance
            .get(`/uniques/${fileName}/${fields}`, {
                params: queries,
                ...config,
            })
            .then((data: AxiosResponse) => data?.data);
    } else {
        return axiosInstance
            .get(`/uniques/${fileName}/${fields}`, config)
            .then((data: AxiosResponse) => data?.data);
    }
}

export function getMasterFile(file: string, field: string, id: number) {
    return axiosInstance
        .get(`/masterfile/${file}/${field}/${id}`)
        .then((data: AxiosResponse) => data?.data);
}

export function getUserMe() {
    return axiosInstance.get(`/api/v1/users/me`).then((data: AxiosResponse) => data?.data);
}

export function Commas(x: string | number) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function getStoreProfileLInk() {
    const url = getEnv();
    return `${url.frontend}`;
}

export const merge = (groups?: string, body?: object[], params?: object) => {
    if (params) {
        let query_string = serializeObject(params);
        return axiosInstance
            .post(`/reports/merge/${groups}?${query_string}`, body)
            .then((data: AxiosResponse) => data?.data);
    } else {
        return axiosInstance
            .post(`/reports/merge/${groups}`, body)
            .then((data: AxiosResponse) => data?.data);
    }
};

export const queryV1 = (
    table: string,
    groups: string,
    query: object,
    params?: object,
    by: string = 'parquet'
) => {
    let encrypted_query = encryptText(JSON.stringify(query));

    const config = {
        headers: {
            'data-source': by,
        },
    };
    if (params) {
        let query_string = serializeObject(params);
        return axiosInstance
            .post(`/reports/query/v1/${table}/${groups}?${query_string}`, encrypted_query, config)
            .then((data: AxiosResponse) => data?.data);
    } else {
        return axiosInstance
            .post(`/reports/query/v1/${table}/${groups}`, encrypted_query, config)
            .then((data: AxiosResponse) => data?.data);
    }
};
export const queryV2 = (query: object, params?: object) => {
    let encrypted_query = encryptText(JSON.stringify(query));

    if (params) {
        let query_string = serializeObject(params);
        return axiosInstance
            .post(`/reports/query/v2?${query_string}`, encrypted_query)
            .then((data: AxiosResponse) => data?.data);
    } else {
        return axiosInstance
            .post(`/reports/query/v2`, encrypted_query)
            .then((data: AxiosResponse) => data?.data);
    }
};

export const exportData = (body: object) => {
    return axiosInstance.post(`/reports/excel`, body).then((data: AxiosResponse) => data?.data);
};

export const getToken = (body: object) => {
    return axiosInstance.post(`/api/token`, body).then((data: AxiosResponse) => data?.data);
};

export async function updateUsers(body: object) {
    return axiosInstance
        .post(`/administration/users`, body)
        .then((data: AxiosResponse) => data?.data);
}

export const getUserData = () => {
    return axiosInstance.get(`/api/v1/users/me/`).then((data: AxiosResponse) => data?.data);
};

export const OTPemail = (body: object) => {
    return axiosInstance
        .post(`/administration/users/passwordlessEmail/otp`, body)
        .then((data: AxiosResponse) => data?.data);
};

export const linkSendEmail = (body: object) => {
    return axiosInstance
        .post(`/administration/users/passwordlessEmail/link`, body)
        .then((data: AxiosResponse) => data?.data);
};

export const verifyEmail = (body: object) => {
    return axiosInstance.post(`/administration/authOptions`, body).then((data: AxiosResponse) => {
        if (data.status !== 401) return data?.data;
    });
};

export const verifyEmailAndPassword = (body: object) => {
    return axiosInstance
        .post(`/administration/login`, body)
        .then((data: AxiosResponse) => data?.data);
};

export const resetPassword = (body: object) => {
    return axiosInstance
        .post(`/administration/users/changePasswordOut`, body)
        .then((data: AxiosResponse) => data?.data);
};

export const emailResetPassword = (body: object) => {
    return axiosInstance
        .post(`/administration/users/changePasswordEmail`, body)
        .then((data: AxiosResponse) => data?.data);
};

export const changePassword = (body: object, id: string) => {
    return axiosInstance
        .post(`/administration/users/changePassword/${id}`, body)
        .then((data: AxiosResponse) => data?.data);
};

export const onChangeAgreements = async (value: any) => {
    return axiosInstance
        .post(`/administration/users/accepted-agreement`, value)
        .then((data: any) => data);
};

export const getUserPermissions = (params?: any) => {
    if (params) {
        let query_string = serializeObject(params);
        return axiosInstance
            .get(`/administration/users/me/permissions?${query_string}`)
            .then((data: AxiosResponse) => data?.data);
    } else {
        return axiosInstance
            .get(`/administration/users/me/permissions`)
            .then((data: AxiosResponse) => data?.data);
    }
};
export const groupData = (data: GroupDataProps, params?: object, by: string = 'parquet') => {
    //encrypted two queries and add as a body
    data.queries.forEach((query, index) => {
        data.queries[index].body = encryptText(JSON.stringify(query.body));
    });

    const config = {
        headers: {
            'data-source': by,
        },
    };
    if (params) {
        return axiosInstance
            .post(`/reports/group_data/${data.queries[0].kwargs.groups}`, data, {
                params: params,
                ...config,
            })
            .then((data: AxiosResponse) => data?.data);
    } else {
        return axiosInstance
            .post(`/reports/group_data/${data.queries[0].kwargs.groups}`, data, config)
            .then((data: AxiosResponse) => data?.data);
    }
};

//GLP Alerts

export async function getAlerts() {
    return axiosInstance.get(`/logistics/alerts`).then((data: AxiosResponse) => data?.data);
}

export async function getAlertsView() {
    return axiosInstance
        .get(`logistics/template/alertVisibility`)
        .then((data: AxiosResponse) => data?.data);
}

export async function getAlertsByCustomer(id: number) {
    return axiosInstance
        .get(`/logistics/alertByCustomer/${id}`)
        .then((data: AxiosResponse) => data?.data);
}

export async function getCustomer() {
    return axiosInstance.get(`/logistics/customers`).then((data: AxiosResponse) => data?.data);
}

export async function getGeneralCustomer() {
    return axiosInstance
        .get(`/logistics/customers/general`)
        .then((data: AxiosResponse) => data?.data);
}

export async function getRecipents() {
    return axiosInstance.get(`/logistics/recipents`).then((data: AxiosResponse) => data?.data);
}

export async function getRecipentsByCustomer(id: number) {
    return axiosInstance
        .get(`/logistics/customer/${id}/recipents`)
        .then((data: AxiosResponse) => data?.data);
}

export async function getGeneralRecipents() {
    return axiosInstance
        .get(`/logistics/customer/general/recipents`)
        .then((data: AxiosResponse) => data?.data);
}
export async function getTypeAlert() {
    return axiosInstance.get(`/logistics/type`).then((data: AxiosResponse) => data?.data);
}

export async function getRecipentsBySettings(type_id: number, customer_id: number) {
    return axiosInstance
        .get(`/logistics/setting/type/${type_id}/customer/${customer_id}/recipents`)
        .then((data: AxiosResponse) => data?.data);
}

export async function addRecipentToCustomer(customer_id: number, email: object) {
    return axiosInstance
        .post(`/logistics/customer/${customer_id}/recipent`, email)
        .then((data: AxiosResponse) => data?.data);
}

export async function deleteRecipent(email_id: number) {
    return axiosInstance
        .delete(`/logistics/recipents/${email_id}`)
        .then((data: AxiosResponse) => data?.data);
}
export async function deleteGeneralRecipent(email_id: number) {
    return axiosInstance
        .delete(`/logistics/customers/general/${email_id}/recipent`)
        .then((data: AxiosResponse) => data?.data);
}

export async function verifyDomain(customer_id: number, body: object) {
    return axiosInstance
        .get(`/logistics/customersDomains/${customer_id}`, { params: { ...body } })
        .then((data: AxiosResponse) => data?.data);
}

export async function addCustomerDomain(body: object) {
    return axiosInstance
        .post(`/logistics/customersDomains`, body)
        .then((data: AxiosResponse) => data?.data);
}

export async function postSetting(data: object) {
    return axiosInstance.post(`/logistics/setting`, data).then((data: AxiosResponse) => data?.data);
}

export async function patchSetting(data: object) {
    return axiosInstance
        .patch(`/logistics/setting`, data)
        .then((data: AxiosResponse) => data?.data);
}

export async function getTree() {
    // console.log("getTree",httpCommon,"token",token);

    return axiosInstance.get(`/administration/tree`).then((data) => data?.data);
}
export async function createPowerBiReport(body: any) {
    return axiosInstance.post(`/administration/views`, body).then((data: AxiosResponse) => {
        let result = {
            outcome: true,
            message: '',
        };
        if (data?.data != null || typeof data?.data != 'undefined') {
            result.message = data?.data.detail;
        } else {
            result.outcome = false;
            result.message = data.request.response;
        }
        return result;
    });
}

export async function updatePowerBI(id: number, body: any) {
    return axiosInstance.patch(`/administration/views/${id}`, body).then((data: AxiosResponse) => {
        let result = {
            outcome: true,
            message: '',
        };
        if (data?.data != null || typeof data?.data != 'undefined') {
            result.message = data?.data.detail;
        } else {
            result.outcome = false;
            result.message = data.request.response;
        }
        return result;
    });
}

export async function createCover(body: any) {
    return axiosInstance.post(`/administration/viewsType`, body).then((data: AxiosResponse) => {
        let result = {
            outcome: true,
            message: '',
        };
        if (data?.data != null || typeof data?.data != 'undefined') {
            result.message = data?.data.detail;
        } else {
            result.outcome = false;
            result.message = data.request.response;
        }
        return result;
    });
}
export async function checkIdExists(id: string) {
    return axiosInstance.get(`/administration/viewsType/${id}`).then((data) => data?.data);
}
export async function createSubView(body: any) {
    return axiosInstance.post(`/administration/subViews`, body).then((data: AxiosResponse) => {
        let result = {
            outcome: true,
            message: '',
        };
        if (data?.data != null || typeof data?.data != 'undefined') {
            result.message = data?.data.detail;
        } else {
            result.outcome = false;
            result.message = data.request.response;
        }
        return result;
    });
}

export async function getFavorites() {
    return axiosInstance.get(`/administration/favorite`).then((data) => data?.data);
}
export async function addFavorite(body: any) {
    return axiosInstance.post(`/administration/favorite`, body).then((data) => data?.data);
}
export async function deleteFavorite(id: number) {
    return axiosInstance
        .delete(`/administration/favorite/${id}`)
        .then((data: AxiosResponse) => data?.data);
}
export async function getRefresh(view_id: number) {
    return axiosInstance.get(`/reports/reports/powerbi/${view_id}`).then((data) => data?.data);
}
export async function getRefreshStatus(view_id: number) {
    return axiosInstance
        .get(`/reports/reports/powerbi/${view_id}/status`)
        .then((data: AxiosResponse) => data?.data);
}

/* POST ACTIONS LOGS */
export async function postActionLogs(view_id: any, view_name: string = '') {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let payload: any = {
        view: view_id,
        timezone: timezone,
    };
    if (view_name !== '') {
        payload = { ...payload, view_name: view_name };
    }
    return axiosInstance
        .post(`/administration/actionlog`, payload)
        .then((data: AxiosResponse) => data?.data);
}

export async function showStatus(view_id: any) {
    return axiosInstance
        .get(`/administration/changeShow/${view_id}`)
        .then((data: AxiosResponse) => data?.data);
}
export const reSendAlertEmail = async (id: any) => {
    return axiosInstance
        .post(`/logistics/reSendAlertEmail/${id}`, {
            id: id,
        })
        .then((data: AxiosResponse) => data?.data);
};
export const createSendAlertEmail = async (obj: any) => {
    return axiosInstance
        .post(`/logistics/sendAlert`, {
            ...obj,
        })
        .then((data: AxiosResponse) => data?.data);
};
export const getGitbookToken = async () => {
    return axiosInstance.get(`/administration/gitbook/token`).then((data: any) => data?.data.token);
};

/* NOTIFICATIONS ENDPOINTS */
export const getNotifications = async () => {
    return axiosInstance.get(`/administration/notifications`).then((data: any) => data?.data);
};
export const getNotificationSchema = async (url: any) => {
    return axios.get(url).then((data: any) => data?.data);
};
export const renderNotificationSchema = async (data: any) => {
    return axiosInstance
        .post(`/administration/notification/render`, data, { responseType: 'text' })
        .then((data: any) => data?.data);
};
export const setNotificationAsRead = async (id: Number) => {
    return axiosInstance
        .post(`/administration/notification/${id}/read`)
        .then((data: any) => data?.data);
};

export async function updateDomain(id: any, params: any) {
    try {
        return axiosInstance
            .patch(`/administration/authorizedEmails/${id}`, { ...params })
            .then((data: AxiosResponse) => data?.data);
    } catch (e) {
        console.error(e);
    }
}
export async function createDomain(body: any) {
    return axiosInstance
        .post(`/administration/authorizedEmails`, body)
        .then((data: AxiosResponse) => data?.data);
}

export async function deleteDomain(id: any) {
    console.log(id);
    return axiosInstance
        .delete(`/administration/authorizedEmails/${id}`)
        .then((data: AxiosResponse) => data?.data);
}

export async function updateCompany(id: any, params: any) {
    try {
        return axiosInstance
            .patch(`/administration/companies/${id}`, { ...params })
            .then((data: AxiosResponse) => data?.data);
    } catch (e) {
        console.error(e);
    }
}
export async function createCompany(body: any) {
    return axiosInstance
        .post(`/administration/companies`, body)
        .then((data: AxiosResponse) => data?.data);
}

export async function deleteCompany(id: any) {
    console.log(id);
    return axiosInstance
        .delete(`/administration/companies/${id}`)
        .then((data: AxiosResponse) => data?.data);
}

export async function updateCountry(id: any, params: any) {
    try {
        return axiosInstance
            .patch(`/administration/countries/${id}`, { ...params })
            .then((data: AxiosResponse) => data?.data);
    } catch (e) {
        console.error(e);
    }
}

export async function createCountry(body: any) {
    return axiosInstance
        .post(`/administration/countries`, body)
        .then((data: AxiosResponse) => data?.data);
}
export async function deleteCountry(id: any) {
    console.log(id);
    return axiosInstance
        .delete(`/administration/countries/${id}`)
        .then((data: AxiosResponse) => data?.data);
}

/* PASSWORD POLICIES */
export const getPasswordPolicies = async () => {
    return axiosInstance.get(`/administration/passwordPolicies`).then((data: any) => data?.data);
};

export const updateParent = (body: object) => {
    return axiosInstance
        .patch('/administration/viewParent/update-parent', body)
        .then((data: AxiosResponse) => data?.data);
};

export const updateOrderOfViews = (body: object) => {
    return axiosInstance
        .patch(`/administration/views/update-order`, body)
        .then((data: AxiosResponse) => data?.data);
};

export async function unlinkView(body: any) {
    return axiosInstance
        .delete(`/administration/link-unlink-views`, {
            data: {
                ...body,
            },
        })
        .then((data: AxiosResponse) => data?.data);
}

export async function linkView(body: object) {
    return axiosInstance
        .post(`/administration/link-unlink-views`, {
            data: {
                ...body,
            },
        })
        .then((data: AxiosResponse) => data?.data);
}

export async function renameView(body: object, id: any) {
    console.log('body', body);
    return axiosInstance
        .post(
            `administration/rename-view/${id}`,

            body
        )
        .then((data: AxiosResponse) => data?.data);
}

export async function changeShowProperty(body: object, id: any) {
    return axiosInstance
        .post(
            `administration/update-show-view/${id}`,

            body
        )
        .then((data: AxiosResponse) => data?.data);
}
