// import { useEffect } from 'react';
import stylesC from './map.module.scss';
import { GoogleMap, useLoadScript, InfoWindow } from '@react-google-maps/api';
import { Loader } from '../loader/loader';
import { useEffect, useMemo, useState, useRef } from 'react';

//import classNames from 'classnames';

export interface MapProps {
    className?: string;
    title?: string;
    data_chart: any;
    center_map?: any;
    zoom?: any;
    customTitle?: any;
    children?: any;
    setIsLoadedMarkets?: any;
    isLoaddedMarkets?: boolean;
    touch?: boolean;
}

export const Map = ({
    className,
    title = '',
    center_map = null,
    zoom = null,
    customTitle = false,
    children,
    setIsLoadedMarkets,
    isLoaddedMarkets,
    touch,
}: MapProps) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyB1QRJq3dL5TrbDlCaHjTFbbFrs2GO6MRg',
    });
    const [currentCenter, setCurrentCenter] = useState(center_map);
    const [storedCenter, setStoredCenter] = useState(center_map);
    const mapRef = useRef<google.maps.Map | null>(null);

    console.log("mapRef", mapRef)

    const handleMapDrag = () => {
        // Update current center when map is dragged
        setCurrentCenter(mapRef.current?.getCenter());
    };

    const handleMapZoomChange = () => {
        // Update current center when map zoom changes
        setCurrentCenter(mapRef.current?.getCenter());
    };
    useEffect(() => {
        if (isLoaded) {
            mapRef.current?.addListener('dragend', handleMapDrag);
            mapRef.current?.addListener('zoom_changed', handleMapZoomChange);
        }
        return () => {
            if (mapRef.current) {
                google.maps.event.clearListeners(mapRef.current, 'dragend');
                google.maps.event.clearListeners(mapRef.current, 'zoom_changed');
            }
        };
    }, [isLoaded]);
    // const isLoaded = true;
    // const center = useMemo(() => ({ lat: center_map ? center_map.lat : 15.00000, lng: center_map ? center_map.lng : -73.000000 }), []);

    useEffect(() => {
        if (isLoaded) if (setIsLoadedMarkets) setIsLoadedMarkets(true);
    }, [isLoaded]);
    useEffect(() => {}, [children]);
    // useEffect(() => {
    //     if(center_map)
    //     center = { lat: center_map ? center_map.lat : 15.00000, lng: center_map ? center_map.lng : -73.000000 };
    // }, [center_map]);
    // console.log("Data in map", center,"center_map",center_map, "zoom", zoom)

    // console.log("data in map",data_chart)

    // this is what the exported json gives you
    const styles = [
        {
            elementType: 'geometry',
            stylers: [
                {
                    color: '#ebe3cd',
                },
            ],
        },
        {
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#523735',
                },
            ],
        },
        {
            elementType: 'labels.text.stroke',
            stylers: [
                {
                    color: '#f5f1e6',
                },
            ],
        },
        {
            featureType: 'administrative',
            elementType: 'geometry.stroke',
            stylers: [
                {
                    color: '#c9b2a6',
                },
            ],
        },
        {
            featureType: 'administrative.land_parcel',
            elementType: 'geometry.stroke',
            stylers: [
                {
                    color: '#dcd2be',
                },
            ],
        },
        {
            featureType: 'administrative.land_parcel',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#ae9e90',
                },
            ],
        },
        {
            featureType: 'landscape.natural',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#e0e4ed',
                },
            ],
        },
        {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#e0e4ed',
                },
            ],
        },
        {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#93817c',
                },
            ],
        },
        {
            featureType: 'poi.park',
            elementType: 'geometry.fill',
            stylers: [
                {
                    color: '#a5b076',
                },
            ],
        },
        {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#447530',
                },
            ],
        },
        {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#f5f1e6',
                },
            ],
        },
        {
            featureType: 'road.arterial',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#fdfcf8',
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#f8c967',
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [
                {
                    color: '#e9bc62',
                },
            ],
        },
        {
            featureType: 'road.highway.controlled_access',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#e98d58',
                },
            ],
        },
        {
            featureType: 'road.highway.controlled_access',
            elementType: 'geometry.stroke',
            stylers: [
                {
                    color: '#db8555',
                },
            ],
        },
        {
            featureType: 'road.local',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#806b63',
                },
            ],
        },
        {
            featureType: 'transit.line',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#e0e4ed',
                },
            ],
        },
        {
            featureType: 'transit.line',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#8f7d77',
                },
            ],
        },
        {
            featureType: 'transit.line',
            elementType: 'labels.text.stroke',
            stylers: [
                {
                    color: '#ebe3cd',
                },
            ],
        },
        {
            featureType: 'transit.station',
            elementType: 'geometry',
            stylers: [
                {
                    color: '#e0e4ed',
                },
            ],
        },
        {
            featureType: 'water',
            elementType: 'geometry.fill',
            stylers: [
                {
                    color: '#ffffff',
                },
            ],
        },
        {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#92998d',
                },
            ],
        },
    ];

    const bounds = {
        north: 90,
        south: -90,
        east: 180,
        west: -180,
    };


    //("children", children);
    const memoized = useMemo(() => {
        let centerMap = center_map;
        if (touch == true) {
            centerMap = undefined;
        } else {
            centerMap = center_map;
        }

        return (
            <GoogleMap
                mapContainerClassName={stylesC[className!]}
                center={centerMap}
                zoom={zoom}
                options={{
                    styles,
                    mapTypeControl: false,
                    restriction: {
                        latLngBounds: bounds,
                        strictBounds: true, // Enforce bounds strictly
                    },
                    minZoom: 3.0, // Adjust this to your needs
                }}
                onLoad={(map: any) => (mapRef.current = map)}
            >
                {isLoaddedMarkets && children ? children : null}
            </GoogleMap>
        );
    }, [center_map, children]);

    return (
        <div className={stylesC[className!]}>
            {customTitle ? (
                <div className={stylesC.customTitle}>
                    <h2>{customTitle}</h2>
                </div>
            ) : (
                ''
            )}
            {!isLoaded ? (
                <h1>
                    <Loader></Loader> {title}
                </h1>
            ) : (
                memoized
            )}
        </div>
    );
};
