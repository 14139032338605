import { useEffect, useState } from 'react';
import { getAlertsView } from '../../services/python-services';
import styles from './glp-alerts-embeded.module.scss';
import classNames from 'classnames';

export interface GlpAlertsEmbededProps {
    className?: string;
    name?: string;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const GlpAlertsEmbeded = ({ className, name }: GlpAlertsEmbededProps) => {
    const url = "http://localhost:3000/glp_v2/glp_ale";
    const [html, setHtml] = useState<any>();
    const getHtmlData = async () => {
        const data = await getAlertsView();
        setHtml(`${data}`);

    }
    useEffect(() => {
        getHtmlData();
    }, []);

    return <div className={classNames(styles.rootAlerts, className)}>
        <h1 className={styles.title}>{name}</h1>
        <div> <div dangerouslySetInnerHTML={{ __html: html }} /></div>
    </div>;
};
