import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import styles from './modal.module.scss';
import classNames from 'classnames';
import ClickAwayListener from 'react-click-away-listener';

export interface ModalProps {
    className?: string;
    closeDialog?: any;
    name?: string;
    isMultiple?: boolean;
    isClosable?: boolean;
    domain?: string;
    costumerDomain?: string;
    onReject?: () => void;
    onAccept?: () => void;
}

export const Modal = ({
    className,
    closeDialog,
    name = 'generic-dialog',
    isMultiple = false,
    isClosable = true,
    domain,
    costumerDomain,
    onAccept,
    onReject,
}: ModalProps) => {
    const handleClose = () => {
        !isMultiple ? closeDialog() : closeDialog(name);
    };
    const closeDialogClickOut = (e: any) => {
        if (e.target.id === name && isClosable) {
            const dialog = document.getElementById(name);
            //@ts-expect-error
            if (dialog) dialog?.close();
            handleClose();
        }
    };
    return (
        <ClickAwayListener onClickAway={(e: any) => closeDialogClickOut(e)}>
            <dialog
                id={name}
                className={classNames(styles['' + className]) + ' modal'}
                onClick={(e: any) => closeDialogClickOut(e)}
                style={{ borderRadius: '10px' }}
            >
                <div id="contentModal" className={styles.contentModal}>
                    <div className={styles.topDiv}>
                        <div className={styles.redIcon}>
                            <ExclamationTriangleIcon className="w-10 h-10 text-red-500" />
                        </div>
                        <div className={styles.modalTitle}>Adding new domain</div>
                        <div className={styles.modalMessage}>
                            You are trying to add an email with a domain (
                            <strong className={styles.redHighlight}>{domain}</strong>) that is not
                            valid for{' '}
                            <strong className={styles.redHighlight}>{costumerDomain}</strong>
                            <br />
                            Are you sure you want to add this domain for{' '}
                            <strong className={styles.redHighlight}>{costumerDomain}</strong> ?
                        </div>
                    </div>
                    <div className={styles.buttonGroup}>
                        <div className={styles.leftButton} onClick={onReject}>
                            No, Don't add it.
                        </div>
                        <div className={styles.rightButton} onClick={onAccept}>
                            Yes, Add it!
                        </div>
                    </div>
                </div>
            </dialog>
        </ClickAwayListener>
    );
};
