import classNames from 'classnames';
import { Table } from '../table/table';
import { Outlet, Link } from 'react-router-dom';
import { useContext, useState } from 'react';
import { getAllRoles } from '../../services/python-services';
import { useEffect } from 'react';
import { Spinner } from 'flowbite-react';
import { CustomButtonGroup } from '../../utils/interfaces';
import { deleteRoles } from '../../services/python-services';
import styles from './roles-table.module.scss';
import Notiflix from 'notiflix';
import { createUser } from '../../services/python-services';
import { TextInput } from 'flowbite-react';
import { Button } from '../button/button';
import { GenericModal } from '../generic-modal/generic-modal';
import { isValidString, viewInArrayViews } from '../../utils/functions';
import { RMContext } from '../../context/RMContext';
export interface RolesTableProps {
    className?: string;
    idsArray?: number[];
}
interface Row {
    id: number;
    // Add other properties as needed
}
/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const RolesTable = ({ className }: RolesTableProps) => {
    const [row, setRow] = useState();
    const [allRows, setAllRows] = useState<Row[]>([]);
    const [idsArray, setIdsArray] = useState<number[]>([]);
    const [checkAllSwitch, setCheckAllSwitch] = useState(false);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const currentView = window.location.href.split('/').slice(-1)[0];
    const { sitemap } = useContext(RMContext).userData;
    async function initialDataCall() {
        setLoading(true);
        const resData: any = await getAllRoles();
        await setAllRows(resData);
        viewInArrayViews(currentView, sitemap);
        setLoading(false);
    }

    async function refetchData() {
        try {
            setLoading(true); // Set loading to true before the API call
            const resData: any = await getAllRoles();
            await setAllRows(resData);
        } catch (error) {
            // Handle any errors that occur during the API call
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false); // Set loading to false after the API call (even if it fails)
            Notiflix.Notify.success('Role Removed Successfully');
        }
    }

    useEffect(() => {
        initialDataCall();
    }, []);

    async function handleChangeElement(e: any) {
        await setValue(e.target.value);
    }

    async function validateRole(value: any) {
        if (isValidString(value)) {
            setOpenModal(false);
            Notiflix.Confirm.show(
                'Create a new role confirmation',
                'Are you sure you want to create a new role?',
                'Accept',
                'Cancel',
                confirCreateRole
            );
        } else {
            handleCloseModal();
            Notiflix.Notify.failure('Please add a valid value to add a role!');
        }
    }
    async function confirCreateRole() {
        let body = {
            role_name: value,
            status: true,
            countries: [],
            accounts: [],
            divisions: [],
            views: [],
        };
        await createUser(body);
        await initialDataCall();
    }

    const getExample = () => {
        return (
            <div>
                {/* <label htmlFor="">Create New Role</label> */}
                <TextInput
                    sizing={'sm'}
                    type={'text'}
                    id={'select_value'}
                    className="w-full"
                    style={{ backgroundColor: '#fff', height: '40px', fontSize: '16px' }}
                    placeholder=""
                    value={value}
                    onChange={(e) => handleChangeElement(e)}
                />
                <div className={classNames(styles.rootz)}>
                    <Button
                        click={() => validateRole(value)}
                        styleProp="inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex gap-1 items-center mt-[5px]"
                        style={{ width: '76px' }}
                        className="submit-button"
                    >
                        Add Role
                    </Button>
                    <Button
                        click={() => handleCloseModal()}
                        styleProp="inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex gap-1 items-center mt-[5px]"
                        className="submit-button"
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        );
    };

    const selectAll = () => {
        let idsTmp: number[] = [...idsArray];
        if (checkAllSwitch === false) {
            const holder1: number[] = allRows.map((x: Row) => x.id);
            idsTmp.push(...holder1);
            setCheckAllSwitch(true);
        } else {
            idsTmp = [];
            setCheckAllSwitch(false);
        }
        setIdsArray([...idsTmp]);
    };

    const selectId = (id: number) => {
        let idsTmp = [...idsArray];
        if (idsTmp.includes(id)) {
            idsTmp = idsTmp.filter((i: number) => i !== id);
        } else {
            idsTmp.push(id);
        }
        setIdsArray([...idsTmp]);
    };

    let customButton: CustomButtonGroup[] = [
        {
            onClick: async () => {
                await setLoading(true);
                let idsTmp = [...idsArray];
                for (const idToDelete of idsTmp) {
                    await deleteRoles(idToDelete);
                }
                await setIdsArray([]);
                await refetchData(); // Fetch new data
                await setLoading(false);
            },
            children: (
                <div className="flex gap-2">
                    Disable
                    {loading ? <Spinner size="sm" /> : <></>}
                </div>
            ),
            styleProp:
                'inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex gap-1 items-center',
        },
        {
            onClick: () => {
                setIdsArray([]);
            },
            children: <div>Cancel</div>,
            styleProp:
                'inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex gap-1 items-center',
        },
    ];

    useEffect(() => {
        if (openModal) {
            handleOpenModal();
        } else {
            handleCloseModal();
        }
    }, [openModal]);

    const handleCloseModal = () => {
        setValue('');
        setOpenModal(false);
        const dialog = document.getElementById('add-role');
        //@ts-expect-error
        if (dialog) dialog.close();
    };

    const handleOpenModal = () => {
        setOpenModal(true);
        const dialog = document.getElementById('add-role');
        //@ts-expect-error
        if (dialog) dialog.showModal();
    };

    const addRole: CustomButtonGroup[] = [
        {
            onClick: () => {
                handleOpenModal();
            },
            children: <div>Add Role</div>,
            styleProp:
                'inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex gap-1 items-center',
        },
    ];

    let total = allRows.length;

    return (
        <div className={classNames(className)}>
            <Table
                title="Roles"
                columns={[
                    {
                        name: 'checkbox',
                        prop: 'id',
                        open: false,
                        show: true,
                        id: 'id',
                        style: { maxWidth: '50px', minWidth: '50px' },
                    },
                    {
                        name: 'ID',
                        prop: 'id',
                        open: false,
                        show: true,
                        id: 1,
                        style: { maxWidth: '50px', minWidth: '50px' },
                    },
                    { name: 'Role', prop: 'role_name', show: true, open: false, id: 69 },
                    {
                        name: 'Countries',
                        prop: 'country_codes_str',
                        open: false,
                        show: true,
                        id: 2,
                    },
                    { name: 'Divisions', prop: 'divisions_str', open: false, show: true, id: 3 },
                    {
                        name: 'Countries MENU',
                        prop: '8',
                        open: false,
                        show: true,
                        formatting: (v, r: any) => {
                            if (typeof v !== 'string') console.log(v, typeof v);
                            return (
                                <Link
                                    className={styles.forMenu}
                                    onClick={() => setRow(r)}
                                    to={`${r.id}/countries/`}
                                >
                                    Visit Countries Menu
                                </Link>
                            );
                        },
                        id: 10,
                    },
                    {
                        name: 'Divisions MENU',
                        prop: '9',
                        open: false,
                        show: true,
                        formatting: (v, r: any) => {
                            if (typeof v !== 'string') console.log(v, typeof v);
                            return (
                                <Link
                                    className={styles.forMenu}
                                    onClick={() => setRow(r)}
                                    to={`${r.id}/divisions/`}
                                >
                                    Visit Divisions Menu
                                </Link>
                            );
                        },
                        id: 10,
                    },
                    {
                        name: 'Views MENU',
                        prop: '10',
                        open: false,
                        show: true,
                        formatting: (v, r: any) => {
                            if (typeof v !== 'string') console.log(v, typeof v);
                            return (
                                <Link
                                    className={styles.forMenu}
                                    onClick={() => setRow(r)}
                                    to={`${r.id}/roles-tree-view/`}
                                >
                                    Visit Views Menu
                                </Link>
                            );
                        },
                        id: 10,
                    },
                    {
                        name: 'MEMBERS',
                        prop: '11',
                        open: false,
                        show: true,
                        formatting: (v, r: any) => {
                            if (typeof v !== 'string') console.log(v, typeof v);
                            return (
                                <Link
                                    className={styles.forMenu}
                                    onClick={() => setRow(r)}
                                    to={`${r.id}/members/`}
                                >
                                    Visit Members
                                </Link>
                            );
                        },
                        id: 11,
                    },
                ]}
                data={allRows}
                fetchData={initialDataCall}
                haveMoreItems={false}
                updateData={function (): void {
                    throw new Error('Function not implemented.');
                }}
                count={total}
                searchText=""
                columnsButton={true}
                resetButton={false}
                isSearch={false}
                allColumnsStyle="w-full"
                selectId={selectId}
                selectAll={selectAll}
                idsArray={idsArray}
                customButton={idsArray.length > 0 ? customButton : undefined}
                addRole={addRole}
                openModal={openModal}
                setOpenModal={setOpenModal}
                isLoadingData={loading}
            />
            {
                <GenericModal
                    className="add-role"
                    closeDialog={handleCloseModal}
                    haveComponent={true}
                    getComponent={getExample()}
                    name={'add-role'}
                    title="Create New Role"
                ></GenericModal>
            }
            <Outlet />
        </div>
    );
};
