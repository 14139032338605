import { useEffect, useMemo, useState } from 'react';
import { queries_glp_gatun, size_page } from '../../utils/constants';
import styles from './gatun-water-level.module.scss';
import classNames from 'classnames';
import { Column, GLPGatun, GroupDataWithTypesProps } from '../../utils/interfaces';
import { groupData, queryV1 } from '../../services/python-services';
import { Table } from '../table/table';
import { addDayToDateString, formatterStringToDate, handleCloseModalById, handleOpenModalById, reduceColumnsFunction, reduceColumnTypesFunction } from '../../utils/functions';
import { DateRangePicker } from '../date-range-picker/date-range-picker';
import { GenericModal } from '../generic-modal/generic-modal';
import { Button } from '../button/button';
import { CalendarDaysIcon } from '@heroicons/react/24/outline';

export interface GatunWaterLevelProps {
    className?: string;
}

export const GatunWaterLevel = ({ className }: GatunWaterLevelProps) => {
    const [loadingMainData, setLoadingMainData] = useState(true);
    const [downLoader, setDownLoader] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState(false);
    const nameOfmodal = 'edit-dateRangePicker';
    const initialUniquesValues = {
        page: 1,
        size: size_page,
        date_log: null,
        sort_by: null,
        searchGlp: null,
    };
    const [queries, setQueries] = useState<GLPGatun>(initialUniquesValues);

    const [searchText, setSearchText] = useState('');
    const [applySearch, setApplySearch] = useState(true);
    const [executeApply, setExecuteApply] = useState(true);

    const initialTableState: any[] = [];
    const [mainTableData, setMainTableData] = useState(initialTableState);
    const [totalDatas, setTotalDatas] = useState(0);

    //Pagination
    const [page, setPage] = useState(1);
    const [isLoadingDataScroll, setIsLoadingDataScroll] = useState(false);
    const [moreItems, setMoreItems] = useState(true);
    //soring
    const [sortBy, setSortBy] = useState('');

    const initialDateRangeState = { startDate: '', endDate: '' };
    const [dateRange, setDateRange] = useState<any>(initialDateRangeState);
    const [isReset, setIsReset] = useState(false);

    const getNewQueyWithFilter = (query_C: any, isTotal = false, isExport=false) => {
        let query: any = { ...query_C }
        let data = [...query.data];
        if (!isTotal) {
            let sort = {};
            if (sortBy === '') {
                sort = { by: 'date_log', descending: true };
                data[0] = { ...data[0], sort: sort };
            }
            query = { ...query, data: data };
        }
        if ((applySearch || isExport) && dateRange.startDate !== "" && dateRange.endDate !== "") {
            // Spread the array to create a copy
            let filters = `( date_log >=  Date '${!isExport?addDayToDateString(dateRange.startDate,0):addDayToDateString(dateRange.startDate,0)}' and date_log <=  Date '${!isExport?addDayToDateString(dateRange.endDate,0):addDayToDateString(dateRange.endDate,0)}')`;


            if (isTotal) {
                const select = [...query.data[0].select];
                let filters = `(date_log >=  Date '${dateRange.startDate}' and date_log <=  Date '${dateRange.endDate}')`;

                data[0] = { filter: filters, select: [...select] };
            } else {
                data[0] = { ...data[0], filter: filters };
            }

            query = { ...query, data: data };
            if (!isTotal) setPage(1);
        }
        return query;
    };
    const getMainData = async () => {
        let newQueiries = {
            ...queries,
            page: 1,
            size: size_page,
        };
        let query: any = { ...queries_glp_gatun.mainTable.query };
        query = getNewQueyWithFilter(query);

        const response = await queryV1(
            queries_glp_gatun.mainTable.table,
            queries_glp_gatun.mainTable.groupsBy,
            query,
            newQueiries,
            'athena'
        );
        if (response && response.data) {
            const newData = response.data?.map((row: any, index: number) => {
                return { ...row, index: index };
            });
            setMainTableData(newData);
        } else {
            setMainTableData([]);
        }
    };
    const getTotalDatas = async () => {
        let filter_queries = { ...queries };
        filter_queries.sort_by = '';
        delete filter_queries.page;
        delete filter_queries.size;

        let query: any = { ...queries_glp_gatun.totalMainTable.query };
        query = getNewQueyWithFilter(query, true);
        const response = await queryV1(
            queries_glp_gatun.totalMainTable.table,
            queries_glp_gatun.totalMainTable.groupsBy,
            query,
            filter_queries,
            'athena'
        );
        if (response) {
            setTotalDatas(response?.data[0].totals);
            if (parseInt(response?.data[0].totals) <= mainTableData.length) {
                setMoreItems(false);
            } else {
                setMoreItems(true);
            }
        }
        setLoadingMainData(false);
    };

    useEffect(() => {
        if (applySearch) {
            getTotalDatas();
            getMainData();
            setApplySearch(false);
        }
    }, [applySearch]);

    const fetchData = async (isScroll: boolean = false) => {
        // avoid multiple call to the fetchData
        // console.log("fetchData", isScroll, loadingMainData, applySearch, page)
        if (!isReset) {
            if (loadingMainData) {
                return;
            }
            const updatedQueries = {
                ...queries,
                page: page + 1,
                size: size_page,
                sort_by: sortBy !== '' ? sortBy : 'date_log,desc',
            };
            setQueries(updatedQueries);
            setIsLoadingDataScroll(isScroll);
            setLoadingMainData(true); // Establecer isLoadingData a true antes de la solicitud

            let query: any = { ...queries_glp_gatun.mainTable.query };
            if (applySearch && dateRange.startDate !== '' && dateRange.endDate !== '') {
                let data = [...query.data]; // Spread the array to create a copy
                let filters = `( date_log >=  Date '${addDayToDateString(dateRange.startDate,0)}' and date_log <=  Date '${addDayToDateString(dateRange.endDate,0)}')`;
                data[0] = { ...data[0], filter: filters };
                query = { ...query, data: data };
                setPage(1);
            }
            try {
                // make and run request
                const response = await queryV1(
                    queries_glp_gatun.mainTable.table,
                    queries_glp_gatun.mainTable.groupsBy,
                    query,
                    updatedQueries,
                    'athena'
                );
                // update data and state variables
                const newDataToAdd = response.data?.map((row: any, index: number) => {
                    return { ...row, index: index + page * size_page };
                });
                const newData: any[] = [...mainTableData, ...newDataToAdd];
                setMainTableData(isScroll ? newData : response.data);
                if (newData.length >= totalDatas) {
                    setMoreItems(false);
                } else {
                    setPage((prev) => prev + 1);
                }
                setExecuteApply(false);
                setApplySearch(false);

                // Reset loading states
                setLoadingMainData(false);
                setIsLoadingDataScroll(false);
            } catch (error) {
                // Reset loading states
                setIsLoadingDataScroll(false);
                setLoadingMainData(false);
            }
        }
    };
    const updateData = (newData: any) => {
        setMainTableData([]);
        setMainTableData(newData);
    };
    const resetFilters = (nameComponent: string = 'filter') => {
        //clearValues();
        setIsReset(true);
        if (nameComponent === 'table') {
            // setClosedFilters(true);
        }
        const updatedQueries = {
            page: 1,
            size: size_page,
            date_log: null,
            sort_by: null,
        };
        setSortBy('');
        setSearchText('');
        setPage(1);
        setQueries(updatedQueries);
        setApplySearch(true);
        setLoadingMainData(true);
        setExecuteApply(true);
        setDateRange(initialDateRangeState);
    };
    const mainColumns: Column[] = [
        {
            id: 'date_log',
            name: 'Date log',
            prop: 'date_log',
            open: false,
            show: true,
            type: 'date',
            style: { width: '100%' },
            formatting: (v: any) => {
                return <div>{formatterStringToDate(v)}</div>;
            },
        },
        {
            id: 'gatun_water_level',
            name: 'Gatun Water Level ',
            prop: 'gatun_water_level',
            open: false,
            show: true,
            style: { width: '100%' },
            formatting: (v: any) => <div>{v}</div>,
        },
        {
            id: 'max_neopanamax_draft_ft',
            name: 'Max Neopanamax Draft Ft',
            prop: 'max_neopanamax_draft_ft',
            open: false,
            show: true,
            style: { width: '100%' },
        },
        {
            id: 'max_panamax_draft_ft',
            name: 'Max Panamax Draft Ft',
            prop: 'max_panamax_draft_ft',
            open: false,
            show: true,
            formatting: (v: any) => <div>{v}</div>,
            style: { width: '100%' },
        },
        {
            id: 'surcharge_pcent',
            name: 'Surcharge Pcent',
            prop: 'surcharge_pcent',
            open: false,
            show: true,
            style: { width: '100%' },
            formatting: (v: any) => <div>{v} %</div>,
        },
    ];
    const clearValues = () => {
        setMainTableData([]);
        setTotalDatas(0);
        setPage(1);
        setSearchText('');
    };

    const searchData = (type: any) => {
        setApplySearch(true);
        setLoadingMainData(true);
        type = type.replace(/\s+/g, ' ').trim();
        setSearchText(type);
        if (type === '') {
            clearValues();
            setQueries({ ...queries, searchGlpGatun: null });
        } else if (type.length > 2) {
            clearValues();
            setQueries({ ...queries, searchGlpGatun: type.toUpperCase() });
        }
    };
    const sortData = (type: any) => {
        setLoadingMainData(true);
        setApplySearch(true);
        setPage(1);
        if (type.name === 'Descending') {
            setSortBy(`${type.prop},desc`);
            setQueries({
                ...queries,
                page: 1,
                size: size_page * 1,
                sort_by: `${type.prop},desc`,
            });
        } else {
            setSortBy(`${type.prop}`);
            setQueries({ ...queries, page: 1, size: size_page * 1, sort_by: `${type.prop}` });
        }
        //setPage(1);
    };
    const handleDateRangeSave = (range: { startDate: string; endDate: string }) => {
        let dates = { ...range };
        dates.startDate = addDayToDateString(dates.startDate, 2);
        dates.endDate = addDayToDateString(dates.endDate, 2);
        setDateRange(dates);
        setLoadingMainData(true);
        setApplySearch(true);
        handleCloseModal();
    };

    const handleOpenModal = () => {
        handleOpenModalById(setOpenModal, nameOfmodal);
    };
    const handleCloseModal = () => {
        handleCloseModalById(setOpenModal, nameOfmodal);
    };
    const getDateRangeComponent = () => {
        return (
            <>
                <DateRangePicker
                    onSave={handleDateRangeSave}
                    reset={isReset}
                    setIsReset={setIsReset}
                />
            </>
        );
    };
    const headerFilterCalendar = () => {
        return (
            <div>
                <Button
                    key={'bt-reset'}
                    click={handleOpenModal}
                    styleProp="inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex gap-1 items-center"
                >
                    <CalendarDaysIcon className="h-4 w-4" /> Filter by Date
                </Button>
            </div>
        );
    };
    const exportExcel = async (columns: any) => {
        setDownLoader(true);
        setApplySearch(true);
        const col = reduceColumnsFunction(columns);
        const column_types = reduceColumnTypesFunction(columns);
        let body: any = {...queries_glp_gatun.mainTable.query};
        body = getNewQueyWithFilter(body, false, true);
        delete body.data[0].offset;
        delete body.data[0].limit;

        const payloadGroupData: GroupDataWithTypesProps = {
            queries: [
                {
                    service: 'queryV1',
                    kwargs: {
                        groups: queries_glp_gatun.mainTable.groupsBy,
                        name: queries_glp_gatun.mainTable.table,
                    },
                    body: body
                },
            ],
            export: {
                columns: col,
                column_types: column_types,
                format: 'xlsx',
            },
        };
        const export_query = { ...queries };
        delete export_query.sort_by;
        delete export_query.page;
        delete export_query.size;
        const res = await groupData(payloadGroupData, export_query, 'athena');
        window.open(res['url']);
        setDownLoader(false);
    };
    const MemoizedTable = useMemo(() => {
        return (
            <div className={styles['mainTable']}>
                <Table
                    isSearch={true}
                    searchFunction={searchData}
                    searchText={searchText}
                    sortFunction={sortData}
                    columnsButton={true}
                    resetButton={true}
                    columns={mainColumns}
                    data={mainTableData}
                    count={totalDatas}
                    exportLoader={downLoader}
                    fetchData={fetchData}
                    title="Gatun Water Level"
                    exportData={exportExcel}
                    haveMoreItems={moreItems}
                    updateData={updateData}
                    isLoadingData={loadingMainData}
                    isLoadingDataScroll={isLoadingDataScroll}
                    allColumnsStyle="w-full"
                    activeCompactMode={true}
                    onReset={resetFilters}
                    hasAdditionalHeader={true}
                    additionalHeader={headerFilterCalendar}
                />
            </div>
        );
    }, [
        searchData,
        searchText,
        sortData,
        mainColumns,
        mainTableData,
        totalDatas,
        // downLoader,
        // fetchData,
        // exportExcel,
        moreItems,
        updateData,
        loadingMainData,
        // isLoadingDataScroll,
        resetFilters,
        clearValues,
    ]);

    return (
        <div className={classNames(styles.root, className)}>
            <>
                <div className={styles.filterRange}>
                    <GenericModal
                        className="dateRangePicker"
                        closeDialog={handleCloseModal}
                        haveComponent={true}
                        getComponent={getDateRangeComponent()}
                        name={'edit-dateRangePicker'}
                        title={''}
                    />
                </div>
                <div>{MemoizedTable}</div>
            </>
        </div>
    );
};
