import styles from './glp-key-mapping.module.scss';
import classNames from 'classnames';
import { formatterDate, viewInArrayViews } from '../../utils/functions';
import { CheckIcon, FlagIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useState, useEffect, useContext } from 'react';
import { Column, GLPSales, GroupDataWithTypesProps, QueryV1Response } from '../../utils/interfaces';
import { groupData, merge, postActionLogs, queryV1 } from '../../services/python-services';
import { queries_glp, queries_glp_key_mapping, size_page } from '../../utils/constants';
import { Table } from '../table/table';
import { FilterButton } from '../filter-button/filter-button';
import { FilterCard } from '../filter-card/filter-card';
import { RMContext } from '../../context/RMContext';

export interface GlpKeyMappingProps {
    name: string;
}

export const GlpKeyMapping = ({ name }: GlpKeyMappingProps) => {
    const today = new Date();
    const priorDate = new Date(new Date().setDate(today.getDate() - 90));

    const initialTableState: any[] = [];
    const [mainTableData, setMainTableData] = useState(initialTableState);
    const initialState: object[] = [];
    const [departureDestinationTableData, setDepartureDestinationTableData] = useState(initialState);
    const [totalDatas, setTotalDatas] = useState(0);
    const [totalNotMapped, setTotalNotMapped] = useState(0);
    const [allRoutes23, setAllRoutes23] = useState(0);
    const [percentNotMapped, setPercentNotMapped] = useState("");
    const [concatData, setConcatData] = useState(initialState);

    const [loadingMainData, setLoadingMainData] = useState(false);
    const [loadingOtherTableData, setLoadingOtherTableData] = useState(false);
    const [downLoader, setDownLoader] = useState<boolean>(false);
    const [downLoaderMain, setDownLoaderMain] = useState<boolean>(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    //Pagination
    const [page, setPage] = useState(1);
    const [isLoadingDataScroll, setIsLoadingDataScroll] = useState(false);
    const [moreItems, setMoreItems] = useState(true);

    const currentView = window.location.href.split("/").slice(-1)[0];
    const {sitemap}= useContext(RMContext).userData;

    useEffect(() => {
        const viewD =viewInArrayViews(currentView, sitemap);
        if(viewD?.hasOwnProperty("id")) {
            postActionLogs(viewD.id)
        }
    },[sitemap]);

    // FILTERS
    const [searchText, setSearchText] = useState('');
    const [applySearch, setApplySearch] = useState(true);
    const [customFilters, setCustomFilters] = useState<any>([]);
    const [executeApply, setExecuteApply] = useState(true);
    const filtersString = [
        'factory',
        'product-group-glp',
        'customer-glp',
        'country-glp',
        // 'delayeddays',
        // 'on-time',
        'delayed',
    ];
    const [closedFilters, setClosedFilters] = useState(true);
    const [queries, setQueries] = useState<GLPSales>({
        page: 1,
        size: size_page,
        sitefromname: null,
        divisionname: null,
        soldtoname: null,
        finalctry: null,
        'delayeddays:not': null,
        delayeddays: null,
        searchGlp: null,
        sort_by: null,
    });

    const [uniques, setUniques] = useState<GLPSales>({
        sitefromname: null,
        divisionname: null,
        soldtoname: null,
        finalctry: null,
    });

    const clearValues = () => {
        setMainTableData([]);
        setTotalDatas(0);
        setPage(1);
    };

    const resetFilters = (nameComponent: string = "filter") => {
        //clearValues();
        if (nameComponent === "table") {
            setClosedFilters(true);
        }
        const updatedQueries = {
            page: 1,
            size: size_page,
            sitefromname: null,
            divisionname: null,
            soldtoname: null,
            finalctry: null,
            'delayeddays:not': null,
            delayeddays: null,
            searchGlp: null,
            sort_by: null,
        };
        setSearchText("");
        setApplySearch(true)
        setExecuteApply(true);
        setPage(1)
        setQueries(updatedQueries);
    };

    const applyFilters = (filters: any, type: string = '', apply: boolean = false) => {
        if (type === 'reset') {
            resetFilters();
        } else {
            //clearValues();
            const updatedQueries = { ...filters, page: 1, size: size_page };
            setQueries(updatedQueries);
            setUniques(updatedQueries);
        }
        setExecuteApply(apply);
        setApplySearch(apply);
    };

    const closeFilter = (isClosed: boolean) => {
        setClosedFilters(isClosed);
    };

    const addColumnDelta = (data: object[]) => {
        const responseData: object[] = [];
        const newColumn: object[] = [];
        data.forEach((element: any) => {
            const newItem = { ...element, delta: (parseFloat(element.avg_ly) - parseFloat(element.avg_cy)) };
            newColumn.push({ "delta": parseFloat(element.avg_ly) - parseFloat(element.avg_cy) })
            responseData.push(newItem);
        });
        setConcatData(newColumn)
        return responseData;
    };
    const countNewDatas = async (oldArray: object[], newArray: object[]) => {
        let counter = 0;
        oldArray.map((list: any) => {
            const isIn = newArray.some(
                (oldList: any) => oldList.departure === list.departure && oldList.destination === list.destination
            );
            if (!isIn) {
                counter++;
            }
        });
        return counter;
    }
    const getKeyDepartureDestinationData = async () => {
        setLoadingOtherTableData(true);
        let filter_queries = queries;
        delete filter_queries.page;
        delete filter_queries.size;
        const leadcalc_response = queryV1(
            queries_glp_key_mapping.key_departure_leadcalc.table,
            queries_glp_key_mapping.key_departure_leadcalc.groupsBy,
            queries_glp_key_mapping.key_departure_leadcalc.query,
            filter_queries,
            'athena'
        );
        const shipment_response = queryV1(
            queries_glp_key_mapping.key_departure_shipment.table,
            queries_glp_key_mapping.key_departure_shipment.groupsBy,
            queries_glp_key_mapping.key_departure_shipment.query,
            filter_queries,
            'athena'
        );
        const leadcalc_data_response: QueryV1Response = await leadcalc_response;
        const shipment_data_response: QueryV1Response = await shipment_response;

        const grouped_data = await merge('departure$destination', [
            leadcalc_data_response.data,
            shipment_data_response.data,
        ]);

        setDepartureDestinationTableData(addColumnDelta(grouped_data));
        const totalNot = await countNewDatas(shipment_data_response.data, leadcalc_data_response.data);
        setTotalNotMapped(totalNot)
        setAllRoutes23(shipment_data_response.data.length)
        setPercentNotMapped(((totalNot / shipment_data_response.data.length) * 100).toFixed(0) + " %")
        setLoadingOtherTableData(false);
    };

    const getKeyDepartureData = async () => {
        const leadcalc_response = queryV1(
            queries_glp_key_mapping.key_departure_leadcalc_departure.table,
            queries_glp_key_mapping.key_departure_leadcalc_departure.groupsBy,
            queries_glp_key_mapping.key_departure_leadcalc_departure.query,
            queries,
            'athena'
        );
        const shipment_response = queryV1(
            queries_glp_key_mapping.key_departure_shipment_departure.table,
            queries_glp_key_mapping.key_departure_shipment_departure.groupsBy,
            queries_glp_key_mapping.key_departure_shipment_departure.query,
            queries,
            'athena'
        );
        const leadcalc_data_response: QueryV1Response = await leadcalc_response;
        const shipment_data_response: QueryV1Response = await shipment_response;

        const grouped_data = await merge('departure$departure_port', [
            leadcalc_data_response.data,
            shipment_data_response.data,
        ]);
        //console.log("departure grouped data: ", grouped_data);
    };

    const getMainTableData = async () => {
        setLoadingMainData(true);
        let newQueiries = { ...queries, page: 1, size: size_page };
        const response = await queryV1(
            queries_glp_key_mapping.mainTable.table,
            queries_glp_key_mapping.mainTable.groupsBy,
            queries_glp_key_mapping.mainTable.query,
            newQueiries,
            'athena'
        );
        if (response) {
            setMainTableData(response?.data);
        }
        setLoadingMainData(false);
    };
    const getTotalDatas = async () => {
        let filter_queries = queries;
        filter_queries.sort_by = '';
        delete filter_queries.page;
        delete filter_queries.size;
        const response = await queryV1(
            queries_glp_key_mapping.totalMainTable.table,
            queries_glp_key_mapping.totalMainTable.groupsBy,
            queries_glp_key_mapping.totalMainTable.query,
            filter_queries,
            'athena'
        );
        if (response) {
            setTotalDatas(response?.data[0].totals);
            if (parseInt(response?.data[0].totals) <= mainTableData.length) {
                setMoreItems(false);
            }
            else {
                setMoreItems(true);
            }
        }
    };


    useEffect(() => {
        if (executeApply) {
            getKeyDepartureDestinationData();
            getKeyDepartureData();
            setExecuteApply(false);
        }
        if (applySearch) {
            getTotalDatas();
            getMainTableData();
            setApplySearch(false);
        }
        setDataLoaded(true);
    }, [queries]);


    const mainColumns: Column[] = [
        {
            id: 'arrived_f',
            name: 'V',
            prop: 'arrived_f',
            open: false,
            show: true,
            style: { minWidth: '50px' },
            formatting: (v: any) => {
                return (
                    <div>
                        {' '}
                        {v === '✔' ? (
                            <CheckIcon style={{ color: 'black', width: 20 }} />
                        ) : (
                            <XMarkIcon style={{ color: 'red', width: 20 }} />
                        )}{' '}
                    </div>
                );
            },
        },
        {
            id: 'po_no',
            name: 'PO #',
            prop: 'po_no',
            open: false,
            show: true,
            formatting: (v: any) => <div>{v.toString()}</div>,
            style: { minWidth: '100px' },
        },
        {
            id: 'sku',
            name: 'SKU',
            prop: 'sku',
            open: false,
            show: true,
            formatting: (v: any) => <div>{v.toString()}</div>,
            style: { minWidth: '150px' },

        },
        { id: 'master_bl', name: 'BL #', prop: 'master_bl', open: false, show: true, style: { minWidth: '160px' }, },
        { id: 'cntr_truckno', name: 'CNTR #', prop: 'cntr_truckno', open: false, show: true, style: { minWidth: '120px' }, },
        { id: 'so', name: 'SO #', prop: 'so', open: false, show: true, style: { minWidth: '120px' }, },
        { id: 'shiptoname', name: 'Ship To', prop: 'shiptoname', open: false, show: true, style: { minWidth: '260px' }, },
        {
            id: 'soldtoname',
            name: 'Sold To Name',
            prop: 'soldtoname',
            open: false,
            show: true,
            style: { minWidth: '260px' },
        },
        { id: 'departurectry', name: 'DEP', prop: 'departurectry', open: false, show: true, style: { minWidth: '60px' }, },
        { id: 'departureport', name: 'Dep Port', prop: 'departureport', open: false, show: true, style: { minWidth: '160px' }, },
        { id: 'dischargingctry', name: 'DIS', prop: 'dischargingctry', open: false, show: true, style: { minWidth: '50px' }, },
        {
            id: 'discharging__port',
            name: 'Dis Port',
            prop: 'discharging__port',
            open: false,
            show: true,
            style: { minWidth: '160px' },
        },
        { id: 'finalctry', name: 'DES', prop: 'finalctry', open: false, show: true, style: { minWidth: '70px' }, },
        { id: 'finalport', name: 'Des Port', prop: 'finalport', open: false, show: true, style: { minWidth: '160px' }, },
        { id: 'incoterms1', name: 'Inco Terms', prop: 'incoterms1', open: false, show: true, style: { minWidth: '50px' }, },
        {
            id: 'shipping__carrier__grouping',
            name: 'Shipping',
            prop: 'shipping__carrier__grouping',
            open: false,
            show: true,
            style: { minWidth: '260px' },
        },
        {
            id: 'divisionname',
            name: 'Product Group',
            prop: 'divisionname',
            open: false,
            show: true,
            style: { minWidth: '80px' },
        },
        {
            id: 'historicleadtime',
            name: 'Lead Time',
            prop: 'historicleadtime',
            open: false,
            show: true,
            cellType: 'pl.Int64',
            style: { minWidth: '80px' },
        },
        {
            id: 'lt', name: 'Contract L/T', prop: 'lt', open: false, show: true,
            cellType: 'pl.Int64',
            style: { minWidth: '80px' },
        },
        {
            id: 'gap',
            name: 'Gap',
            prop: 'gap',
            open: false,
            show: true,
            formatting: (v: any) => {
                return `${v !== null ? v : ''}`;
            },
            cellType: 'pl.Int64',
            style: { minWidth: '60px' },
        },
        {
            id: 'actuallt',
            name: 'Actual LT',
            prop: 'actuallt',
            open: false,
            show: true,
            cellType: 'pl.Int64',
            style: { minWidth: '60px' },
        },
        {
            id: 'forecastlt',
            name: 'Forecast LT',
            prop: 'forecastlt',
            open: false,
            show: true,
            cellType: 'pl.Int64',
            style: { minWidth: '60px' },
        },
        {
            id: 'contract_lty23',
            name: 'LT Y23',
            prop: 'contract_lty23',
            open: false,
            show: true,
            cellType: 'pl.Int64',
            style: { minWidth: '60px' },
            formatting: (v: any) => {
                return `${v !== null ? parseFloat(v).toFixed(0) : ''}`;
            },
        },
        {
            id: 'actual_port_atddate', name: 'Dep Date', prop: 'actual_port_atddate', open: false, show: true,
            cellType: 'pl.Date',
            style: { minWidth: '80px' },
        },
        {
            id: 'port_etadate', name: 'Eta Date', prop: 'port_etadate', open: false, show: true,
            cellType: 'pl.Date',
            style: { minWidth: '100px' },
        },
        {
            id: 'port_atadate', name: 'Ata Date', prop: 'port_atadate', open: false, show: true,
            cellType: 'pl.Date',
            style: { minWidth: '100px' },
        },
        {
            id: 'intransitdate',
            name: 'In Transit Date',
            prop: 'intransitdate',
            open: false,
            show: true,
            type: 'date_column',
            cellType: 'pl.Date',
            style: { minWidth: '100px' },
        },
        {
            id: 'port_1sttransit_crty',
            name: 'Port 1sttransit Crty',
            prop: 'port_1sttransit_crty',
            open: false,
            show: true,
            style: { minWidth: '120px' },
        },
        {
            id: 'port_1sttransit_port',
            name: '1sttransit Port',
            prop: 'port_1sttransit_port',
            open: false,
            show: true,
            style: { minWidth: '160px' },
        },
        {
            id: '1st_transit_dateata',
            name: '1st Transit Dateata',
            prop: '1st_transit_dateata',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '100px' },
        },
        {
            id: '1st_transit_dateatd',
            name: '1st Transit Date Atd',
            prop: '1st_transit_dateatd',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '100px' },
        },
        {
            id: 'port_2ndtransit_crty',
            name: 'Port 2ndtransit Crty',
            prop: 'port_2ndtransit_crty',
            open: false,
            show: true,
            style: { minWidth: '120px' },
        },
        {
            id: 'port_2ndtransit_port',
            name: '2nd Transit Port',
            prop: 'port_2ndtransit_port',
            open: false,
            show: true,
            style: { minWidth: '160px' },
        },
        {
            id: '2nd_transit_dateata',
            name: '2nd Transit Date Ata',
            prop: '2nd_transit_dateata',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '100px' },
        },
        {
            id: '2nd_transit_dateatd',
            name: '2nd Transit Date Atd',
            prop: '2nd_transit_dateatd',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '100px' },
        },
        {
            id: 'port_3rdtransit_crty',
            name: 'Port 3rdtransit Crty',
            prop: 'port_3rdtransit_crty',
            open: false,
            show: true,
            style: { minWidth: '120px' },
        },
        {
            id: 'port_3rdtransit_port',
            name: '3rdtransit Port',
            prop: 'port_3rdtransit_port',
            open: false,
            show: true,
            style: { minWidth: '160px' },
        },
        {
            id: '3rd_transit_dateata',
            name: '3rd Transit Date Ata',
            prop: '3rd_transit_dateata',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '100px' },
        },
        {
            id: '3rd_transit_dateatd',
            name: '3rd_transit Date Atd',
            prop: '3rd_transit_dateatd',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '100px' },
        },
        {
            id: 'port_4thtransit_crty',
            name: 'Port 4thtransit Crty',
            prop: 'port_4thtransit_crty',
            open: false,
            show: true,
            style: { minWidth: '120px' },
        },
        {
            id: 'port_4thtransit_port',
            name: '4thtransit Port',
            prop: 'port_4thtransit_port',
            open: false,
            show: true,
            style: { minWidth: '160px' },
        },
        {
            id: '4th_transit_dateata',
            name: '4th Transit Date Ata',
            prop: '4th_transit_dateata',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '100px' },
        },
        {
            id: '4th_transit_dateatd',
            name: '4th Transit Date Atd',
            prop: '4th_transit_dateatd',
            open: false,
            show: true,
            cellType: 'pl.Date',
            style: { minWidth: '100px' },
        },
    ];
    const departureDestinationColumns: Column[] = [
        {
            id: 'departure',
            name: 'Departure',
            prop: 'departure',
            open: false,
            show: true,
        },
        {
            id: 'destination',
            name: 'Destination',
            prop: 'destination',
            open: false,
            show: true,
        },
        {
            id: 'ly_lt', name: 'Contract L/T Y22', prop: 'ly_lt', open: false, show: true,
            formatting: (v: any) => <span>{parseFloat(v) > 0 ? parseFloat(v).toFixed() : ""}</span>, cellType: 'pl.Int64',
        },
        { id: 'avg_cy', name: 'AVG L/T Y23', prop: 'avg_cy', open: false, show: true, formatting: (v: any) => <span>{parseFloat(v).toFixed()}</span>, cellType: 'pl.Float32', },
        { id: 'avg_ly', name: 'AVG L/T Y22', prop: 'avg_ly', open: false, show: true, formatting: (v: any) => <span>{parseFloat(v).toFixed()}</span>, cellType: 'pl.Float32', },
        {
            id: 'delta', name: 'Delta Y22-Y23', prop: 'delta', open: false, show: true,
            formatting: (v: any) => <p style={{ display: 'flex', alignItems: 'center' }}>
                <FlagIcon style={{ color: parseFloat(v) > 0 ? "lightgreen" : "red", height: "15px", width: "15px", }} />
                <span style={{ position: "relative", left: "92px" }}>{parseFloat(v).toFixed()}</span></p>, cellType: 'pl.Int64',
        },
        {
            id: 'lt', name: 'Contract L/T Y23', prop: 'lt', open: false, show: true,
            formatting: (v: any) => <span>{parseFloat(v) > 0 ? parseFloat(v).toFixed() : ""}</span>, cellType: 'pl.Int64',
        },
    ];
    const reduceColumns = (columns: any) => {
        const res = columns.reduce((result: any, column: any) => {
            if (column.show === true) {
                result[column.id] = column.name;
            }
            return result;
        }, {});
        return res;
    };

    const reduceColumnTypes = (columns: any) => {
        const res = columns.reduce((result: any, column: any) => {
            if (column.show === true && column.cellType !== undefined && column.cellType !== null) {
                result[column.id] = column.cellType;
            }
            return result;
        }, {});
        return res;
    };
    const exportExcelCombined = async (columns: any) => {
        setDownLoader(true);
        const col = reduceColumns(columns);
        const column_types = reduceColumnTypes(columns);
        const body: any = queries_glp_key_mapping.mainTable.query;
        delete body.data[0].offset;
        delete body.data[0].limit;

        const payloadGroupData: GroupDataWithTypesProps = {
            queries: [
                {
                    service: 'queryV1',
                    kwargs: {
                        groups: queries_glp_key_mapping.key_departure_leadcalc.groupsBy,
                        name: queries_glp_key_mapping.key_departure_leadcalc.table,
                    },
                    body: queries_glp_key_mapping.key_departure_leadcalc.query,
                },
                {
                    service: 'queryV1',
                    kwargs: {
                        groups: queries_glp_key_mapping.key_departure_shipment.groupsBy,
                        name: queries_glp_key_mapping.key_departure_shipment.table,
                    },
                    body: queries_glp_key_mapping.key_departure_shipment.query,
                },
            ],
            export: {
                columns: col,
                column_types: column_types,
                format: 'xlsx',
            },
            concat: concatData
        };
        const export_query = {...queries}
        delete export_query.sort_by;
        delete export_query.page;
        delete export_query.size;
        const res = await groupData(payloadGroupData, export_query, 'athena');
        window.open(res['url']);
        setDownLoader(false);
    };
    const exportExcel = async (columns: any) => {
        setDownLoaderMain(true);
        const col = reduceColumns(columns);
        const column_types = reduceColumnTypes(columns);
        const body: any = queries_glp_key_mapping.mainTable.query;
        delete body.data[0].offset;
        delete body.data[0].limit;

        const payloadGroupData: GroupDataWithTypesProps = {
            queries: [
                {
                    service: 'queryV1',
                    kwargs: {
                        groups: queries_glp_key_mapping.mainTable.groupsBy,
                        name: queries_glp_key_mapping.mainTable.table,
                    },
                    body: queries_glp_key_mapping.mainTable.query,
                },
            ],
            export: {
                columns: col,
                column_types: column_types,
                format: 'xlsx',
            },
        };
        const export_query = {...queries}
        delete export_query.sort_by;
        delete export_query.page;
        delete export_query.size;
        const res = await groupData(payloadGroupData, export_query, 'athena');
        window.open(res['url']);
        setDownLoaderMain(false);
    };
    const updateData = (newData: any) => {
        setMainTableData([]);
        setMainTableData(newData);
    };
    const searchData = (type: any) => {

        type = type.replace(/\s+/g, ' ').trim()
        setSearchText(type);
        if (type === '') {
            clearValues();
            setQueries({ ...queries, searchGlp: null });
        } else if (type.length > 2) {
            clearValues();
            setQueries({ ...queries, searchGlp: type.toUpperCase() });
        }
        setApplySearch(true);
    };
    const sortData = (type: any) => {
        setApplySearch(true);
        if (type.name === 'Descending') {
            //clearValues();
            setQueries({ ...queries, page: 1, size: (size_page * page), sort_by: `${type.prop},desc` });
        } else {
            //clearValues();
            setQueries({ ...queries, page: 1, size: (size_page * page), sort_by: `${type.prop}` });
        }
        //setPage(1);
    };

    const fetchData = async (isScroll: boolean = false) => {
        // avoid multiple call to the fetchData
        if (loadingMainData) {
            return;
        }
        const updatedQueries = { ...queries, page: (page + 1), size: size_page };
        setQueries(updatedQueries);
        setIsLoadingDataScroll(isScroll);
        setLoadingMainData(true); // Establecer isLoadingData a true antes de la solicitud

        try {
            // make and run request
            const response = await queryV1(
                queries_glp.mainTable.table,
                queries_glp.mainTable.groupsBy,
                queries_glp.mainTable.query,
                updatedQueries,
                'athena'
            );
            // update data and state variables
            const newData = [...mainTableData, ...response.data];
            setMainTableData(isScroll ? newData : response.data);
            if (newData.length >= totalDatas) {
                setMoreItems(false);
            } else {
                setPage((prev) => prev + 1);
            }
            setExecuteApply(false);
            setApplySearch(false);

            // Reset loading states
            setLoadingMainData(false);
            setIsLoadingDataScroll(false);
        } catch (error) {
            // Reset loading states
            setIsLoadingDataScroll(false);
            setLoadingMainData(false);
        }

    };

    const resultsWithHeader = (title: string, content: string) => {
        return <div className='row'>
            <h5>{title}</h5><br />
            <h5>{content}</h5>
        </div>
    };

    const getComponents = () => {

        return (
            <div className={styles.root}>
                <div className={styles.upper}>
                    <div className={styles.headerReport}>
                        <span className={styles.descriptionCountry}>SELA</span>
                        <span className={styles.tittleReport}>
                            Logistic Leadtime Tracking System: Key Mapping Version
                        </span>
                    </div>

                    <div className={styles.subHeader}>
                        <div className={styles.textReport}>
                            <span>
                                Period: {formatterDate(priorDate)} to {formatterDate(today)}
                            </span>
                        </div>
                        <div className={styles.textUpdateReport}>
                            <span>
                                Update by{' '}
                                <strong>
                                    {' '}
                                    GLP Outbound Tracking View at:&emsp;&emsp; {formatterDate(today)}
                                </strong>
                            </span>
                        </div>
                    </div>
                </div>
                <div className={styles.intermediate}>
                    <div className={styles.section1}>

                        <div className={styles['compareTableDeparture']}>
                            <Table
                                isSearch={false}
                                columnsButton={true}
                                resetButton={true}
                                columns={departureDestinationColumns}
                                data={departureDestinationTableData}
                                count={departureDestinationTableData.length || 0}
                                fetchData={getKeyDepartureDestinationData}
                                title=""
                                exportData={exportExcelCombined}
                                haveMoreItems={false}
                                updateData={updateData}
                                searchText={''}
                                isLoadingData={loadingOtherTableData}
                                allColumnsStyle="text-left max-w-[150px] min-w-[150px] "
                                exportLoader={downLoader}
                                activeCompactMode={true}
                            />
                        </div>
                    </div>
                    <div className={styles.section2}>
                        <div className={styles.noMapped}>

                            <>
                                {resultsWithHeader("Y23 Routes No Mapped", totalNotMapped.toString())}
                            </>
                        </div>
                        <div className={styles.allRoutes}>

                            <>
                                {resultsWithHeader("Y23 All Routes", allRoutes23.toString())}
                            </>
                        </div>
                        <div className={styles.percentNoMapped}>

                            <>
                                {resultsWithHeader("Y23 % No Mapped", percentNotMapped.toString())}
                            </>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={styles['mainTable']}>
                        <Table
                            isSearch={true}
                            searchFunction={searchData}
                            searchText={searchText}
                            sortFunction={sortData}
                            columnsButton={true}
                            resetButton={true}
                            columns={mainColumns}
                            data={mainTableData}
                            count={totalDatas}
                            fetchData={fetchData}
                            title=""
                            exportData={exportExcel}
                            haveMoreItems={moreItems}
                            updateData={updateData}
                            isLoadingData={loadingMainData}
                            isLoadingDataScroll={isLoadingDataScroll}
                            allColumnsStyle=" max-w-[200px] min-w-[200px] "
                            exportLoader={downLoaderMain}
                            activeCompactMode={true}
                            onReset={resetFilters}
                        />
                    </div>
                </div>
            </div>



        )
    };

    return (
        <div className={classNames(styles.root) + ' flex h-[98vh] w-screen ' + name}>
            {closedFilters ? (
                <FilterButton className="hidden" close={closeFilter} top={154} />
            ) : (
                <FilterCard
                    close={closeFilter}
                    applyFilters={applyFilters}
                    columns={mainColumns}
                    setUniques={setUniques}
                    filters={filtersString}
                    file={'glp-shipmentoptimized'}
                    uniques={uniques}
                    by="athena"
                    onReset={clearValues}
                    setExecuteApply={setExecuteApply}
                    setApplySearch={setApplySearch}
                    setCustomFilters={setCustomFilters}
                    customFilters={customFilters}
                    isColapsed={true}
                />
            )}
            <div className={closedFilters ? 'w-full' : 'w-[80%]'}>
                {dataLoaded &&
                    getComponents()
                }
            </div>
        </div>
    );
};
