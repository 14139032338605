import styles from './security-policies.module.scss';
import classNames from 'classnames';
import Notiflix from 'notiflix';
import { useEffect, useState } from 'react';
import { onChangeAgreements } from '../../services/python-services';
import { updateAgredmentToContext, updateIsLocalToContext } from '../../utils/functions';
import { Button } from '../button/button';
export interface SecurityPoliciesProps {
    className?: string;
    isModal?: boolean;
    handleClose?: any;
    setUserData: any;
    userData: any;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const SecurityPolicies = ({
    className,
    isModal = false,
    handleClose,
    userData,
    setUserData,
}: SecurityPoliciesProps) => {
    const [value_accepted, setValue_accepted] = useState(false);

    const Accepted = async (e: any) => {
        setValue_accepted(e.target.checked);
    };

    const close_ = (accepted: boolean) => {
        if (isModal) {
            handleClose('securityModal', accepted);
            if (!accepted) {
                updateIsLocalToContext(setUserData, userData, false);
            }
        }
    };

    const axiosInstanceAgredment = async () => {
        await updateAgredmentToContext(setUserData, userData, value_accepted);
    };

    const onClickSave = async (e: any) => {
        e.preventDefault();
        const response: any = await onChangeAgreements({
            value: value_accepted,
        });

        if (response?.data?.detail === 'success') {
            Notiflix.Notify.success('You accepted privacy policies terms!', { timeout: 1000 });
        } else {
            Notiflix.Notify.failure('Request failed!', { timeout: 1000 });
        }
        await axiosInstanceAgredment();
        close_(true);
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, []);
    return (
        <div
            className={classNames(styles.root, className)}
            style={{
                background: '#fff',
                padding: isModal ? '' : '30px',
                paddingTop: isModal ? '' : '0px',
            }}
        >
            <form>
                <div className={classNames(styles['policies-box'])}>
                    <a href="/#" className={styles.withoutLink} style={{ border: '0px' }}>
                        <h1 className={classNames(styles['text-center'])} style={{ border: '0px' }}>
                            Privacy Policy
                        </h1>
                    </a>
                    <br />
                    <div>
                        <div>
                            <p
                                style={{
                                    marginTop: '0',
                                    marginBottom: '8',
                                    textAlign: 'justify',
                                    fontSize: '14',
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    Cheil Central America (hereinafter referred to as “the Company”)
                                    is committed to protecting personal information of the users and
                                    to complying with applicable privacy laws and regulations.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: 8,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    This Privacy Policy describes ways the Company collects and uses
                                    personal information from its users (“users” or “you”). It also
                                    outlines additional measures that are taken to protect the
                                    personal information of the users. Any changes to the Privacy
                                    Policy will be informed via Notices on the Company’s website (or
                                    individual notices).
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: 8,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <br />
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    The Company Privacy Policy contains the following sections:
                                </span>
                            </p>
                            <ol
                                type="1"
                                style={{ margin: 0, paddingLeft: 0, listStyleType: 'decimal' }}
                            >
                                <li
                                    style={{
                                        marginLeft: 36,
                                        textAlign: 'justify',
                                        paddingLeft: '4.73',
                                        fontSize: 14,
                                    }}
                                >
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        Categories and Methods of Personal Information Collection
                                    </span>
                                </li>
                                <li
                                    style={{
                                        marginLeft: 36,
                                        textAlign: 'justify',
                                        paddingLeft: '4.73',
                                        fontSize: 14,
                                    }}
                                >
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        Purpose(s) of Personal Information Collection and Usage
                                    </span>
                                </li>
                                <li
                                    style={{
                                        marginLeft: 36,
                                        textAlign: 'justify',
                                        paddingLeft: '4.73',
                                        fontSize: 14,
                                    }}
                                >
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        Provision of Collected Personal Information
                                    </span>
                                </li>
                                <li
                                    style={{
                                        marginLeft: 36,
                                        textAlign: 'justify',
                                        paddingLeft: '4.73',
                                        fontSize: 14,
                                    }}
                                >
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        Cross-border Transfer collected personal information [Third
                                        party cross-border transfer of personal information]
                                    </span>
                                </li>
                                <li
                                    style={{
                                        marginLeft: 36,
                                        textAlign: 'justify',
                                        paddingLeft: '4.73',
                                        fontSize: 14,
                                    }}
                                >
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        Retention Period of Collected Personal Information
                                    </span>
                                </li>
                                <li
                                    style={{
                                        marginLeft: 36,
                                        textAlign: 'justify',
                                        paddingLeft: '4.73',
                                        fontSize: 14,
                                    }}
                                >
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        Disposal Procedures and Measures of Personal Information
                                    </span>
                                </li>
                                <li
                                    style={{
                                        marginLeft: 36,
                                        textAlign: 'justify',
                                        paddingLeft: '4.73',
                                        fontSize: 14,
                                    }}
                                >
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        Measures to Ensure Security of Personal Information
                                    </span>
                                </li>
                                <li
                                    style={{
                                        marginLeft: 36,
                                        textAlign: 'justify',
                                        paddingLeft: '4.73',
                                        fontSize: 14,
                                    }}
                                >
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        Rights of Users and Their Legal Representatives and Measures
                                        to Exercise the Rights
                                    </span>
                                </li>
                                <li
                                    style={{
                                        marginLeft: 36,
                                        textAlign: 'justify',
                                        paddingLeft: '4.73',
                                        fontSize: 14,
                                    }}
                                >
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        Chief Privacy Officer and Responsible Department (Name and
                                        Contact Information)
                                    </span>
                                </li>
                                <li
                                    style={{
                                        marginLeft: 40,
                                        textAlign: 'justify',
                                        paddingLeft: '4.73',
                                        fontSize: 14,
                                    }}
                                >
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        Duty to Notify
                                    </span>
                                </li>
                            </ol>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>&nbsp;</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>&nbsp;</span>
                            </p>
                            <ol type="1" style={{ margin: 0, paddingLeft: 0 }}>
                                <li
                                    style={{
                                        marginTop: 12,
                                        marginLeft: '13.27',
                                        textAlign: 'justify',
                                        lineHeight: '115%',
                                        paddingLeft: '4.73',
                                        fontSize: 14,
                                    }}
                                >
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        Categories and Methods of Personal Information Collection
                                    </span>
                                </li>
                            </ol>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    lineHeight: '115%',
                                    fontSize: 14,
                                }}
                            >
                                <span>&nbsp;</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textIndent: -18,
                                    textAlign: 'justify',
                                    lineHeight: '115%',
                                    fontSize: 14,
                                }}
                            >
                                <span>1.1</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>&nbsp;&nbsp; </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    Categories of Personal Information
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    lineHeight: '115%',
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    The Company collects the following categories of personal
                                    information for membership registration, service application,
                                    revision/
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}>unsubscription</span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    , warranty, customer service, new release information, events,
                                    user management, etc.
                                </span>
                            </p>
                            <ul
                                style={{
                                    listStyleType: 'disc',
                                    margin: 0,
                                    marginLeft: 39,
                                    paddingLeft: 0,
                                }}
                            >
                                <li
                                    style={{
                                        marginLeft: '10.06',
                                        textAlign: 'justify',
                                        paddingLeft: '25.94',
                                        fontSize: 14,
                                    }}
                                >
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        Required information
                                    </span>
                                </li>
                            </ul>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 36,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>Company, </span>
                                <span style={{ backgroundColor: '#ffffff' }}>country, </span>
                                <span style={{ backgroundColor: '#ffffff' }}>division, name</span>
                                <span style={{ backgroundColor: '#ffffff' }}> and</span>
                                <span style={{ backgroundColor: '#ffffff' }}> e-mail address</span>
                                <span style={{ backgroundColor: '#ffffff' }}> and </span>
                                <span style={{ backgroundColor: '#ffffff' }}>initiation date</span>
                                <span style={{ backgroundColor: '#ffffff' }}>.</span>
                            </p>
                            <ul
                                style={{
                                    listStyleType: 'disc',
                                    margin: 0,
                                    marginLeft: 39,
                                    paddingLeft: 0,
                                }}
                            >
                                <li
                                    style={{
                                        marginLeft: '10.06',
                                        textAlign: 'justify',
                                        paddingLeft: '25.94',
                                        fontSize: 14,
                                    }}
                                >
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        {' '}
                                        Optional information
                                    </span>
                                </li>
                            </ul>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 36,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>Department</span>
                                <span style={{ backgroundColor: '#ffffff' }}>, </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    Job position and team leader.
                                </span>
                            </p>
                            <ul
                                style={{
                                    listStyleType: 'disc',
                                    margin: 0,
                                    marginLeft: 39,
                                    paddingLeft: 0,
                                }}
                            >
                                <li
                                    style={{
                                        marginLeft: '10.06',
                                        textAlign: 'justify',
                                        paddingLeft: '25.94',
                                        fontSize: 14,
                                    }}
                                >
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        The following information may be generated and collected in
                                        the process of using services or business processing:
                                    </span>
                                </li>
                            </ul>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 36,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    Service use history, access logs, access IP information
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}> </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    and suspension history
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}>.</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 36,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>&nbsp;</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textIndent: -18,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>1.2</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>&nbsp;&nbsp; </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    Collection methods
                                </span>
                            </p>
                            <ul
                                style={{
                                    listStyleType: 'disc',
                                    margin: 0,
                                    marginLeft: 39,
                                    paddingLeft: 0,
                                }}
                            >
                                <li
                                    style={{
                                        marginLeft: '10.06',
                                        textAlign: 'justify',
                                        paddingLeft: '25.94',
                                        fontSize: 14,
                                    }}
                                >
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        The Company collects personal information in the following
                                        methods:
                                    </span>
                                </li>
                            </ul>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 36,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    Membership registration via the website
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 36,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>&nbsp;</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>Your Options</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    You have the right to refuse to provide the Company with certain
                                    types of information. In particular cases, this can limit your
                                    ability to use services. The company will explain these
                                    limitations so you can make an informed decision.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>&nbsp;</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 8,
                                    textIndent: -18,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>1.3</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>&nbsp;&nbsp; </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    No Collection of Sensitive Information
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: 8,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    The Company will not collect “sensitive information”, including
                                    but not limited to, racial or ethnic origin opinions, political
                                    opinions, religious or philosophical beliefs, trade-union
                                    membership, precise geolocation, genetic and biometric data
                                    processed solely to identify a human being, a person’s sex life
                                    or sexual orientation.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: 8,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    The Company will not collect personal information of under 1
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}>8</span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    {' '}
                                    years of age child.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: 8,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>&nbsp;</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 8,
                                    textIndent: -18,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>1.4</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>&nbsp;&nbsp; </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    No Sale of Personal Information:
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>&nbsp;</span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    THE COMPANY DOES NOT SELL ANY PERSONAL INFORMATION OF USER.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>&nbsp;</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>&nbsp;</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textIndent: -18,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>2</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    Purpose(s) of Personal Information Collection and Usage
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: 8,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    The Company collects only minimum range of personal information
                                    upon the consent of customer and processes the personal
                                    information in accordance with the purpose of the collection and
                                    as necessary to provide the service.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: 8,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <br />
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    The Company uses collected personal information for the
                                    following purposes:
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textIndent: -18,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>2.1</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>&nbsp;&nbsp; </span>
                                <span style={{ backgroundColor: '#ffffff' }}>Perform service </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>A</span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    s agreed to in relevant contract(s), including providing content
                                    according to service description
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}>.</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>&nbsp;</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textIndent: -18,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>2.2</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>&nbsp;&nbsp; </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    Membership management
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    Identify verification for using membership services; personal
                                    identification; prevention of delinquent members’
                                    inappropriate/unauthorized usage; confirmation of intent to
                                    register, restriction of registration and number of{' '}
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}>registrations</span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    ; retention of records for conflict resolution; claim processing
                                    including complaints; and notification delivery You may withdraw
                                    your consent at any time by contacting us as specified in
                                    contact information below of this Privacy Policy
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>&nbsp;</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>&nbsp;</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textIndent: -18,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>3</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    Provision of Collected Personal Information (Provision of
                                    Personal Information to Third Party)
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>&nbsp;</span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    In principle, the Company will not disclose personal information
                                    of the users, except as follows:
                                </span>
                            </p>
                            <ol type="I" style={{ margin: 0, paddingLeft: 0 }}>
                                <li
                                    style={{
                                        marginLeft: '40.4pt',
                                        textAlign: 'justify',
                                        paddingLeft: '13pt',
                                        fontSize: 14,
                                    }}
                                >
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        When users provide prior consent; or
                                    </span>
                                </li>
                                <li
                                    style={{
                                        marginLeft: '40.4pt',
                                        textAlign: 'justify',
                                        paddingLeft: '13pt',
                                        fontSize: 14,
                                    }}
                                >
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        In accordance with applicable privacy and security laws and
                                        regulations or upon request of an investigative agency in
                                        accordance with the procedures and methods set forth in the
                                        legislation for the purpose of investigation.
                                    </span>
                                </li>
                            </ol>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>&nbsp;</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>&nbsp;</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textIndent: -18,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>4</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    Cross-border Transfer of collected personal information (Third
                                    party overseas provision of personal information)
                                </span>
                                <br />
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    The Company may transfer and process personal information from
                                    the server that is outside of the country in which user reside
                                    through its global affiliates or{' '}
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}>third party</span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    service providers with whom appropriate data processing
                                    agreements have been executed.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>&nbsp;</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textIndent: -18,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>4.1</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>&nbsp;&nbsp; </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    Data transfer and method of transfers
                                </span>
                            </p>
                            <ul
                                style={{
                                    listStyleType: 'disc',
                                    margin: 0,
                                    marginLeft: 39,
                                    paddingLeft: 0,
                                }}
                            >
                                <li
                                    style={{
                                        marginLeft: '10.06',
                                        textAlign: 'justify',
                                        paddingLeft: '25.94',
                                        fontSize: 14,
                                    }}
                                >
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        Transfer target: Overseas companies use VPN, Samsung
                                        affiliated
                                    </span>
                                    <span style={{ backgroundColor: '#ffffff' }}> </span>
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        companies, subsidiaries.
                                    </span>
                                </li>
                                <li
                                    style={{
                                        marginLeft: '10.06',
                                        textAlign: 'justify',
                                        paddingLeft: '25.94',
                                        fontSize: 14,
                                    }}
                                >
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        Transferring Date: Data will be transferred in case of
                                        creating an RM account, modifying and changing personal
                                        information and using the service
                                    </span>
                                </li>
                                <li
                                    style={{
                                        marginLeft: '10.06',
                                        textAlign: 'justify',
                                        paddingLeft: '25.94',
                                        fontSize: 14,
                                    }}
                                >
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        Transfer method: Save in domestic servers (DB), when
                                        creating account and accessing through user portal from
                                        abroad.
                                    </span>
                                </li>
                            </ul>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 36,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>&nbsp;</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textIndent: -18,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>4.2</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>&nbsp;&nbsp; </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    Items of personal information transferred
                                </span>
                            </p>
                            <ul
                                style={{
                                    listStyleType: 'disc',
                                    margin: 0,
                                    marginLeft: 39,
                                    paddingLeft: 0,
                                }}
                            >
                                <li
                                    style={{
                                        marginLeft: '10.06',
                                        textAlign: 'justify',
                                        paddingLeft: '25.94',
                                        fontSize: 14,
                                    }}
                                >
                                    <span style={{ backgroundColor: '#ffffff' }}>Required </span>
                                    <span style={{ backgroundColor: '#ffffff' }}>information:</span>
                                    <span style={{ backgroundColor: '#ffffff' }}> </span>
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        Company, country, division, name and e-mail address and
                                        initiation date.
                                    </span>
                                </li>
                                <li
                                    style={{
                                        marginLeft: '10.06',
                                        textAlign: 'justify',
                                        paddingLeft: '25.94',
                                        fontSize: 14,
                                    }}
                                >
                                    <span style={{ backgroundColor: '#ffffff' }}>Optional </span>{' '}
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        {' '}
                                        information:
                                    </span>
                                    <span style={{ backgroundColor: '#ffffff' }}> </span>{' '}
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        Department, Job position and team leader
                                    </span>
                                </li>
                            </ul>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>&nbsp;</span>
                            </p>

                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textIndent: -18,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>5</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    Retention Period of Collected Personal Information
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    The Company disposes of personal information after fulfilling
                                    the original purposes of collecting and using the information
                                    without delay.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>&nbsp;</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    However, the following information will be retained for the
                                    specified period for the following reasons:
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textIndent: -18,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>5.1</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>&nbsp;&nbsp; </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    Retention of information according to the Company’s internal
                                    guidelines
                                </span>
                            </p>
                            <ul
                                style={{
                                    listStyleType: 'disc',
                                    margin: 0,
                                    marginLeft: 39,
                                    paddingLeft: 0,
                                }}
                            >
                                <li
                                    style={{
                                        marginLeft: '10.06',
                                        textAlign: 'justify',
                                        paddingLeft: '25.94',
                                        fontSize: 14,
                                    }}
                                >
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        Items of retention:{' '}
                                    </span>
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        Company, country, division, name and e-mail address and
                                        initiation date, department, Job position and team leader.
                                    </span>
                                </li>
                            </ul>
                            <ol start={2} type="1" style={{ margin: 0, paddingLeft: 0 }}>
                                <li style={{ marginLeft: 18, textAlign: 'justify', fontSize: 14 }}>
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        Reasons for retention: Prevention of confusion in using
                                        services and
                                    </span>
                                    <span style={{ backgroundColor: '#ffffff' }}> </span>
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        responses to possible conflicts in regard to identify
                                        verification,
                                    </span>
                                    <span style={{ backgroundColor: '#ffffff' }}> </span>{' '}
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        {' '}
                                        misuse, etc.
                                    </span>
                                </li>
                                <li style={{ marginLeft: 18, textAlign: 'justify', fontSize: 14 }}>
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        {' '}
                                        Retention period:{' '}
                                    </span>{' '}
                                    <span style={{ backgroundColor: '#ffffff' }}> T</span>
                                    <span style={{ backgroundColor: '#ffffff' }}> welve </span>{' '}
                                    <span style={{ backgroundColor: '#ffffff' }}> (1</span>
                                    <span style={{ backgroundColor: '#ffffff' }}> 2</span>{' '}
                                    <span style={{ backgroundColor: '#ffffff' }}>) </span>
                                    <span style={{ backgroundColor: '#ffffff' }}> year</span>
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        after termination of service
                                    </span>
                                    <span style={{ backgroundColor: '#ffffff' }}>.</span>
                                </li>
                            </ol>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <br />
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    Notwithstanding the above, the Company will retain your personal
                                    information to the extent necessary to comply with any
                                    applicable legal and contractual obligations.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>&nbsp;</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>&nbsp;</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textIndent: -18,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>6</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    Disposal Procedures and Measures of Personal Information
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>&nbsp;</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textIndent: -18,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>6.1</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>&nbsp;&nbsp; </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    Disposal procedures
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    The information that the members provide for membership
                                    registration and other reasons will be retained for a certain
                                    period of time after the original purposes are fulfilled and
                                    disposed in accordance with the internal guidelines and other
                                    related legislation for information protection purposes (see #5.
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}> r</span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    etention Period of Collected Personal Information).
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    The above-mentioned personal information will not be used for
                                    any purposes other than the purpose of retention except as
                                    required by law.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textIndent: -18,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>6.2</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>&nbsp;&nbsp; </span>
                                <span style={{ backgroundColor: '#ffffff' }}>Disposal methods</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    Personal information printed on paper will be disposed by a
                                    paper shredder or an incinerator. Personal information in
                                    electronic files are deleted by a technical method that
                                    prohibits regeneration of the record.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>&nbsp;</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>&nbsp;</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 8,
                                    textIndent: -18,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>7</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    Measures to Ensure Security of Personal Information
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: 8,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    The Company pursues the following technical/managerial measures
                                    in order to ensure security in processing personal information
                                    of the users to prevent any loss, theft, leakage, tampering or
                                    damage of personal information.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textIndent: -18,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>7.1</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>&nbsp;&nbsp; </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    Establishment and execution of internal management plan
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    The Company establishes and executes an internal management plan
                                    for personal information protection.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textIndent: -18,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>7.2</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>&nbsp;&nbsp; </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    Minimizing number of persons who handle personal information and
                                    training
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    The Company appoints the minimum number of persons who handle
                                    personal information and implements measures to manage personal
                                    information more efficiently.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textIndent: -18,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>7.3</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>&nbsp;&nbsp; </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    Restrictions on access to personal information
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    The Company takes the necessary steps to monitor access to
                                    personal information by granting,
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}> </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    changing and removing access to the database systems in which
                                    personal information is processed.
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}> </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    The Company also deploys and uses an intrusion prevention system
                                    to control unauthorized external
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}> </span>
                                <span style={{ backgroundColor: '#ffffff' }}>access.</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textIndent: -18,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>7.4</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>&nbsp;&nbsp; </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    Access record storage and tempering prevention
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    The Company retains access records of the personal information
                                    processing system (e.g. web logs, summary data, etc.) for at
                                    least six (6) months, and enables security functions to prevent
                                    tampering, theft and loss of the access records.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textIndent: -18,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>7.5</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>&nbsp;&nbsp; </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    Personal information encryption
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    Personal information of the users is encrypted to be saved and
                                    managed. Additional security functions are used for important
                                    data, including encryption when saving/sending.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textIndent: -18,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>7.6</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>&nbsp;&nbsp; </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    Technical measures against hacking
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    In order to prevent any leakage of or damage to personal
                                    information caused by hacking or computer viruses, the Company
                                    has installed security software with regular updates and
                                    monitoring, as well as a system in access-controlled areas for
                                    technical/physical monitoring and blocking. The Company also
                                    provides network traffic monitoring and detection of any
                                    attempts to illegally change the information.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>&nbsp;</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    fontSize: 14,
                                }}
                            >
                                <span>&nbsp;</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textIndent: -18,
                                    fontSize: 14,
                                }}
                            >
                                <span>8</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    Rights of Users and Their Legal Representatives and Measures to
                                    Exercise the Rights
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    fontSize: 14,
                                }}
                            >
                                <span>&nbsp;</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textIndent: -18,
                                    fontSize: 14,
                                }}
                            >
                                <span>8.1</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>&nbsp;&nbsp; </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    The Company does not collect personal Information of children
                                    under the age of{' '}
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}>18</span>
                                <span style={{ backgroundColor: '#ffffff' }}>.</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 36,
                                    marginBottom: 0,
                                    fontSize: 14,
                                }}
                            >
                                <span>&nbsp;</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 36,
                                    marginBottom: 0,
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>Users Rights</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 36,
                                    marginBottom: 0,
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    You are entitled (in the circumstances and under the conditions,
                                    and subject to the exceptions, set out in applicable law) to:
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 36,
                                    marginBottom: 0,
                                    textIndent: -36,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>8.1.1</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>
                                    &nbsp;&nbsp;&nbsp;{' '}
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    The right to access{' '}
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}>？</span>
                                <span style={{ backgroundColor: '#ffffff' }}> </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    You have the right to request the Company for copies of your
                                    personal data. We can request additional information necessary
                                    for self-certification for this service. In addition,{' '}
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    users may access/view their personal information after identity
                                    verification procedures under ‘Revise Personal Information’ (or
                                    ‘Revise Member Information’) to view personal information.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 36,
                                    marginBottom: 0,
                                    textIndent: -36,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>8.1.2</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>
                                    &nbsp;&nbsp;&nbsp;{' '}
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    The right to rectification/
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}>modify</span>
                                <span style={{ backgroundColor: '#ffffff' }}> </span>
                                <span style={{ backgroundColor: '#ffffff' }}>？</span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    You have the right to request that the Company correct any
                                    information you believe is inaccurate. You also have the right
                                    to request the Company to complete the information you believe
                                    is incomplete. In addition, users may revise their personal
                                    information after identity verification procedures under ‘Revise
                                    Personal Information’ (or ‘Revise Member Information’) to revise
                                    personal information. If users request for a correction on the
                                    error of their personal information, that personal information
                                    will not be used or provided until the correction is completed.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 36,
                                    marginBottom: 0,
                                    textIndent: -36,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>8.1.3</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>
                                    &nbsp;&nbsp;&nbsp;{' '}
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    The right to erasure/
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}>delete</span>
                                <span style={{ backgroundColor: '#ffffff' }}> </span>
                                <span style={{ backgroundColor: '#ffffff' }}>？</span>
                                <span style={{ backgroundColor: '#ffffff' }}> </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    You have the right to request that the Company erase your
                                    personal data, Personal information that have been cancelled or
                                    deleted upon the request of the users or their legal
                                    representatives will be processed pursuant to the “Retention
                                    Period of Collected Personal Information.” This information may
                                    not be accessed or used for any other purposes.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 36,
                                    marginBottom: 0,
                                    textIndent: -36,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>8.1.4</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>
                                    &nbsp;&nbsp;&nbsp;{' '}
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    The right to restrict processing{' '}
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}>？</span>
                                <span style={{ backgroundColor: '#ffffff' }}> </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    You have the right to request that the Company restrict the
                                    processing of your personal data for purposes other than those
                                    specified in this policy.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 36,
                                    marginBottom: 0,
                                    textIndent: -36,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>8.1.5</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>
                                    &nbsp;&nbsp;&nbsp;{' '}
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    The right to object to processing{' '}
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}>？</span>
                                <span style={{ backgroundColor: '#ffffff' }}> </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    You have the right to object to the Company’s processing of your
                                    personal data for purposes other than those specified in this
                                    policy.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 36,
                                    marginBottom: 0,
                                    textIndent: -36,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>8.1.6</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>
                                    &nbsp;&nbsp;&nbsp;{' '}
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    The right to data portability{' '}
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}>？</span>
                                <span style={{ backgroundColor: '#ffffff' }}> </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    You have the right to request that the Company transfer the data
                                    that we have collected to another organization, or directly to
                                    you, under certain conditions.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 36,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>&nbsp;</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textIndent: -18,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>8.2</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>&nbsp;&nbsp; </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    Users may unsubscribe membership after identity verification
                                    procedures under ‘Revise Personal
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}> </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    Information’ (or ‘Revise Member Information’) to ‘Unsubscribe’
                                    to unsubscribe membership
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}> </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    (withdrawal of consent).
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 36,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>&nbsp;</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 36,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    Addition Rights of California Resident Users [California
                                    Residents Only]
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}> </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    In addition to the rights above, California Residents may
                                    exercise the following additional rights:
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 36,
                                    marginBottom: 0,
                                    textIndent: -36,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>8.2.1</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>
                                    &nbsp;&nbsp;&nbsp;{' '}
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    The right to “Opt-out” of “Sale”.{' '}
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}>？</span>
                                <span style={{ backgroundColor: '#ffffff' }}> </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    The Company does not sell your personal information, so we do
                                    not offer an opt out.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 36,
                                    marginBottom: 0,
                                    textIndent: -36,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>8.2.2</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>
                                    &nbsp;&nbsp;&nbsp;{' '}
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    The right to limit use and disclosure of sensitive personal
                                    information.{' '}
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}>？</span>
                                <span style={{ backgroundColor: '#ffffff' }}> </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    The Company does not collect your sensitive personal information
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 36,
                                    marginBottom: 0,
                                    textIndent: -36,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>8.2.3</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>
                                    &nbsp;&nbsp;&nbsp;{' '}
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    The right to non-discrimination{' '}
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}>？</span>
                                <span style={{ backgroundColor: '#ffffff' }}> </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    It goes without saying, but we will not hold it against you when
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}> </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    you exercise any of your rights. In fact, the Company encourage
                                    you to review your privacy settings
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}> </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    closely and reach out to us with your questions.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: 0,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <br />
                                <span>&nbsp;</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 8,
                                    textIndent: -18,
                                    fontSize: 14,
                                }}
                            >
                                <span>9</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                                </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    Chief Privacy Officer and Responsible Department
                                </span>
                                <br />
                                <span>&nbsp;</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: 8,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>&nbsp;</span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    The Company has designated a Chief Privacy Officer and a
                                    responsible department to protect personal information and
                                    handle related complaints as follows:
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: 8,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>&nbsp;</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textIndent: -18,
                                    fontSize: 14,
                                }}
                            >
                                <span>9.1</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>&nbsp;&nbsp; </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    Chief Privacy Officer
                                </span>
                            </p>
                            <ul
                                style={{
                                    listStyleType: 'disc',
                                    margin: 0,
                                    marginLeft: 39,
                                    paddingLeft: 0,
                                }}
                            >
                                <li
                                    style={{
                                        marginLeft: '10.06',
                                        paddingLeft: '25.94',
                                        fontSize: 14,
                                    }}
                                >
                                    <span style={{ backgroundColor: '#ffffff' }}> Name: </span>
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        {' '}
                                        Sung Wook Jung
                                    </span>
                                </li>
                                <li
                                    style={{
                                        marginLeft: '10.06',
                                        paddingLeft: '25.94',
                                        fontSize: 14,
                                    }}
                                >
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        {' '}
                                        Department:{' '}
                                    </span>{' '}
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        Retail Business Intelligence
                                    </span>
                                </li>
                                <li
                                    style={{
                                        marginLeft: '10.06',
                                        paddingLeft: '25.94',
                                        fontSize: 14,
                                    }}
                                >
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        {' '}
                                        Phone number:{' '}
                                    </span>
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        +507 6861-6683
                                    </span>
                                </li>
                            </ul>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginLeft: 18,
                                    marginBottom: 0,
                                    textIndent: -18,
                                    fontSize: 14,
                                }}
                            >
                                <br />
                                <span>9.2</span>
                                <span style={{ font: "7pt 'Times New Roman'" }}>&nbsp;&nbsp; </span>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    <span style={{ backgroundColor: '#ffffff' }}>
                                        E-mail address:{' '}
                                    </span>
                                    <a
                                        href="mailto:sung.j@samsung.com"
                                        style={{ textDecoration: 'none' }}
                                    >
                                        <span
                                            style={{
                                                textDecoration: 'underline',
                                                color: '#0000ff',
                                                backgroundColor: '#ffffff',
                                            }}
                                        >
                                            sung.j@samsung.com
                                        </span>
                                    </a>
                                </span>
                            </p>

                            <p style={{ marginTop: 0, marginBottom: 8, fontSize: 14 }}>
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    Users may report all personal information related claims that
                                    may arise in the process of using the Company’s services to
                                    Chief Privacy Officer or the responsible department. The Company
                                    will promptly provide proper responses to the claims.
                                </span>
                                <br />
                                <span>&nbsp;</span>
                            </p>
                            <p style={{ marginTop: 0, marginBottom: 8, fontSize: 14 }}>
                                <span>&nbsp;</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: 8,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    10. Duty to Notify
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: 8,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>&nbsp;</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: 8,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>
                                    If the Company makes any additions, deletions, or changes to
                                    this Privacy Policy, it will notify via Notices on the website
                                    and least seven (7) days prior to such changes.
                                </span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: 8,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>&nbsp;</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: 8,
                                    textAlign: 'center',
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>Notify Date: </span>
                                <span style={{ backgroundColor: '#ffffff' }}>12</span>
                                <span style={{ backgroundColor: '#ffffff' }}> </span>
                                <span style={{ backgroundColor: '#ffffff' }}>July</span>
                                <span style={{ backgroundColor: '#ffffff' }}>, 202</span>
                                <span style={{ backgroundColor: '#ffffff' }}>2</span>
                                <span style={{ backgroundColor: '#ffffff' }}>.</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: 8,
                                    textAlign: 'center',
                                    fontSize: 14,
                                }}
                            >
                                <span style={{ backgroundColor: '#ffffff' }}>Effective </span>
                                <span style={{ backgroundColor: '#ffffff' }}>Date :</span>
                                <span style={{ backgroundColor: '#ffffff' }}> </span>
                                <span style={{ backgroundColor: '#ffffff' }}>12</span>
                                <span style={{ backgroundColor: '#ffffff' }}> </span>
                                <span style={{ backgroundColor: '#ffffff' }}>July</span>
                                <span style={{ backgroundColor: '#ffffff' }}>, 202</span>
                                <span style={{ backgroundColor: '#ffffff' }}>2</span>
                                <span style={{ backgroundColor: '#ffffff' }}>.</span>
                            </p>
                            <p
                                style={{
                                    marginTop: 0,
                                    marginBottom: 8,
                                    textAlign: 'justify',
                                    fontSize: 14,
                                }}
                            >
                                <span>&nbsp;</span>
                            </p>
                        </div>
                        <div
                            id="monica-content-root"
                            // className={classNames(styles["monica-widget"])}
                            data-darkreader-mode="dynamic"
                            data-darkreader-scheme="dark"
                        >
                            <meta name="darkreader" content="888b0d0bc68a4b619cc8e01724151b5d" />
                        </div>
                    </div>
                    {isModal && (
                        <>
                            <div className={classNames(styles['checkbox-box'])}>
                                <input
                                    type="checkbox"
                                    name="accepted"
                                    id="accepted"
                                    onChange={(event) => Accepted(event)}
                                    value={value_accepted.toString()}
                                />
                                <label htmlFor="accepted" style={{ margin: 0 }}>
                                    I have read and agree to the terms and conditions
                                </label>
                            </div>
                            <div className={classNames(styles['buttons-box'])}>
                                <button
                                    onClick={(e: any) => {
                                        onClickSave(e);
                                    }}
                                    className="inline-block rounded border px-12 py-3 text-sm font-medium focus:outline-none focus:ring border-primary-buttons text-white bg-primary-buttons hover:bg-white hover:text-primary-buttons"
                                    disabled={!value_accepted}
                                >
                                    Save
                                </button>
                                <Button
                                    click={() => close_(false)}
                                    styleProp="inline-block rounded border px-12 py-3 text-sm font-medium focus:outline-none focus:ring border-primary-buttons bg-white text-primary-buttons hover:bg-primary-buttons hover:text-white"
                                >
                                    Cancel
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </form>
        </div>
    );
};
