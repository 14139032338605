import { group } from 'console';
import styles from './mock-up.module.scss';
import mso from '/reports/mso.png';
export interface MockUpProps {
    className?: string;
    data: any;
}

export const MockUp = ({ data }: MockUpProps) => {
    // console.log("data in Mockup",data);
    const getName = () => data?.name?.split('_');

    const getDivision = () => (data?.division_name === 'IM' ? 'MX' : data?.division_name);

    const getDivisionBg = () =>
        data.backgroundD ? data.backgroundD : getDivision() !== 'DA' ? '#7188AB' : '#C2D1E2';

    const getTitle = () => {
        if (data.cover_name) {
            if (data?.name.includes('glp_dashboard')) {
                return data.description;
            }
            return data.cover_name;
        } else if (getName()?.includes('ms')) {
            return 'Market Share by POS';
        } else if (getName()?.includes('ds')) {
            return 'Display Share by POS';
        } else if (getName()?.includes('re')) {
            return 'Trade Marketing Report';
        } else {
            return data?.report || data?.description;
        }
    };

    const getTitleBg = () => {
        // if (
        //   getName()?.includes("ms") ||
        //   data?.report?.includes("One") ||
        //   getName()?.includes("sa")
        // ) {
        //   return "#7188AB";
        // } else if (
        //   getName()?.includes("ds") ||
        //   data?.report === "Data Control"
        // ) {
        //   return "#BEBEBE";
        // } else if (getName()?.includes("dead") || getName()?.includes("aw")) {
        //   return "#A1B3C8";
        // } else if (
        //   getName()?.includes("run") ||
        //   getName()?.includes("sd")
        // ) {
        //   return "#C2D1E2";
        // } else if (getName()?.includes("war")) {
        //   return "#282828";
        // } else if (getName()?.includes("stock")) {
        //   return "#7483B1";
        // } else if (getName()?.includes("tr")) {
        //   return "#92A557";
        // } else if (getName()?.includes("rmf")) {
        //   return "#BB757C";
        // } else if (getName()?.includes("glp")) {
        //   return "#154d87";
        // console.log("get background",data)

        if (data.background_color || data.color) {
            return data.background_color || data.color;
        } else {
            return '#66D0AF';
        }
    };

    const getIfImg = () => !getName()?.includes('commerce');

    const getGroup = () => {
        const gv = data?.name?.split('_').slice(-1)[0];
        const groupD =
            data?.division_name === 'DA' && data?.name?.includes('re')
                ? gv?.toUpperCase() !== 'UNDEFINED' &&
                  typeof parseInt(gv) === 'number' &&
                  parseInt(gv) > 0
                    ? 'Version # ' + gv + ' | ' + data?.name?.split('_').slice(-2)[0].toUpperCase()
                    : typeof gv === 'string'
                    ? data?.name?.split('_').slice(-1)[0].toUpperCase()
                    : data?.division_name
                : !data?.name?.includes('re') &&
                  typeof parseInt(gv) === 'number' &&
                  parseInt(gv) > 0
                ? 'Version # ' + gv
                : false;
        return groupD;
    };

    return (
        <div className={styles['mock-up']}>
            <div
                className={styles['top']}
                style={{ justifyContent: !getIfImg() ? 'flex-end !important' : '' }}
            >
                {getIfImg() && <img src={mso} alt="" />}
                {getDivision() && (
                    <div className={styles['division-box']}>
                        {getGroup() && <p className={styles['group']}>{getGroup() || ''}</p>}
                        {getDivision() !== '' && getDivision() !== 'default' && (
                            <div
                                className={styles['division']}
                                style={{ background: getDivisionBg() }}
                            >
                                {getDivision()}
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className={styles['report']} style={{ background: getTitleBg() }}>
                {getTitle()} {data.description && data.description.includes('PBI') ? 'PBI' : ''}
            </div>
            <div className={styles['confidential']}>Confidential</div>
        </div>
    );
};
