import { FunnelIcon } from '@heroicons/react/24/outline';
import styles from './filter-button.module.scss';
import { useContext } from 'react';
import { RMContext } from '../../context/RMContext';
export interface FilterProps {
    className?: string;
    close: (v: boolean) => void;
    top?: number;
    regularFormat?: boolean;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const FilterButton = ({ close, top = 0, regularFormat }: FilterProps) => {
    const { isLocal } = useContext(RMContext).userData;
    return (
        <>
            {!regularFormat ? (
                <div
                    className="absolute left-0 bg-[#2E323F] text-white cursor-pointer"
                    onClick={() => close(false)}
                    id="filter-button"
                    style={{ zIndex: 9999, top: `${top}px`, marginLeft: isLocal ? '80px':'0px'}}
                >
                    <FunnelIcon className="h-8 w-8" />
                </div>
            ) : (
                <div
                    className={styles.button}
                    onClick={() => close(false)}
                    id="filter-button"
                    style={{
                        zIndex: 9999,
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                        style={{ height: '20px', color: 'white' }}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
                        />
                    </svg>
                    <h1
                        style={{
                            marginLeft: '5px',
                            fontSize: '14px',
                            fontWeight: '500',
                            color: 'white',
                        }}
                    >
                        Filters
                    </h1>
                </div>
            )}
        </>
    );
};
