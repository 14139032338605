import { useContext, useEffect, useState } from 'react';
import styles from './sidebar-menu.module.scss';
import { GenericModal } from '../generic-modal/generic-modal';
import {
    ChevronDownIcon,
    ChevronRightIcon,
    Cog6ToothIcon,
    UserIcon,
} from '@heroicons/react/24/outline';
import { NavLink, useNavigate } from 'react-router-dom';
import { RMContext } from '../../context/RMContext';
import logo from '../../assets/logo_white_2x.svg';
import { SecurityPolicies } from '../security-policies/security-policies';
import { ChangePassword } from '../change-password/change-password';
import { returnToLogin } from '../../utils/functions';

const SideBar = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenSecond, setIsOpenSecond] = useState(false);
    const { userData, setUserData } = useContext(RMContext);
    const [treeData, setTreeData] = useState<any>();
    const viewsWithChildren = ['glp_v2', 'settings', 'coming_soon', 'mdm'];
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [showSecurityPolicies, setShowSecurityPolicies] = useState(false);
    const [showSideBar, setShowSideBar] = useState(true);
    const convertTreeData = async (data: any) => {
        const dataToSideBar = data.map((item: any) => {
            return {
                title: item?.description,
                link: '/' + item.name,
                code: item?.description?.substring(0, 2)?.toUpperCase(),
                children: !viewsWithChildren.some((v: any) => v === item.name)
                    ? []
                    : item?.children.map((item2: any) => {
                          return {
                              title: item2?.description.toUpperCase(),
                              link:
                                  item2?.name === 'data_control'
                                      ? '/' + item.name + '/data_control/' + item2.name
                                      : '/' + item.name + '/' + item2.name,
                              code: item2?.description?.substring(0, 2)?.toUpperCase(),
                          };
                      }),
            };
        });
        setTreeData(dataToSideBar);
    };
    useEffect(() => {
        if (userData.authOptions && userData?.authOptions?.password === false) {
            setShowChangePassword(true);
        } else {
            setShowChangePassword(false);
        }
        //validate if agreed terms
        if (userData.authOptions && userData?.userData?.agreement === false) {
            setShowSecurityPolicies(true);
        } else {
            setShowSecurityPolicies(false);
        }
    }, [userData.authOptions]);
    useEffect(() => {
        if (showChangePassword === true) showModal('changePasswordModal');
        //else showModal('');
    }, [showChangePassword]);

    useEffect(() => {
        if (showChangePassword === false && showSecurityPolicies === true)
            showModal('securityModal');
        //else showModal('');
    }, [showChangePassword, showSecurityPolicies]);
    const handleOpenModal = (id: string) => {
        if (id !== '') {
            const dialog = document.getElementById(id);
            //@ts-expect-error
            if (dialog) dialog.showModal();
        }
    };

    const showModal = (id: string) => {
        if (id !== '') handleOpenModal(id);
    };

    const handleCloseModal = (id: string, acepted: boolean = false) => {
        const dialog = document.getElementById(id);
        //@ts-expect-error
        if (dialog) dialog.close();
        if (id === 'changePassword') setShowChangePassword(false);
        if (id === 'securityModal') {
            setShowSecurityPolicies(false);
            if (!acepted) {
                setShowSideBar(false);
                returnToLogin(
                    navigate,
                    false,
                    false,
                    false,
                    false,
                    'You have to accept security policies'
                );
            }
        }
    };
    const getChangePasswordComponent = () => {
        const user_id = userData?.userData?.id;
        return (
            <ChangePassword
                userData={userData}
                setUserData={setUserData}
                user_id={user_id}
                className={styles.changePasswordModal}
                handleClose={handleCloseModal}
            ></ChangePassword>
        );
    };

    const getSecurityPolicesComponent = () => {
        return (
            <SecurityPolicies
                userData={userData}
                setUserData={setUserData}
                className={styles.changePasswordModal}
                handleClose={handleCloseModal}
                isModal={true}
            ></SecurityPolicies>
        );
    };

    useEffect(() => {
        if (userData.sitemap) convertTreeData(userData.sitemap);
    }, [userData.sitemap]);
    const handleOpen = () => {
        setIsOpen(true);
    };
    const openSendLevel = (item: any) => {
        setIsOpenSecond(item);
    };
    const closeSendLevel = (item: any) => {
        if (item == isOpenSecond) {
            setIsOpenSecond(false);
        } else {
            setIsOpenSecond(item);
        }
    };
    const toggle = () => {
        setIsOpen(!isOpen);
        setIsOpenSecond(false);
    };

    const handleMouseLeave = () => {
        setIsOpen(false);
        setIsOpenSecond(false);
    };

    return (
        <div
            className={styles.container}
            onMouseLeave={handleMouseLeave}
            style={{ display: showSideBar ? '' : 'none' }}
        >
            <div style={{ width: isOpen ? '290px' : '78px' }} className={styles.sidebar}>
                <div className={styles.top_section}>
                    <div
                        className={styles.bars}
                        title={!isOpen ? 'Open navigation bar' : 'Close navigation bar'}
                        style={{
                            marginLeft: isOpen ? '0px' : '2px',
                            marginRight: '7px',
                            marginTop: '12px',
                            marginBottom: '12px',
                            transition: 'transform 0.3s',
                            transform: !isOpen ? 'rotate(0deg)' : 'none',
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="w-6 h-6"
                            onClick={toggle}
                            style={{ cursor: 'pointer', color: '#929aac' }}
                        >
                            {!isOpen ? (
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M3.50 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                                />
                            ) : (
                                <>
                                    <line x1="6" y1="6" x2="18" y2="18" />
                                    <line x1="6" y1="18" x2="18" y2="6" />
                                </>
                            )}
                        </svg>
                    </div>
                    <NavLink to={'/'}>
                        <h1 style={{ display: isOpen ? 'block' : 'none' }} className={styles.logo}>
                            <img src={logo} alt="logo" width="150" style={{ marginTop: '2px' }} />
                        </h1>
                    </NavLink>
                </div>
                <div className={styles.optionsMenu}>
                    {treeData &&
                        treeData.length > 0 &&
                        treeData.map((item: any, index: number) => (
                            <div
                                key={index}
                                className={styles.linkT + ' ' + styles.containerLi}
                                onClick={() => {
                                    isOpenSecond == false
                                        ? openSendLevel(isOpen ? item.title : false)
                                        : closeSendLevel(item.title);
                                    toggle;
                                    handleOpen();
                                }}
                            >
                                <div className={styles.descriptionMenu}>
                                    <div className={styles['icon-thumbnail']}>
                                        <div>
                                            {item.code === 'SE' ? (
                                                <Cog6ToothIcon className={styles.iconCode} />
                                            ) : (
                                                item.code
                                            )}
                                        </div>
                                    </div>
                                    <div className={styles.secondElement}>
                                        <div
                                            style={{ display: isOpen ? 'block' : 'none' }}
                                            className={styles.link_text}
                                        >
                                            {item.children.length == 0 ? (
                                                <NavLink
                                                    to={
                                                        item.children.length === 0
                                                            ? item.link
                                                            : item.children[0].link
                                                    }
                                                    key={index}
                                                    className={styles.link}
                                                    style={{ textAlign: 'left', minWidth: '100px' }}
                                                >
                                                    {item.title}
                                                </NavLink>
                                            ) : (
                                                <div
                                                    key={index}
                                                    className={styles.link}
                                                    style={{ textAlign: 'left', minWidth: '100px' }}
                                                >
                                                    {item.title}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={styles.thirdElement}>
                                        {item.children?.length > 0 && isOpen && (
                                            <>
                                                {isOpenSecond !== item.title ? (
                                                    <div>
                                                        <ChevronRightIcon
                                                            className={styles.arrowIcon}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <ChevronDownIcon
                                                            className={styles.arrowIconDown}
                                                        />
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                                {item.children?.length > 0 && isOpenSecond === item.title && (
                                    <div
                                        className={styles.subMenu}
                                        style={{
                                            display: isOpenSecond ? 'block' : 'none',
                                            height: '5px',
                                        }}
                                    >
                                        {item.children.map((lvl2: any) => (
                                            <NavLink
                                                to={lvl2.link ? lvl2.link : ''}
                                                key={lvl2.title + index}
                                                className={styles.itemSubMenu}
                                                style={{ padding: '10px 0px' }}
                                            >
                                                {lvl2.title}
                                                {item.code === 'SE' ? (
                                                    <UserIcon className={styles.iconCodeL2} />
                                                ) : (
                                                    <></>
                                                )}
                                            </NavLink>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                </div>
            </div>
            <>
                {showChangePassword && (
                    <>
                        modalll change pass
                        <GenericModal
                            closeDialog={handleCloseModal}
                            haveComponent={true}
                            getComponent={getChangePasswordComponent()}
                            className="changePasswordModal"
                            name="changePasswordModal"
                            showCloseOptions={false}
                            isMultiple={true}
                            isClosable={false}
                        ></GenericModal>
                    </>
                )}
                {!showChangePassword && showSecurityPolicies && (
                    <GenericModal
                        closeDialog={handleCloseModal}
                        haveComponent={true}
                        getComponent={getSecurityPolicesComponent()}
                        className="securityModal"
                        name="securityModal"
                        showCloseOptions={false}
                        isMultiple={true}
                        isClosable={false}
                    ></GenericModal>
                )}
            </>
        </div>
    );
};

export default SideBar;
