import styles from './master-file-comparison-table.module.scss';
import classNames from 'classnames';

export interface MasterFileComparisonTableProps {
    className?: string;
    data : object | any
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const MasterFileComparisonTable = ({ className,data = {Column1:"Data1",Column2:"Data1"} }: MasterFileComparisonTableProps) => {
    return (
        <div className={classNames(styles.root, className)}>
            <table className={styles.table}>
                <thead>
                    { Object.keys(data).map((k,i) => (
                            <th className={styles.tableHeader} key={"tableheader"+k+i.toString()}>{k}</th>
                    )) }
                    
                </thead>
                <tbody>
                    <tr>
                        {Object.keys(data).map((k,i) => (
                            <td className={styles.tableCell} key={"tableheader"+k+i.toString()}>{data[k]}</td>
                    )) }
                        
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
