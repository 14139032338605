import styles from './tree-view-roles.module.scss';
import classNames from 'classnames';
import { TreeViewNestedRow } from '../tree-view-nested-row/tree-view-nested-row';
import { CustomCheckbox } from '../custom-checkbox/custom-checkbox';
import { TreeViewHeader } from '../tree-view-header/tree-view-header';
import { useEffect, useState } from 'react';
import { updateRoles, getAllRoles } from '../../services/python-services';


export interface TreeViewRolesProps {
    className?: string;
    jsonObject: any;
    roleId: any;
    arrayOfPreSelected: any;
    dataLoaded: boolean;
}

type Data = {
    [key: string]: any;
    countries: object[],
    views: object[],
    divisions: object[],
};
/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const TreeViewRoles = ({ className, jsonObject, arrayOfPreSelected, dataLoaded }: TreeViewRolesProps) => {

    
    const [currentNodeValue, setCurrentNodeValue] = useState(false)
    const [currentNodeClicked, setCurrentNodeClicked] = useState(false)
    /* const [arrayOfPreSelected, setArrayOfPreSelected] = useState([] as any) */
    /* const [dataLoaded, setDataLoaded] = useState(false); */
    
    let roleData: any

    
    /* async function setArray(id: number) {
        let resData: { views: { id: number }[] } = await getAllRoles(id);
        let holder = resData.views.map((x) => x.id)
        let secondHolder = [...holder]
        setArrayOfPreSelected(secondHolder)
        let thirdHolder = JSON.stringify(secondHolder)
        localStorage.setItem("arrayOfIds", thirdHolder)
    } */

    /* useEffect(() => {
        setArray(roleId)
            .then(() => {
                setDataLoaded(x=>!x); // Mark data as loaded
            });
    }, []); */

    useEffect(() => {
        
    }, []);


    function addIdsArray(id: number, node: boolean) {
        if (node && localStorage.getItem("arrayOfIds"))  {
            const a:any = localStorage.getItem("arrayOfIds")
            const b:any = JSON.parse(a)
            const c:any =[...b,id]
            const d:any = JSON.stringify(c)
            localStorage.setItem("arrayOfIds", d )
        }
        else if (!node) {
            const a: string | null = localStorage.getItem("arrayOfIds");
            if (a) {
                const b: number[] = JSON.parse(a);
                const c: number[] = b.filter((x) => x !== id);
                const d = JSON.stringify(c)
                localStorage.setItem("arrayOfIds", d)
            }
        }
    }


    useEffect(() => {
        addIdsArray(jsonObject["id"], currentNodeValue)
    }, [currentNodeValue]);
    
    const calculateDepth = (node: any, currentDepth: number): number => {
        if (node.children && node.children.length > 0) {
            return Math.max(
                ...node.children.map((child: any) => calculateDepth(child, currentDepth + 1))
            );
        } else {
            return currentDepth;
        }
    };

    // Set the initial depth
    const initialDepth = calculateDepth(jsonObject, 1);

    function createArrayWithNumberElements(number: number) {
        return Array.from({ length: number }, (_, index) => index + 1);
    }

    const headerArray = createArrayWithNumberElements(initialDepth)


    function handleClick() {
        setCurrentNodeValue(x => !x)
        setCurrentNodeClicked(true)
    }

    const columnWidth = 100 / initialDepth + "vw"
    const depthCounter = 2

    

    return (
        <div className={classNames(styles.wholeBox)}>
            {/* <button onClick={()=>sendUpdatedNodeStatus(roleId)}>hello</button> */}
            <div className={classNames(styles.flex)}>
                {headerArray.map(number => { return <TreeViewHeader key={number}>View {number}</TreeViewHeader> })}
            </div>
            <div className={classNames(styles.root, className)}>
                <div style={{ width: columnWidth, display: "flex" }} className={classNames(styles.borders)}>
                    <CustomCheckbox handleClick={handleClick} ifChecked={currentNodeValue} />
                    <div className={classNames(styles.fontColor)}>{jsonObject.description}</div>
                </div>
                <div className={classNames(styles.verticalRows, styles.secondChild)}>
                    {dataLoaded && jsonObject.children.map((row: any, index: any) => (
                        <TreeViewNestedRow
                            addIdsArray={addIdsArray}
                            parentClicked={currentNodeClicked}
                            setParentClicked={setCurrentNodeClicked}
                            parentValue={currentNodeValue}
                            setParentValue={setCurrentNodeValue}
                            columnWidth={columnWidth}
                            key={index}
                            row={row}
                            initialDepth={initialDepth}
                            depthCounter={depthCounter}
                            arrayOfPreSelected={arrayOfPreSelected}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};



