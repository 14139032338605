// // import { createContext, useContext } from "react"
// // export type GlobalContent = {
// //   userData: any
// //   setUserData:(c: any) => void
// // }
// // export const RMContext = createContext<GlobalContent>({
// // userData: {}, // set a default value
// // setUserData: () => {},
// // })
// // export const useGlobalContext = () => useContext(RMContext)

// import React, { useState, createContext, ReactNode } from "react";
// export const initialContextState:any = {
//   userData:{},
//   authOptions: {},
//   siteMap: {},
//   isLocal: true,
// }

// interface RMContextProps {
//   children: ReactNode;
// }
// export const RMContext = createContext(initialContextState);
// export const RMContextProvider: React.FC<RMContextProps> = ({ children }) => {

//   // Status
//   const [userData, setUserData] = useState(initialContextState);

//   return (
//     <>
//       <RMContext.Provider
//         value={{
//           userData,
//           setUserData
//         }}
//       >
//         {children}
//       </RMContext.Provider>
//     </>
//   );
// };
import React, { useState, createContext, ReactNode } from 'react';

const intialFavorites: any[] = [];
export const initialContextState: any = {
    userData: {},
    authOptions: {},
    sitemap: [],
    newtree:[],
    favorites: intialFavorites,
    isLocal: false, // or false, depending on your initial requirement
    userPermissions: {},
    currentRoute: '',
    countries: [],
    userGlobals:{
        isSuperUser: false,
        canDownload: false,
        manageReports: false,
    }
};

interface RMContextProps {
    children: ReactNode;
}

export const RMContext = createContext(initialContextState);

export const RMContextProvider: React.FC<RMContextProps> = ({ children }) => {
    // Status
    const [userData, setUserData] = useState(initialContextState);

    return (
        <RMContext.Provider
            value={{
                userData,
                setUserData,
            }}
        >
            {children}
        </RMContext.Provider>
    );
};
