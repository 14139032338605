import styles from './slicer.module.scss';
import classNames from 'classnames';
// import { MaterialSymbol } from 'react-material-symbols';
//import 'react-material-symbols/dist/rounded.css';
import { SlicerItem } from '../slicer-item/slicer-item';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { Spinner } from 'flowbite-react';
import { active } from 'sortablejs';
//import { useState } from 'react';

export interface SlicerProps {
    className?: string;
    title: string;
    options: object[] | any;
    selected: Function;
    color?: string;
    isOpen?: boolean;
    filtersOpened?: any[];
    setFiltersOpened?: Function;
    type?: string;
    compactMode?: boolean;
    loadingParent?: boolean;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */

export const Slicer = ({
    className,
    title = 'Title',
    options,
    selected = () => {},
    color = '#66D0AF',
    type = '',
    isOpen = true,
    filtersOpened = [],
    setFiltersOpened = () => {},
    compactMode = false,
    loadingParent =false,
}: SlicerProps) => {
    // const [disabledReset, setDisabledReset] = useState(true);
    const [options_,setOptions] = useState(options);
    //let options_ = options;
    const [isLoading, setIsLoading] = useState(false);
    const handleOpen = (labelFilter: string, open: boolean) => {
        let openedOptions = [...filtersOpened];
        if (open) {
            openedOptions.push(labelFilter);
            if (options_.length === 0) {
                setIsLoading(true);
            }
        } else {
            openedOptions = filtersOpened.filter((filt: string) => filt !== labelFilter);
        }
        setFiltersOpened(openedOptions);
    };
    useEffect(() => {
        setIsLoading(false);
    }, [options?.length > 0 && isLoading]);

    useEffect(() => {
        setTimeout(() => setIsLoading(false), 5000);
    }, [options?.length === 0 && isLoading]);
    useEffect(() => {
        if(options.length > 0)
        setOptions(options)
    }, [options]);

    useEffect(() => {
        // console.log("options_",options_)
    }, [options_,selected]);
    const onClickItem = (value: any) => {
        let updatedValues: any;
        let updatedOptions: any;
        // if (options_.every((option: any) => option.check)) {
            updatedOptions = options_.map((option: any) => {
                return option.value === value.value
                    ? { ...option, check: true ,active:true }
                    : { ...option, check: false,active:false };
            });
        // } else {
        //     updatedOptions = options_.map((option: any) =>
        //         option.value === value.value ? { ...option, check: !option.check } : option
        //     );
        // }
        updatedValues = updatedOptions.filter((option: any) => option.check);
        if (updatedOptions.every((option: any) => !option.check)) {
            updatedOptions = options_.map((option: any) => ({ ...option, check: true }));
        }
        setIsLoading(true);
        //options_ = updatedOptions;
        setOptions(updatedOptions)

        selected(updatedValues);

        // setDisabledReset(false);
    };

    // const resetOptions = () => {
    //     const updatedOptions: any = options_.map((option: any) => ({
    //         ...option,
    //         check: true,
    //         active: true,
    //     }));
    //     console.log(updatedOptions);
    //     options_ = updatedOptions;
    //     selected([], 'reset');
    //     // setDisabledReset(true);
    // };
    return (
        <div
            className={
                classNames(styles.root, className) +
                ' flex w-full flex-col  bg-white rounded-md border ' +
                styles.clickable
            }
        >
            <div
                className="flex w-full justify-between px-2 items-center"
                style={{ borderColor: color }}
                onClick={() => handleOpen(type, !isOpen)}
            >
                <h5 className=" text-primary-buttons text-sm font-bold">{title}</h5>
                {!isOpen ? (
                    <ChevronDownIcon className={styles.arrowIcon} />
                ) : (
                    <ChevronUpIcon
                        className={styles.arrowIcon}
                        // onClick={() => handleOpen(type, false)}
                    />
                )}
                {/* <button
                    style={
                        disabledReset
                            ? { color: '#ccc', cursor: 'not-allowed' }
                            : { color: 'black', cursor: 'pointer' }
                    }
                    onClick={resetOptions}
                    disabled={disabledReset}
                >
                    <MaterialSymbol icon="filter_alt_off" size={24} />
                </button> */}
            </div>
            {(isLoading || loadingParent) && (
                <div
                    style={{ gap: '1px' }}
                    className="flex px-2 py-1 overflow-x-auto max-h-[175px]"
                >
                    <Spinner size="sm" />
                </div>
            )}
            {isOpen && (!isLoading && !loadingParent) && (
                <div
                    style={{ gap: '1px', margin: '0px' }}
                    className={
                        'px-2 overflow-x-auto max-h-[175px]' +
                        (compactMode ? ' py-0 ' : ' flex flex-col py-1 ')
                    }
                >
                    {options_?.length > 0 &&
                        options_?.map((item: any, i: number) => (
                            <SlicerItem
                                title={item?.label}
                                key={`${item?.label}_${item?.value}_${i}`}
                                check={item?.check}
                                prop={item}
                                click={onClickItem}
                                active={item?.active}
                                compactMode={compactMode}
                            />
                        ))}
                    {options_?.length === 0 && (
                        <div
                            style={{ gap: '1px', margin: '0px' }}
                            className={
                                'px-2 overflow-x-auto max-h-[180px]  py-1 flex ' +
                                (compactMode ? styles.compactMode : '  flex-col ')
                            }
                            id="no-data"
                        >
                            <p>
                                {' '}
                                <strong>
                                    <span>No filtering options available.</span>
                                </strong>
                                Click on "reset" button below to clean up the filters
                            </p>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
