import styles from './tree-base.module.scss';
import classNames from 'classnames';
import { getTreeViews } from '../../services/python-services';
import { TreeViewRoles } from '../tree-view-roles/tree-view-roles';
import { useState, useEffect } from 'react';
import { Button } from '../button/button';
import { updateRoles, getAllRoles } from '../../services/python-services';
import { useParams } from 'react-router-dom';
import Notiflix from 'notiflix';

export interface TreeBaseProps {
    className?: string;
}

type TreeData = {
    // Define the structure of your data here
};

// Define a type for the Axios response
type TreeResponse = {
    data: TreeData[];
};

export const TreeBase = ({}: TreeBaseProps) => {
    const { t, id } = useParams<{ t: string; id: any }>();
    const [tree, setTree] = useState<TreeData[]>([]);
    const [arrayOfPreSelected, setArrayOfPreSelected] = useState([] as any);
    const [dataLoaded, setDataLoaded] = useState(false);
    let roleData: any;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getTreeViews(id);
                setTree(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    async function sendUpdatedNodeStatus(id: any) {
        const resData: any = await getAllRoles(id);
        roleData = resData;
        let unmodifiedBodyHolder = { ...roleData };
        /*you need to first transform your unmodified data to the payload and then send it as a body in the patch api  */
        let countriesIdsArray = (unmodifiedBodyHolder['countries'] as object[]).map(
            (x: any) => x['id']
        );
        let accountsIdsArray = (unmodifiedBodyHolder['accounts'] as object[]).map(
            (x: any) => x['id']
        );
        let viewsIdsArray = (unmodifiedBodyHolder['views'] as object[]).map((x: any) => x['id']);
        let divisionsIdsArray = (unmodifiedBodyHolder['divisions'] as object[]).map(
            (x: any) => x['id']
        );

        const a: any = localStorage.getItem('arrayOfIds');
        const b: any = JSON.parse(a);
        let payload = {
            role_name: unmodifiedBodyHolder['role_name'],
            status: true,
            countries: countriesIdsArray,
            accounts: accountsIdsArray,
            views: [...b],
            divisions: divisionsIdsArray,
        };
        const response = await updateRoles(id, payload);
        if (response) {
            Notiflix.Notify.success('Views updated successfully!');
        } else {
            Notiflix.Notify.failure("Views can't be updated!");
        }
    }

    async function setArray(id: number) {
        let resData: { views: { id: number }[] } = await getAllRoles(id);
        let holder = resData.views.map((x) => x.id);
        let secondHolder = [...holder];
        setArrayOfPreSelected(secondHolder);
        let thirdHolder = JSON.stringify(secondHolder);
        localStorage.setItem('arrayOfIds', thirdHolder);
    }

    useEffect(() => {
        setArray(id).then(() => {
            setDataLoaded((x) => !x); // Mark data as loaded
        });
    }, []);

    return (
        <div>
            <div className={classNames(styles.container)}>
                <Button
                    styleProp={
                        'inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex gap-1 items-center w-[75px] mr-2 justify-center '
                    }
                    click={() => sendUpdatedNodeStatus(id)}
                    children={
                        <div className="flex gap-2">
                            Save
                            {/*  {loading ? (<Spinner size="sm" />) : (<></>) } */}
                        </div>
                    }
                ></Button>
            </div>
            <div className={classNames(styles.verticalTrees)}>
                {tree.map((object, index) => (
                    <TreeViewRoles
                        roleId={id}
                        key={index}
                        jsonObject={object}
                        arrayOfPreSelected={arrayOfPreSelected}
                        dataLoaded={dataLoaded}
                    />
                ))}
            </div>
        </div>
    );
};
