import styles from './link.module.scss';
import classNames from 'classnames';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';

export interface LinkProps {
    className?: string;
    label: string;
    link: string;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const Link = ({ className, label = 'default label', link = '#' }: LinkProps) => {
    return (
        <div className={classNames(styles.root, className)}>
            <a href={link} className={styles.link} target="_blank" rel="noreferrer">
                {label}
                <ArrowTopRightOnSquareIcon className={styles['link-icon']} />
            </a>
        </div>
    );
};
