import styles from './passwordless-component.module.scss';
import { useEffect, useContext } from 'react';
import { Spinner } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';
import { RMContext } from '../../context/RMContext';

import { clearLocalStorage, executeLoginProcess, returnToLogin, updateIsLocalToContext } from '../../utils/functions';
import logo from "../../assets/logo_2x.svg";

export interface PasswordLessComponentProps {
}

export const PasswordLessComponent = ({ }: PasswordLessComponentProps) => {
  const navigate = useNavigate();
  const { setUserData, userData } = useContext(RMContext);
  const url = window.location.href;
  const token = url.split("?t=")[1];

  useEffect(() => {
    if(token !== undefined && token !== "") {
      localStorage.setItem("loggingIn", "true");
      executeLoginProcess(token, setUserData, userData, navigate);
      updateIsLocalToContext(setUserData, userData, true);
    }
    else{
      returnToLogin(navigate,setUserData,false,true);
    }
  }, [token]);


  return (
    <div className={styles.box}>
      <img src={logo} alt="logo" width="150"
        className={styles.img} />

      <div className={styles.boxloading}>
        <Spinner></Spinner>
      </div>
    </div>

  );;
};






