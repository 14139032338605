import { useEffect, useState } from 'react';
import styles from './sso-login.module.scss';
import classNames from 'classnames';
import { postActionLogs } from '../../services/python-services';
import { LoginComponent } from '../login-component/login-component';
import { executeSSOLoginProcess, getEnvironment, validatePassword } from '../../utils/functions';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Spinner } from 'flowbite-react';
import {
    OTPemail,
    getToken,
    linkSendEmail,
    verifyEmail,
    verifyEmailAndPassword,
} from '../../services/python-services';
import Notiflix from 'notiflix';
import AuthCode from 'react-auth-code-input';
import { useNavigate } from 'react-router-dom';

export interface SsoLoginProps {}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const SsoLogin = ({}: SsoLoginProps) => {
    // const sso_login_id = 640;
    const [maintenance, setMaintenance] = useState();
    const [passwordError, setPasswordError] = useState<any>([]);
    const [spinnerOnNext, setSpinnerOnNext] = useState(false);
    const [authOptions_, setAuthOptions_] = useState<{ password: boolean | null }>({
        password: null,
    });

    const [upCounter, setUpCounter] = useState(0);
    const [counterDown, setCounterDown] = useState(5);
    const [wrongEntry, setWrongEntry] = useState(false);

    const [userName, setUserName] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [OTP, setOTP] = useState('');
    const [password, setPassword] = useState('');

    const [authentication, setAuthentication] = useState(false);

    const [inputPassword, setInputPassword] = useState(false);
    const [showNext, setShowNext] = useState(true);
    const [otpLoading, setOTPLoading] = useState(false);
    const [showOTP, setShowOTP] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const [loadingLink, setLoadingLink] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetch('https://4k5vc4z6nxi7ztcbyre4bygqt40nuinx.lambda-url.us-east-1.on.aws/')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                const URL = window.location.href;
                let env = getEnvironment(URL);
                env !== null && setMaintenance(data[env].mantainance_RM);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [maintenance]);

    useEffect(() => {
        if (OTP.length === 6) {
            const fetchToken = async () => {
                try {
                    const body = {
                        email: userName,
                        password: OTP,
                    };
                    // console.log('enter then error', body.password);
                    const response = await getToken(body);
                    if (response.access) {
                        executeSSOLoginProcess(
                            response.access
                            //  setUserData, userData,
                            //navigate
                        );
                        setUserPassword('');
                        setShowOTP(false);
                        setInputPassword(true);
                        setShowNext(true);
                    } else if (response.detail) {
                        Notiflix.Notify.failure(response.detail);
                    }
                } catch (error) {
                    console.log(error);
                    setWrongEntry(true);
                    Notiflix.Notify.failure('Invalid OTP');
                }
            };

            fetchToken();
            setUpCounter((x) => x + 1);
            setCounterDown((x) => x - 1);
        }
    }, [OTP]);

    const handleSubmit = (e: any) => {
        e.preventDefault(); // Prevent the default form submission behavior
        // Your logic for handling the form submission
    };
    const conditionalFlex = classNames(
        styles['boxButtons'], // Always include this class
        styles[!showNext ? 'spaceBetween' : 'end']
    );

    const handleOTPEntered = (res: any) => {
        setOTP(res);
    };

    const setCodeFreeze = () => {
        return upCounter >= 5 ? false : true;
    };
    const authOptionsF = async (email: any) => {
        try {
            const response = await verifyEmail(email);
            // addAuthOptionsToContext(setUserData, userData, response);
            setSpinnerOnNext(false);
            if (response?.email === true) {
                setShowSuccess(true);
                return response;
            } else if (response?.email === false) {
                Notiflix.Notify.failure('User does not exist');
            } else if (response !== undefined) {
                Notiflix.Notify.failure('', response.toString());
            }
        } catch (error) {
            setSpinnerOnNext(false);
            console.log('Error getting authOptionsF', error);
            Notiflix.Notify.failure('Error:' + error);
            throw new Error(`Fetch error`);
        }
    };
    const authOptions = async () => {
        setSpinnerOnNext(true);
        if (authOptions_?.password && userPassword.length >= 1) {
            try {
                const body = {
                    email: userName,
                    password: userPassword,
                };
                const login_res = await verifyEmailAndPassword(body);
                setSpinnerOnNext(false);
                if (login_res.access) {
                    //Call log action in the sso-login view
                    const response = await postActionLogs(-1, 'sso-login');
                    if (response) {
                        setShowNext(false);
                        setInputPassword(false);
                        setShowOTP(false);
                        Notiflix.Notify.success('Login SSO success, Coming back to the Sub System');
                    }
                } else {
                    Notiflix.Notify.failure('Invalid password');
                    setShowNext(true);
                }
            } catch (error) {
                // Handle errors
                const passwordValidation = await validatePassword(userPassword);
                if (!passwordValidation.isValid) {
                    setPasswordError(passwordValidation.errorMessages);
                    console.log(passwordError);
                    Notiflix.Notify.failure('Password validation failed.');
                    return;
                }
                console.error(error);
            }
        } else {
            try {
                const authOptionsData = await authOptionsF({ email: userName });
                // setInputPassword(authOptionsData?.password ? true : false);
                setShowOTP(authOptionsData?.password ? true : false);
                if (authOptionsData.password) {
                    setAuthOptions_({ password: true });
                    setShowNext(false);
                    await otpEmail();
                } else {
                    setAuthOptions_({ password: false });
                    setShowNext(false);
                }
            } catch (error) {
                console.error('Error:');
                // Handle any errors from the API call
            }
        }
    };
    const linkEmail = async () => {
        setLoadingLink(true);
        const body = { email: userName };
        try {
            const res = await linkSendEmail(body);
            if (res.detail === 'email sent succesfully') {
                setShowSuccess(true);
                setShowOTP(false);
            }
        } catch (error) {
            // Handle errors
            console.error(error);
        } finally {
            setLoadingLink(false);
        }
    };

    const otpEmail = async () => {
        setOTPLoading(true);
        try {
            const body = { email: userName };
            const res = await OTPemail(body);
            if (res.detail === 'email sent succesfully') {
                setShowOTP(true);
                setShowSuccess(false);
                // updateIsLocalToContext(setUserData, userData, true);
            }
        } catch (error) {
            // Handle errors
            console.error(error);
        }
        setOTPLoading(false);
    };

    useEffect(() => {
        const input = document.getElementById('userPassword');
        input?.setAttribute('autocomplete', 'new-password');

        return () => {
            input?.removeAttribute('autocomplete');
        };
    }, []);
    // const sendForgotPWEmail = async () => {
    //     const body = { email: userName };
    //     try {
    //         const res = await emailResetPassword(body);
    //         if (res.detail === 'email sent succesfully') {
    //             Notiflix.Notify.success('Email sent succesfully');
    //             navigate(`/forgot-password?email=${userName}&code=`);
    //         }
    //     } catch (error) {
    //         // Handle errors
    //         console.error(error);
    //     }
    // };

    return (
        <LoginComponent>
            {maintenance === 0 && (
                <div>
                    <h4 className={classNames(styles['welcomeBack'])}>Welcome back.</h4>
                    <h4 className={classNames(styles['welcomeBack'])}>SSO Login</h4>
                    <p className={classNames(styles['welcomeBackSubscript'])}>
                        please sign in to your account
                    </p>
                    <form className={classNames(styles['pt5'])} onSubmit={handleSubmit}>
                        <div className={classNames(styles['formGroup'])}>
                            <div className={classNames(styles['formInputGroup'])}>
                                <label className={classNames(styles['emailLabel'])}>Email</label>
                                <input
                                    type="email"
                                    id="loginUserName"
                                    name="userName"
                                    value={userName}
                                    onChange={(e) => {
                                        setUserName(e.target.value);
                                    }}
                                    disabled={authentication}
                                    required
                                    className={classNames(styles['formControl'])}
                                />
                            </div>
                        </div>
                        {inputPassword && (
                            <div className={classNames(styles['formGroup'])}>
                                <div className={classNames(styles['formInputGroup'])}>
                                    <label className={classNames(styles['emailLabel'])}>
                                        Password
                                    </label>
                                    <input
                                        type="password"
                                        id="userPassword"
                                        name="userPassword"
                                        value={userPassword}
                                        onChange={(e: any) => {
                                            setUserPassword(e.target.value);
                                            setPassword(e.target.value);
                                        }}
                                        // autoComplete="off"
                                        disabled={authentication}
                                        required
                                        className={classNames(styles['formControl'])}
                                        autoComplete="new-password"
                                    />
                                </div>
                            </div>
                        )}
                        {passwordError.length > 0 && (
                            <div>
                                Please, for continuing access, click on the Forgot your Password?
                                link below.
                                {passwordError.map((x: any) => (
                                    <div
                                        style={{
                                            color: 'red',
                                            textAlign: 'left',
                                            display: 'flex',
                                            gap: '5px',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {' '}
                                        <ExclamationTriangleIcon className="w-4 h-4" /> {x}
                                    </div>
                                ))}
                            </div>
                        )}
                        {/* {inputPassword && (
                            <div
                                className={classNames(styles['forgotText'])}
                                onClick={() => {
                                    sendForgotPWEmail();
                                    //navigate('/forgot-password');
                                }}
                            >
                                Forgot your Password?
                                {sendResetPassword && <p>Loading...</p>}
                            </div>
                        )} */}
                        <div className={conditionalFlex}>
                            {showNext && (
                                <button
                                    onClick={authOptions}
                                    className={classNames(styles['nextButton'])}
                                    type="submit"
                                >
                                    Next
                                    {spinnerOnNext && <Spinner size="sm" />}
                                </button>
                            )}
                            {/* {!showNext && (
                                <button
                                    style={
                                        !setCodeFreeze()
                                            ? { backgroundColor: '#C4C4C4' }
                                            : { backgroundColor: '#2E323F' }
                                    }
                                    className={classNames(styles['loginWithOtp'])}
                                    disabled={!setCodeFreeze()}
                                    onClick={otpEmail}
                                    type="button"
                                >
                                    <span>{!authentication ? 'Login with OTP' : ''}</span>
                                    {otpLoading && <Spinner size="sm" />}
                                </button>
                            )} */}
                            {/* {!showNext && (
                                <button
                                    style={
                                        !setCodeFreeze()
                                            ? { backgroundColor: '#C4C4C4' }
                                            : { backgroundColor: '#2E323F' }
                                    }
                                    className={classNames(styles['loginWithLink'])}
                                    disabled={!setCodeFreeze()}
                                    onClick={linkEmail}
                                    type="button"
                                >
                                    <span>{!authentication ? 'Login with Link' : ''}</span>
                                    {loadingLink && <Spinner size="sm" />}
                                </button>
                            )} */}
                        </div>
                        {showOTP && (
                            <div className={classNames(styles['insertOTPWrapper'])}>
                                <p className={classNames(styles['insertOTP'])}>
                                    {setCodeFreeze() ? 'Insert OTP code' : ''}
                                </p>
                                <p className={classNames(styles['OTPText'])}>
                                    {setCodeFreeze()
                                        ? 'You should have received an OTP code to the email you entered'
                                        : ''}
                                </p>
                                {setCodeFreeze() && ( // Remove redundant curly braces
                                    <AuthCode
                                        onChange={handleOTPEntered}
                                        length={6}
                                        containerClassName={classNames(styles['OTP'])}
                                        inputClassName={classNames(styles['inputBoxes'])}
                                    />
                                )}
                            </div>
                        )}
                        {showSuccess && (
                            <div className={classNames(styles['succesfulEmailSent'])}>
                                <strong>Success: </strong>The link has been sent to your email.
                            </div>
                        )}
                    </form>
                    {!setCodeFreeze() && (
                        <div className={classNames(styles['errorMessage'])}>
                            Your account {userName} has been suspended for 10 minutes for repeated
                            failed login attempts. Please try again later.
                        </div>
                    )}
                    {wrongEntry && (
                        <div className={classNames(styles['errorMessage'])}>
                            Incorrect OTP. <strong>Please try again </strong> ({counterDown}{' '}
                            opportunities left)
                        </div>
                    )}
                    <div className={classNames(styles['bottomWrapper'])}>
                        <div className={classNames(styles['bottomWrapperInner'])}>
                            <div>
                                <p>
                                    <small className={classNames(styles['bottomText'])}>
                                        Retail Management 2024 | By. Cheil Central America.
                                    </small>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {maintenance == 1 && (
                <div>
                    <h4 className={classNames(styles['welcomeBack'])}>
                        Website currently under maintenance
                    </h4>
                    <br />
                    <p className={classNames(styles['welcomeBackSubscript'])}>
                        We are very sorry for the inconvenience but our website is currently
                        undergoing scheduled maintenance.
                    </p>
                    <p className={classNames(styles['welcomeBackSubscript'])}>
                        Please, check back shortly.
                    </p>
                </div>
            )}
        </LoginComponent>
    );
};
