
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import { RMContextProvider } from './context/RMContext';



const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    // <React.StrictMode>
    <RMContextProvider >
        <App />
    </RMContextProvider>
    // </React.StrictMode>
);
