/* eslint-disable react-hooks/exhaustive-deps */
import styles from './master-file-data-comparison.module.scss';
import classNames from 'classnames';
import { MasterFileComparisonItem } from '../master-file-comparison-item/master-file-comparison-item';
import { ArrowDownTrayIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link } from '../link/link';
import { DownloadLink } from '../download-link/download-link';
import { Loader } from '../loader/loader';
import { getStoreProfileLInk } from '../../services/python-services';
import { Button } from '../button/button';
import jsPDF from 'jspdf';
import { useRef, useState } from 'react';
import { PointOfSale } from '../../utils/interfaces';
/* import html2canvas from 'html2canvas'; */

export interface MasterFileDataComparisonProps {
    className?: string;
    setClosedComparison: Function;
    data: PointOfSale;
    dataLoaded: Boolean;
    userEmail?: string;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const MasterFileDataComparison = ({
    className,
    setClosedComparison,
    data,
    dataLoaded = true,
    userEmail = '',
}: MasterFileDataComparisonProps) => {
    const [showTools, setShowTools] = useState(true);
    const linkRM: string = getStoreProfileLInk();

    const tokenMso = btoa(`{"email":"${userEmail}", "siteLocationID":${data.site_location_id}}`);

    const MsoLink: any = `https://cms.msocheil.com/auth?v=${tokenMso}`;

    const reportTemplateRef: any = useRef(null);

    const handleExportPDF = () => {
        const reportTemplate = reportTemplateRef.current;

        //aumentar ancho de reportTemplateRef
        reportTemplate.style.width = 'max-content';
        setShowTools(false);

        const doc = new jsPDF();

        doc.html(reportTemplate, {
            callback: function (pdf: any) {
                pdf.save(`${data.gscn_site_name}.pdf`);
                //regresar ancho de reportTemplateRef
                setShowTools(true);
                reportTemplate.style.width = '25%';
            },
            html2canvas: {
                scale: 0.24,
            },
            autoPaging: 'slice',
            margin: 4,
        });
    };

    return (
        <div className={classNames(styles.root, className)} ref={reportTemplateRef}>
            <div className={styles['data-comparison-header']}>
                <h2 className={styles['data-comparison-title']}>POS Data Comparison</h2>
                <XMarkIcon className="h-6" onClick={() => setClosedComparison(true)} />
            </div>

            {dataLoaded ? (
                <>
                    <MasterFileComparisonItem
                        data={{
                            RM: data.point_of_sale,
                            GSCM: data.site_id,
                            MSO: data.site_location_id,
                        }}
                        title="POS ID"
                    />
                    <MasterFileComparisonItem
                        data={{
                            CUSTOM: data.custom_account__name,
                            GSCM: data.account__name,
                            //MSO:data.mso_name
                        }}
                        title="Account"
                    />
                    <MasterFileComparisonItem
                        data={{
                            GSCM: data.gscn_site_name,
                            MSO: data.mso_name,
                        }}
                        title="Site Name"
                    />
                    <MasterFileComparisonItem
                        data={{
                            KIOSK: data.kiosco ? 'yes' : 'no',
                            PHOTO: data.photo ? 'yes' : 'no',
                            PROMOTER: data.promoter_coverage ? 'yes' : 'no',
                            UNIVERSE: data.universe ? 'yes' : 'no',
                        }}
                        title="Attributes"
                    />
                    <MasterFileComparisonItem
                        data={{
                            COUNTRY: data.country__name,
                            ZONE: data.zone__name,
                            REGION: data.region__name,
                            CITY: data.city__name,
                        }}
                        title="Location"
                    />
                    <MasterFileComparisonItem
                        data={{
                            NCA: data.rank_nca,
                            COUNTRY: data.rank_country,
                            ACCOUNT: data.rank_account,
                        }}
                        title="Rank"
                    />

                    <MasterFileComparisonItem
                        data={{
                            MSO: data.status,
                        }}
                        title="Status"
                    />
                    <MasterFileComparisonItem
                        data={{
                            MX: data.grade_im ? data.grade_im : 'N/A',
                            AV: data.grade_av ? data.grade_av : 'N/A',
                            DA: data.grade_da ? data.grade_da : 'N/A',
                        }}
                        title="Grade"
                    />
                    <MasterFileComparisonItem
                        data={{
                            MX: data.antenna_im ? data.antenna_im : 'N/A',
                            AV: data.antenna_av ? data.antenna_av : 'N/A',
                            DA: data.antenna_da ? data.antenna_da : 'N/A',
                        }}
                        title="Antenna Shop"
                    />
                    <MasterFileComparisonItem
                        data={{
                            MX: data.coverage_im ? data.coverage_im : 'N/A',
                            AV: data.coverage_av ? data.coverage_av : 'N/A',
                            DA: data.coverage_da ? data.coverage_da : 'N/A',
                        }}
                        title="Coverage"
                    />
                    <MasterFileComparisonItem
                        data={{
                            LAT: data.latitude,
                            LNG: data.longitude,
                        }}
                        title="Coordinates"
                    />
                    <MasterFileComparisonItem
                        data={{
                            2020: data['2020_gscm_available'].toString(),
                            2021: data['2021_gscm_available'].toString(),
                            2022: data['2022_gscm_available'].toString(),
                            2023: data['2023_gscm_available'].toString(),
                        }}
                        title="GSCM Data Available"
                    />
                    <MasterFileComparisonItem
                        data={{
                            2019: data['2019_ds_available'].toString(),
                            2020: data['2020_ds_available'].toString(),
                            2021: data['2021_ds_available'].toString(),
                            2022: data['2022_ds_available'].toString(),
                            2023: data['2023_ds_available'].toString(),
                        }}
                        title="Display Share Data Available"
                    />
                    <MasterFileComparisonItem
                        data={{
                            2017: data['2017_ms_available'].toString(),
                            2018: data['2018_ms_available'].toString(),
                            2019: data['2019_ms_available'].toString(),
                            2020: data['2020_ms_available'].toString(),
                            2021: data['2021_ms_available'].toString(),
                            2022: data['2022_ms_available'].toString(),
                            2023: data['2023_ms_available'].toString(),
                        }}
                        title="Display Share Data Available"
                    />

                    <div className={showTools ? 'flex flex-col gap-1 mb-2' : 'hidden'}>
                        <hr className={styles.hr} />
                        <h2 className={styles['data-comparison-subtitle']}>Data Source</h2>

                        <Link
                            label="Store Profile"
                            link={`${linkRM}/store-profile?point_of_sale_id=${data.point_of_sale}`}
                        />
                        <Link
                            label="Store Locator"
                            link={`${linkRM}/store-locator?i=${data.site_id}`}
                        />
                        <Link label="MSO" link={MsoLink} />
                        <hr className={styles.hr} />
                        <h2 className={styles['data-comparison-subtitle']}>Data Source</h2>
                        <Button
                            styleProp="flex text-[--link] items-center underline"
                            click={handleExportPDF}
                        >
                            <ArrowDownTrayIcon className="text-[--link] h-4" />
                            PDF
                        </Button>
                        <DownloadLink label="EXCEL" link="#" />
                    </div>
                </>
            ) : (
                <div className={styles.loaderContainer}>
                    <Loader />
                </div>
            )}
        </div>
    );
};
