import { useState, useEffect, useContext } from 'react';
import { object } from './object';
import styles from './dynamic-menu.module.scss';
import { TreeNode } from './tree-node';
import { isLastParentWithChildren, updatePreferedTreeToContext } from '../../utils/functions';
import { getTree } from '../../services/python-services';
import Select from 'react-select/dist/declarations/src/Select';
import { RMContext } from '../../context/RMContext';
import { Button } from '../button/button';

export const DynamicMenu = () => {
    const objectMock = object;
    const [levels, setLevels] = useState<any>([]);
    const [selectedNodes, setSelectedNodes] = useState<any>({});
    const [expandedNodes, setExpandedNodes] = useState<number | null>(null);
    const [rerender, setForceReRender] = useState(false);
    const [allChildrenViews, setAllChildrenViews] = useState([]);
    const [options, setOptions] = useState([]);
    const treeOptions = ["old_tree", "newtree"];
    const [currentNode, setCurrentNode] = useState(-1);

    const { setUserData, userData } = useContext(RMContext);
    const { prefered_tree} = userData.userData;
    const [currenPreferedTree, setCurrentPreferedTree] = useState(prefered_tree)
    const { newtree,sitemap } = useContext(RMContext).userData;


    let pool: any = []

    const hasValue = (obj: any, value: any) => Object.values(obj).includes(value);

    const handleSelectNode = (level: any, children: any, nodeId: any, lastParent: any) => {
        setCurrentNode(nodeId);
        // console.log('handleSelectNode', level, children, nodeId, lastParent);
        if (lastParent) {
            const removeLevel = levels.slice(0, level + 1);
            const deletedNode = Object.keys(selectedNodes)
                .filter((objKey: any) => parseInt(objKey) !== level)
                .reduce((newObj: any, key: any) => {
                    newObj[key] = selectedNodes[key];
                    return newObj;
                }, {});
            const newSelectedNodes = { ...selectedNodes, [level]: nodeId };
            setLevels(removeLevel);
            /*  setNodes(removeLevel[0]) */
            setSelectedNodes(newSelectedNodes);
            if (hasValue(selectedNodes, nodeId)) {
                const removeLevel = levels.slice(0, level + 1);
                const deletedNode = Object.keys(selectedNodes)
                    .filter((objKey: any) => parseInt(objKey) !== level)
                    .reduce((newObj: any, key: any) => {
                        newObj[key] = selectedNodes[key];
                        return newObj;
                    }, {});
                setSelectedNodes(deletedNode);
                setLevels(removeLevel);
            }
        } else if (hasValue(selectedNodes, nodeId)) {
            const removeLevel = levels.slice(0, level + 1);
            const deletedNode = Object.keys(selectedNodes)
                .filter((objKey: any) => parseInt(objKey) !== level)
                .reduce((newObj: any, key: any) => {
                    newObj[key] = selectedNodes[key];
                    return newObj;
                }, {});
            setSelectedNodes(deletedNode);
            setLevels(removeLevel);
        } else {
            const newLevels = levels.slice(0, level + 1);
            newLevels[level + 1] = children;
            const newSelectedNodes = { [level]: nodeId };
            if(children.length > 0)
            setLevels(newLevels);
           
            setSelectedNodes(newSelectedNodes);
            // Close previously expanded nodes when selecting a new one
            if (!lastParent && children.length > 0) {
                setExpandedNodes(nodeId);
            }
        }
    };

    const handleToggleExpand = (nodeId: number) => {
        setExpandedNodes((prevExpandedNode) => (prevExpandedNode === nodeId ? null : nodeId));
    };


    useEffect(() => {
        const fetchData = async () => {
            
            const treeData = prefered_tree ==="old_tree"?sitemap:newtree
            const modData = [[...treeData]] // Assuming getTree is an async function
            setLevels(modData);

            treeData.forEach((parent: any) => {
                parent.children.forEach((child: any) => {
                    child.children.forEach((grandChild: any) => {
                        pool.push(grandChild)
                    });
                });
            });

            const newOptions = pool.map((x: any) => ({
                ...x,
                value: x.id,
                label: x.description,
                parentID: x.parent_id
            }));
            setOptions(newOptions);

        };
        if (newtree?.length > 0 && sitemap?.length >0 ) {
            
            fetchData();
        }
    }, [prefered_tree, newtree, sitemap]);



    function returnIDs(grandpaID: any, old_parent_id: any, new_parent_id: any, childID: any, selectedOption: any) {   //for reassignment of children from one node to the next
        let holder = [...levels]
        //remove the child from the old parent
        for (let item of holder[0]) {
            {
                if (item.id === grandpaID) {
                    for (let secondItem of item.children) {
                        if (secondItem.id === old_parent_id) {
                            secondItem.children = secondItem.children.filter((child: any) => child.id !== childID);
                        }
                    }
                }
            }
        }
        //add the child from the new parent
        for (let item of holder[0]) {
            {
                if (item.id === grandpaID) {
                    for (let secondItem of item.children) {
                        if (secondItem.id === new_parent_id) {
                            selectedOption.parent_id =new_parent_id;
                            selectedOption.parentID =new_parent_id;
                            secondItem.children.push(selectedOption);
                        }
                    }
                }
            }
        }
        setLevels(holder)
    }

    function updateHorizontalPosition() {

    }


    return (
        <div>
            <div className={styles.titleContainer}>Master Data Management: Menu Display</div>
                        <div className={styles.buttonContainer}><button className={styles.theButton} onClick={()=>{const opt = treeOptions.filter((o:any)=>o!==prefered_tree)[0];
                        
                        setCurrentPreferedTree(opt);
                        updatePreferedTreeToContext(setUserData,userData,opt)
                        }}>{prefered_tree}</button></div>
            {levels && levels.map((nodes: any, level: any) => {
                return (
                    nodes.length>0 &&
                    <div className={styles.general} key={level}>
                        <div className={styles.editBox}>
                            {nodes.map((node: any, index: any, nodes: any) => {
                                const lastParent = isLastParentWithChildren(node);
                                return (
                                    <TreeNode
                                        grandpaID={node.parent_id}
                                        allChildrenViews={allChildrenViews}
                                        setAllChildrenViews={setAllChildrenViews}
                                        key={node.id}
                                        node={node}
                                        level={level}
                                        onSelectNode={handleSelectNode}
                                        isSelected={selectedNodes[level] === node.id}
                                        isExpanded={expandedNodes === node.id}
                                        onToggleExpand={handleToggleExpand}
                                        lastParent={lastParent}
                                        nodes={nodes}
                                        index={index}
                                        options={options}
                                        levels={levels}
                                        setLevels={setLevels}
                                        returnIDs={returnIDs}
                                        currentNode={currentNode}
                                        setCurrentNode={setCurrentNode}
                                    />
                                );
                            })}
                        </div>
                    </div>
                        
                );
            })}
        </div>
    );
};
