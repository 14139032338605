/* eslint-disable react-hooks/exhaustive-deps */
import { Select, TextInput } from 'flowbite-react';
import { useState } from 'react';
import styles from './comparison-filter.module.scss';
//[id, field, operation, value, operation type description, filter value mode (Col/Val), columntype  ]
type ComparisonFilterValues = [number, string, string, string, string, string, string];

export interface ComparisonFilterProps {
    className?: string;
    columns: object[];
    values: ComparisonFilterValues;
    id?: string;
    updatedAttributes?: Function;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const ComparisonFilter = ({
    columns,
    id = 'id_value',
    updatedAttributes = () => {},
    values,
}: ComparisonFilterProps) => {
    const [settings, setSettings] = useState({
        field: values[1],
        type: values[2],
        mode: values[3],
        value: values[4],
    });
    const [textValue, setTextValue] = useState(values[3]);

    const handleChangeElement = (event: any, by: string) => {
        const input: string =
            // by === 'value' && settings.mode !== 'column'
            //     ? event.target.value.toUpperCase()
            //     :
            event.target.value;
        setSettings((prev) => ({ ...prev, [by]: input }));

        let dataSend: ComparisonFilterValues = values;

        let id: number = dataSend[0];
        let field: string = dataSend[1];
        let operation: string = dataSend[2];
        let value: string = dataSend[3];
        let type_desc: string = dataSend[4];
        let mode: string = dataSend[5];
        let valueType: string = dataSend[6];

        switch (by) {
            case 'field':
                columns.forEach((c: any) => {
                    if (c.prop === input) {
                        valueType = c.valueType;
                    }
                });

                field = input;
                break;
            case 'type':
                switch (input) {
                    case 'no_equal':
                        operation = ':not';
                        type_desc = 'no_equal';
                        break;
                    case 'empty':
                        operation = '';
                        type_desc = 'empty';
                        value = 'eval:None';
                        break;
                    case 'not_empty':
                        operation = ':not';
                        type_desc = 'not_empty';
                        value = 'eval:None';
                        break;
                    case 'greather_than':
                        operation = ':gt';
                        type_desc = 'greather_than';

                        break;
                    case 'less_than':
                        operation = ':lt';
                        type_desc = 'less_than';

                        break;
                    case 'greather_than_equal':
                        operation = ':gte';
                        type_desc = 'greather_than_equal';

                        break;
                    case 'less_than_equal':
                        operation = ':lte';
                        type_desc = 'less_than_equal';
                        break;

                    default:
                        operation = '';
                        type_desc = 'equal';
                        break;
                }
                break;
            case 'value':
                value = input;

                break;
            case 'mode':
                mode = input;
                break;
        }

        dataSend = [id, field, operation, value, type_desc, mode, valueType];
        updatedAttributes(dataSend);
    };

    return (
        <div className="flex gap-1 justify-between w-full" id={id}>
            <Select
                onChange={(e) => handleChangeElement(e, 'field')}
                sizing={'sm'}
                id={id + 'columns'}
                className={styles.flowbSelect + ' w-full'}
                value={values[1]}
                style={{ backgroundColor: 'white', borderColor: '#d1cbcb', color: '#2E323F' }}
            >
                <option value="" hidden>
                    Select a column
                </option>
                {columns.map((column: any) => (
                    <option value={column.prop}>{column.name}</option>
                ))}
            </Select>
            <Select
                onChange={(e) => handleChangeElement(e, 'type')}
                sizing={'sm'}
                id={id + 'options'}
                className={styles.flowbSelect + ' w-full'}
                value={values[4]}
                disabled={values[1] === ''}
                style={{ backgroundColor: 'white', borderColor: '#d1cbcb', color: '#2E323F' }}
            >
                <option value="" selected hidden>
                    Select rule
                </option>
                <option value="equal">Equal</option>
                <option value="no_equal">Is not equal</option>
                <option value="empty">Is empty</option>
                <option value="not_empty">is not Empty</option>

                {values[6] === 'number' ? (
                    <>
                        <option value="greather_than">{'>'}</option>
                        <option value="less_than">{'<'}</option>
                        <option value="greather_than_equal">{'>='}</option>
                        <option value="less_than_equal">{'<='}</option>
                    </>
                ) : (
                    <></>
                )}
            </Select>
            {values[4] !== 'empty' && values[4] !== 'not_empty' && (
                <>
                    <Select
                        onChange={(e) => handleChangeElement(e, 'mode')}
                        sizing={'sm'}
                        id={id + 'mode'}
                        className={styles.flowbSelect + ' w-full'}
                        value={values[5]}
                        style={{
                            backgroundColor: 'white',
                            borderColor: '#d1cbcb',
                            color: '#2E323F',
                        }}
                    >
                        <option hidden>Select mode</option>
                        <option value="value">Value</option>
                        <option value="column">Column</option>
                    </Select>

                    <>
                        {settings.mode === 'column' ? (
                            <Select
                                onChange={(e) => handleChangeElement(e, 'value')}
                                sizing={'sm'}
                                id={id + 'select_columns'}
                                className={styles.flowbSelect + ' w-full'}
                                disabled={settings.field === ''}
                                value={values[3]}
                                style={{
                                    backgroundColor: 'white',
                                    borderColor: '#d1cbcb',
                                    color: '#2E323F',
                                }}
                            >
                                <option value="" selected hidden>
                                    Select a column
                                </option>
                                {columns.map((column: any) => (
                                    <option value={'col:' + column.prop}>{column.name}</option>
                                ))}
                            </Select>
                        ) : (
                            <>
                                <TextInput
                                    sizing={'sm'}
                                    type={values[6] === 'number' ? 'number' : 'text'}
                                    id={id + 'select_value'}
                                    className="w-full"
                                    onChange={(e) => setTextValue(e.target.value)}
                                    disabled={settings.field === ''}
                                    placeholder="Write a value"
                                    value={textValue}
                                    onBlur={(e) => {
                                        handleChangeElement(e, 'value');
                                    }}
                                    style={{
                                        backgroundColor: 'white',
                                        borderColor: '#d1cbcb',
                                        color: '#2E323F',
                                    }}
                                />
                            </>
                        )}
                    </>
                </>
            )}
        </div>
    );
};
