import styles from './rmf.module.scss';
import { merge, queryV1 } from '../../services/python-services';
import { PivotTable } from '../pivot-table/pivot-table';
// import { useEffect, useState } from 'react';
import { Column, PointOfSale, QueryV1Response } from '../../utils/interfaces';
import { FilterCard } from '../filter-card/filter-card';
import { useState } from 'react';
import { FilterButton } from '../filter-button/filter-button';
import { getColunmsPOS } from '../../pipes/columns/pos/pos-colums';
import { queries_rmf } from '../../utils/constants';

export interface RmfProps {}

export const Rmf = () => {
    const [customFilters, setCustomFilters] = useState<any>([]);

    const queries = queries_rmf;

    const pivotTableService = async (group: any, filters?: any) => {
        const mso_data = queryV1(queries.market.table, group, queries.market.query, filters);

        const gscm_data = queryV1(queries.weekly.table, group, queries.weekly.query, filters);

        const mso_data_response: QueryV1Response = await mso_data;
        const gscm_data_resopnse: QueryV1Response = await gscm_data;
        const grouped_data = await merge('aggrupation', [
            mso_data_response.data,
            gscm_data_resopnse.data,
        ]);

        const data = {
            data: grouped_data,
            totals: mso_data_response.totals,
        };
        return data;
    };

    const posCounterPivotService = async (group: string, filters: object) => {
        const pos_counter: QueryV1Response = await queryV1(
            queries.pos_counter.table,
            group,
            queries.pos_counter.query,
            filters
        );
        return pos_counter;
    };

    //For pivot table
    // const computedRow = (row: any) => {
    //     return ((row?.cy_so - row?.ly_so) / row?.ly_so) * 100 || 0;
    // };
    const salesPivotGroupHierarchy = [
        {
            name: 'Country',
            id: 'point_of_sale_country',
            filter: 'point_of_sale_country',
            group: 'country__name',
        },
        {
            name: 'Account',
            id: 'point_of_sale_account',
            filter: 'point_of_sale_account',
            group: 'account__name',
        },

        {
            name: 'Point of sale',
            id: 'point_of_sale',
            filter: 'point_of_sale',
            group: 'gscn_site_name',
        },
        {
            name: 'Product',
            id: 'product_id',
            filter: 'sku',
            group: 'sku',
        },
    ];
    const posCounterPivotGroupHierarchy = [
        {
            name: 'Country',
            id: 'point_of_sale_country',
            filter: 'point_of_sale_country',
            group: 'country__name',
        },
        {
            name: 'Account',
            id: 'point_of_sale_account',
            filter: 'point_of_sale_account',
            group: 'account__name',
        },

        {
            name: 'Point of sale',
            id: 'point_of_sale',
            filter: 'point_of_sale',
            group: 'gscn_site_name',
        },
        // {
        //     name: 'Product',
        //     id: 'product_id',
        //     filter: 'sku',
        //     group: 'sku',
        // },
    ];

    const columns: Column[] = [
        {
            name: 'Group',
            key: 'group__' /* SIEMPRE DEBE LLAMARSE ASI */,

            prop: 'group__' /* SIEMPRE DEBE LLAMARSE ASI */,
            id: 'group__' /* SIEMPRE DEBE LLAMARSE ASI */,
            show: true,

            headerStyle: () => {
                return { minWidth: '250px;' };
            },
            totalStyle: () => {
                return { minWidth: '250px;' };
            },
            computedTotals: () => '',
        },

        // {
        //     /* COMPUTADA */
        //     name: '# POS',

        //     style: (row?: any) => {
        //         const comp = ((row?.cy_so - row?.ly_so) / row?.ly_so) * 100;
        //         if (comp === Infinity) return {};
        //         else if (comp >= 0) return { color: 'green' };
        //         else if (comp < 0) return { color: 'red' };
        //         else {
        //             return null;
        //         }
        //     },
        //     formatter: (v: any) => {
        //         return v.toFixed(1) + '%' || 0;
        //     },
        //     computedTotals: (c: any) => {
        //         return computedRow(c); //.toFixed(1) + "%";
        //     },
        //     key: 'no_pos',
        // prop: 'no_pos',
        // id: 'no_pos',
        // show:true,
        //     computed: (row: any) => {
        //         return row?.ly_so === 0 ? 0 : computedRow(row); //.toFixed(1);
        //     },
        // },
        {
            /* COMPUTADA */
            name: 'MSO LY SO',
            key: 'mso_ly_so',

            prop: 'mso_ly_so',
            id: 'mso_ly_so',
            show: true,
            formatter: (v: any) => {
                return v ? parseInt(v) : 0;
            },
        },
        {
            /* COMPUTADA */
            name: 'MSO CY SO',
            key: 'mso_cy_so',

            prop: 'mso_cy_so',
            id: 'mso_cy_so',
            show: true,
            formatter: (v: any) => {
                return v ? parseInt(v) : 0;
            },
        },
        {
            /* COMPUTADA */
            name: 'MSO SO YoY',
            key: 'mso_gr',

            prop: 'mso_gr',
            id: 'mso_gr',
            show: true,
            style: (v?: any) => {
                if (v.mso_gr === Infinity) return {};
                else if (v.mso_gr >= 0) return { color: 'green' };
                else if (v.mso_gr < 0) return { color: 'red' };
                else {
                    return {};
                }
            },
            formatter: (v: any) => {
                return v !== null && typeof v === 'number' ? v.toFixed(1) + '%' : '0%';
            },
        },
        {
            /* COMPUTADA */
            name: 'LY SO (QTY)',
            key: 'ly_so',

            prop: 'ly_so',
            id: 'ly_so',
            show: true,
            formatter: (v: any) => {
                return v ? v : '0';
            },
        },
        {
            /* COMPUTADA */
            name: 'CY SO (QTY)',
            key: 'cy_so',

            prop: 'cy_so',
            id: 'cy_so',
            show: true,
        },
        {
            /* COMPUTADA */
            name: 'SO YoY',
            key: 'gr',

            prop: 'gr',
            id: 'gr',
            show: true,
            style: (v?: any) => {
                if (v.gr === Infinity) {
                    return {};
                } else if (v.gr >= 0) return { color: 'green' };
                else if (v.gr < 0) return { color: 'red' };
                else {
                    return {};
                }
            },
            formatter: (v: any) => {
                return v !== null && typeof v === 'number' ? v.toFixed(1) + '%' : '0%';
            },
        },
    ];

    let posCounterColumns: Column[] = [
        {
            name: 'Group',
            key: 'group__' /* SIEMPRE DEBE LLAMARSE ASI */,

            prop: 'group__' /* SIEMPRE DEBE LLAMARSE ASI */,
            id: 'group__' /* SIEMPRE DEBE LLAMARSE ASI */,
            show: true,

            style: (acc: number) => {
                const alpha_shift = 1.8;
                const red = 6.222 * (acc * alpha_shift + alpha_shift) + 187.8;
                const green = 4.556 * (acc * alpha_shift + alpha_shift) + 204.4;
                const blue = 2.667 * (acc * alpha_shift + alpha_shift) + 223.3;
                return { backgroundColor: `rgba(${red}, ${green}, ${blue}, 1)` };
            },

            headerStyle: () => {
                return { minWidth: '250px;' };
            },
            totalStyle: () => {
                return { minWidth: '250px;' };
            },

            computedTotals: () => '',
        },
        {
            name: 'Universe VD',
            key: 'universe',

            prop: 'universe',
            id: 'universe',
            show: true,
        },
        {
            name: 'A',
            key: 'av_A',

            prop: 'av_A',
            id: 'av_A',
            show: true,
        },
        {
            name: 'B',
            key: 'av_B',

            prop: 'av_B',
            id: 'av_B',
            show: true,
        },
        {
            name: 'C',
            key: 'av_C',

            prop: 'av_C',
            id: 'av_C',
            show: true,
        },
        {
            name: 'D',
            key: 'av_D',

            prop: 'av_D',
            id: 'av_D',
            show: true,
        },
        {
            name: 'Pending',
            key: 'pending',

            prop: 'pending',
            id: 'pending',
            show: true,
        },
    ];

    const groupSalesString = salesPivotGroupHierarchy.map((o) => o.group).join('$');
    const queries_to_export_mso = [
        {
            service: 'queryV1',
            kwargs: {
                groups: `groups:${groupSalesString}`,
                name: queries.market.table,
            },
            body: queries.market.query,
        },
        {
            service: 'queryV1',
            kwargs: {
                groups: `groups:${groupSalesString}`,
                name: queries.weekly.table,
            },
            body: queries.weekly.query,
        },
    ];
    const posCounterString = posCounterPivotGroupHierarchy.map((o) => o.group).join('$');
    const queries_to_export_pos = [
        {
            service: 'queryV1',
            kwargs: {
                groups: `groups:${posCounterString}`,
                name: queries.pos_counter.table,
            },
            body: queries.pos_counter.query,
        },
    ];
    // const divisionCounterString = divisionCounterPivotGroupHierarchy.map((o) => o.group).join('$');
    // const queries_to_export_division = [
    //     {
    //         service: "queryV1",
    //         kwargs: {
    //             groups: `groups:${divisionCounterString}`,
    //             name: queries.division.table,
    //         },
    //         body: queries.division.query
    //     },
    // ]

    // Filters
    const [closedFilters, setClosedFilters] = useState(false);

    const filtersString = [
        'region',
        'countryZone',
        'store',
        'country',
        'anntena',
        'photo',
        'site_id',
    ];
    const [queriesFilter, setQueriesFilter] = useState<PointOfSale>({
        page: 1,
        size: 50,
        point_of_sale_country: null,
        point_of_sale_region: null,
        point_of_sale_zone: null,
        point_of_sale_account: null,
        kiosco: null,
        photo: null,
        search: null,
        sort_by: null,
    });
    const [uniques, setUniques] = useState<PointOfSale>({
        page: 1,
        size: 50,
        point_of_sale_country: null,
        point_of_sale_region: null,
        point_of_sale_zone: null,
        point_of_sale_account: null,
        kiosco: null,
        photo: null,
        search: null,
        sort_by: null,
    });

    const resetFilters = () => {
        const updatedQueries = {
            page: 1,
            size: 50,
            point_of_sale_country: null,
            point_of_sale_region: null,
            point_of_sale_zone: null,
            point_of_sale_account: null,
            kiosco: null,
            photo: null,
            search: null,
            sort_by: null,
        };
        setQueriesFilter(updatedQueries);
        setUniques(updatedQueries);
    };

    const applyFilters = (filters: any) => {
        // clearValues();
        const updatedQueries = { ...filters, page: 0 };
        setQueriesFilter(updatedQueries);
        setUniques(updatedQueries);
    };

    const closeFilter = (isClosed: boolean) => {
        setClosedFilters(isClosed);
    };

    return (
        <div className={styles.root}>
            {closedFilters ? <FilterButton className="hidden" close={closeFilter} /> : <></>}
            <FilterCard
                style={
                    closedFilters
                        ? {
                              width: '0px',
                              'min-width': '0px',
                              'max-width': '0px',
                              padding: '0px',
                          }
                        : {}
                }
                close={closeFilter}
                applyFilters={applyFilters}
                columns={getColunmsPOS()}
                setUniques={setUniques}
                filters={filtersString}
                file="pos"
                uniques={uniques}
                onReset={resetFilters}
                setCustomFilters={setCustomFilters}
                customFilters={customFilters}
            />
            <div className="flex flex-col gap-3 w-screen ">
                <PivotTable
                    title={'YoY Sell Out & Market Share'}
                    columns={columns}
                    initialGroups={salesPivotGroupHierarchy}
                    PivotService={pivotTableService}
                    filters={queriesFilter}
                    isExport={true}
                    queries_to_export={queries_to_export_mso}
                ></PivotTable>
                <PivotTable
                    title={'Point of Sale Count'}
                    columns={posCounterColumns}
                    initialGroups={posCounterPivotGroupHierarchy}
                    PivotService={posCounterPivotService}
                    filters={queriesFilter}
                    isExport={true}
                    queries_to_export={queries_to_export_pos}
                ></PivotTable>
            </div>
        </div>
    );
};
