// gtag.js
import {getEnvironment} from '/src/utils/functions';

const URL = window.location.href;
const env = getEnvironment(URL);

const scriptDev = document.createElement('script');
scriptDev.src = 'https://www.googletagmanager.com/gtag/js?id=G-4R103W9H1T';
scriptDev.async = true;

const scriptPord = document.createElement('script');
scriptPord.src = 'https://www.googletagmanager.com/gtag/js?id=G-YZJYNHVXYS';
scriptPord.async = true;

document.body.appendChild(env === 'prod' ? scriptPord : scriptDev);

window.dataLayer = window.dataLayer || [];
function gtag() {
    window.dataLayer.push(arguments);
}
gtag('js', new Date());

gtag('config', env === 'prod' ? 'G-YZJYNHVXYS' : 'G-4R103W9H1T');
