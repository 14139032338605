import { Column } from '../../../utils/interfaces';

export const getColunmsPreWeeklySales: Function = (): Column[] => [
    { 
        name: 'ID', 
        id: 'ID', 
        prop: 'id', open: false, show: true, valueType: 'number' },
    { 
        name: 'SITE ID', 
        id: 'SITE ID', 
        prop: 'site_id', open: false, show: true },
    { 
        name: 'SKU', 
        id: 'SKU', 
        prop: 'sku', open: false, show: true },
    {
        name: 'ACCOUNT NAME',
        id: 'ACCOUNT NAME',
        prop: 'account__name',
        open: false,
        show: true,
    },
    {
        name: 'GROUP VALUE',
        id: 'GROUP VALUE',
        prop: 'group__value',
        open: false,
        show: true,
    },
    {
        name: 'GSCN SITE NAME',
        id: 'GSCN SITE NAME',
        prop: 'gscn_site_name',
        open: false,
        show: true,
    },
    {
        name: 'REGION NAME',
        id: 'REGION NAME',
        prop: 'region__name',
        open: false,
        show: true,
    },
    {
        name: 'SOLD UNITS',
        id: 'SOLD UNITS',
        prop: 'sold_units',
        open: false,
        show: true,
        valueType: 'number',
    },
    {
        name: 'INVENTORY',
        id: 'INVENTORY',
        prop: 'inventory',
        open: false,
        show: true,
        valueType: 'number',
    },
    { 
        name: 'AMOUNT', 
        id: 'AMOUNT', 
        prop: 'amount', open: false, show: true, valueType: 'number' },
    {
        name: 'POINT OF SALE ACCOUNT',
        id: 'POINT OF SALE ACCOUNT',
        prop: 'point_of_sale_account',
        open: false,
        show: true,
        valueType: 'number',
    },
    {
        name: 'PRODUCT GROUP',
        id: 'PRODUCT GROUP',
        prop: 'product_group',
        open: false,
        show: true,
        valueType: 'number',
    },
    {
        name: 'POS',
        id: 'POS',
        prop: 'point_of_sale',
        open: false,
        show: true,
        valueType: 'number',
    },
    {
        name: 'POS REGION',
        id: 'POS REGION',
        prop: 'point_of_sale_region',
        open: false,
        show: true,
        valueType: 'number',
    },
    {
        name: 'CUSTOM ACCOUNT',
        id: 'CUSTOM ACCOUNT',
        prop: 'custom_account',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'PRODUCT ID',
        id: 'PRODUCT ID',
        prop: 'product_id',
        open: false,
        show: false,
        valueType: 'number',
    },
    { 
        name: 'INCHES', 
        id: 'INCHES', 
        prop: 'INCHES', open: false, show: false },
    { 
        name: 'LOAD', 
        id: 'LOAD', 
        prop: 'LOAD', open: false, show: false },
    {
        name: 'PRODUCT DIVISION',
        id: 'PRODUCT DIVISION',
        prop: 'product_division',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'DIVISION VALUE',
        id: 'DIVISION VALUE',
        prop: 'division__value',
        open: false,
        show: false,
    },
    {
        name: 'PRODUCT RANGE',
        id: 'PRODUCT RANGE',
        prop: 'product_range',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'PRODUCT RANGE VALUE',
        id: 'PRODUCT RANGE VALUE',
        prop: 'product_range__value',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'PRODUCT SEGMENT',
        id: 'PRODUCT SEGMENT',
        prop: 'product_segment',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'SEGMENT VALUE',
        id: 'SEGMENT VALUE',
        prop: 'segment__value',
        open: false,
        show: false,
    },
    {
        name: 'POS CITY',
        id: 'POS CITY',
        prop: 'point_of_sale_city',
        open: false,
        show: false,
    },
    {
        name: 'CITY NAME',
        id: 'CITY NAME',
        prop: 'city__name',
        open: false,
        show: false,
    },
    {
        name: 'POS ZONE',
        id: 'POS ZONE',
        prop: 'point_of_sale_zone',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'ZONE NAME',
        id: 'ZONE NAME',
        prop: 'zone__name',
        open: false,
        show: false,
    },
    {
        name: 'POS COUNTRY',
        id: 'POS COUNTRY',
        prop: 'point_of_sale_country',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'COUNTRY NAME',
        id: 'COUNTRY NAME',
        prop: 'country__name',
        open: false,
        show: false,
    },
    { 
        name: 'KIOSCO', 
        id: 'KIOSCO', 
        prop: 'kiosco', open: false, show: false, valueType: 'number' },
    {
        name: 'GRADE IM',
        id: 'GRADE IM',
        prop: 'grade_im',
        open: false,
        show: false,
    },
    {
        name: 'GRADE AV',
        id: 'GRADE AV',
        prop: 'grade_av',
        open: false,
        show: false,
    },
    {
        name: 'GRADE DA',
        id: 'GRADE DA',
        prop: 'grade_da',
        open: false,
        show: false,
    },
    {
        name: 'ANTENNA IM',
        id: 'ANTENNA IM',
        prop: 'antenna_im',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'ANTENNA AV',
        id: 'ANTENNA AV',
        prop: 'antenna_av',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'ANTENNA DA',
        id: 'ANTENNA DA',
        prop: 'antenna_da',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'SEGMENT IM',
        id: 'SEGMENT IM',
        prop: 'segment_im',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'SEGMENT AV',
        id: 'SEGMENT AV',
        prop: 'segment_av',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'SEGMENT DA',
        id: 'SEGMENT DA',
        prop: 'segment_da',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'COVERAGE IM',
        id: 'COVERAGE IM',
        prop: 'coverage_im',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'COVERAGE AV',
        id: 'COVERAGE AV',
        prop: 'coverage_av',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'COVERAGE DA',
        id: 'COVERAGE DA',
        prop: 'coverage_da',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'MERCHANDISER COVERAGE',
        id: 'MERCHANDISER COVERAGE',
        prop: 'merchandiser_coverage',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'PROMOTER COVERAGE',
        id: 'PROMOTER COVERAGE',
        prop: 'promoter_coverage',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'COVERAGE',
        id: 'COVERAGE',
        prop: 'coverage',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'UNIVERSE',
        id: 'UNIVERSE',
        prop: 'universe',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'LATITUDE',
        id: 'LATITUDE',
        prop: 'latitude,',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'LONGITUDE',
        id: 'LONGITUDE',
        prop: 'longitude,',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'INTERNAL CHEIL',
        id: 'INTERNAL CHEIL',
        prop: 'internal_cheil',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'MSO NAME',
        id: 'MSO NAME',
        prop: 'mso_name',
        open: false,
        show: false,
    },
    { 
        name: 'STATUS', 
        id: 'STATUS', 
        prop: 'status', open: false, show: false, valueType: 'number' },
    { 
        name: 'PHOTO', 
        id: 'PHOTO', 
        prop: 'photo', open: false, show: false, valueType: 'number' },
    {
        name: 'MARKETING NAME',
        id: 'MARKETING NAME',
        prop: 'marketing_name',
        open: false,
        show: false,
    },
    { 
        name: 'ANTENNA', 
        id: 'ANTENNA', 
        prop: 'antenna', open: false, show: false, valueType: 'number' },
    { 
        name: 'YEAR', 
        id: 'YEAR', 
        prop: 'year', open: false, show: false, valueType: 'number' },
    { 
        name: 'WEEK', 
        id: 'WEEK', 
        prop: 'week', open: false, show: false, valueType: 'number' },
    {
        name: 'WEEK OBJECT ID',
        id: 'WEEK OBJECT ID',
        prop: 'week_object_id',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'ACCOUNT FLOORING',
        id: 'ACCOUNT FLOORING',
        prop: 'account_flooring',
        open: false,
        show: false,
    },
    {
        name: 'SITE FLOORING',
        id: 'SITE FLOORING',
        prop: 'site_flooring',
        open: false,
        show: false,
    },
    {
        name: 'FLOORING PLAN',
        id: 'FLOORING PLAN',
        prop: 'flooring_plan',
        open: false,
        show: false,
        valueType: 'number',
    },
    { 
        name: 'CLUSTER', 
        id: 'CLUSTER', 
        prop: 'cluster', open: false, show: false },
];
