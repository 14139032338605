import styles from './search-box-for-children-views.module.scss';
import classNames from 'classnames';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import { updateParent } from '../../services/python-services';
import { opacity } from 'html2canvas/dist/types/css/property-descriptors/opacity';
import { overflow } from 'html2canvas/dist/types/css/property-descriptors/overflow';

export interface SearchBoxForChildrenViewsProps {
    className?: string;
    options: any;
    newParentID: any;
    returnIDs: any;
    grandpaID: any;
    node?:any;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const SearchBoxForChildrenViews = ({ className, options, newParentID, returnIDs, grandpaID,node }: SearchBoxForChildrenViewsProps) => {


    const [selectedOption, setSelectedOption] = useState<any>(null);
    const [gate, setGate] = useState(false)


    const handleChange = (newValue:any) => {
        setGate(true)
        setSelectedOption(newValue);
      };


    useEffect(() => {
        if (gate && selectedOption?.hasOwnProperty("parentID")) {
          const bodyForUnLink = {
            'old_parent_id': selectedOption?.parentID, // Ensure selectedOption has parentID
            'new_parent_id': newParentID,
            'child_id': selectedOption?.value,
          };
          
           returnIDs(grandpaID, selectedOption.parentID, newParentID, selectedOption.value, selectedOption)
    
          updateParent(bodyForUnLink);
          setSelectedOption(null);
        }
      }, [selectedOption]); 
    


      const customStyles = {
        control: (provided:any) => ({
          ...provided,
          maxWidth: '250px',
          height: '40px', // Set your desired height
          minHeight: '40px', // Ensure a minimum height
          border: '1px solid #ccc', // Light border
          boxShadow: 'none', // Remove shadow for a flat look
          '&:hover': {
              borderColor: '#aaa', // Darker border on hover
          },
          borderRadius: '4px', // Rounded corners
          overflowY: 'scroll',
          opacity: .7
          
      }),
        menu: (provided:any) => ({
            ...provided,
            
            overflowY: 'scroll', // Enable vertical scrolling
            borderRadius: '4px', // Rounded corners for menu
            marginTop: '20px', // Space above the menu
            backgroundColor: '#808080',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow
            opacity: '15'
        }),
        option: (provided:any, state:any) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#f0f0f0' : '#fff', // Light grey on hover
            color: '#333', // Text color
            padding: '10px 15px', // Padding for options
            transition: 'background-color 0.2s', // Smooth transition
            overflowY: 'scroll'
        }),
        menuPortal: (provided:any) => ({
            ...provided,
            zIndex: 9999, // Ensure the dropdown menu appears above other elements
        }),
        singleValue: (provided:any) => ({
            ...provided,
            color: '#333', // Text color for selected value
        }),
    };

    const newOptions = options.filter((item:any) => !node.children.some(((i:any)=>i.id===item.id)));
    
    return <div className={classNames(styles.dropdownInputContainer, className)}>

        <Select
          isSearchable={false}
            options={newOptions}
            value={selectedOption}
            onChange={handleChange}
            inputValue=''
            onInputChange={() => {}}
            onMenuOpen={() => {}}
            isClearable
            menuPortalTarget={document.body}
            styles={customStyles}

            />

        </div>;
};




