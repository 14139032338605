import { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import styles from './pie-e-chart.module.scss';

export interface PieEChartProps {
    className: string;
    title: string;
    name: string;
    data_chart: { label: string; value: number }[];
}

export const PieEChart = ({ className, title, name, data_chart }: PieEChartProps) => {
    const chartRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const chartInstance = echarts.init(chartRef.current!);
        const resizeHandler = () => {
            chartInstance.resize();
        };

        window.addEventListener('resize', resizeHandler);

        chartInstance.setOption({
            title: {
                text: title,
                textStyle: {
                    fontSize: 12,
                },
            },
            tooltip: {
                trigger: 'item',
                align: 'center',
            },
            legend: {
                top: '8%',
                left: '5%',
            },
            series: [
                {
                    name: name,
                    type: 'pie',
                    top: '10%',
                    radius: ['40%', '60%'],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: '#fff',
                        borderWidth: 2,
                    },
                    label: {
                        show: true,
                        position: 'outside',
                        fontSize: 10,
                        formatter: '{c}',
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: 10,
                            fontWeight: 'bold',
                        },
                    },
                    labelLine: {
                        show: true,
                    },
                    data: data_chart.map((dat) => ({ value: dat.value, name: dat.label })),
                },
            ],
        });

        return () => {
            window.removeEventListener('resize', resizeHandler);
            chartInstance.dispose();
        };
    }, [className, data_chart, name, title]);

    return (
        <div className={styles[className]}>
            <div
                id={'myChartP' + className}
                ref={chartRef}
                className={styles[className] + ' ' + styles.root}
                style={{ width: '100%', height: '100%' }}
            />
        </div>
    );
};
