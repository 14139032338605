import { XMarkIcon } from '@heroicons/react/24/outline';
import styles from './generic-modal.module.scss';
import classNames from 'classnames';
import ClickAwayListener from 'react-click-away-listener';

export interface GenericModalProps {
    className?: string;
    closeDialog: any;
    haveComponent?: boolean;
    getComponent?: any;
    name?: string;
    showCloseOptions?: boolean;
    isMultiple?: boolean;
    isClosable?: boolean;
    title?: string;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const GenericModal = ({
    className,
    closeDialog,
    haveComponent,
    getComponent,
    name = 'generic-dialog',
    showCloseOptions = true,
    isMultiple = false,
    isClosable = true,
    title,
}: GenericModalProps) => {
    const handleClose = () => {
        !isMultiple ? closeDialog() : closeDialog(name);
    };
    const closeDialogClickOut = (e: any) => {
        if (e.target.id === name && isClosable) {
            const dialog = document.getElementById(name);
            //@ts-expect-error
            if (dialog) dialog?.close();
            handleClose();
        }
    };
    // console.log(className);
    // return <div className={classNames(styles.root, className)}>
    return (
        <ClickAwayListener onClickAway={(e: any) => closeDialogClickOut(e)}>
            <dialog
                id={name}
                className={classNames(styles['' + className]) + ' modal'}
                onClick={(e: any) => closeDialogClickOut(e)}
                style={{ borderRadius: '5px' }}
            >
                <div
                    id="contentModal"
                    className={
                        title === 'EDIT FORM'
                            ? styles.contentEditForm
                            : title === 'DELETE VALUE'
                            ? styles.contentConfirm
                            : styles.contentModal
                    }
                >
                    {showCloseOptions && title !== 'DELETE VALUE' && (
                        <div className={styles['modal-header']}>
                            <div className={styles.addAnUserTitle}>{title}</div>
                            <div className={styles.buttonDiv} onClick={handleClose}>
                                <XMarkIcon
                                    className={styles['closeButtonModal']}
                                    title="Close"
                                ></XMarkIcon>{' '}
                            </div>
                        </div>
                    )}
                    {haveComponent !== undefined && haveComponent === true && getComponent}
                    {showCloseOptions && (
                        <div className="flex flex-col justify-center items-center">
                            {name === 'add-role' || 'edit-form' ? (
                                <div></div>
                            ) : (
                                <button
                                    type="button"
                                    className={
                                        'inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray  flex gap-1 items-center ' +
                                        styles.butonClose
                                    }
                                    onClick={handleClose}
                                >
                                    Close
                                </button>
                            )}
                        </div>
                    )}
                </div>
            </dialog>
        </ClickAwayListener>
    );
    // </div>;
};
