import { Menu } from '../menu/menu';
import {
    ArrowLongDownIcon,
    ArrowLongUpIcon,
    Bars2Icon,
    EllipsisVerticalIcon,
} from '@heroicons/react/24/outline';
import { Column } from '../../utils/interfaces';
import { CustomCheckbox } from '../custom-checkbox/custom-checkbox';
import classNames from 'classnames';
import styles from './table-column-header.module.scss';
import { capitalizeWords } from '../../utils/functions';
export interface ColumnHeaderProps {
    className?: string;
    isCheckbox?: boolean;
    props: Column;
    functionSort: (s: any) => void;
    onClick: (s: any) => void;
    selectAll?: () => void;
    allColumnsStyle?: string;
    selectAllBoolean?: boolean;
    index?: number;
    activeCompactMode?: boolean;
    isRolesTable?: boolean;
    lightTheme?: boolean;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const TableHeaderColumn = ({
    props,
    isCheckbox,
    functionSort,
    onClick,
    selectAll,
    allColumnsStyle,
    selectAllBoolean,
    index,
    activeCompactMode = false,
    lightTheme = false,
}: ColumnHeaderProps) => {
    // console.log(isRolesTable)
    const menu_options = [
        {
            name: 'Ascending',
            icon: <ArrowLongUpIcon className="h-4 w-4" />,
            column: props.name,
            prop: props.prop,
        },
        {
            name: 'Descending',
            icon: <ArrowLongDownIcon className="h-4 w-4" />,
            column: props.name,
            prop: props.prop,
        },
    ];

    const addClass =
        styles.rowStyle +
        ' ' +
        (isCheckbox
            ? 'relative flex justify-between font-semibold '
            : ' relative w-full flex justify-between font-semibold  ') +
        allColumnsStyle +
        ' ' +
        (activeCompactMode ? styles.compactHeader : 'p-1 text-sm ') +
        ' ' +
        (lightTheme ? styles.lightTheme : styles.darckTheme);

    return (
        <>
            {props.show && (
                <div
                    className={classNames(styles.root) + ' ' + addClass}
                    style={
                        isCheckbox
                            ? { maxWidth: '50px', paddingLeft: '10px', minWidth: '50px' }
                            : typeof props.style == 'function'
                            ? props.style(undefined)
                            : props.style
                    }
                >
                    {isCheckbox ? (
                        <>
                            <div className={classNames(styles.check) + ' '}>
                                <CustomCheckbox change={selectAll} ifChecked={selectAllBoolean} />
                            </div>
                        </>
                    ) : (
                        <>
                            {capitalizeWords(props.name)}
                            <div
                                className="flex flex-col overflow-hidden cursor-pointer"
                                onClick={() => onClick(props)}
                            >
                                <EllipsisVerticalIcon className="h-4 w-4 " />
                                {props.open && (
                                    <Menu
                                        options={menu_options}
                                        onSelect={(s) => functionSort(s)}
                                        index={index}
                                    />
                                )}
                            </div>
                        </>
                    )}
                </div>
            )}
        </>
    );
};
