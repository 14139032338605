import styles from './tree-view.module.scss';
import React, { useState } from 'react';
import { validateUrl } from '../../utils/functions';
import Collapsible from '../collapsible/collapsible';
import FixedMenuPBI from '../fixed-menu-pbi/fixed-menu-pbi';
import { Link } from 'react-router-dom';

export interface TreeViewProps {
    className?: string;
    data: any;
    setUserData: any;
    userData: any;
}

const TreeView = ({ className = '', data, setUserData, userData }: TreeViewProps) => {
    const [nodeStates, setNodeStates] = useState<{ [key: string]: boolean }>({});

    const handleNodeToggle = (nodeId: string) => {
        setNodeStates((prevNodeStates) => ({
            ...prevNodeStates,
            [nodeId]: !prevNodeStates[nodeId],
        }));
    };

    const TreeNode = ({
        node,
        isChild = false,
        firstLevel = false,
        level = 0,
        first_parent_name = ''
    }: {
        node: any;
        isChild?: boolean;
        firstLevel?: boolean;
        level?: number;
        first_parent_name?: string;
    }) => (
        <>
            <div
                style={{ marginLeft: '20px', marginBottom: '5px' }}
                className={(firstLevel ? styles['node-container'] : '') + ' ' + styles.column}
            >
                <div
                    className={isChild ? styles.children : styles.tittleParent}
                    style={{ display: 'flex' }}
                >
                    <Collapsible
                        label={
                            <>
                                <Link
                                    to={`${level===2?('/'+first_parent_name+'/'):'/'}${node.parent_name?(node.parent_name+'/'):''}${node.name}` || '/#'}
                                    style={{
                                        cursor: validateUrl(node.url) ? 'pointer' : '',
                                    }}
                                    className="row"
                                >
                                    {' '}
                                    {node.children && node.children.length > 0 && firstLevel && (
                                        <span className={'col '}></span>
                                    )}{' '}
                                    {node?.description?.toUpperCase()}
                                </Link>
                            </>
                        }
                        arrow={level === 0 || node.children.length < 1 ? false : true}
                        node={node}
                        setUserData={setUserData}
                        userData={userData}
                        initialOpen={nodeStates[node.id] || false}
                        onToggle={() => handleNodeToggle(node.id)}
                    >
                        {level < 2 && node.children && (
                            <div style={{ paddingLeft: '10px' }}>
                                {node?.children?.map((child: any) => (
                                    <TreeNode
                                        key={child.id}
                                        node={child}
                                        isChild={true}
                                        firstLevel={false}
                                        level={level + 1}
                                        first_parent_name={first_parent_name}
                                    />
                                ))}
                            </div>
                        )}
                    </Collapsible>
                </div>
                <>
                    {firstLevel && (
                        <div style={{ paddingLeft: '10px' }}>
                            {node?.children?.map((child: any) => (
                                <TreeNode
                                    key={child.id}
                                    node={child}
                                    isChild={true}
                                    firstLevel={false}
                                    level={level + 1}
                                    first_parent_name={first_parent_name}
                                />
                            ))}
                        </div>
                    )}
                </>
            </div>
        </>
    );

    return (
        <div className={styles[className]}>
            <div className={styles.containerT}>
                <>
                    {data?.length > 0 &&
                        data?.map((node: any) => (
                            <TreeNode
                                key={'node-tree-' + node.id}
                                node={node}
                                isChild={false}
                                firstLevel={true}
                                first_parent_name={node.name}
                            />
                        ))}
                </>
            </div>
        </div>
    );
};

export default TreeView;
