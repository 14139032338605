import styles from './login-forgot-password-base.module.scss';
import classNames from 'classnames';
import { LoginComponent } from '../login-component/login-component';
import { useEffect, useState, useContext } from 'react';
import Notiflix from 'notiflix';
import { useLocation, useNavigate } from 'react-router-dom';
import { RMContext } from '../../context/RMContext';
import { getToken, getUserData, resetPassword } from '../../services/python-services';
import { Spinner } from 'flowbite-react';
import { getInterceptorError, validatePassword } from '../../utils/functions';
import { ExclamationCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';

export interface LoginForgotPasswordBaseProps {
    className?: string;
}

export const LoginForgotPasswordBase = ({}: LoginForgotPasswordBaseProps) => {
    const { setUserData } = useContext(RMContext);
    const [userPassword, setUserPassword] = useState('');
    const [OTP, setOTP] = useState('');
    const [password, setPassword] = useState('');
    const [authentication, setAuthentication] = useState(false);
    const [spinnerChangePassword, setSpinnerChangePassword] = useState(false);
    //read query params
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    //read search params email and code
    const email = location.state?.email || searchParams.get('email');
    const [code, setCode] = useState(searchParams.get('code') || '');
    const [passwordError, setPasswordError] = useState<any>([]);
    const navigate = useNavigate();
    const regexError = /:\s*(.+)/;
    const [userName, setUserName] = useState('');  
    

    useEffect(() => {
        if (email) {
            setUserName(email);
        }
    }, [email]);

    const forgotPassword = async () => {
        const passwordValidation = await validatePassword(userPassword);
        if (!passwordValidation.isValid) {
            setPasswordError(passwordValidation.errorMessages);
            console.log(passwordError);
            Notiflix.Notify.failure('Password validation failed.');
            return;
        }
        const body = {
            email: userName,
            code: code,
            password: userPassword,
        };
        try {
            setSpinnerChangePassword(true);
            const res = await resetPassword(body);
            setSpinnerChangePassword(false);
            if (res?.detail === 'user password changed succesfully') {
                Notiflix.Notify.success('Password changed succesfully');
                navigate('/login');
            } else if (res?.detail === 'email sent successfully') {
                Notiflix.Notify.success('Recovery email sent succesfully');
                navigate('/');
            } else {
                Notiflix.Notify.failure('Error with the request');
            }
        } catch (error) {
            setSpinnerChangePassword(false);
            const interceptorError = getInterceptorError();
            const matchError: any = regexError.exec(interceptorError.response.data.detail);
            setPasswordError(matchError[1]);
            console.error(error);
        }
    };

    useEffect(() => {
        if (OTP.length === 6) {
            const fetchToken = async () => {
                try {
                    const body = {
                        email: userName,
                        password: OTP,
                    };
                    const response = await getToken(body);

                    if (response.access) {
                        await localStorage.setItem('tokenkey', response.access.toString());
                        localStorage.setItem('login', 'true');
                        try {
                            const userData = await getUserData();
                            setUserData(userData);
                        } catch (error) {
                            console.error(error);
                        }
                        navigate('/executive');
                    }else if(response.detail){
                        Notiflix.Notify.failure(response.detail);
                    }
                } catch (error) {
                    console.error(error);
                }
            };

            fetchToken();
        }
    }, [OTP, userName, setUserData, navigate]);

    useEffect(() => {
        const input = document.getElementById('codeUser');
        input?.setAttribute('autocomplete', 'new-password');
    
        return () => {
          input?.removeAttribute('autocomplete');
        };
      }, []);

    return (
        <LoginComponent>
            <div>
                <h4 className={classNames(styles['reset-password'])}>Reset Password</h4>
                <p className={classNames(styles['reset-password-subscript'])}>
                    You have received an email with the code to change your password and log in to
                    your account.
                </p>
                <form className={classNames(styles['p-t-5'])} role="form">
                    <div className={classNames(styles['form-group'])}>
                        <div className={classNames(styles['form-input-group'])}>
                            <label className={classNames(styles['email-label'])}>Email</label>
                            <input
                                type="email"
                                id="loginUserName"
                                name="userName"
                                value={userName}
                                onChange={(e) => {
                                    userName && setUserName(e.target.value);
                                }}
                                disabled={authentication}
                                required
                                className={classNames(styles['form-control'])}
                            />
                        </div>
                    </div>
                    <div className={classNames(styles['form-group'])}>
                        <div className={classNames(styles['form-input-group'])}>
                            <label className={classNames(styles['email-label'])}>Code</label>
                            <input
                                type="text"
                                id="codeUser"
                                title='Code'
                                name="codeUser"
                                value={code}
                                onChange={(e) => {
                                    setCode(e.target.value);
                                }}
                                autoComplete="off"
                                required
                                className={classNames(styles['form-control'])}
                            />
                        </div>
                    </div>
                    <div className={classNames(styles['form-group'])}>
                        <div className={classNames(styles['form-input-group'])}>
                            <label className={classNames(styles['email-label'])}>
                                New Password
                            </label>
                            <input
                                type="password"
                                id="passwordUser"
                                name="userPassword"
                                value={userPassword}
                                onChange={(e) => {
                                    setUserPassword(e.target.value);
                                    setPassword(e.target.value);
                                }}
                                disabled={authentication}
                                required
                                className={classNames(styles['form-control'])}
                            />
                        </div>
                    </div>
                    {passwordError.map((x: any) => (
                        <div
                            style={{
                                color: 'red',
                                textAlign: 'left',
                                display: 'flex',
                                gap: '5px',
                                alignItems: 'center',
                            }}
                        >
                            {' '}
                            <ExclamationTriangleIcon className="w-4 h-4" /> {x}
                        </div>
                    ))}
                    <div
                        className={classNames(styles['forgot-text'])}
                        onClick={() => navigate('/login')}
                    >
                        &larr; Go back
                    </div>
                    <button
                        onClick={forgotPassword}
                        className={classNames(styles['next-button'])}
                        type="button"
                    >
                        Change Password
                        {spinnerChangePassword && <Spinner size="sm" />}
                    </button>
                </form>
                <div className={classNames(styles['bottom-wrapper'])}>
                    <div className={classNames(styles['bottom-wrapper-inner'])}>
                        <div>
                            <p>
                                <small className={classNames(styles['bottom-text'])}>
                                    Retail Management 2024 | By. Cheil Central America.
                                </small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </LoginComponent>
    );
};
