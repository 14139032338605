import { Select, Spinner } from 'flowbite-react';
import { Button } from '../button/button';
import styles from './glp-alerts-setting.module.scss';
import classNames from 'classnames';
import {
    getCustomer,
    getRecipentsByCustomer,
    getTypeAlert,
    getRecipentsBySettings,
    addRecipentToCustomer,
    deleteRecipent,
    patchSetting,
    getGeneralRecipents,
    getGeneralCustomer,
    deleteGeneralRecipent,
} from '../../services/python-services';
import { useEffect, useState } from 'react';
import { Column } from '@cheilca/cheil-commons/src/utils/interfaces';
import { Table } from '@cheilca/cheil-commons';
export interface GlpAlertsSettingProps {
    className?: string;
    customer_id?: number;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const GlpAlertsSetting = ({ className, customer_id }: GlpAlertsSettingProps) => {
    const [customers, setCustomers] = useState([]);
    const [generalCustomer, setGeneralCustomer] = useState<any>();
    // const [recipents, setRecipents] = useState([]);
    const [recipentsByCustomer, setRecipentsByCustomer] = useState([]);
    const [recipentsBySettings, setRecipentsSettings] = useState([]);
    const [generalRecipents, setGeneralRecipents] = useState([]);
    const [type, setType] = useState([]);
    const [typeId, setTypeId] = useState(0);
    const [customerId, setCustomerId] = useState(customer_id ? customer_id : 0);
    const [settingScreen, setSettingScreen] = useState(1);
    const [isLoadingDataRecipents, setIsLoadingDataRecipents] = useState(false);
    const [isLoadingDataGeneralRecipents, setIsLoadingDataGenealRecipents] = useState(false);
    const [moreItems, setMoreItems] = useState(true);
    const [searchText, setSearchText] = useState('');

    const [newEmail, setNewEmail] = useState('');
    const [newEmailLoader, setNewEmailLoader] = useState(false);
    const [customerSelected, setCustomerSeleceted] = useState(0);

    const [newGeneralEmail, setNewGeneralEmail] = useState('');
    const [newGeneralEmailLoader, setNewGeneralEmailLoader] = useState(false);

    const [idsArray, setIdsArray] = useState<number[]>([]);
    const [checkAllSwitch, setCheckAllSwitch] = useState(false);
    const columnsCustomerRecipents: Column[] = [
        {
            id: 'id_',
            name: 'ID',
            prop: 'id',
            open: false,
            show: true,
            style: { minWidth: '50px', maxWidth: '50px' },
        },
        {
            id: 'email',
            name: 'Email',
            prop: 'email',
            open: false,
            show: true,
            // style: { minWidth: '130px' },
        },
        {
            id: 'delete',
            name: 'Delete',
            prop: 'delete',
            open: false,
            show: true,
            onClick: async (_e: React.MouseEvent, _value: string, row: any) => {
                const res = await deleteRecipent(row.id);
                if (res.success) {
                    updateRecipentsByCustomer();
                }
            },
            formatting: () => {
                return (
                    <div style={{ color: '#D00000', cursor: 'pointer', fontWeight: 'bold' }}>
                        Delete
                    </div>
                );
            },
        },
    ];

    const columnsGeneralRecipents: Column[] = [
        {
            id: 'id_',
            name: 'ID',
            prop: 'id',
            open: false,
            show: true,
            style: { minWidth: '50px', maxWidth: '50px' },
        },
        {
            id: 'email',
            name: 'Email',
            prop: 'email',
            open: false,
            show: true,
            // style: { minWidth: '130px' },
        },
        {
            id: 'delete',
            name: 'Delete',
            prop: 'delete',
            open: false,
            show: true,
            onClick: async (_e: React.MouseEvent, _value: string, row: any) => {
                const res = await deleteGeneralRecipent(row.id);
                //console.log(res);
                if (res.success) {
                    fetchGeneralRecipients();
                }
            },
            formatting: () => {
                return (
                    <div style={{ color: '#D00000', cursor: 'pointer', fontWeight: 'bold' }}>
                        Delete
                    </div>
                );
            },
        },
    ];

    const updateRecipentsByCustomer = () => {
        console.log(customerSelected);
    };

    const columnsSettings: Column[] = [
        {
            id: 'id',
            name: 'ID',
            prop: 'id',
            open: false,
            show: true,
            // style: { minWidth: '50px', maxWidth: '50px' },
        },
        {
            id: 'email',
            name: 'Email',
            prop: 'email',
            open: false,
            show: true,

            // style: { minWidth: '130px' },
        },
    ];

    const fetchData = async () => {
        const c = await getCustomer();
        setCustomers(c);

        const gc = await getGeneralCustomer();
        setGeneralCustomer(gc);
        setIsLoadingDataGenealRecipents(true);
        await fetchGeneralRecipients();
        setIsLoadingDataGenealRecipents(false);
        // const r = await getRecipents();
        // setRecipents(r);

        const t = await getTypeAlert();
        setType(t);
    };

    const fetchGeneralRecipients = async () => {
        const res = await getGeneralRecipents();
        setGeneralRecipents(res);
    };
    const onChangeCustomer = async (e: any, c: number = 0) => {
        if (isLoadingDataRecipents) {
            return;
        } else {
            setIsLoadingDataRecipents(true);
            const value = c === 0 ? e.target.value : c;
            setCustomerSeleceted(value);
            const r = await getRecipentsByCustomer(value);
            setRecipentsByCustomer(r);
            setIsLoadingDataRecipents(false);
            setMoreItems(false);
        }
    };

    const updateData = (newData: any) => {
        setRecipentsByCustomer([]);
        setRecipentsByCustomer(newData);
    };

    const onChangeSettings = async (e: any, field: string) => {
        const value = e.target.value;
        if (field === 'type') {
            setTypeId(value);
        } else {
            setCustomerId(value);
        }
    };

    const getRecipentsSettings = async (type_id: number, customer_id: number) => {
        if (isLoadingDataRecipents) {
            return;
        } else {
            setIsLoadingDataRecipents(true);
            const r = await getRecipentsByCustomer(customer_id);
            const s = await getRecipentsBySettings(type_id, customer_id);
            joinData(r, s);
            setRecipentsSettings(r);
            setIsLoadingDataRecipents(false);
            setMoreItems(false);
        }
    };

    const joinData = async (a: any[], b: any[]) => {
        let arr: number[] = [];
        b.forEach((d: any) => {
            const exist = a.some((e: any) => e.id === d.recipent.id);
            if (exist) {
                arr.push(d.recipent.id);
            }
        });
        setIdsArray(arr);
    };

    const handleNewEmail = (event: any) => {
        const value = event.target.value;
        setNewEmail(value);
    };

    const handleNewGeneralEmail = (event: any) => {
        const value = event.target.value;
        setNewGeneralEmail(value);
    };

    const addRecipent = async (event: any) => {
        event.preventDefault();
        const rgxEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (rgxEmail.test(newEmail)) {
            setNewEmailLoader(true);
            const email = { email: newEmail };
            const res = await addRecipentToCustomer(customerSelected, email);
            setNewEmailLoader(false);
            if (res.success) {
                setIsLoadingDataRecipents(true);
                const r = await getRecipentsByCustomer(customerSelected);
                setRecipentsByCustomer(r);
                setIsLoadingDataRecipents(false);
            }
        } else {
            console.error('Email invalido');
        }
    };
    const addGeneralRecipent = async (event: any) => {
        event.preventDefault();
        const rgxEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (rgxEmail.test(newGeneralEmail)) {
            setNewGeneralEmailLoader(true);
            const email = { email: newGeneralEmail };
            const res = await addRecipentToCustomer(generalCustomer?.id, email);
            setNewGeneralEmailLoader(false);
            if (res?.success) {
                setIsLoadingDataRecipents(true);
                await fetchGeneralRecipients();
                setIsLoadingDataGenealRecipents(false);
                setNewGeneralEmail('');
            }
        } else {
            console.error('Email invalido');
        }
    };

    const customerSettings = () => {
        return (
            <div>
                <h1>Customer Settings</h1>
                <h2 className="mb-3">Select a customer</h2>
                <Select value={customerSelected} onChange={(e) => onChangeCustomer(e)}>
                    <option value="" hidden>
                        Select a column
                    </option>
                    {customers.map((c: any) => (
                        <option key={'custom-' + c.id + c.name} value={c.id}>
                            {c.code + ' - ' + c.name}
                        </option>
                    ))}
                </Select>
                <div className="mt-5 flex justify-between items-center">
                    <h2>Recipents</h2>
                    <form onSubmit={addRecipent} className="flex gap-3">
                        <input
                            value={newEmail}
                            onChange={handleNewEmail}
                            className="rounded border-slate-300"
                            type="email"
                        />
                        <Button styleProp="h-[42px] pt-[0.5rem] pb-[0.5rem]  inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex gap-1 items-center h-full">
                            {newEmailLoader ? <Spinner size="sm" /> : <p>Add</p>}
                        </Button>
                    </form>
                </div>

                <Table
                    title={'Recipents By Customer'}
                    columns={columnsCustomerRecipents}
                    data={recipentsByCustomer}
                    count={recipentsByCustomer.length}
                    isSearch={false}
                    fetchData={() => {}}
                    isLoadingData={isLoadingDataRecipents}
                    resetButton={false}
                    columnsButton={false}
                    haveMoreItems={moreItems}
                    searchText={searchText}
                    updateData={updateData}
                />
            </div>
        );
    };

    const alertSetting = () => {
        return (
            <div>
                <h1>Alert Recipents Settings</h1>
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <h2>Select an alert type</h2>
                        <Select value={typeId} onChange={(e) => onChangeSettings(e, 'type')}>
                            <option value="" hidden>
                                Select a column
                            </option>
                            {type.map((t: any, index: number) => (
                                <option key={'type-' + index} value={t.id}>
                                    {t.description}
                                </option>
                            ))}
                        </Select>
                    </div>
                    <div>
                        <h2>Select a customer</h2>
                        <Select
                            value={customerId}
                            onChange={(e) => onChangeSettings(e, 'customer')}
                        >
                            <option value="" hidden>
                                Select a column
                            </option>
                            {customers.map((c: any, index: number) => (
                                <option key={'customer-' + index} value={c.id}>
                                    {c.code + ' - ' + c.name}
                                </option>
                            ))}
                        </Select>
                    </div>
                </div>
                <h2 className="mt-4">Recipents</h2>
                <Table
                    title={'Recipents By Customer'}
                    columns={columnsSettings}
                    data={recipentsBySettings}
                    count={recipentsBySettings.length}
                    isSearch={false}
                    fetchData={() => {}}
                    isLoadingData={isLoadingDataRecipents}
                    resetButton={false}
                    columnsButton={false}
                    haveMoreItems={moreItems}
                    searchText={searchText}
                    updateData={updateData}
                    selectId={selectId}
                    selectAll={selectAll}
                    idsArray={idsArray}
                />
            </div>
        );
    };

    const generalRecipientSettings = () => {
        return (
            <div>
                <h1>General Recipients Settings</h1>
                {/* <h2 className="mb-3">Select a customer</h2>
                <Select value={customerSelected} onChange={(e) => onChangeCustomer(e)}>
                    <option value="" hidden>
                        Select a column
                    </option>
                    {customers.map((c: any) => (
                        <option key={"custom-"+c.id + c.name} value={c.id}>
                            {c.code + ' - ' + c.name}
                        </option>
                    ))}
                </Select> */}
                <div className="mt-5 flex justify-between items-center">
                    <h2>Recipients</h2>
                    <form onSubmit={addGeneralRecipent} className="flex gap-3">
                        <input
                            value={newGeneralEmail}
                            onChange={handleNewGeneralEmail}
                            className="rounded border-slate-300"
                            type="email"
                        />
                        <Button styleProp="h-[42px] pt-[0.5rem] pb-[0.5rem]  inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex gap-1 items-center h-full">
                            {newGeneralEmailLoader ? <Spinner size="sm" /> : <p>Add</p>}
                        </Button>
                    </form>
                </div>

                <Table
                    title={'General Recipients'}
                    columns={columnsGeneralRecipents}
                    data={generalRecipents}
                    count={generalRecipents.length}
                    isSearch={false}
                    fetchData={() => {}}
                    isLoadingData={isLoadingDataGeneralRecipents}
                    resetButton={false}
                    columnsButton={false}
                    haveMoreItems={false}
                    searchText={searchText}
                    updateData={updateData}
                />
            </div>
        );
    };
    const selectId = (id: number) => {
        const data: any = {
            customer: customerId,
            type: typeId,
            recipent: id,
        };
        const res: any = patchSetting(data);
        // if (res.success) {
        let idsTmp = [...idsArray];
        if (idsTmp.includes(id)) {
            idsTmp = idsTmp.filter((i: number) => i !== id);
        } else {
            idsTmp.push(id);
        }
        setIdsArray([...idsTmp]);
        // }
    };

    const selectAll = () => {
        let idsTmp: number[] = [...idsArray];
        if (checkAllSwitch === false) {
            const holder1: number[] = recipentsBySettings.map((x: any) => x.id);
            idsTmp.push(...holder1);
            setCheckAllSwitch(true);
        } else {
            idsTmp = [];
            setCheckAllSwitch(false);
        }
        setIdsArray([...idsTmp]);
    };

    useEffect(() => {
        if (customer_id) {
            onChangeCustomer('', customer_id);
            setSettingScreen(1);
        }
    }, [customer_id]);

    useEffect(() => {
        if (typeId !== 0 && customerId !== 0) {
            getRecipentsSettings(typeId, customerId);
        }
    }, [typeId, customerId]);

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className={classNames(styles.root, className)}>
            <div className="w-[20%]">
                <h2 onClick={() => setSettingScreen(1)} className="cursor-pointer">
                    Customer
                </h2>
                <h2 onClick={() => setSettingScreen(2)} className="cursor-pointer mt-3">
                    Recipents by Alert
                </h2>
                <h2 onClick={() => setSettingScreen(3)} className="cursor-pointer mt-3">
                    Recipients for all Alerts
                </h2>
            </div>
            <div className="w-[80%]">
                {settingScreen === 1
                    ? customerSettings()
                    : settingScreen === 2
                    ? alertSetting()
                    : generalRecipientSettings()}
            </div>
        </div>
    );
};
