import Toggle from '../toggle/toggle';

export interface OptionToggleProps {
    className?: string;
    label?: string;
    idToggle: any;
    onChangeToggle: (v: any) => void;
    value: boolean;
    type?: string;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const OptionToggle = ({
    label = '',
    onChangeToggle,
    idToggle,
    value,
    type,
}: OptionToggleProps) => {
    return (
        <div className={'flex justify-between w-full items-center'}>
            {label}
            <Toggle id={idToggle} onChange={(v) => onChangeToggle(v)} value={value} type={type} />
        </div>
    );
};
