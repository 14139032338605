import { Column } from '../../../utils/interfaces';

export const getColunmsMarketShare: Function = (): Column[] => [
    // { name: 'id', id: 'id', prop: 'id', open: false, show: true },
    {
        name: 'YEAR',
        id: 'year',
        prop: 'year',
        open: false,
        show: true,
        valueType: 'number',
        style: { minWidth: '60px', maxWidth: '60px' },
    },
    {
        name: 'WEEK',
        id: 'week',
        prop: 'week',
        open: false,
        show: true,
        valueType: 'number',
        style: { minWidth: '60px', maxWidth: '60px' },
    },
    {
        name: 'BRAND',
        id: 'brand__value',
        prop: 'brand__value',
        open: false,
        show: true,
        style: { minWidth: '100px', maxWidth: '100px' },
    },
    {
        name: 'AMOUNT',
        id: 'amount',
        prop: 'amount',
        open: false,
        show: true,
        valueType: 'number',
        style: { minWidth: '110px', maxWidth: '110px' },
    },
    {
        name: 'COUNTRY CODE',
        id: 'country__code',
        prop: 'country__code',
        open: false,
        show: true,
        style: { minWidth: '120px', maxWidth: '120px' },
    },
    {
        name: 'PRODUCT RANGE',
        id: 'product_range__value',
        prop: 'product_range__value',
        open: false,
        show: true,
        style: { minWidth: '100px', maxWidth: '100px' },
    },
    {
        name: 'DIVISION',
        id: 'division__value',
        prop: 'division__value',
        open: false,
        show: true,
        style: { minWidth: '100px', maxWidth: '100px' },
    },
    {
        name: 'GROUP',
        id: 'group__value',
        prop: 'group__value',
        open: false,
        show: true,
        style: { minWidth: '100px', maxWidth: '100px' },
    },
    {
        name: 'QUANTITY',
        id: 'qty',
        prop: 'qty',
        open: false,
        show: true,
        valueType: 'number',
        style: { minWidth: '110px', maxWidth: '110px' },
    },
    {
        name: 'SITE ID',
        id: 'site_id',
        prop: 'site_id',
        open: false,
        show: true,
        style: { minWidth: '110px', maxWidth: '110px' },
    },
    {
        name: 'COUNTRY',
        id: 'country__name',
        prop: 'country__name',
        open: false,
        show: true,
        style: { minWidth: '120px', maxWidth: '120px' },
    },
    { name: 'SKU', id: 'sku', prop: 'sku', open: false, show: true },
    {
        name: 'MARKETING NAME',
        id: 'marketing_name',
        prop: 'marketing_name',
        open: false,
        show: true,
    },
    {
        name: 'ACCOUNT',
        id: 'account__name',
        prop: 'account__name',
        open: false,
        show: true,
        style: { minWidth: '100px', maxWidth: '100px' },
    },
    {
        name: 'CANAL',
        id: 'channel',
        prop: 'channel',
        open: false,
        show: true,
        style: { minWidth: '100px', maxWidth: '100px' },
    },
    {
        name: 'DATE',
        id: 'date',
        prop: 'date',
        open: false,
        show: true,
        style: { minWidth: '100px', maxWidth: '100px' },
    },
    { name: 'MSO SITE NAME', id: 'mso_site_name', prop: 'mso_site_name', open: false, show: true },
    {
        name: 'PRODUCT TYPE',
        id: 'product_type',
        prop: 'product_type',
        open: false,
        show: true,
        style: { minWidth: '120px', maxWidth: '120px' },
    },
    {
        name: 'PRICE',
        id: 'price',
        prop: 'price',
        open: false,
        show: true,
        valueType: 'number',
        style: { minWidth: '70px', maxWidth: '70px' },
    },
    {
        name: 'STORAGE',
        id: 'storage',
        prop: 'storage',
        open: false,
        show: true,
        style: { minWidth: '90px', maxWidth: '90px' },
    },
    {
        name: 'COLOR',
        id: 'color',
        prop: 'color',
        open: false,
        show: true,
        style: { minWidth: '85px', maxWidth: '85px' },
    },
    {
        name: 'RAM',
        id: 'ram',
        prop: 'ram',
        open: false,
        show: true,
        style: { minWidth: '80px', maxWidth: '80px' },
    },
    {
        name: 'CATEGORY SERIES',
        id: 'category_series',
        prop: 'category_series',
        open: false,
        show: true,
        style: { minWidth: '100px', maxWidth: '100px' },
    },
    {
        name: 'RESOLUTION',
        id: 'resolution',
        prop: 'resolution',
        open: false,
        show: true,
        style: { minWidth: '120px', maxWidth: '120px' },
    },
    {
        name: 'TECHNOLOGY',
        id: 'technology',
        prop: 'technology',
        open: false,
        show: true,
        style: { minWidth: '120px', maxWidth: '120px' },
    },
    {
        name: 'MARKET TECHNOLOGY',
        id: 'market_technology',
        prop: 'market_technology',
        open: false,
        show: true,
    },
    {
        name: 'SIZE',
        id: 'size',
        prop: 'size',
        open: false,
        show: true,
        style: { minWidth: '100px', maxWidth: '100px' },
    },
    {
        name: 'SIZE RANGE',
        id: 'size_range',
        prop: 'size_range',
        open: false,
        show: true,
        style: { minWidth: '100px', maxWidth: '100px' },
    },
    {
        name: 'INVERTER',
        id: 'inverter',
        prop: 'inverter',
        open: false,
        show: true,
        style: { minWidth: '100px', maxWidth: '100px' },
    },
    {
        name: 'DISPENSER',
        id: 'dispenser',
        prop: 'dispenser',
        open: false,
        show: true,
        style: { minWidth: '100px', maxWidth: '100px' },
    },
    // { name: 'CONTROL', id: 'control', prop: 'control', open: false, show: true },
    // {
    //     name: 'PRODUCT DIVISION',
    //     id: 'product_division',
    //     prop: 'product_division',
    //     open: false,
    //     show: true,
    // },
    // { name: 'PRODUCT GROUP', id: 'product_group', prop: 'product_group', open: false, show: true },
    // {
    //     name: 'POS COUNTRY',
    //     id: 'point_of_sale_country',
    //     prop: 'point_of_sale_country',
    //     open: false,
    //     show: true,
    // },
];
