import { useEffect, useState } from 'react';
import styles from './add-cover.module.scss';
import classNames from 'classnames';
import { checkIdExists, createCover } from '../../services/python-services';
import { isValidString, isValidId, cleanString } from '../../utils/functions';
import { MockUp } from '../mock-up/mock-up';
import Notiflix from 'notiflix';

export interface AddCoverProps {
    className?: string;
    type: string;
    CloseModalAndUpdate: any;
    OnlyClose: any;
    reportsData?: any;
    setNewCover?: any;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const AddCover = ({
    className,
    type,
    CloseModalAndUpdate,
    OnlyClose,
    reportsData,
}: //setNewCover,
AddCoverProps) => {
    const [name, setName] = useState('');
    const [color, setColor] = useState('#66D0AF');
    const [id, setId] = useState('');
    //const [validForm, SetValidForm] = useState(false);
    const [somethingChange, setSomethingChange] = useState(false);
    const parentId = reportsData!.find((r: any) => r.name === type)?.id;
    const [mockUpData, setMockUpData] = useState({});
    const [isValidNameSpan, setIsValidNameSpan] = useState(true);
    const [isValidIdSpan, setIsValidIdSpan] = useState(true);
    const [isValidColorSpan, setIsValidColorSpan] = useState(true);
    const [submitSwitch, setSubmitSwitch] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const clearFields = () => {
        setName('');
        setColor('#66D0AF');
        setId('');
        setIsValidColorSpan(true);
        setIsValidNameSpan(true);
        setIsValidIdSpan(true);
        setMockUpData({});
        OnlyClose();
    };
    useEffect(() => {});
    const validateId = (value: any) => {
        let validF = true;
        if (!isValidString(value)) {
            setIsValidIdSpan(false);
            validF = validF && false;
        } else {
            validF = validF && true;
            setIsValidIdSpan(true);
        }
        return validF;
    };
    const validateName = (value: any) => {
        let validF = true;
        if (!isValidString(value)) {
            setIsValidNameSpan(false);
            validF = validF && false;
        } else {
            validF = validF && true;
            setIsValidNameSpan(true);
        }
        return validF;
    };
    const validateColor = (value: any) => {
        let validF = true;
        if (!isValidString(value)) {
            setIsValidColorSpan(false);
            validF = validF && false;
        } else {
            validF = validF && true;
            setIsValidColorSpan(true);
        }
        return validF;
    };
    const isValidForm = async () => {
        let valid = true;
        const validations = [validateId(id), validateName(name), validateColor(color)];
        for (const validation of validations) {
            if (!validation) {
                valid = false;
                // Don't break here to accumulate all validation errors
            }
        }
        return valid;
    };
    useEffect(() => {
        // Handle initialization or update when idItem changes
        setMockUpData({
            name: '',
            division_name: '',
            report: name,
            background_color: color,
            backgroundD: '#66D0AF',
        });
        if (somethingChange) {
            setSomethingChange(false);
        }
    }, [somethingChange]);
    const saveCover = async () => {
        const valid = await isValidForm();
        if (valid) {
            try {
                const payload = {
                    name: cleanString(name),
                    id: id,
                    color: color || null,
                    view_parent_id: parentId,
                };
                const response = await createCover(payload);
                if (response.outcome) {
                    CloseModalAndUpdate('Cover created successfully!', response.outcome);
                    setSubmitSwitch(!submitSwitch);
                    clearFields();
                } else {
                    Notiflix.Notify.failure(response.message);
                }
                setOpenModal(false);
            } catch (err) {
                Notiflix.Notify.failure("Cover can't be created!");
            }
        }
    };

    return (
        <div
            className={
                classNames(styles.root, className + ' ' + styles[className!]) +
                ' ' +
                styles.containerData
            }
        >
            <h1 className={styles.header}>Add Cover to {type?.toUpperCase()} Reports</h1>
            <div>
                <div className="form-flex">
                    {!isValidIdSpan && (
                        <span className={styles.requiredEntry}>An Id is required</span>
                    )}
                    <div id="" className="form-box">
                        <label>ID</label>
                        <input
                            type="text"
                            name="id"
                            className="test"
                            value={id}
                            onChange={(e) => {
                                setId(e.target.value);
                                setSomethingChange(true);
                            }}
                        />
                    </div>

                    {!isValidNameSpan && (
                        <span className={styles.requiredEntry}>A cover name is required</span>
                    )}
                    <div id="" className="form-box">
                        <label>Cover name</label>
                        <input
                            type="text"
                            name="cover_name"
                            className="test"
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value);
                                setSomethingChange(true);
                            }}
                        />
                    </div>
                    <div id="" className="form-box">
                        <label>Color</label>
                        <input
                            type="color"
                            title="color"
                            name="url"
                            className="testColor"
                            value={color}
                            onChange={(e) => {
                                setColor(e.target.value);
                                setSomethingChange(true);
                            }}
                            style={{ background: color }}
                        ></input>
                    </div>

                    <div className={styles.coverMockup}>
                        <MockUp data={mockUpData}></MockUp>
                    </div>

                    <div className="bottom-flex-pop">
                        <button
                            className="btn"
                            onClick={() => {
                                clearFields();
                            }}
                        >
                            CANCEL
                        </button>
                        <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={async () => await saveCover()}
                        >
                            UPLOAD
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
