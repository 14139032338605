import styles from './tree-view-nested-row-children.module.scss';
import classNames from 'classnames';
import { TreeViewNestedRow } from '../tree-view-nested-row/tree-view-nested-row';

export interface TreeViewNestedRowChildrenProps {
    className?: string;
    rowChildren: any;
    setParentClicked: any;
    parentClicked: any;
    parentValue: any;
    setParentValue: any;
    columnWidth: any;
    initialDepth: number;
    depthCounter: number;
    addIdsArray: any;
    arrayOfPreSelected: any;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const TreeViewNestedRowChildren = ({ className,setParentValue,parentValue, rowChildren, setParentClicked, parentClicked, columnWidth, initialDepth, depthCounter, addIdsArray, arrayOfPreSelected }: TreeViewNestedRowChildrenProps) => {
    return (<div className={classNames(styles.root, className)}>

        {rowChildren.map((row: any, index: number) => (
            <div className={classNames(styles.firstChild)}>
                <TreeViewNestedRow
                    parentValue={parentValue} 
                    setParentValue={setParentValue} 
                    columnWidth={columnWidth}
                    key={index}
                    row={row}
                    setParentClicked={setParentClicked}
                    parentClicked={parentClicked}
                    initialDepth={initialDepth}
                    depthCounter={depthCounter+1}
                    addIdsArray={addIdsArray}
                    arrayOfPreSelected={arrayOfPreSelected}
                />
            </div>
        ))}
    </div>)
}
