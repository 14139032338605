import React from 'react';
import styles from './toggle.module.scss';

export interface ToggleProps {
    className?: string;
    id: string;
    onChange: (v: boolean) => void;
    value: boolean;
    type?: string;
}

const Toggle: React.FC<ToggleProps> = ({ id = 'AcceptConditions', onChange, value, type }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value_ = event.target.checked;
        onChange(value_);
    };

    const elementClassName =
        type === 'edit'
            ? styles.element
            : 'absolute inset-0 rounded-full bg-gray-300 transition peer-checked:bg-green-500';
    const circleClassName =
        type === 'edit'
            ? styles.circle
            : 'absolute inset-y-0 start-0 m-1 h-6 w-6 rounded-full bg-white transition-all peer-checked:start-6';

    return (
        <label htmlFor={id} className="relative h-8 w-14 cursor-pointer">
            <input
                type="checkbox"
                id={id}
                className="peer sr-only"
                checked={value}
                onChange={handleChange}
                readOnly
            />
            <span className={elementClassName}></span>
            <span className={circleClassName}></span>
        </label>
    );
};

export default Toggle;
