import styles from './login-component.module.scss';
import logo from "../../assets/logo_2x.svg";


export interface LoginComponentProps {
  className?: string;
  children: any;
}

export const LoginComponent = ({ className, children }: LoginComponentProps) => {
 

  return (
    <div className={styles.loginWrapper+" "+styles.root+" "+ styles[""+className]}>
      <div className={styles.nestedLoginWrapper}>
        <div className={styles.imgHolder}></div>
      </div>
      <div className={styles.secondLoginWrapper}>
        <div className={styles.thirdLoginWrapper}>
          <img src={logo} alt="logo" width="150" />
          {children}
        </div>
      </div>
    </div>
  );;
};






