import styles from './horizontal-bar-e-chart.module.scss';
import { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { convertArrayString } from '../../utils/functions';

export interface HorizontalBarEChartProps {
    className: string;
    title: string;
    data_chart: { label: string; value: number }[];
}

export const HorizontalBarEChart = ({ className, title, data_chart }: HorizontalBarEChartProps) => {
    const labelRight = {
        position: 'right',
    } as const;
    const data = data_chart.map((dat) => {
        return { value: dat.value.toFixed(), label: labelRight };
    });
    const labels = data_chart.map((dat) => {
        return dat.label;
    });
    let convertedLabels: string[] = [];
    if (labels !== null && labels.length > 0) {
        convertedLabels = convertArrayString(labels);
    }
    const chartRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const chartInstance = echarts.init(chartRef.current!);
        const resizeHandler = () => {
            chartInstance.resize();
        };

        window.addEventListener('resize', resizeHandler);

        const option = {
            title: {
                text: title,
                textStyle: {
                    fontSize: 12,
                },
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                },
            },
            legend: { show: false },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                top: '15%',
                containLabel: true,
            },
            xAxis: {
                type: 'value',
                boundaryGap: [0, 0.01],
            },
            yAxis: {
                type: 'category',
                data: convertedLabels,
                axisLabel: {
                    fontSize: 10,
                },
            },
            series: [
                {
                    type: 'bar',
                    data: data,
                    label: {
                        show: true,
                        fontSize: 10,
                        position: 'top',
                    },
                },
            ],
        };
        chartInstance.setOption(option);
        return () => {
            window.removeEventListener('resize', resizeHandler);
            chartInstance.dispose();
        };
    }, [data, title, convertedLabels]);

    return (
        <div className={styles[className]}>
            <div id={'myChartD' + className} ref={chartRef} className={styles[className]} />
        </div>
    );
};
