import styles from './custom-checbox.module.scss';
import classNames from 'classnames';
export interface CustomCheckboxProps {
    change?: () => void;
    ifChecked?: boolean;
    handleClick?: any;
}
export const CustomCheckbox = ({ change, ifChecked, handleClick }: CustomCheckboxProps) => {
    return (
        <div className={classNames(styles.root)}>
            <input onChange={change} onClick={handleClick} checked={ifChecked} type="checkbox" name="" id="" />
        </div>
    );
};





