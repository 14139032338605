import styles from './tabs.module.scss';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getQueryParams, updateCurrentRoueteToContext } from '../../utils/functions';
import { MultiTabChild } from '../multi-tab-child/multi-tab-child';

export interface TabsProps {
    className?: string;
    firstChildrenData: any;
    selectedCountryName: any;
    currentViewData?: any;
    setUserData: any;
    userData: any;
    canEdit?: boolean;
    handleEditReport?: any;
    setUpdatedData?:any;
}


export const Tabs = ({ firstChildrenData, selectedCountryName, currentViewData, setUserData, userData, canEdit = false,
     handleEditReport,  setUpdatedData }: TabsProps) => {
    const [selectedChildrenData, setSelectedChildrenData] = useState<any>();
    const [titleTab, setTitleTab] = useState('');
    const queries: any = getQueryParams(window.location.search);

    //console.log("selectedCountryName: any;", selectedCountryName, "firstChildrenData", firstChildrenData, "currentViewData", currentViewData);
    //trigger re-render with onclick that fires a variable, adding an onclick that adds styling id or class to the event.target.
    useEffect(() => {
        //set the default data form the selected country
        if (firstChildrenData?.length > 0 && selectedCountryName !== undefined) {
            // console.log("selectedCountryName", selectedCountryName)
            const selectedCountry = firstChildrenData?.filter((x: any) => currentViewData?.parent.includes("ecb")?x.description === selectedCountryName:x.description === selectedCountryName );
            // console.log("selectedCountry", selectedCountry)
            if (selectedCountry?.length > 0) {
                setSelectedChildrenData(selectedCountry[0]);
            }
        }
    }, [selectedCountryName, currentViewData]);

    const firstChildrenDataFilteredToNames = firstChildrenData?.map((x: any) => {
        return { country_name: x.country_name, name: x.name, description: x.description };
    });
    const firstChildrenDataFilteredDuplicates: any[] = Array.from(
        new Set(firstChildrenDataFilteredToNames)
    );

    const handleChangeSelectedCountryName = (country: any) => {
        const newReports = firstChildrenData.filter((x: any) =>
            country?.name?.includes('ecb') ? x.name === country?.name : x.name === country?.name
        );
        setSelectedChildrenData(newReports);
        updateCurrentRoueteToContext(
            setUserData,
            userData,
            `/${currentViewData?.parent}/${country.name}`
        );
    };
    const getRidOfDefault = firstChildrenDataFilteredDuplicates?.map((x: any, index: number) => {
        if (x.country_name === 'default' || x.country_name === 'TEST') {
            return (
                <Link
                    key={'tab-d' + x.name}
                    className={
                        styles.tab +
                        ' ' +
                        (selectedCountryName === x.description ? styles['highlighted-button'] : '')
                    }
                    onClick={() => handleChangeSelectedCountryName(x)}
                    to={
                        '/' +
                        currentViewData?.parent +
                        '/' +
                        x.name +
                        (queries?.t ? '?t=' + queries?.t : '')
                    }
                >
                    {x.description?.toUpperCase()}
                </Link>
            );
            // <button  key={"tab-d"} className={styles.tab+" "+(selectedCountryName===x.country_name?styles["highlighted-button"]:"")}
            // onClick={()=>handleChangeSelectedCountryName(x)}>{x.country_name}</button>
        }
        //else if selected tab keep it highlighted
        else {
            return <Link key={"tab-" + index} className={styles.tab + " " + ((selectedCountryName === x.description || selectedCountryName === x.description)  ? styles["highlighted-button"] : "")}
                onClick={() => handleChangeSelectedCountryName(x)} to={"/" + currentViewData?.parent + "/" + x.name + (queries?.t ? "?t=" + queries?.t : "")}>{x.description?.toUpperCase()}</Link>
        }
        // <button  key={"tab-"+index} className={styles.tab+" "+(selectedCountryName===x.country_name?styles["highlighted-button"]:"")}
        // onClick={()=>handleChangeSelectedCountryName(x)}>{x.country_name}</button>}
    });
    // console.log(firstChildrenDataFilteredDuplicates, "look here")

    Array.isArray(selectedChildrenData) ? setSelectedChildrenData(selectedChildrenData[0]) : '';
    return (
        <>
            {firstChildrenDataFilteredDuplicates?.length === 1 &&
            (firstChildrenDataFilteredDuplicates[0]?.country_name === 'default' ||
                firstChildrenDataFilteredDuplicates[0]?.country_name === 'TEST') ? (
                <></>
            ) : (
                <div className={styles.nav + ' ' + styles['containerTop']}>
                    <div className={styles.nav}>{getRidOfDefault}</div>
                </div>
            )}
            <div
                className={
                    'container-fluid sm-gutter m-b-0 pt-0 mt-0 container-father ' +
                    styles['validate-qa']
                }
                id="gutter-fix"
            >
                {selectedChildrenData !== null && (
                    <MultiTabChild
                        data={selectedChildrenData}
                        canEdit={canEdit}
                        currentTab={selectedCountryName}
                        itemUpdate={handleEditReport}
                        currentViewData={currentViewData}
                        setUpdatedData={setUpdatedData}
                    ></MultiTabChild>
                )}
            </div>
        </>
    );
};
