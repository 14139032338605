import styles from './executive.module.scss';
import { queryV1, queryV2 } from '../../services/python-services';
import { PivotTable } from '../pivot-table/pivot-table';
import { Column, PointOfSale, QueryV1Response } from '../../utils/interfaces';
import { useContext } from 'react';
import { RMContext } from '../../context/RMContext';
//import {Table} from "@cheilca/cheil-commons"
interface divisionTableRowPremium {
    pc: number; // premium count
    pct: number; // premium total
}

interface divisionTableRowVariation {
    cy: number; // current year
    ly: number; //last year
}

export const Executive = () => {
    const globalState = useContext(RMContext);
    console.log("globalState", globalState)
    const queriesFilter: PointOfSale = {
        page: 1,
        size: 50,
        point_of_sale_country: null,
        point_of_sale_region: null,
        point_of_sale_zone: null,
        point_of_sale_account: null,
        kiosco: null,
        photo: null,
        search: null,
        sort_by: null,
    };

    const queries = {
        division: {
            table: 'weeklysales',
            query: {
                data: [
                    {
                        groupby: {
                            pos: "pl.col('point_of_sale').filter(pl.col('week_object_id')>=week_range['fwoy']).unique().count()",
                            cy: "pl.col('amount').filter(pl.col('week_object_id')>=week_range['fwoy']).sum().round(2).cast(pl.Int64)",
                            ly: "pl.col('amount').filter((pl.col('week_object_id')>=week_range['fwoy']-52)&(pl.col('week_object_id')<=week_range['to']-52)).sum().round(2).cast(pl.Int64)",
                            pc: "pl.col('product_range').filter((pl.col('product_range')==3)&(pl.col('week_object_id')>=week_range['fwoy'])).count()",
                        },

                        with_columns: [
                            "pl.col('pc').sum().alias('pct')",
                            "pl.col('cy').sum().alias('cy_total')",
                        ],
                    },
                    {
                        with_columns: [
                            "pl.when(pl.col('cy_total')!=0).then(100*pl.col('cy')/pl.col('cy_total')).otherwise(0).round(1).alias('p')",
                        ],
                    },
                ],
                totals: {
                    cy: "pl.col('cy').sum()",
                    ly: "pl.col('ly').sum()",
                    p: "pl.col('p').sum()",
                },
            },
        },

        extraData: {
            query: [
                {
                    id: 'totalPOS',
                    query: "get_parquet('./app/weeklysales').select([pl.col('site_id').unique().count().alias('pos_count')]).collect().to_dicts()[0]['pos_count']",
                },
            ],
        },

        account: {
            table: 'weeklysales',
            query: {
                data: [
                    {
                        groupby: {
                            pos: "pl.col('point_of_sale').filter(pl.col('week_object_id')>=week_range['fwoy']).unique().count()",
                            cy: "pl.col('amount').filter(pl.col('week_object_id')>=week_range['fwoy']).sum().round(2).cast(pl.Int64)",
                            ly: "pl.col('amount').filter((pl.col('week_object_id')>=week_range['fwoy']-52)&(pl.col('week_object_id')<=week_range['to']-52)).sum().round(2).cast(pl.Int64)",
                            pc: "pl.col('product_range').filter((pl.col('product_range')==3)&(pl.col('week_object_id')>=week_range['fwoy'])).count()",
                        },

                        with_columns: [
                            "pl.col('pc').sum().alias('pct')",
                            "pl.col('cy').sum().alias('cy_total')",
                        ],
                    },
                    {
                        with_columns: [
                            "pl.when(pl.col('cy_total')!=0).then(100*pl.col('cy')/pl.col('cy_total')).otherwise(0).round(1).alias('p')",
                        ],
                    },
                ],
                totals: {
                    cy: "pl.col('cy').sum()",
                    ly: "pl.col('ly').sum()",
                    p: "pl.col('p').sum()",
                },
            },
        },
    };

    let divisionColumns: Column[] = [
        {
            name: 'Group',
            key: 'group__' /* SIEMPRE DEBE LLAMARSE ASI */,

            prop: 'group__' /* SIEMPRE DEBE LLAMARSE ASI */,
            id: 'group__' /* SIEMPRE DEBE LLAMARSE ASI */,
            show: true,

            headerStyle: () => {
                return { minWidth: '250px;' };
            },
            totalStyle: () => {
                return { minWidth: '250px;' };
            },
            computedTotals: () => '',
        },
        {
            name: 'POS',
            key: 'pos',

            prop: 'pos',
            id: 'pos',
            show: true,
        },
        {
            name: 'CY',
            key: 'cy',

            prop: 'cy',
            id: 'cy',
            show: true,
        },
        {
            name: 'LY',
            key: 'ly',

            prop: 'ly',
            id: 'ly',
            show: true,
        },
        {
            name: 'VARIATION',
            key: 'variation',

            prop: 'variation',
            id: 'variation',
            show: true,
            style: (v?: any) => {
                const variation = v.ly !== 0 ? (100 * (v.cy - v.ly)) / v.ly : 0;
                if (variation === Infinity) {
                    return {};
                } else if (variation >= 0) return { color: 'green' };
                else if (variation < 0) return { color: 'red' };
                else {
                    return {};
                }
            },
            formatter: (v: any) => {
                v = v || '0';
                v = typeof v === 'number' ? v.toFixed(1) : v;
                return v + '%';
            },
            computed(row?: object) {
                let r: divisionTableRowVariation = row as divisionTableRowVariation;
                const variation = r.ly !== 0 ? (100 * (r.cy - r.ly)) / r.ly : 0;
                return variation;
            },
            computedTotals(row?: object) {
                let r: divisionTableRowVariation = row as divisionTableRowVariation;
                return r ? (r.ly !== 0 ? (100 * (r.cy - r.ly)) / r.ly : 0) : 0;
            },
        },
        {
            name: 'PORTION',
            key: 'p',

            prop: 'p',
            id: 'portion',
            show: true,
            style: (v?: any) => {
                if (v.p === Infinity) {
                    return {};
                } else if (v.p >= 0) return { color: 'green' };
                else if (v.p < 0) return { color: 'red' };
                else {
                    return {};
                }
            },
            formatter: (v: any) => {
                v = v || '0';
                v = typeof v === 'number' ? v.toFixed(1) : v;
                return v + '%';
            },
        },
        {
            name: 'PREMIUM',
            key: 'premium',

            id: 'premium',
            show: true,
            style: (v?: any) => {
                const row = 100 * (v ? v.pc / v.pct : 0);
                if (row === Infinity) {
                    return {};
                } else if (row >= 0) return { color: 'green' };
                else if (row < 0) return { color: 'red' };
                else {
                    return {};
                }
            },
            formatter: (v: any) => {
                v = v || '0';
                v = typeof v === 'number' ? v.toFixed(1) : v;
                return v + '%';
            },
            computed(row?: object) {
                let r: divisionTableRowPremium = row as divisionTableRowPremium;
                return 100 * (r ? r.pc / r.pct : 0);
            },
            computedTotals(row?: object, data?: []) {
                let r = 0;
                console.log(row);
                data?.forEach((d: any) => {
                    r = r + d.premium;
                });
                return r;
            },
        },
    ];

    let accountColumns: Column[] = [
        {
            name: 'Group',
            key: 'group__' /* SIEMPRE DEBE LLAMARSE ASI */,

            prop: 'group__' /* SIEMPRE DEBE LLAMARSE ASI */,
            id: 'group__' /* SIEMPRE DEBE LLAMARSE ASI */,
            show: true,

            headerStyle: () => {
                return { minWidth: '250px;' };
            },
            totalStyle: () => {
                return { minWidth: '250px;' };
            },
            computedTotals: () => '',
        },
        {
            name: 'POS',
            key: 'pos',

            prop: 'pos',
            id: 'pos',
            show: true,
        },
        {
            name: 'CY',
            key: 'cy',

            prop: 'cy',
            id: 'cy',
            show: true,
        },
        {
            name: 'LY',
            key: 'ly',

            prop: 'ly',
            id: 'ly',
            show: true,
        },
        {
            name: 'VARIATION',
            key: 'variation',

            prop: 'variation',
            id: 'variation',
            show: true,
            style: (v?: any) => {
                const variation = v.ly !== 0 ? (100 * (v.cy - v.ly)) / v.ly : 0;
                if (variation === Infinity) {
                    return {};
                } else if (variation >= 0) return { color: 'green' };
                else if (variation < 0) return { color: 'red' };
                else {
                    return {};
                }
            },
            formatter: (v: any) => {
                v = v || '0';
                v = typeof v === 'number' ? v.toFixed(1) : v;
                return v + '%';
            },
            computed(row?: object) {
                let r: divisionTableRowVariation = row as divisionTableRowVariation;
                const variation = r.ly !== 0 ? (100 * (r.cy - r.ly)) / r.ly : 0;
                return variation;
            },
            computedTotals(row?: object) {
                let r: divisionTableRowVariation = row as divisionTableRowVariation;
                return r ? (r.ly !== 0 ? (100 * (r.cy - r.ly)) / r.ly : 0) : 0;
            },
        },
        {
            name: 'PORTION',
            key: 'p',

            prop: 'p',
            id: 'portion',
            show: true,
            style: (v?: any) => {
                if (v.p === Infinity) {
                    return {};
                } else if (v.p >= 0) return { color: 'green' };
                else if (v.p < 0) return { color: 'red' };
                else {
                    return {};
                }
            },
            formatter: (v: any) => {
                v = v || '0';
                v = typeof v === 'number' ? v.toFixed(1) : v;
                return v + '%';
            },
        },
        {
            name: 'PREMIUM',
            key: 'premium',

            id: 'premium',
            show: true,
            style: (v?: any) => {
                const row = 100 * (v ? v.pc / v.pct : 0);
                if (row === Infinity) {
                    return {};
                } else if (row >= 0) return { color: 'green' };
                else if (row < 0) return { color: 'red' };
                else {
                    return {};
                }
            },
            formatter: (v: any) => {
                v = v || '0';
                v = typeof v === 'number' ? v.toFixed(1) : v;
                return v + '%';
            },
            computed(row?: object) {
                let r: divisionTableRowPremium = row as divisionTableRowPremium;
                return 100 * (r ? r.pc / r.pct : 0);
            },
            computedTotals(row?: object, data?: []) {
                let r = 0;
                console.log(row);
                data?.forEach((d: any) => {
                    r = r + d.premium;
                });
                return r;
            },
        },
    ];

    const divisionCounterPivotGroupHierarchy = [
        {
            name: 'Division',
            id: 'product_division',
            filter: 'product_division',
            group: 'division__value',
        },
        {
            name: 'Product Group',
            id: 'product_group',
            filter: 'product_group',
            group: 'group__value',
        },
        {
            name: 'Product Segment',
            id: 'product_segment',
            filter: 'product_segment',
            group: 'segment__value',
        },
        {
            name: 'Product Range',
            id: 'product_range',
            filter: 'product_range',
            group: 'product_range__value',
        },
    ];
    const accountCounterPivotGroupHierarchy = [
        {
            name: 'Account',
            id: 'point_of_sale_account',
            filter: 'point_of_sale_account',
            group: 'account__name',
        },
        {
            name: 'Division',
            id: 'product_division',
            filter: 'product_division',
            group: 'division__value',
        },
        {
            name: 'Product Group',
            id: 'product_group',
            filter: 'product_group',
            group: 'group__value',
        },
        {
            name: 'Product Segment',
            id: 'product_segment',
            filter: 'product_segment',
            group: 'segment__value',
        },
        {
            name: 'Product',
            id: 'marketing_name',
            filter: 'marketing_name',
            group: '',
        },
    ];
    const divisionCounterPivotService = async (group: string, filters: object) => {
        const division = queryV1(queries.division.table, group, queries.division.query, filters);

        const extraData = queryV2(queries.extraData.query);

        const extraDataResponse = await extraData;

        const divisionResponse: QueryV1Response = await division;

        divisionResponse.totals[0] = {
            ...divisionResponse.totals[0],
            pos: extraDataResponse.totalPOS,
        };
        const grouped_totals = {
            ...divisionResponse.totals[0],
            // ...divisionMoreColumnsResponse.totals[0],
        };

        const data = {
            data: divisionResponse.data,
            totals: [grouped_totals],
        };
        return data;
    };

    const accountCounterPivotService = async (group: string, filters: object) => {
        const account = queryV1(queries.account.table, group, queries.account.query, filters);

        const extraData = queryV2(queries.extraData.query);

        const extraDataResponse = await extraData;

        const accountResponse: QueryV1Response = await account;

        accountResponse.totals[0] = {
            ...accountResponse.totals[0],
            pos: extraDataResponse.totalPOS,
        };
        const grouped_totals = {
            ...accountResponse.totals[0],
            // ...accountMoreColumnsResponse.totals[0],
        };

        const data = {
            data: accountResponse.data,
            totals: [grouped_totals],
        };
        return data;
    };
    const divisionCounterString = divisionCounterPivotGroupHierarchy.map((o) => o.group).join('$');
    const queries_to_export_division = [
        {
            service: 'queryV1',
            kwargs: {
                groups: `groups:${divisionCounterString}`,
                name: queries.division.table,
            },
            body: queries.division.query,
        },
    ];

    const accountCounterString = accountCounterPivotGroupHierarchy.map((o) => o.group).join('$');
    const queries_to_export_account = [
        {
            service: 'queryV1',
            kwargs: {
                groups: `groups:${accountCounterString}`,
                name: queries.account.table,
            },
            body: queries.account.query,
        },
    ];
    return (
        <div className={styles.root + ' grid grid-cols-2 gap-4'}>
            <div className="max-h-[400px]">
                <PivotTable
                    className="division"
                    title={'Division'}
                    columns={divisionColumns}
                    initialGroups={divisionCounterPivotGroupHierarchy}
                    PivotService={divisionCounterPivotService}
                    filters={queriesFilter}
                    isExport={true}
                    queries_to_export={queries_to_export_division}
                ></PivotTable>
            </div>
            <div className="max-h-[400px]">
                <PivotTable
                    className="division"
                    title={'Account'}
                    columns={accountColumns}
                    initialGroups={accountCounterPivotGroupHierarchy}
                    PivotService={accountCounterPivotService}
                    filters={queriesFilter}
                    isExport={true}
                    queries_to_export={queries_to_export_account}
                ></PivotTable>
            </div>
           
        </div>
    );
};
