import { PointOfSale } from '../../../utils/interfaces';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { Column } from '../../../utils/interfaces';
let pos: any;
export const getColunmsPOS: Function = (f: Function): Column[] => [
    // {
    //     name: 'POS',
    //     id: 'id',
    //     prop: 'point_of_sale',
    //     open: false,
    //     show: true,
    //     valueType: 'number',
    // },
    {
        name: 'IS RMF',
        id: 'is_rmf',
        prop: 'is_rmf',
        open: false,
        show: true,
        cellType: 'numberBoolean',
        valueType: 'number',
        style: { minWidth: '100px' },
    },
    {
        name: 'Universe',
        id: 'universe',
        prop: 'universe',
        open: false,
        show: true,
        cellType: 'numberBoolean',
        valueType: 'number',
        style: { minWidth: '100px' },
    },
    {
        name: 'Universe VD',
        id: 'universeAV',
        prop: 'universeAV',
        open: false,
        show: true,
        cellType: 'numberBoolean',
        valueType: 'number',
        style: { minWidth: '100px' },
    },
    {
        name: 'Universe MX',
        id: 'universeIM',
        prop: 'universeIM',
        open: false,
        show: true,
        cellType: 'numberBoolean',
        valueType: 'number',
        style: { minWidth: '100px' },
    },
    {
        name: 'Universe DA',
        id: 'universeDA',
        prop: 'universeDA',
        open: false,
        show: true,
        cellType: 'numberBoolean',
        valueType: 'number',
        style: { minWidth: '100px' },
    },

    {
        name: 'COUNTRY',
        id: 'COUNTRY',
        prop: 'country__name',
        open: false,
        show: true,
    },
    {
        name: 'REGION',
        id: 'REGION',
        prop: 'region__name',
        open: false,
        show: true,
    },
    {
        name: 'COUNTRY ZONE',
        id: 'COUNTRY ZONE',
        prop: 'zone__name',
        open: false,
        show: true,
    },
    // {
    //     name: 'STORE',
    //     id: 'STORE',
    //     prop: 'custom_account__name',
    //     open: false,
    //     show: true,
    // },
    {
        name: 'SITE NAME',
        id: 'SITE NAME',
        prop: 'gscn_site_name',
        open: false,
        show: true,
        style: { minWidth: '350px' },
        onClick: (_e: React.MouseEvent, _value: string, row: PointOfSale) => {
            pos = row.point_of_sale;
            f(pos);
        },
        formatting: (value: any) => (
            <div
                style={{ color: 'var(--tertiary)' }}
                className="text-teal-500  cursor-pointer gap-1 items-center font-medium flex"
            >
                {value} <ChevronRightIcon className="h-4 w-4" />
            </div>
        ),
    },
    {
        name: 'SITE ID',
        id: 'SITE ID',
        prop: 'site_id',
        open: false,
        show: true,
    },
    {
        name: 'ANTENNA SHOP',
        id: 'ANTENNA SHOP',
        prop: 'antenna',
        open: false,
        show: true,
        cellType: 'numberBoolean',
        style: { minWidth: '100px' },
    },
    {
        name: 'COV. MX',
        id: 'COV. MX',
        prop: 'coverage_im',
        open: false,
        show: true,
        cellType: 'numberBoolean',
        style: { minWidth: '100px' },
    },

    {
        name: 'COV. AV',
        id: 'COV. AV',
        prop: 'coverage_av',
        open: false,
        show: true,
        cellType: 'numberBoolean',
        style: { minWidth: '100px' },
    },
    {
        name: 'COV. DA',
        id: 'COV. DA',
        prop: 'coverage_da',
        open: false,
        show: true,
        cellType: 'numberBoolean',
        style: { minWidth: '100px' },
    },
    {
        name: 'PHOTO',
        id: 'PHOTO',
        prop: 'photo',
        open: false,
        show: true,
        cellType: 'numberBoolean',
        style: { minWidth: '100px' },
    },
    {
        name: 'KIOSK',
        id: 'KIOSK',
        prop: 'kiosco',
        open: false,
        show: true,
        cellType: 'numberBoolean',
        style: { minWidth: '100px' },
    },
    {
        name: 'STATUS',
        id: 'STATUS',
        prop: 'status',
        open: false,
        show: false,
        cellType: 'numberBoolean',
        style: { minWidth: '100px' },
    },
    // {
    //     name: 'POS ACCOUNT',
    //     id: 'POS ACCOUNT',
    //     prop: 'point_of_sale_account',
    //     open: false,
    //     show: false,
    //     style: { minWidth: '100px' },
    //     valueType: 'number',
    // },
    // {
    //     name: 'POS CITY',
    //     id: 'POS CITY',
    //     prop: 'point_of_sale_city',
    //     open: false,
    //     show: false,
    //     style: { minWidth: '100px' },
    //     valueType: 'number',
    // },
    {
        name: 'POS TYPE',
        id: 'POS TYPE',
        prop: 'pos_type',
        open: false,
        show: false,
        style: { minWidth: '100px' },
        valueType: 'number',
    },
    // {
    //     name: 'SEGMENT',
    //     id: 'SEGMENT',
    //     prop: 'segment',
    //     open: false,
    //     show: false,
    //     style: { minWidth: '100px' },
    //     valueType: 'number',
    // },
    {
        name: 'LATITUDE',
        id: 'LATITUDE',
        prop: 'latitude',
        open: false,
        show: false,
        style: { minWidth: '100px' },
        valueType: 'number',
    },
    {
        name: 'LONGITUDE',
        id: 'LONGITUDE',
        prop: 'longitude',
        open: false,
        show: false,
        style: { minWidth: '100px' },
        valueType: 'number',
    },
    {
        name: 'MSO NAME',
        id: 'MSO NAME',
        prop: 'mso_name',
        open: false,
        show: false,
        style: { width: '100px' },
    },
    // {
    //     name: 'POS COUNTRY',
    //     id: 'POS COUNTRY',
    //     prop: 'point_of_sale_country',
    //     open: false,
    //     show: false,
    //     style: { minWidth: '100px' },
    //     valueType: 'number',
    // },
    // {
    //     name: 'POS REGION',
    //     id: 'POS REGION',
    //     prop: 'point_of_sale_region',
    //     open: false,
    //     show: false,
    //     style: { minWidth: '100px' },
    //     valueType: 'number',
    // },
    // {
    //     name: 'POS ZONE',
    //     id: 'POS ZONE',
    //     prop: 'point_of_sale_zone',
    //     open: false,
    //     show: false,
    //     style: { minWidth: '100px' },
    //     valueType: 'number',
    // },
    // {
    //     name: 'CUSTOM ACCOUNT',
    //     id: 'CUSTOM ACCOUNT',
    //     prop: 'custom_account',
    //     open: false,
    //     show: false,
    //     style: { minWidth: '100px' },
    //     valueType: 'number',
    // },
    {
        name: 'MSO MONITORING',
        id: 'MSO MONITORING',
        prop: 'mso_monitoring',
        open: false,
        show: false,
        cellType: 'numberBoolean',
        style: { minWidth: '100px' },
        valueType: 'number',
    },
    {
        name: 'INTERNAL CHEIL',
        id: 'INTERNAL CHEIL',
        prop: 'internal_cheil',
        open: false,
        show: false,
        cellType: 'numberBoolean',
        style: { minWidth: '100px' },
        valueType: 'number',
    },
    {
        name: 'COVERAGE',
        id: 'COVERAGE',
        prop: 'coverage',
        open: false,
        show: false,
        cellType: 'numberBoolean',
        style: { minWidth: '100px' },
        valueType: 'number',
    },

    {
        name: 'MERCHANDISER COVERAGE',
        id: 'MERCHANDISER COVERAGE',
        prop: 'merchandiser_coverage',
        open: false,
        show: false,
        cellType: 'numberBoolean',
        style: { minWidth: '100px' },
        valueType: 'number',
    },
    {
        name: 'PROMOTER COVERAGE',
        id: 'PROMOTER COVERAGE',
        prop: 'promoter_coverage',
        open: false,
        show: false,
        cellType: 'numberBoolean',
        style: { minWidth: '100px' },
        valueType: 'number',
    },
    {
        name: 'COVERAGE PROMOTE CE',
        id: 'COVERAGE PROMOTE CE',
        prop: 'coverage_promote_ce',
        open: false,
        show: false,
        cellType: 'numberBoolean',
        style: { minWidth: '100px' },
        valueType: 'number',
    },
    {
        name: 'COVERAGE PROMOTE IM',
        id: 'COVERAGE PROMOTE IM',
        prop: 'coverage_promote_im',
        open: false,
        show: false,
        cellType: 'numberBoolean',
        style: { minWidth: '100px' },
        valueType: 'number',
    },
    {
        name: 'RANK ACCOUNT',
        id: 'RANK ACCOUNT',
        prop: 'rank_account',
        open: false,
        show: false,
        style: { minWidth: '100px' },
        valueType: 'number',
    },
    {
        name: 'RANK COUNTRY',
        id: 'RANK COUNTRY',
        prop: 'rank_country',
        open: false,
        show: false,
        style: { minWidth: '100px' },
        valueType: 'number',
    },
    {
        name: 'RANK NCA',
        id: 'RANK NCA',
        prop: 'rank_nca',
        open: false,
        show: false,
        style: { minWidth: '100px' },
    },
    {
        name: 'IS SELA',
        id: 'IS SELA',
        prop: 'is_sela',
        open: false,
        show: false,
        cellType: 'numberBoolean',
        style: { minWidth: '100px' },
        valueType: 'number',
    },
    // {
    //     name: 'SITE LOCATION ID',
    //     id: 'SITE LOCATION ID',
    //     prop: 'site_location_id',
    //     open: false,
    //     show: false,
    //     style: { minWidth: '100px' },
    //     valueType: 'number',
    // },
    {
        name: 'ACCOUNT NAME',
        id: 'ACCOUNT NAME',
        prop: 'account__name',
        open: false,
        show: false,
    },
    {
        name: 'CITY NAME',
        id: 'CITY NAME',
        prop: 'city__name',
        open: false,
        show: false,
    },

    {
        name: 'GRADE IM',
        id: 'GRADE IM',
        prop: 'grade_im',
        open: false,
        show: false,
        style: { minWidth: '100px' },
    },
    {
        name: 'GRADE AV',
        id: 'GRADE AV',
        prop: 'grade_av',
        open: false,
        show: false,
        style: { minWidth: '100px' },
    },
    {
        name: 'GRADE DA',
        id: 'GRADE DA',
        prop: 'grade_da',
        open: false,
        show: false,
        style: { minWidth: '100px' },
    },
    {
        name: 'ANTENNA IM',
        id: 'ANTENNA IM',
        prop: 'antenna_im',
        open: false,
        show: false,
        cellType: 'numberBoolean',
        style: { minWidth: '100px' },
        valueType: 'number',
    },
    {
        name: 'ANTENNA AV',
        id: 'ANTENNA AV',
        prop: 'antenna_av',
        open: false,
        show: false,
        cellType: 'numberBoolean',
        style: { minWidth: '100px' },
        valueType: 'number',
    },
    {
        name: 'ANTENNA DA',
        id: 'ANTENNA DA',
        prop: 'antenna_da',
        open: false,
        show: false,
        cellType: 'numberBoolean',
        style: { minWidth: '100px' },
        valueType: 'number',
    },
    {
        name: 'SEGMENT IM',
        id: 'SEGMENT IM',
        prop: 'segment_im',
        open: false,
        show: false,
        cellType: 'numberBoolean',
        style: { minWidth: '100px' },
        valueType: 'number',
    },
    {
        name: 'SEGMENT AV',
        id: 'SEGMENT AV',
        prop: 'segment_av',
        open: false,
        show: false,
        cellType: 'numberBoolean',
        style: { minWidth: '100px' },
        valueType: 'number',
    },
    {
        name: 'SEGMENT DA',
        id: 'SEGMENT DA',
        prop: 'segment_da',
        open: false,
        show: false,
        cellType: 'numberBoolean',
        style: { minWidth: '100px' },
        valueType: 'number',
    },
    {
        name: '2023 DS AVAILABLE',
        id: '2023 DS AVAILABLE',
        prop: '2023_ds_available',
        open: false,
        show: false,
        cellType: 'boolean',
        style: { minWidth: '100px' },
    },
    {
        name: '2020 DS AVAILABLE',
        id: '2020 DS AVAILABLE',
        prop: '2020_ds_available',
        open: false,
        show: false,
        cellType: 'boolean',
        style: { minWidth: '100px' },
    },
    {
        name: '2019 DS AVAILABLE',
        id: '2019 DS AVAILABLE',
        prop: '2019_ds_available',
        open: false,
        show: false,
        cellType: 'boolean',
        style: { minWidth: '100px' },
    },
    {
        name: '2021 DS AVAILABLE',
        id: '2021 DS AVAILABLE',
        prop: '2021_ds_available',
        open: false,
        show: false,
        cellType: 'boolean',
        style: { minWidth: '100px' },
    },
    {
        name: '2022 DS AVAILABLE',
        id: '2022 DS AVAILABLE',
        prop: '2022_ds_available',
        open: false,
        show: false,
        cellType: 'boolean',
        style: { minWidth: '100px' },
    },
    {
        name: '2023 MS AVAILABLE',
        id: '2023 MS AVAILABLE',
        prop: '2023_ms_available',
        open: false,
        show: false,
        cellType: 'boolean',
        style: { minWidth: '100px' },
    },
    {
        name: '2020 MS AVAILABLE',
        id: '2020 MS AVAILABLE',
        prop: '2020_ms_available',
        open: false,
        show: false,
        cellType: 'boolean',
        style: { minWidth: '100px' },
    },
    {
        name: '2021 MS AVAILABLE',
        id: '2021 MS AVAILABLE',
        prop: '2021_ms_available',
        open: false,
        show: false,
        cellType: 'boolean',
        style: { minWidth: '100px' },
    },
    {
        name: '2017 MS AVAILABLE',
        id: '2017 MS AVAILABLE',
        prop: '2017_ms_available',
        open: false,
        show: false,
        cellType: 'boolean',
        style: { minWidth: '100px' },
    },
    {
        name: '2018 MS AVAILABLE',
        id: '2018 MS AVAILABLE',
        prop: '2018_ms_available',
        open: false,
        show: false,
        cellType: 'boolean',
        style: { minWidth: '100px' },
    },
    {
        name: '2019 MS AVAILABLE',
        id: '2019 MS AVAILABLE',
        prop: '2019_ms_available',
        open: false,
        show: false,
        cellType: 'boolean',
        style: { minWidth: '100px' },
    },
    {
        name: '2016 MS AVAILABLE',
        id: '2016 MS AVAILABLE',
        prop: '2016_ms_available',
        open: false,
        show: false,
        cellType: 'boolean',
        style: { minWidth: '100px' },
    },
    {
        name: '2022 MS AVAILABLE',
        id: '2022 MS AVAILABLE',
        prop: '2022_ms_available',
        open: false,
        show: false,
        cellType: 'boolean',
        style: { minWidth: '100px' },
    },
    {
        name: '2020 GSCM AVAILABLE',
        id: '2020 GSCM AVAILABLE',
        prop: '2020_gscm_available',
        open: false,
        show: false,
        cellType: 'boolean',
        style: { minWidth: '100px' },
    },
    {
        name: '2021 GSCM AVAILABLE',
        id: '2021 GSCM AVAILABLE',
        prop: '2021_gscm_available',
        open: false,
        show: false,
        cellType: 'boolean',
        style: { minWidth: '100px' },
    },
    {
        name: '2022 GSCM AVAILABLE',
        id: '2022 GSCM AVAILABLE',
        prop: '2022_gscm_available',
        open: false,
        show: false,
        cellType: 'boolean',
        style: { minWidth: '100px' },
    },
    {
        name: '2023 GSCM AVAILABLE',
        id: '2023 GSCM AVAILABLE',
        prop: '2023_gscm_available',
        open: false,
        show: false,
        cellType: 'boolean',
        style: { minWidth: '100px' },
    },
];

export function setSelectedPOS() {
    return pos;
}
