import { TableHeaderColumn } from '../table-column-header/table-column-header';
import { Column } from '../../utils/interfaces';
export interface ColumnsHeadProps {
    className?: string;
    columns: Column[];
    sortData: (s: any) => void;
    onOpen?: (s: any) => void;
    selectAll?: () => void;    allColumnsStyle?: string;
    selectAllBoolean?: boolean;
    activeCompactMode?: boolean;
    isRolesTable?: boolean;
    lightTheme?: boolean;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const TableHeader = ({
    columns,
    sortData,
    selectAll,
    onOpen = () => {},allColumnsStyle,
    selectAllBoolean, 
    activeCompactMode = false,
    isRolesTable = false,
    lightTheme= false,
}: ColumnsHeadProps) => {
    const closeAllMenu = (v: any) => {
        const newColumns = columns.map((c) => {
            if (c.prop === v.prop) {
                c.open = !c.open;
            } else {
                c.open = false;
            }
            return c;
        });

        onOpen(newColumns);
    };
    return (
        <div className="sticky top-0 flex w-full">
            {columns.map((c, index:number) => (
                <TableHeaderColumn
                    props={c}
                    key={c.prop+c.name+index}
                    isCheckbox={c.id === 'id'}
                    functionSort={(s: any) => sortData(s)}
                    onClick={closeAllMenu}
                    selectAll={selectAll} 
                    allColumnsStyle={allColumnsStyle}
                    selectAllBoolean={selectAllBoolean}
                    index={index}
                    activeCompactMode={activeCompactMode}
                    isRolesTable={isRolesTable}
                    lightTheme={lightTheme}
                />
            ))}
        </div>
    );
};
