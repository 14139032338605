import { CustomCheckbox } from '../custom-checkbox/custom-checkbox';
import styles from './cell.module.scss';

export interface CellProps {
    className?: string;
    name?: any;
    value?: string;
    style?: any;
    onClick?: Function; // cell onClick event
    row?: object;
    formatting?: Function;
    cellType?: string;
    isCheckbox?: boolean;
    selectId?: (id: number) => void | undefined;
    allColumnsStyle?: string;
    idsArray?: number[] | undefined;
    // cell's row raw data
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const TableCell = ({
    value = 'TableCell',
    style,
    onClick = () => {},
    row = {},
    formatting,
    cellType,
    isCheckbox,
    selectId,
    allColumnsStyle,
    idsArray,
}: CellProps) => {
    const formatValue = (value: any, cellType: string = '') => {
        switch (cellType) {
            case 'numberBoolean':
                return value === 1 ? 'YES' : 'NO';

            case 'boolean':
                return value ? 'YES' : 'NO';

            default:
                return value;
        }
    };

    const sendIdValue = () => {
        if (selectId) selectId(parseInt(value));
    };
    const addClass = isCheckbox ? '' : allColumnsStyle;
    return (
        <div
            className={'p-1 truncate text-sm ' + addClass+' '+styles.rowStyle}
            style={style ? style : {}}
            onClick={(e) => onClick(e, value, row)}
        >
            {isCheckbox ? (
                <CustomCheckbox
                    ifChecked={idsArray?.includes(parseInt(value))}
                    change={sendIdValue}
                />
            ) : formatting ? (
                formatting(value, row)
            ) : (
                formatValue(value, cellType)
            )}
        </div>
    );
};
