import styles from './pbi-embed.module.scss';
import { useEffect, useRef, useState, useContext } from 'react';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { getPowerBiEmbeddedData } from '../../services/python-services';
import { viewInArrayViews, isValidString, registerViewLog } from '../../utils/functions';
import { useNavigate, useParams } from 'react-router-dom';
import { RMContext } from '../../context/RMContext';
import { getBiData } from '../../services/python-services';
import { getRefresh, getRefreshStatus } from '../../services/python-services';
import { Button } from '../button/button';
import Notiflix from 'notiflix';

export interface PbiEmbedProps {
    className?: string;
    selectedReport?: any;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const PbiEmbed = ({ selectedReport = null }: PbiEmbedProps) => {
    const { parent, child, view } = useParams();
    const [embeddedData, setEmbeddedData] = useState<any>(null);
    const [embedConfig, setEmbeddedConfig] = useState<any>(null);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [updatedReport, setUpdatedReport] = useState(false);
    const divRef = useRef<any>('report');
    const [selectedReportsData, setSelectedReportsData] = useState<any>(selectedReport);
    const { sitemap, isLocal, userGlobals } = useContext(RMContext).userData;
    const [reports, setReports] = useState<any[]>([]);
    const [showAlert, setShowAlert] = useState(false);

    let navigate = useNavigate();

    const childIsLastLevel = (selecteChild: string) => {
        const dataView = viewInArrayViews(
            selecteChild,
            isValidString(view!) || isValidString(child!) ? reports : sitemap
        );
        if (dataView !== undefined && dataView !== null && dataView?.children?.length === 0) {
            setSelectedReportsData(dataView);
        } else if (dataView !== undefined && dataView !== null) {
            setSelectedReportsData(dataView);
        } else {
            setSelectedReportsData(null);
            setShowAlert(true);
        }
    };
    useEffect(() => {
        if (showAlert) {
            Notiflix.Notify.failure('View does not exist in RM views');
            setShowAlert(false);
        }
    }, [showAlert]);

    useEffect(() => {
        if (isValidString(parent!) && sitemap.length > 0) {
            callGetBiData();
            //childIsLastLevel(parent!);
            if (!isValidString(view!) && !isValidString(child!)) {
                registerViewLog(viewInArrayViews(parent!, sitemap)?.id);
            }
        }
    }, [parent, sitemap]);

    useEffect(() => {
        if ((isValidString(view!) || isValidString(child!)) && reports!.length > 0) {
            childIsLastLevel(view! || child!);
            registerViewLog(
                isValidString(view!)
                    ? viewInArrayViews(view!, reports)?.id
                    : isValidString(child!)
                    ? viewInArrayViews(child!, reports)?.id
                    : ''
            );
        }
    }, [view, child, reports]);

    async function getPowerBiData() {
        if (selectedReportsData?.report_id && selectedReportsData?.workspace_id) {
            const response = await getPowerBiEmbeddedData(
                selectedReportsData?.report_id,
                selectedReportsData?.workspace_id
            );
            const urlIframeWrapper = document.getElementById('urlIframe');
            urlIframeWrapper?.classList.add('styles.hideContent');
            setEmbeddedData(response);
        } else {
            const urlIframeWrapper = document.getElementById('urlIframe');
            urlIframeWrapper?.classList.remove('styles.hideContent');
        }
    }

    async function callGetBiData() {
        let reports = await getBiData(parent?.replace('-', '_'));
        setReports(reports);
    }

    useEffect(() => {
        getPowerBiData();
    }, [selectedReportsData]);

    const getPowerBIEmbedConfig = () => {
        // console.log("getPowerBIEmbedConfig", canDownload);
        setEmbeddedConfig({
            type: 'report',
            id: embeddedData?.data?.reportConfig[0].reportId,
            embedUrl: embeddedData?.data?.reportConfig[0].embedUrl,
            accessToken: embeddedData?.data?.accessToken,
            tokenType: models.TokenType.Embed,
            settings: {
                filterPaneEnabled: false,
                navContentPaneEnabled: true,
                useCustomSaveAsDialog: true,
                zoomLevel: 1,
                bars: {
                    statusBar: {
                        visible: true,
                    },
                    actionBar: {
                        visible: true,
                    },
                },
                commands: [
                    {
                        exportData: {
                            displayOption: !userGlobals.canDownload
                                ? models.CommandDisplayOption.Hidden
                                : models.CommandDisplayOption.Enabled,
                        },
                    },
                ],
            },
        });
    };

    useEffect(() => {
        if (updatedReport) {
            getPowerBiData();
            setUpdatedReport(false);
        }
    }, [updatedReport]);

    useEffect(() => {
        if (embeddedData !== null) {
            getPowerBIEmbedConfig();
            // Force a render without state change...
        }
    }, [embeddedData]);
    useEffect(() => {
        document.addEventListener('fullscreenchange', handleFullscreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
        document.addEventListener('mozfullscreenchange', handleFullscreenChange);
        document.addEventListener('MSFullscreenChange', handleFullscreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
            document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
            document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
        };
    }, []);

    const handleFullscreenChange = () => {
        if (!isFullscreen) {
            // Fullscreen mode exited
            setIsFullScreen(false);
        }
    };

    const isFullscreen = (): boolean => {
        return (
            document.fullscreenElement ||
            (document as any).webkitFullscreenElement ||
            (document as any).mozFullScreenElement ||
            (document as any).msFullscreenElement
        );
    };

    const keyboardHandler = (event: any) => {
        event.stopPropagation();

        if (event.key === 'Escape' || event.key === 'Esc') {
            setIsFullScreen(false);
        }
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    const [isRefreshHovered, setIsRefreshHovered] = useState(false);
    const [isFullscreenHovered, setIsFullscreenHovered] = useState(false);
    const currentView = window.location.href.split('/').slice(-1)[0];
    const [spin, setSpin] = useState(false);
    const [lastRefresh, setLastRefresh] = useState('');
    const [startRefresh, setStartRefresh] = useState('');
    const [fullScreenState, setIsFullScreenState] = useState(false);
    const regexLastDate = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.\d+Z/;
    const replacement = (
        _match: string,
        year: string,
        month: string,
        day: string,
        hour: string,
        minute: string,
        _second: string
    ): string => {
        let newHour = parseInt(hour) - 5;
        if (newHour < 0) {
            newHour += 24;
        }
        return `${year}-${month}-${day}\n${newHour}:${minute}`;
    };
    const viewD = viewInArrayViews(currentView, sitemap);

    // console.log(viewD, "viewD")

    useEffect(() => {
        /* const fetchData = async () => {
            if (viewD) {
                const statusRefresh = await getRefreshStatus(viewD?.id);
                setLastRefresh(
                    statusRefresh?.value[0]?.endTime.toString().replace(regexLastDate, replacement)
                );
            }
        }; */

        const fetchStatus = async () => {
            if (viewD || updatedReport) {
                const statusRefresh = await getRefreshStatus(viewD?.id);
                if (statusRefresh?.value[0]?.status !== 'Unknown') {
                    setSpin(false);
                    clearInterval(statusInterval);
                    // setUpdatedReport(true);
                } else {
                    setSpin(true);
                    setStartRefresh(
                        statusRefresh?.value[0]?.startTime
                            .toString()
                            .replace(regexLastDate, replacement)
                    );
                }
                setUpdatedReport(false);
            }
        };

        /* fetchData().catch(console.error);
        fetchStatus().catch(console.error); */

        const statusInterval = setInterval(fetchStatus, 10000);

        return () => clearInterval(statusInterval);
    }, [viewD && viewD.id]);

    const handleRefreshHover = (hover: boolean) => {
        setIsRefreshHovered(hover);
    };

    const handleFullscreenHover = (hover: boolean) => {
        setIsFullscreenHovered(hover);
    };

    const displayTry = async () => {
        setSpin(true);
        setStartRefresh('...');
        const statusInterval = setInterval(async function () {
            if (viewD) {
                const statusR = await getRefreshStatus(viewD?.id);
                setStartRefresh(
                    statusR.value[0].startTime.toString().replace(regexLastDate, replacement)
                );
                if (statusR.value[0].status !== 'Unknown') {
                    clearInterval(statusInterval);
                    setSpin(false);
                    setUpdatedReport(true);
                }
            }
        }, 5000);
        getRefresh(viewD?.id).catch(console.error);
    };

    useEffect(() => {
        const viewD = viewInArrayViews(currentView, sitemap);
        if (!spin && viewD) {
            const fetchData = async () => {
                const statusRefresh = await getRefreshStatus(viewD?.id);
                setLastRefresh(
                    statusRefresh?.value[0]?.endTime?.toString().replace(regexLastDate, replacement)
                );
            };
            fetchData().catch(console.error);
        }
    }, [spin]);

    const requestFullscreenFunction = () => {
        if (fullScreenState === false) {
            document.getElementById('urlIframe')?.requestFullscreen();
            setIsFullScreenState((value: any) => !value);
        } else {
            document.exitFullscreen();
            setIsFullScreenState((value: any) => !value);
        }
    };

    return (
        <div
            id="urlIframe"
            style={{ height: '100%' }}
            onKeyDown={keyboardHandler}
            className={styles['power-bi-report-container']}
        >
            <div
                className={fullScreenState ? styles.returnButtonFullscreen : styles.returnButton}
                style={{ top: isLocal ? '120px' : '5px' }}
            >
                <Button
                    click={handleGoBack}
                    styleProp="inline-block rounded border px-2 py-1 text-sm font-medium focus:outline-none focus:ring border-light-gray bg-white text-dark-gray flex gap-1 items-center h-full"
                >
                    Return
                </Button>
                <div
                    className={styles.iconContainer}
                    onMouseEnter={() => handleRefreshHover(true)}
                    onMouseLeave={() => handleRefreshHover(false)}
                    onClick={() => !spin && displayTry()}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="black"
                        className={`w-6 h-6 ${spin ? styles.spin : ''}`}
                        style={{ cursor: 'pointer' }}
                    >
                        <path d="M4.755 10.059a7.5 7.5 0 0 1 12.548-3.364l1.903 1.903h-3.183a.75.75 0 1 0 0 1.5h4.992a.75.75 0 0 0 .75-.75V4.356a.75.75 0 0 0-1.5 0v3.18l-1.9-1.9A9 9 0 0 0 3.306 9.67a.75.75 0 1 0 1.45.388Zm15.408 3.352a.75.75 0 0 0-.919.53 7.5 7.5 0 0 1-12.548 3.364l-1.902-1.903h3.183a.75.75 0 0 0 0-1.5H2.984a.75.75 0 0 0-.75.75v4.992a.75.75 0 0 0 1.5 0v-3.18l1.9 1.9a9 9 0 0 0 15.059-4.035.75.75 0 0 0-.53-.918Z" />
                    </svg>
                    {isRefreshHovered && spin == false && (
                        <div className={styles.tooltip}>
                            <div style={{ alignItems: 'center' }}> Last Refresh:</div>
                            <div
                                style={{
                                    textAlign: 'center',
                                    whiteSpace: 'pre-wrap',
                                }}
                            >
                                {lastRefresh}
                            </div>
                        </div>
                    )}
                    {spin && (
                        <div className={styles.tooltip}>
                            <div style={{ fontSize: '15px' }}> Started Refreshing: </div>
                            <div style={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>
                                {startRefresh}
                            </div>
                        </div>
                    )}
                </div>
                <div
                    className={styles.iconContainer}
                    onMouseEnter={() => handleFullscreenHover(true)}
                    onMouseLeave={() => handleFullscreenHover(false)}
                    onClick={() => requestFullscreenFunction()}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="black"
                        className={`w-6 h-6 ${isFullscreenHovered ? styles.hovered : ''}`}
                        style={{ cursor: 'pointer' }}
                    >
                        <path d="M15 3.75a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0V5.56l-3.97 3.97a.75.75 0 1 1-1.06-1.06l3.97-3.97h-2.69a.75.75 0 0 1-.75-.75Zm-12 0A.75.75 0 0 1 3.75 3h4.5a.75.75 0 0 1 0 1.5H5.56l3.97 3.97a.75.75 0 0 1-1.06 1.06L4.5 5.56v2.69a.75.75 0 0 1-1.5 0v-4.5Zm11.47 11.78a.75.75 0 1 1 1.06-1.06l3.97 3.97v-2.69a.75.75 0 0 1 1.5 0v4.5a.75.75 0 0 1-.75.75h-4.5a.75.75 0 0 1 0-1.5h2.69l-3.97-3.97Zm-4.94-1.06a.75.75 0 0 1 0 1.06L5.56 19.5h2.69a.75.75 0 0 1 0 1.5h-4.5a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 1 1.5 0v2.69l3.97-3.97a.75.75 0 0 1 1.06 0Z" />
                    </svg>
                    {isFullscreenHovered && <div className={styles.status}>Fullscreen</div>}
                </div>
            </div>
            {selectedReportsData?.report_id &&
                selectedReportsData?.workspace_id &&
                embedConfig !== null && (
                    <div className={styles.fullH}>
                        <PowerBIEmbed
                            key={embedConfig.accessToken}
                            embedConfig={embedConfig}
                            cssClassName={styles['power-bi-report-class']}
                        />
                    </div>
                )}
            {!selectedReportsData?.report_id &&
                !selectedReportsData?.workspace_id &&
                selectedReportsData?.url && (
                    <iframe
                        style={{ height: '100%', width: '100%' }}
                        src={selectedReportsData?.url}
                    />
                )}
        </div>
    );
};
