import ClickAwayListener from 'react-click-away-listener';
import { ItemsMenu } from '../items-menu/items-menu';

export interface MenuProps {
    className?: string;
    options: any[];
    onSelect: (value: any) => void;
    index?: number;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const Menu = ({ options, onSelect, index=0 }: MenuProps) => {
    return (
        
        <div id="menu" className={"absolute z-100 mt-2 w-50 divide-y divide-gray-100 rounded-md border border-gray-100 bg-white shadow-lg "+(index!==0?'end-0 right-2 top-4':'end-[-1] left-1 top-4')}>
            <ClickAwayListener
                                            onClickAway={(event: any) => {
                                                if (event.target?.id !== 'menuItem'){
                                                    document.getElementById("menuItem")!.style.display="none";                                                           
                                                }
                                            }}
                                        >
            <div className={"p-2 "} id = 'menuItem'>
                {options.map((option) => (
                    <ItemsMenu
                        props={option}
                        key={option.name}
                        onClick={(value) => onSelect(value)}
                    />
                ))}
            </div>
            </ClickAwayListener>
        </div>
    );
};
