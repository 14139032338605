import { useContext, useEffect, useState } from 'react';
import styles from './header-bar.module.scss';
import classNames from 'classnames';
import logo from '../../assets/logo_2x.svg';
import userImg from '../../assets/avatar_img.png';
import { BellIcon } from '@heroicons/react/24/outline';
import { LogOut, formatDate } from '../../utils/functions';
import { NavLink } from 'react-router-dom';
import { getGitbookToken, getNotifications } from '../../services/python-services';
import { RMContext } from '../../context/RMContext';
import { Notifications } from '../notifications/notifications';
import ClickAwayListener from 'react-click-away-listener';

export interface HeaderBarProps {
    className?: string;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const HeaderBar = ({ className }: HeaderBarProps) => {
    // const [notificationsOpened, setNotificationsOpened] = useState(false);
    const [dropDownOpened, setDropDownOpened] = useState(false);
    // const [unreadNotifications, setUnredNotifications] = useState(0);
    const { email, last_login } = useContext(RMContext).userData?.userData;
    // const [notifications, setNotifications] = useState<any[]>([]);
    // const [updateNotifications,setUpdateNotifications] = useState(true);
    const lastLogin = formatDate(last_login);

    // const getUserNotifications = async () => {
    //     const dataN = await getNotifications();
    //     if (dataN.length > 0) {
    //         setNotifications(dataN);
    //         setUnredNotifications(dataN.filter((not:any)=>not.read===false).length);
    //     }
    //     setUpdateNotifications(false);
    // }
    // useEffect(() => {
    //     if(updateNotifications)
    //     getUserNotifications();
    // }
    //     , [updateNotifications]);
    // const notificationsToggle = () => {
    //     setNotificationsOpened(!notificationsOpened);
    // }
    const openDropDown = () => {
        setDropDownOpened(true);
    };
    const closeDropDown = () => {
        setDropDownOpened(false);
    };
    const onClickHelpAndDocs = async () => {
        try {
            const token = await getGitbookToken();
            const url = 'https://docs.cheilbidx.io/?jwt_token=' + token;
            window.open(url, '_blank');
        } catch (e) {
            console.error('Error getting token', e);
        }
    };
    // const closeNotifications = () => {
    //     setNotificationsOpened(false);
    // }
    return (
        <div className={styles.header + ' ' + classNames(styles.root, className)}>
            <div className={styles.brand + ' ' + styles.inline}>
                <img src={logo} alt="logo" width="100" />
            </div>

            <div className={styles['d-flex'] + ' ' + styles['align-items-center']}>
                {/* START User Info */}
                <div className={styles['header-container']}>
                    {/* <div
                className={styles["header-icon-container"]}
                    onClick={() => notificationsToggle()}
                >
                    { unreadNotifications > 0 && <span>{unreadNotifications}</span>}
                    <BellIcon className={styles.smallIcon + ` ${notificationsOpened || unreadNotifications ? styles['active'] : ''
                        }`}  />

                </div> */}
                    <div
                        className={
                            styles['dropdown'] +
                            ' ' +
                            styles['pull-right'] +
                            ' ' +
                            styles['d-lg-block']
                        }
                    >
                        <button
                            className={styles['profile-dropdown-toggle']}
                            type="button"
                            id="profileDropdown"
                            aria-label="profile dropdown"
                            onClick={openDropDown}
                        >
                            <div className={styles['user_img']}>
                                <img
                                    className={styles['img-fluid']}
                                    src={userImg}
                                    alt="User Logo"
                                />
                            </div>
                        </button>
                    </div>
                </div>
                {/* {notificationsOpened && (
                <ClickAwayListener onClickAway={closeNotifications}>
                <div className="notifications-container" 
                >
                   <Notifications notifications={notifications}  notificationsToggle={notificationsToggle} 
                   setUpdateNotifications={setUpdateNotifications}></Notifications>
                </div>
                </ClickAwayListener>
            )} */}
                {dropDownOpened && (
                    <ClickAwayListener onClickAway={closeDropDown}>
                        <div
                            role="menu"
                            className={
                                styles['dropdown-menu'] +
                                ' ' +
                                styles['dropdown-menu-right'] +
                                ' ' +
                                styles['profile-dropdown'] +
                                ' ' +
                                styles['show']
                            }
                            style={{
                                inset: '70px 12px auto auto',
                                transform: 'translateY(0px)',
                                width: 'fit-content',
                                padding: '5px 10px 5px 10px',
                            }}
                        >
                            <div className={styles.triangle} />
                            <div id="user-id-item" className={styles['dropdown-item']}>
                                <span>
                                    Signed in as <br />
                                    <b style={{ fontSize: '17px' }}>{email}</b>
                                </span>
                                <br />
                            </div>
                            <span
                                className={styles['dropdown-item'] + ' ' + styles['hint-text']}
                                style={{ fontSize: '12px' }}
                            >
                                Last Login: {lastLogin}
                            </span>
                            <hr style={{ marginTop: '1px' }} />
                            <div className={styles['options']} onClick={onClickHelpAndDocs}>
                                {' '}
                                Help and Docs{' '}
                            </div>
                            {/* <NavLink to="/settings/data_control/data_control" className={styles["dropdown-item"]} style={{ fontSize: "12px" }}>Settings </NavLink> */}

                            <NavLink to="/policies" className={styles['options']}>
                                Policies
                            </NavLink>
                            <div onClick={LogOut} className={styles['buttonLogout']}>
                                {' '}
                                Logout{' '}
                            </div>
                        </div>
                    </ClickAwayListener>
                )}
            </div>
        </div>
    );
};
