export interface FilterSectionProps {
    className?: string;
    children: any;
    title: string;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const FilterSection = ({
    children = 'CONTENIDO',
    title = 'Titulo',
    className = 'flex flex-col gap-3',
}: FilterSectionProps) => {
    return (
        <div className={className}>
            <h1 className="text-base font-extrabold uppercase text-[#66D0AF]">{title}</h1>
            {children}
        </div>
    );
};
