import styles from './button-group.module.scss';
import { useState } from 'react'
//import classNames from 'classnames';

export interface ButtonGroupProps {
    className?: string;
    buttons: string[];
    id?: string;
    onChange: Function;
    label?: string;
    clickedGroupId:number;
    setClickedGroupId: Function;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const ButtonGroup = ({ className, buttons, id = 'buttonGroup', onChange, label,
clickedGroupId,setClickedGroupId, }: ButtonGroupProps) => {
    // const [clickedGroupId, setClickedGroupId] = useState(value);
    const handleClick = (i: number) => {
        setClickedGroupId(i)
        onChange(i);
    }
    return (
        <div id={id}>
            <div ><label ><strong>{label}</strong></label></div>
            <div className={styles.buttonGroup} >
                {buttons.map((buttonLabel, i) => (
                    <button key={i} name={buttonLabel} onClick={() => handleClick(i)} className={(i === 0 ? styles.firstButton : i === buttons.length - 1 ? styles.lastButton : styles.singleButton) + " " +
                     styles[className!] + " " + ((clickedGroupId === (i) && (clickedGroupId!==-1 ) ) || (i===0 && (clickedGroupId===-1 ||clickedGroupId===0 )) ? styles.active : styles.inactive)}
                        id={buttonLabel}
                        style={{ width: (100 / buttons.length) + "%" }}>
                        {buttonLabel}
                    </button>
                ))}
            </div>
            <br/>
        </div>
    );

}