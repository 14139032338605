import { useContext, useEffect, useState, useMemo } from 'react';
import styles from './radio-button-form.module.scss';

export interface RadioButtonFormProps {
    className?: string;
    props?: any;
    name?: any;
    onChange?: any;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const RadioButtonForm = ({ props, onChange }: RadioButtonFormProps) => {
    const options = props.options;
    const types = props.types;
    const getNamesQty = Object.keys(options).length / 2;
    const alertTypesTiles = [];
    for (let i = 0; i < getNamesQty; i++) {
        alertTypesTiles.push(options[i]);
    }

    const handleRadioChange = (index: number) => {
        if (onChange) {
            onChange(index);
        }
        if (index === 0 || index === 1){
        props.setPortsOrAny(true)
        }
        else {
            props.setPortsOrAny(false)
        }
    };
    return (
        <div className={styles['filter']}>
            {props.titleRatio}
            <div className={styles['filter-btns']}>
                {alertTypesTiles.map((value: any, index) => (
                    <span key={index}>
                        <input
                            value={index + 1}
                            type="radio"
                            name="alert_type"
                            checked={types === index}
                            onChange={() => {
                                handleRadioChange(index);
                            }}
                            key={index}
                        />{' '}
                        <label>{value}</label>
                    </span>

                ))}
            </div>
        </div>
    );
};
