import styles from './login-regular-base.module.scss';
import classNames from 'classnames';
import { LoginComponent } from '../login-component/login-component';
import { useEffect, useState, useContext } from 'react';
import Notiflix from 'notiflix';
import { Spinner } from 'flowbite-react';
import AuthCode from 'react-auth-code-input';
import { useNavigate } from 'react-router-dom';
import { RMContext } from '../../context/RMContext';
import {
    getToken,
    verifyEmail,
    OTPemail,
    linkSendEmail,
    verifyEmailAndPassword,
    emailResetPassword,
} from '../../services/python-services';
import {
    addAuthOptionsToContext,
    executeLoginProcess,
    getEnvironment,
    updateIsLocalToContext,
    validatePassword,
} from '../../utils/functions';
import { getInterceptorError } from '../../utils/functions';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
export interface LoginRegularBaseProps {
    className?: string;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const LoginRegularBase = ({}: /* className */ LoginRegularBaseProps) => {
    const { setUserData, userData } = useContext(RMContext);
    const [userName, setUserName] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [OTP, setOTP] = useState('');
    const [password, setPassword] = useState('');
    const [authentication, setAuthentication] = useState(false);
    const [showOTP, setShowOTP] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [loadingLink, setLoadingLink] = useState(false);
    const [otpLoading, setOTPLoading] = useState(false);
    const [upCounter, setUpCounter] = useState(0);
    const [counterDown, setCounterDown] = useState(5);
    const [wrongEntry, setWrongEntry] = useState(false);
    const [showNext, setShowNext] = useState(true);
    const [inputPassword, setInputPassword] = useState(false);
    const [authOptions_, setAuthOptions_] = useState<{ password: boolean | null }>({
        password: null,
    });
    const [sendResetPassword, setSendResetPassword] = useState(false);
    const [spinnerOnNext, setSpinnerOnNext] = useState(false);
    const [maintenance, setMaintenance] = useState(0);
    const [passwordError, setPasswordError] = useState<any>([]);
    const regexError = /:\s*(.+)/;
    const navigate = useNavigate();

    const setCodeFreeze = () => {
        return upCounter >= 5 ? false : true;
    };
    const authOptionsF = async (email: any) => {
        try {
            const response = await verifyEmail(email);
            //console.log('response: ', response);
            addAuthOptionsToContext(setUserData, userData, response);
            setSpinnerOnNext(false);
            if (response?.email === true) {
                return response;
            } else if (response?.email === false) {
                Notiflix.Notify.failure('User does not exist');
            } else if (response !== undefined) {
                Notiflix.Notify.failure('', response.toString());
            }
        } catch (error) {
            setSpinnerOnNext(false);
            //console.log('Error getting authOptionsF', error);
            //Notiflix.Notify.failure('Error:' + error);
            throw new Error(`Fetch error`);
        }
    };
    const authOptions = async () => {
        setSpinnerOnNext(true);
        if (authOptions_?.password && userPassword.length >= 1) {
            try {
                const body = {
                    email: userName,
                    password: userPassword,
                };
                const login_res = await verifyEmailAndPassword(body);
                setSpinnerOnNext(false);
                if (login_res.access) {
                    setShowNext(false);
                    setInputPassword(false);
                } else {
                    Notiflix.Notify.failure('Invalid password');
                    setShowNext(true);
                }
            } catch (error) {
                // Handle errors
                const passwordValidation = await validatePassword(userPassword);
                if (!passwordValidation.isValid) {
                    setPasswordError(passwordValidation.errorMessages);
                    //console.log(passwordError);
                    Notiflix.Notify.failure('Password validation failed.');
                    return;
                }
                console.error(error);
            }
        } else {
            try {
                const authOptionsData = await authOptionsF({ email: userName });
                setInputPassword(authOptionsData?.password ? true : false);
                if (authOptionsData.password) {
                    setAuthOptions_({ password: true });
                } else {
                    setAuthOptions_({ password: false });
                    setShowNext(false);
                }
            } catch (error) {
                console.error('Error:');
                // Handle any errors from the API call
            }
        }
    };

    const sendForgotPWEmail = async () => {
        const body = { email: userName };
        try {
            const res = await emailResetPassword(body);
            if (res.detail === 'email sent succesfully') {
                Notiflix.Notify.success('Email sent succesfully');
                navigate(`/forgot-password?email=${userName}&code=`);
            }
        } catch (error) {
            // Handle errors
            console.error(error);
        }
    };

    const linkEmail = async () => {
        setLoadingLink(true);
        const body = { email: userName };
        try {
            const res = await linkSendEmail(body);
            if (res.detail === 'email sent succesfully') {
                setShowSuccess(true);
                setShowOTP(false);
            }
        } catch (error) {
            // Handle errors
            console.error(error);
        } finally {
            setLoadingLink(false);
        }
    };

    const otpEmail = async () => {
        setOTPLoading(true);
        //console.log('hey');
        try {
            const body = { email: userName };
            const res = await OTPemail(body);
            if (res.detail === 'email sent succesfully') {
                setShowOTP(true);
                setShowSuccess(false);
                updateIsLocalToContext(setUserData, userData, true);
            }
        } catch (error) {
            // Handle errors
            console.error(error);
        }
        setOTPLoading(false);
    };

    const conditionalFlex = classNames(
        styles['boxButtons'], // Always include this class
        styles[!showNext ? 'spaceBetween' : 'end']
    );

    const handleOTPEntered = (res: any) => {
        setOTP(res);
    };

    useEffect(() => {
        if (OTP.length === 6) {
            const fetchToken = async () => {
                try {
                    const body = {
                        email: userName,
                        password: OTP,
                    };
                    // console.log('enter then error', body.password);
                    const response = await getToken(body);
                    if (response.access) {
                        executeLoginProcess(response.access, setUserData, userData, navigate);
                    } else if (response.detail) {
                        Notiflix.Notify.failure(response.detail);
                    }
                } catch (error) {
                    console.log(error);
                    setWrongEntry(true);
                    Notiflix.Notify.failure('Invalid OTP');
                }
            };

            fetchToken();
            setUpCounter((x) => x + 1);
            setCounterDown((x) => x - 1);
        }
    }, [OTP]);

    //For Maintance
    const URL = window.location.href;

    useEffect(() => {
        fetch('https://4k5vc4z6nxi7ztcbyre4bygqt40nuinx.lambda-url.us-east-1.on.aws/')
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                const URL = window.location.href;
                let env = getEnvironment(URL);
                env !== null && setMaintenance(data[env].mantainance_RM);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [maintenance]);

    const handleSubmit = (e: any) => {
        e.preventDefault(); // Prevent the default form submission behavior
        // Your logic for handling the form submission
    };

    return (
        <LoginComponent>
            {maintenance == 0 && (
                <div>
                    <h4 className={classNames(styles['welcomeBack'])}>Welcome back.</h4>
                    <p className={classNames(styles['welcomeBackSubscript'])}>
                        please sign in to your account
                    </p>
                    <form className={classNames(styles['pt5'])} onSubmit={handleSubmit}>
                        <div className={classNames(styles['formGroup'])}>
                            <div className={classNames(styles['formInputGroup'])}>
                                <label className={classNames(styles['emailLabel'])}>Email</label>
                                <input
                                    type="email"
                                    id="loginUserName"
                                    name="userName"
                                    value={userName}
                                    onChange={(e) => {
                                        setUserName(e.target.value);
                                    }}
                                    disabled={authentication}
                                    required
                                    className={classNames(styles['formControl'])}
                                />
                            </div>
                        </div>
                        {inputPassword && (
                            <div className={classNames(styles['formGroup'])}>
                                <div className={classNames(styles['formInputGroup'])}>
                                    <label className={classNames(styles['emailLabel'])}>
                                        Password
                                    </label>
                                    <input
                                        type="password"
                                        id="passwordUser"
                                        name="userPassword"
                                        value={userPassword}
                                        onChange={(e) => {
                                            setUserPassword(e.target.value);
                                            setPassword(e.target.value);
                                        }}
                                        disabled={authentication}
                                        required
                                        className={classNames(styles['formControl'])}
                                    />
                                </div>
                            </div>
                        )}
                        {passwordError.length > 0 && (
                            <div>
                                Please, for continuing access, click on the Forgot your Password?
                                link below.
                                {passwordError.map((x: any) => (
                                    <div
                                        style={{
                                            color: 'red',
                                            textAlign: 'left',
                                            display: 'flex',
                                            gap: '5px',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {' '}
                                        <ExclamationTriangleIcon className="w-4 h-4" /> {x}
                                    </div>
                                ))}
                            </div>
                        )}
                        {inputPassword && (
                            <div className={classNames(styles['forgotText'])}>
                                <span
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        sendForgotPWEmail();
                                        //navigate('/forgot-password');
                                    }}
                                >
                                    Forgot your Password?
                                </span>
                                {sendResetPassword && <p>Loading...</p>}
                            </div>
                        )}
                        <div className={conditionalFlex}>
                            {showNext && (
                                <button
                                    onClick={authOptions}
                                    className={classNames(styles['nextButton'])}
                                    type="submit"
                                >
                                    Next
                                    {spinnerOnNext && <Spinner size="sm" />}
                                </button>
                            )}
                            {!showNext && (
                                <button
                                    style={
                                        !setCodeFreeze()
                                            ? { backgroundColor: '#C4C4C4' }
                                            : { backgroundColor: '#2E323F' }
                                    }
                                    className={classNames(styles['loginWithOtp'])}
                                    disabled={!setCodeFreeze()}
                                    onClick={otpEmail}
                                    type="button"
                                >
                                    <span>{!authentication ? 'Login with OTP' : ''}</span>
                                    {otpLoading && <Spinner size="sm" />}
                                </button>
                            )}
                            {!showNext && (
                                <button
                                    style={
                                        !setCodeFreeze()
                                            ? { backgroundColor: '#C4C4C4' }
                                            : { backgroundColor: '#2E323F' }
                                    }
                                    className={classNames(styles['loginWithLink'])}
                                    disabled={!setCodeFreeze()}
                                    onClick={linkEmail}
                                    type="button"
                                >
                                    <span>{!authentication ? 'Login with Link' : ''}</span>
                                    {loadingLink && <Spinner size="sm" />}
                                </button>
                            )}
                        </div>
                        {showOTP && (
                            <div className={classNames(styles['insertOTPWrapper'])}>
                                <p className={classNames(styles['insertOTP'])}>
                                    {setCodeFreeze() ? 'Insert OTP code' : ''}
                                </p>
                                <p className={classNames(styles['OTPText'])}>
                                    {setCodeFreeze()
                                        ? 'You should have received an OTP code to the email you entered'
                                        : ''}
                                </p>
                                {setCodeFreeze() && ( // Remove redundant curly braces
                                    <AuthCode
                                        onChange={handleOTPEntered}
                                        length={6}
                                        containerClassName={classNames(styles['OTP'])}
                                        inputClassName={classNames(styles['inputBoxes'])}
                                    />
                                )}
                            </div>
                        )}
                        {showSuccess && (
                            <div className={classNames(styles['succesfulEmailSent'])}>
                                <strong>Success: </strong>The link has been sent to your email.
                            </div>
                        )}
                    </form>
                    {!setCodeFreeze() && (
                        <div className={classNames(styles['errorMessage'])}>
                            Your account {userName} has been suspended for 10 minutes for repeated
                            failed login attempts. Please try again later.
                        </div>
                    )}
                    {wrongEntry && (
                        <div className={classNames(styles['errorMessage'])}>
                            Incorrect OTP. <strong>Please try again </strong> ({counterDown}{' '}
                            opportunities left)
                        </div>
                    )}
                    <div className={classNames(styles['bottomWrapper'])}>
                        <div className={classNames(styles['bottomWrapperInner'])}>
                            <div>
                                <p>
                                    <small className={classNames(styles['bottomText'])}>
                                        RMv2 2024 | By. Cheil Central America.
                                    </small>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {maintenance == 1 && (
                <div>
                    <h4 className={classNames(styles['welcomeBack'])}>
                        Website currently under maintenance
                    </h4>
                    <br />
                    <p className={classNames(styles['welcomeBackSubscript'])}>
                        We are very sorry for the inconvenience but our website is currently
                        undergoing scheduled maintenance.
                    </p>
                    <p className={classNames(styles['welcomeBackSubscript'])}>
                        Please, check back shortly.
                    </p>
                </div>
            )}
        </LoginComponent>
    );
};
