import Notiflix from 'notiflix';
import styles from './add-sub-view.module.scss';
import classNames from 'classnames';
import { cleanString, isValidId, isValidString } from '../../utils/functions';
import { useState } from 'react';
import { createSubView } from '../../services/python-services';

export interface AddSubViewProps {
    className?: string;
    type: string;
    CloseModalAndUpdate: any;
    OnlyClose: any;
    reportsData?: any;
    setNewSubView?: any;
    countriesData?: any;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const AddSubView = ({ className, type,
    CloseModalAndUpdate,
    OnlyClose,
    reportsData, countriesData }: AddSubViewProps) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [countryId, setCountryId] = useState<any>(-1);
    const [order, setOrder] = useState<any>(1);
    //const [validForm, SetValidForm] = useState(false);
    // const [somethingChange, setSomethingChange] = useState(false);
    const parentId = reportsData!.find((r: any) => r.name === type)?.id;
    // const [mockUpData, setMockUpData] = useState({});
    const [isValidNameSpan, setIsValidNameSpan] = useState(true);
    const [isValidDescriptionSpan, setIsValidDescriptionSpan] = useState(true);
    const [validCountry, setValidCountry] = useState(true);
    const [submitSwitch, setSubmitSwitch] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const clearFields = () => {
        setName('');
        setDescription('');
        setCountryId(-1);
        setOrder(1);
        // setIsValidColorSpan(true);
        setIsValidNameSpan(true);
        setIsValidDescriptionSpan(true);
        setValidCountry(true);
        // setMockUpData({});
        OnlyClose();
    };

    const validateDescription = (value: any) => {
        let validF = true;
        if (!isValidString(value)) {
            setIsValidDescriptionSpan(false);
            validF = validF && false;
        } else {
            validF = validF && true;
            setIsValidDescriptionSpan(true);
        }
        return validF;
    };
    const validateName = (value: any) => {
        let validF = true;
        if (!isValidString(value)) {
            setIsValidNameSpan(false);
            validF = validF && false;
        } else {
            validF = validF && true;
            setIsValidNameSpan(true);
        }
        return validF;
    };
    const validateCountry = (value: any) => {
        let isValidCountry = isValidId(value);
        setValidCountry(isValidCountry);
        return isValidCountry;
    };
    // const validateColor = (value: any) => {
    //     let validF = true;
    //     if (!isValidString(value)) {
    //         setIsValidColorSpan(false);
    //         validF = validF && false;
    //     } else {
    //         validF = validF && true;
    //         setIsValidColorSpan(true);
    //     }
    //     return validF;
    // };
    const isValidForm = async () => {
        let valid = true;
        const validations = [validateDescription(description), validateName(name)
            , validateCountry(countryId)
        ];
        for (const validation of validations) {
            if (!validation) {
                valid = false;
                // Don't break here to accumulate all validation errors
            }
        }
        return valid;
    };
    const handleCountryInput = (e: any) => {
        setCountryId(e);
        // console.log("Country", e)
        //setSomethingChange(true);
    }
    // useEffect(() => {
    //     // Handle initialization or update when idItem changes
    //     setMockUpData({
    //         name: '',
    //         division_name: '',
    //         report: name,
    //         background_color: color,
    //         backgroundD: '#66D0AF',
    //     });
    //     if (somethingChange) {
    //         setSomethingChange(false);
    //     }
    // }, [somethingChange]);
    const saveSubView = async () => {
        const valid = await isValidForm();
        if (valid) {
            try {
                const newName = cleanString(name);
                const payload = {
                    name: newName,
                    description: description,
                    order_field: order,
                    country_id: countryId,
                    view_parent_id: parentId,
                    type: type
                };
                const response = await createSubView(payload);
                if (response.outcome) {
                    CloseModalAndUpdate('Sub View created successfully!', response.outcome);
                    setSubmitSwitch(!submitSwitch);
                    clearFields();
                } else {
                    Notiflix.Notify.failure(response.message);
                }
                setOpenModal(false);
            } catch (err) {
                Notiflix.Notify.failure("Cover can't be created!");
            }
        }
    };
    return <div className={classNames(styles.root, className)}>

        <div
            className={
                classNames(styles.root, className + ' ' + styles[className!]) +
                ' ' +
                styles.containerData
            }
        >
            <h1 className={styles.header}>Add Sub View to {type?.toUpperCase()} Reports</h1>
            <div>
                <div className="form-flex">

                    <div id="" className="form-box">
                        <label>Code</label>
                        <input
                            type="text"
                            name="sub_view_name"
                            className="test"
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value);
                                // setSomethingChange(true);
                            }}
                        />
                    </div>
                    {!isValidNameSpan && (
                        <span className={styles.requiredEntry}>A sub view Code is required</span>
                    )}
                    <div id="" className="form-box">
                        <label>Description</label>
                        <input
                            type="text"
                            name="sub_view_description"
                            className="test"
                            value={description}
                            onChange={(e) => {
                                setDescription(e.target.value);
                                // setSomethingChange(true);
                            }}
                        />
                    </div>
                    {!isValidDescriptionSpan && (
                        <span className={styles.requiredEntry}>A Description is required</span>
                    )}
                    <div id="" className="form-box">
                        <label>Order</label>
                        <input
                            type="number"
                            name="order"
                            className="test"
                            value={order}
                            onChange={(e) => {
                                setOrder(e.target.value);
                                // setSomethingChange(true);
                            }}
                        />
                    </div>
                    <div id="" className="form-box">
                        <label>Country</label>
                        {countriesData &&
                            <select
                                id="country"
                                name="division_name"
                                placeholder='Select a Country'
                                className="test"
                                value={countryId}
                                onChange={(e) => { handleCountryInput(parseInt(e.target.value)); }}
                            >
                                <option key={-1} value={-1}>
                                    {""}
                                </option>
                                {countriesData?.map((name: any) => (
                                    <option key={name.id} value={name.id}>
                                        {name.name}
                                    </option>
                                ))}
                            </select>
                        }
                    </div>
                    {!validCountry &&
                        <small className='text-red-500'>The Sub View field is required.</small>
                    }
                    {/* <div id="" className="form-box">
                        <label>Color</label>
                        <input
                            type="color"
                            title="color"
                            name="url"
                            className="testColor"
                            value={color}
                            onChange={(e) => {
                                setColor(e.target.value);
                                setSomethingChange(true);
                            }}
                            style={{ background: color }}
                        ></input>
                    </div> */}

                    {/* <div className={styles.coverMockup}>
                        <MockUp data={mockUpData}></MockUp>
                    </div> */}

                    <div className="bottom-flex-pop">
                        <button
                            className="btn"
                            onClick={() => {
                                clearFields();
                            }}
                        >
                            CANCEL
                        </button>
                        <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={async () => await saveSubView()}
                        >
                            UPLOAD
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};
