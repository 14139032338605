import styles from './download-link.module.scss';
import classNames from 'classnames';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';

export interface DownloadLinkProps {
    className?: string;
    label: string;
    link: string;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const DownloadLink = ({
    className,
    link = '#',
    label = 'download here',
}: DownloadLinkProps) => {
    return (
        <div className={classNames(styles.root, className)}>
            <a href={link} className={styles.link}>
                 <ArrowDownTrayIcon  className={styles['link-icon']} />
                {label}
                
               
            </a>
        </div>
    );
};
