import React, { useContext, useState, useEffect } from 'react';
import { RMContext } from '../../context/RMContext';
import { addFavorite, deleteFavorite, getUserData } from '../../services/python-services';
import { addSiteMapToContext, getTreeData } from '../../utils/functions';

const Collapsible = (props: any) => {
    const { setUserData } = useContext(RMContext);
    const [isFavorite, setIsFavorite] = useState(!!props.node.favorite);
    const [open, setOpen] = useState(props.initialOpen || false);
    const [waitFetch, setWaitFetch] = useState(false);

    const onClickFav = async () => {
        try {
            if (!waitFetch) {
                setWaitFetch(true);
                if (!isFavorite && waitFetch == false) {
                    await addFavorite({ view: props.node.id });
                } else {
                    await deleteFavorite(props.node.id);
                }

                const updatedUserData = await getUserData();
                await getTreeData(setUserData, updatedUserData);

                setIsFavorite(!isFavorite);
                setTimeout(function () {
                    setWaitFetch(false);
                });
            }
        } catch (error) {
            console.error('Error updating favorite:', error);
        }
    };

    const toggle = () => {
        setOpen(!open);
        if (props.onToggle) {
            props.onToggle(!open);
        }
    };

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                <div>
                    <svg
                        width="31"
                        height="30"
                        viewBox="0 0 16 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={onClickFav}
                        style={{ cursor: 'pointer' }}
                    >
                        <path
                            d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                            fill={isFavorite ? '#BEFFEC' : '#D9D9D9'}
                        />
                    </svg>
                </div>
                <div>
                    <p>{props.label}</p>
                </div>
                {props.arrow && (
                    <div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                            onClick={toggle}
                            cursor={'pointer'}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m19.5 8.25-7.5 7.5-7.5-7.5"
                            />
                        </svg>
                    </div>
                )}
            </div>
            {open && <div>{props.children}</div>}
        </div>
    );
};

export default Collapsible;
