import { Link, useNavigate } from 'react-router-dom';
import styles from './home.module.scss';
import { useEffect, useContext, useState } from 'react';
import {
    LogOut,
    executeLoginProcess,
    returnToLogin,
    viewInArrayViews,
} from '../../utils/functions';
import { RMContext } from '../../context/RMContext';
import { ChangePassword } from '../change-password/change-password';
import { SecurityPolicies } from '../security-policies/security-policies';
import TreeView from '../tree-view/tree-view';
import HeaderMenu from '../header-menu/header-menu';

import { postActionLogs } from '../../services/python-services';
import MenuFilter from '../filtering/main-menu-filter';

export interface HomeProps {}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const Home = ({}: HomeProps) => {
    const navigate = useNavigate();
    //clearLocalStorage();
    const { setUserData, userData } = useContext(RMContext);
    const { sitemap } = useContext(RMContext).userData;
    const token = localStorage.getItem('tokenkey');
    const [loginSuccess, setLogginSuccess] = useState(false);

    const currentView = window.location.href.split('/').slice(-1)[0];

    useEffect(() => {
        if (
            sitemap.length > 0 &&
            sitemap &&
            currentView &&
            (currentView === '' || currentView === '/home')
        )
            postActionLogs(2);
    }, [sitemap, currentView]);
    const handleOpenModal = (id: string) => {
        if (id !== '') {
            const dialog = document.getElementById(id);
            //@ts-expect-error
            if (dialog) dialog.showModal();
        }
    };

    const showModal = (id: string) => {
        if (id !== '') handleOpenModal(id);
    };

    useEffect(() => {
        if (token !== undefined && token !== '' && token !== null && userData?.authOptions) {
            setLogginSuccess(true);
        } else {
            returnToLogin(navigate, setUserData, false, false, false);
        }
    }, [token]);

    return (
        <div className={styles.root} style={{ display: 'flex' }}>
            {/* <div>
                {!loginSuccess &&
                    <Link to={'/login'} className={'btn btn-secondary'}
                    >Login</Link>
                }
                {loginSuccess &&
                    <a className='btn btn-primary m-3' onClick={() => {LogOut() }} href='/login'
                    >Logout </a>
                }
                
            </div> */}
            <div className={styles.siteMap} style={{ paddingTop: '100px', paddingBottom: '45px' }}>
                <div className={styles.tittlesitemap}>
                    <h1>Site Map</h1>
                </div>
                <br />
                {sitemap && sitemap?.length > 0 && (
                    <TreeView
                        setUserData={setUserData}
                        data={sitemap}
                        className="tree-container"
                        userData={userData}
                    />
                )}
            </div>
        </div>
    );
};
