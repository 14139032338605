

export interface TreeViewHeaderProps {
    className?: string;
    children: any;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const TreeViewHeader = ({  children }: TreeViewHeaderProps) => {
    return <div className={' relative w-full flex justify-between bg-[#262a34] p-1 text-sm font-semibold text-white '} >{children}</div>;
};
