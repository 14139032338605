import { useEffect, useState } from 'react';
import { Column, Filter } from '../../utils/interfaces';
import styles from './filter-table.module.scss';
import classNames from 'classnames';
import { getOrmUniquesValues, getUniques } from '../../services/python-services';
import { Slicer } from '../slicer/slicer';
import { arrayIncludes, getOpenedFilters, removeNullOptions } from '../../utils/functions';
import { queries } from '@testing-library/react';
import { Button } from '../button/button';
import {
    ArrowPathIcon,
    BriefcaseIcon,
    BuildingOfficeIcon,
    FunnelIcon,
    GlobeAltIcon,
    GlobeAmericasIcon,
    UserGroupIcon,
    UserIcon,
} from '@heroicons/react/24/outline';
import { MultiSelectDropdown } from '../multi-select-dropdown/multi-select-dropdown';

export interface FilterTableProps {
    className?: string;
    // close: (v: boolean) => void;
    filters: string[];
    uniques?: any;
    onReset: Function;
    // file: string;
    applyFilters: Function;
    columns: any[];
    setUniques: Function;
    setCustomFilters: Function;
    customFilters: [];
    // style?: object;
    by?: 'athena' | 'parquet';
    setExecuteApply?: Function;
    setApplySearch?: Function;
    isColapsed?: boolean;
}

export const FilterTable = ({
    // close,
    applyFilters,
    columns,
    setUniques,
    filters,
    uniques,
    onReset,
    // file,
    customFilters,
    setCustomFilters,
    // style,
    by = 'parquet',
    setExecuteApply = () => {},
    setApplySearch = () => {},
    isColapsed = false,
}: FilterTableProps) => {
    const [components, setComponents] = useState<any>(customFilters);
    const [applyFiltersAction, setApplyFilterAction] = useState(false);
    const [isReset, setIsReset] = useState(false);
    // console.log("uniques: ", uniques, "columns: ", columns, "isColapsed: ", isColapsed)
    const [properties, setProperties] = useState<Filter>({
        point_of_sale_country: null,
        point_of_sale_region: null,
        point_of_sale_zone: null,
        point_of_sale_account: null,
        sitefromname: null,
        divisionname: null,
        soldtoname: null,
        finalctry: uniques?.finalctry !== undefined ? uniques?.finalctry : null,
        kiosco: null,
        'delayeddays:not': null,
        delayeddays: null,
        photo: null,
        site_id: null,
        // is_rmf: file === 'pos' ? '1' : null,
        // universe: file === 'pos' ? '1' : null,
        // status: file === 'pos' ? '1' : null,
        // 'country__name:not': file === 'pos' ? 'PARAGUAY' : null,
        arrived: null,
        country__name: uniques?.country__name !== undefined ? uniques?.country__name : null,
        company__value: uniques?.company__value !== undefined ? uniques?.company__value : null,
        user_status: uniques?.user_status !== undefined ? uniques?.user_status : null,
        roles__role_name:
            uniques?.roles__role_name !== undefined ? uniques?.roles__role_name : null,
        job_position__value:
            uniques?.job_position__value !== undefined ? uniques?.job_position__value : null,
        department__value:
            uniques?.department__value !== undefined ? uniques?.department__value : null,
    });
    const [customProperties, setCustomProperties] = useState<any>({});
    const [finalQuery, setFinalQuery] = useState<any>({});
    const onApply = () => {
        setExecuteApply(true);
        setApplySearch(true);
        applyFilters(finalQuery, '', true);
    };
    // Slicers
    const [countries, setCountries] = useState([]);
    const [userCountries, setUserCountries] = useState(
        uniques?.country__name !== undefined ? [uniques?.country__name] : []
    );
    const [userRoles, setUserRoles] = useState(
        uniques?.roles__role_name !== undefined ? [uniques?.roles__role_name] : []
    );
    const [userJobPosition, setUserJobPosition] = useState(
        uniques?.job_position__value !== undefined ? [uniques?.job_position__value] : []
    );
    const [userDepartment, setUserDepartment] = useState(
        uniques?.department__value !== undefined ? [uniques?.department__value] : []
    );
    const [companies, setCompanies] = useState(
        uniques?.company__value !== undefined ? [uniques?.company__value] : []
    );
    const [userStatus, setUserStatus] = useState(
        uniques?.user_status !== undefined ? [uniques?.user_status] : []
    );
    const [regions, setRegions] = useState([]);
    const [zones, setZones] = useState([]);
    const [stores, setStores] = useState([]);
    const [productGroup, setProductGroup] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [productDivisions, setProductDivisions] = useState([]);
    const [segments, setSegments] = useState([]);
    const [sitefromname, setSitefromname] = useState([]);
    const [productGroupGLP, setProductGroupGLP] = useState([]);
    const [customerGLP, setCustomerGLP] = useState([]);
    const [countryGLP, setCountryGLP] = useState([]);
    const [year, setYear] = useState([]);
    const [week, setWeek] = useState([]);

    const [loadingData, setLoadingData] = useState(false);

    //group button delayed days
    const [clickedGroupId, setClickedGroupId] = useState(properties['delayed'] || -1);

    const [clickedGroupIdArrived, setClickedGroupIdArrived] = useState(properties['arrived'] || -1);

    //state to management open and closed options
    const initialState: any = getOpenedFilters(uniques) || [];

    const [filtersOpened, setFiltersOpened] = useState(isColapsed ? initialState : filters);
    // console.log("filtersOpened  Main", filtersOpened, (isColapsed ? initialState : filters));
    const handleReset = () => {
        setIsReset(true);
        setFiltersOpened([]);
        setProperties({
            point_of_sale_country: null,
            point_of_sale_region: null,
            point_of_sale_zone: null,
            point_of_sale_account: null,
            sitefromname: null,
            divisionname: null,
            soldtoname: null,
            finalctry: null,
            'delayeddays:not': null,
            delayeddays: null,
            kiosco: null,
            photo: null,
            site_id: null,
            delayed: null,
            country__name: null,
            company__value: null,
            user_status: null,
            roles__role_name: null,
            job_position__value: null,
            department__value: null,
            // is_rmf: file === 'pos'? '1' : null,
            // universe: file === 'pos'? '1' : null,
            // status: file === 'pos'? '1' : null,
        });
        onReset();
        setCountries([]);
        setUserJobPosition([]);
        setUserCountries([]);
        setCompanies([]);
        setUserStatus([]);
        setUserRoles([]);
        setUserCountries([]);
        setRegions([]);
        setZones([]);
        setStores([]);
        setProductGroup([]);
        setDivisions([]);
        setProductDivisions([]);
        setSegments([]);
        setSitefromname([]);
        setProductGroupGLP([]);
        setCustomerGLP([]);
        setCountryGLP([]);
        setComponents([]);
        applyFilters([]);
        setClickedGroupId(-1);
        setExecuteApply(true);
        setApplySearch(true);
        setClickedGroupIdArrived(-1);
    };

    const getArrayLikeQueries = (array: any) => {
        // console.log("getArrayLikeQueries", array);
        const join_map = array.map((a: any) => a.value).join(',');
        return join_map === '' ? null : join_map;
    };

    const getArrayLikeMultipleQueries = (array: any) => {
        // console.log("getArrayLikeMultipleQueries", array);
        const join_map = array.map((a: any) => a).join(',');
        // console.log('join_map', join_map);
        return join_map === '' ? null : join_map;
    };

    const OptionsAdapter = (label: string, value: string, optionsData: any) => {
        const results = optionsData?.map((o: any) => {
            return {
                label:
                    o[label] !== null
                        ? typeof o[label] === 'number'
                            ? o[label]
                            : o[label].replaceAll('_', ' ')
                        : '',
                value: o[value],
                check: true,
                active: true,
            };
        });
        return results;
    };

    const OptionsAdapterList = (optionsData: any) => {
        const results = optionsData?.map((o: any) => {
            return {
                label: o[1],
                value: o[0],
                check: true,
                active: true,
            };
        });
        return results;
    };

    const joinOptions = (options: any, options2: any) => {
        if (options.length === 0) {
            return options2;
        } else {
            const updatedOptions = options?.map((option: any) => {
                if (!options2.some((o: any) => o.label === option.label)) {
                    return { ...option, active: false };
                } else {
                    return { ...option, active: true };
                }
            });

            return updatedOptions.sort((a: any, b: any) => {
                if (a.active && !b.active) {
                    return -1; // a viene antes que b
                } else if (!a.active && b.active) {
                    return 1; // a viene después de b
                } else {
                    return 0; // No se cambia el orden relativo entre a y b
                }
            });
        }
    };

    useEffect(() => {
        let filters = {};
        components
            ?.filter((c: any) => c[1] !== '' && c[3] !== '')
            .forEach((c: any) => {
                const propertie: string = `${c[1]}${c[2]}`;
                if (propertie in filters) {
                    //@ts-ignore
                    filters = { ...filters, [propertie]: `${filters[propertie]},${c[3]}` };
                } else {
                    filters = { ...filters, [`${c[1]}${c[2]}`]: c[3] };
                }
            });
        setCustomProperties(filters);
        setCustomFilters(components);
    }, [components]);

    useEffect(() => {
        setUniques({ ...customProperties, ...properties });
        setFinalQuery({ ...customProperties, ...properties });
    }, [properties]);

    useEffect(() => {
        setFinalQuery({ ...properties, ...customProperties });
        setUniques({ ...properties, ...customProperties });
    }, [customProperties]);

    useEffect(() => {
        //remove sort_by property in the uniques array
        const newUniques: any = uniques!;
        delete newUniques.sort_by;
        setUniques(newUniques);
        // console.log(`filtersOpened in UseEffect: ${filtersOpened} ,,, ${filtersOpened.length}  loadingData ${loadingData}, applyFilterAction ${applyFiltersAction}`)
        if (!loadingData && filtersOpened.length > 0) {
            getFiltersData();
            if (applyFiltersAction) {
                applyFilters({ ...properties }, true);
                setApplyFilterAction(false);
            }
        }
    }, [uniques, filtersOpened]);

    const getFiltersData = async () => {
        setLoadingData(true);
        filters?.forEach(async (f: string) => {
            if (filtersOpened.length > 0) {
                if (f === 'country' && arrayIncludes(filtersOpened, 'country')) {
                    await getCountryOptions();
                }
                // console.log("f", f,filtersOpened)
                if (
                    (f === 'user_country' || f === 'country__name') &&
                    (arrayIncludes(filtersOpened, 'user_country') ||
                        arrayIncludes(filtersOpened, 'country__name'))
                ) {
                    await getUserCountryOptions();
                }
                if (
                    f === 'roles__role_name' &&
                    (arrayIncludes(filtersOpened, 'roles__role_name') ||
                        arrayIncludes(filtersOpened, 'roles__role_name'))
                ) {
                    await getUserRoleOptions();
                }
                if (
                    f === 'department__value' &&
                    (arrayIncludes(filtersOpened, 'department__value') ||
                        arrayIncludes(filtersOpened, 'department__value'))
                ) {
                    await getUserDeparmentOptions();
                }
                if (
                    f === 'job_position__value' &&
                    (arrayIncludes(filtersOpened, 'job_position__value') ||
                        arrayIncludes(filtersOpened, 'job_position__value'))
                ) {
                    await getUserJobPositionOptions();
                }
                if (
                    (f === 'company' || f === 'company__value') &&
                    (arrayIncludes(filtersOpened, 'company') ||
                        arrayIncludes(filtersOpened, 'company__value'))
                ) {
                    await getCompanyOptions();
                }
                if (f === 'user_status' && arrayIncludes(filtersOpened, 'user_status')) {
                    await getUserStatusOptions();
                }
                if (f === 'region' && arrayIncludes(filtersOpened, 'region')) {
                    await getRegionOptions();
                }
                if (f === 'countryZone' && arrayIncludes(filtersOpened, 'countryZone')) {
                    await getZoneOptions();
                }
                if (f === 'store' && arrayIncludes(filtersOpened, 'store')) {
                    await getStoresOptions();
                }
                if (f === 'division' && arrayIncludes(filtersOpened, 'division')) {
                    await getDivisionOptions();
                }
                if (f === 'product-division' && arrayIncludes(filtersOpened, 'product-division')) {
                    await getProductDivisionOptions();
                }
                if (f === 'product-group' && arrayIncludes(filtersOpened, 'product-group')) {
                    await getProductGroupOptions();
                }
                if (f === 'segments' && arrayIncludes(filtersOpened, 'segments')) {
                    await getSegmentsOptions();
                }
                if (f === 'factory' && arrayIncludes(filtersOpened, 'factory')) {
                    await getFactory();
                }
                if (
                    f === 'product-group-glp' &&
                    arrayIncludes(filtersOpened, 'product-group-glp')
                ) {
                    await getProductGroupGLP();
                }
                if (f === 'customer-glp' && arrayIncludes(filtersOpened, 'customer-glp')) {
                    await getCustomerGLP();
                }
                if (f === 'country-glp' && arrayIncludes(filtersOpened, 'country-glp')) {
                    await getCountryGLP();
                }

                if (f === 'year' && arrayIncludes(filtersOpened, 'year')) {
                    await getYear();
                }
                if (f === 'week' && arrayIncludes(filtersOpened, 'week')) {
                    await getWeek();
                }
            }
        });
        setLoadingData(false);
    };
    // SERVICES

    const getCountryOptions = async () => {
        let countries_: any = await getUniques(
            // file ??
            '',
            'country__name$point_of_sale_country',
            uniques,
            by
        );
        countries_ = OptionsAdapter('country__name', 'point_of_sale_country', countries_);
        const updatedCountries: any = joinOptions(countries, countries_);
        setCountries(removeNullOptions(updatedCountries));
    };

    const getUserCountryOptions = async () => {
        let countries_: any = await getOrmUniquesValues(
            'AllUserView',
            'country__name'
            // properties
        );
        countries_ = OptionsAdapter('country__name', 'country__name', countries_);
        const updatedCountries: any = joinOptions(countries, countries_);
        setUserCountries(removeNullOptions(updatedCountries));
    };
    const getUserRoleOptions = async () => {
        let roles_: any = await getOrmUniquesValues(
            'AllUserView',
            'roles__role_name'
            // properties
        );
        roles_ = OptionsAdapter('roles__role_name', 'roles__role_name', roles_);
        const updatedRoles: any = joinOptions(countries, roles_);
        setUserRoles(removeNullOptions(updatedRoles));
    };
    const getUserJobPositionOptions = async () => {
        let job_positions_: any = await getOrmUniquesValues(
            'AllUserView',
            'job_position__value'
            // properties
        );
        job_positions_ = OptionsAdapter(
            'job_position__value',
            'job_position__value',
            job_positions_
        );
        const updatedJobPositions: any = joinOptions(countries, job_positions_);
        setUserJobPosition(removeNullOptions(updatedJobPositions));
    };
    const getUserDeparmentOptions = async () => {
        let countries_: any = await getOrmUniquesValues(
            'AllUserView',
            'department__value'
            // properties
        );
        countries_ = OptionsAdapter('department__value', 'department__value', countries_);
        const updatedCountries: any = joinOptions(countries, countries_);
        setUserDepartment(removeNullOptions(updatedCountries));
    };
    const getCompanyOptions = async () => {
        let companies_: any = await getOrmUniquesValues(
            'AllUserView',
            'company__value'
            // properties
        );

        companies_ = OptionsAdapter('company__value', 'company__value', companies_);
        const updatedCompanies: any = joinOptions(countries, companies_);
        setCompanies(removeNullOptions(updatedCompanies));
    };

    const getUserStatusOptions = async () => {
        let status_: any = await getOrmUniquesValues(
            'AllUserView',
            'user_status'
            // properties
        );
        status_ = OptionsAdapter('user_status', 'user_status', status_);
        const updatedStatus: any = joinOptions(countries, status_);
        setUserStatus(removeNullOptions(updatedStatus));
    };
    const getRegionOptions = async () => {
        let region_: any = await getUniques(
            // file ??
            '',

            'region__name$point_of_sale_region',
            uniques,
            by
        );
        region_ = OptionsAdapter('region__name', 'point_of_sale_region', region_);

        const updatedRegions: any = joinOptions(regions, region_);

        setRegions(updatedRegions);
    };

    const getZoneOptions = async () => {
        let zone_: any = await getUniques(
            // file ??
            '',
            'zone__name$point_of_sale_zone',
            uniques,
            by
        );
        zone_ = OptionsAdapter('zone__name', 'point_of_sale_zone', zone_);

        const updatedZones: any = joinOptions(zones, zone_);
        setZones(updatedZones);
    };
    const getStoresOptions = async () => {
        let store_: any = await getUniques(
            // file ??
            '',

            'custom_account__name$point_of_sale_account',
            uniques,
            by
        );
        store_ = OptionsAdapter('custom_account__name', 'point_of_sale_account', store_);

        const updatedStores: any = joinOptions(stores, store_);

        setStores(updatedStores);
    };

    const getDivisionOptions = async () => {
        let division_: any = await getUniques(
            // file ??
            '',
            'division__value$division',
            uniques,
            by
        );
        division_ = OptionsAdapter('division__value', 'division', division_);

        const updatedDivisions: any = joinOptions(divisions, division_);
        setDivisions(updatedDivisions);
    };

    const getProductDivisionOptions = async () => {
        let product_division_: any = await getUniques(
            // file ??
            '',

            'division__value$product_division',
            uniques,
            by
        );
        product_division_ = OptionsAdapter(
            'division__value',
            'product_division',
            product_division_
        );
        const updatedDivisions: any = joinOptions(productDivisions, product_division_);
        setProductDivisions(updatedDivisions);
    };

    const getProductGroupOptions = async () => {
        let productGroup_: any = await getUniques(
            // file ??
            '',
            'group__value$group',
            uniques,
            by
        );
        productGroup_ = OptionsAdapter('group__value', 'group', productGroup_);

        const updatedProductGroup: any = joinOptions(productGroup, productGroup_);
        setProductGroup(updatedProductGroup);
    };

    const getSegmentsOptions = async () => {
        let segments_: any = await getUniques(
            // file ??
            '',
            'segment__value$segment',
            uniques,
            by
        );
        segments_ = OptionsAdapter('segment__value', 'segment', segments_);
        const updatedSegments: any = joinOptions(segments, segments_);
        setSegments(updatedSegments);
    };

    const getFactory = async () => {
        let _sitefromname: any = await getUniques(
            // file ??
            '',
            'carrier',
            uniques,
            by
        );
        _sitefromname = OptionsAdapter('carrier', 'carrier', _sitefromname);
        const updatedFactory: any = joinOptions(sitefromname, _sitefromname);
        setSitefromname(updatedFactory);
    };
    const getProductGroupGLP = async () => {
        let initValues: any = await getUniques(
            // file ??
            '',
            'divisionname',
            uniques,
            by
        );
        initValues = OptionsAdapter('divisionname', 'divisionname', initValues);
        const updatedValues: any = joinOptions(productGroupGLP, initValues);
        setProductGroupGLP(updatedValues);
    };
    const getCustomerGLP = async () => {
        let initValues: any = await getUniques(
            // file ??
            '',
            'shiptoname',
            uniques,
            by
        );
        initValues = OptionsAdapter('shiptoname', 'shiptoname', initValues);
        const updatedValues: any = joinOptions(customerGLP, initValues);
        setCustomerGLP(updatedValues);
    };

    const getCountryGLP = async () => {
        let initValues: any = await getUniques(
            // file ??
            '',
            'finalctry',
            uniques,
            by
        );
        initValues = OptionsAdapter('finalctry', 'finalctry', initValues);
        const updatedValues: any = joinOptions(countryGLP, initValues);
        setCountryGLP(updatedValues);
    };

    const getYear = async () => {
        let initValues: any = await getUniques(
            // file ??
            '',
            'year',
            uniques,
            by
        );
        initValues = OptionsAdapter('year', 'year', initValues);
        const updatedValues: any = joinOptions(year, initValues);
        setYear(updatedValues);
    };

    const getWeek = async () => {
        let initValues: any = await getUniques(
            // file ??
            '',
            'week',
            uniques,
            by
        );
        initValues = OptionsAdapter('week', 'week', initValues);
        const updatedValues: any = joinOptions(week, initValues);
        setWeek(updatedValues);
    };

    const getButtonApply = () => {
        return (
            <div style={{ display: 'ruby-text' }}>
                {/* <FunnelIcon onClick={onApply} className={'h-4 w-4 '} style={{ cursor: 'pointer' }} /> */}
                <ArrowPathIcon
                    onClick={handleReset}
                    className={'h-4 w-4 '}
                    style={{ cursor: 'pointer' }}
                />
            </div>
        );
    };

    const shouldBeNull = (v: any) => {
        return v.some((a: any) =>
            typeof a !== 'object' ? a.toUpperCase() === 'ALL' : a?.label?.toUpperCase() === 'ALL'
        );
    };
    return (
        <div
            //  style={style}
            className={'top-0 flex w-full ' + styles.filterHeader}
        >
            {columns?.map((col: any, index: number) => {
                return (
                    <div
                        key={'table-f-' + index}
                        style={col.style}
                        // className={styles.rowStyle}
                    >
                        {col?.name === 'checkbox' ? (
                            <></>
                        ) : (
                            <>
                                {(col?.prop === 'country' && filters?.includes('user_country')) ||
                                (col?.prop === 'country' && filters?.includes('country__name')) ? (
                                    <>
                                        <MultiSelectDropdown
                                            icon={
                                                <GlobeAltIcon className="w-4 h-4 text-gray-500" />
                                            }
                                            options={userCountries}
                                            chooseLegend={'Country'}
                                            onChange={(v: any) => {
                                                const setNull = shouldBeNull(v);
                                                const updateQuery: any = {
                                                    ...properties,
                                                    country__name: setNull
                                                        ? null
                                                        : getArrayLikeMultipleQueries(v),
                                                };

                                                setProperties(updateQuery);
                                                setApplyFilterAction(true);
                                            }}
                                            reset={isReset}
                                            filtersOpened={filtersOpened}
                                            setFiltersOpened={setFiltersOpened}
                                            name="user_country"
                                        />
                                        {getButtonApply()}
                                    </>
                                ) : col?.prop === 'company' &&
                                  (filters?.includes('company') ||
                                      filters?.includes('company__value')) ? (
                                    <>
                                        <MultiSelectDropdown
                                            icon={
                                                <BuildingOfficeIcon className="w-4 h-4 text-gray-500" />
                                            }
                                            options={companies}
                                            chooseLegend={'Company'}
                                            onChange={(v: any) => {
                                                const setNull = shouldBeNull(v);
                                                const updateQuery: any = {
                                                    ...properties,
                                                    company__value: setNull
                                                        ? null
                                                        : getArrayLikeMultipleQueries(v),
                                                };
                                                setProperties(updateQuery);
                                                setApplyFilterAction(true);
                                            }}
                                            reset={isReset}
                                            filtersOpened={filtersOpened}
                                            setFiltersOpened={setFiltersOpened}
                                            name="company__value"
                                        />
                                        {getButtonApply()}
                                    </>
                                ) : col?.prop === 'role' &&
                                  filters?.includes('roles__role_name') ? (
                                    <>
                                        {/* <Slicer
                                                    title={'Role name'}
                                                    options={userRoles}
                                                    selected={(v: any) => {
                                                        const updateQuery: any = {
                                                            ...properties,
                                                            roles__role_name: getArrayLikeQueries(v),
                                                        };
                                                        setProperties(updateQuery);
                                                        setApplyFilterAction(true);
                                                    }}
                                                    isOpen={arrayIncludes(filtersOpened, 'roles__role_name')}
                                                    filtersOpened={filtersOpened}
                                                    setFiltersOpened={setFiltersOpened}
                                                    type={'roles__role_name'}
                                                /> */}
                                        <MultiSelectDropdown
                                            icon={
                                                <UserGroupIcon className="w-4 h-4 text-gray-500" />
                                            }
                                            options={userRoles}
                                            chooseLegend={'Role name'}
                                            onChange={(v: any) => {
                                                const setNull = shouldBeNull(v);
                                                const updateQuery: any = {
                                                    ...properties,
                                                    roles__role_name: setNull
                                                        ? null
                                                        : getArrayLikeMultipleQueries(v),
                                                };
                                                setProperties(updateQuery);
                                                setApplyFilterAction(true);
                                                // setApplyFilterAction(true);
                                                // applyFilters(updateQuery,true);
                                            }}
                                            reset={isReset}
                                            filtersOpened={filtersOpened}
                                            setFiltersOpened={setFiltersOpened}
                                            name="roles__role_name"
                                        />
                                        {getButtonApply()}
                                    </>
                                ) : col?.prop === 'position' &&
                                  filters?.includes('job_position__value') ? (
                                    <>
                                        <MultiSelectDropdown
                                            icon={
                                                <BriefcaseIcon className="w-4 h-4 text-gray-500" />
                                            }
                                            options={userJobPosition}
                                            chooseLegend={'Job position'}
                                            onChange={(v: any) => {
                                                const setNull = shouldBeNull(v);
                                                const updateQuery: any = {
                                                    ...properties,
                                                    job_position__value: setNull
                                                        ? null
                                                        : getArrayLikeMultipleQueries(v),
                                                };
                                                setProperties(updateQuery);
                                                setApplyFilterAction(true);
                                            }}
                                            reset={isReset}
                                            filtersOpened={filtersOpened}
                                            setFiltersOpened={setFiltersOpened}
                                            name="job_position__value"
                                        />
                                        {getButtonApply()}
                                    </>
                                ) : col?.prop === 'department' &&
                                  filters?.includes('department__value') ? (
                                    <>
                                        <MultiSelectDropdown
                                            icon={
                                                <GlobeAmericasIcon className="w-4 h-4 text-gray-500" />
                                            }
                                            options={userDepartment}
                                            chooseLegend={'Department'}
                                            onChange={(v: any) => {
                                                const setNull = shouldBeNull(v);
                                                const updateQuery: any = {
                                                    ...properties,
                                                    department__value: setNull
                                                        ? null
                                                        : getArrayLikeMultipleQueries(v),
                                                };
                                                setProperties(updateQuery);
                                                setApplyFilterAction(true);
                                            }}
                                            reset={isReset}
                                            filtersOpened={filtersOpened}
                                            setFiltersOpened={setFiltersOpened}
                                            name="department__value"
                                        />
                                        {getButtonApply()}
                                    </>
                                ) : col?.prop === 'status' && filters?.includes('user_status') ? (
                                    <>
                                        <MultiSelectDropdown
                                            icon={<UserIcon className="w-4 h-4 text-gray-500" />}
                                            options={userStatus}
                                            chooseLegend={'Status'}
                                            onChange={(v: any) => {
                                                const setNull = shouldBeNull(v);
                                                const updateQuery: any = {
                                                    ...properties,
                                                    user_status: setNull
                                                        ? null
                                                        : getArrayLikeMultipleQueries(v),
                                                };
                                                setProperties(updateQuery);
                                                setApplyFilterAction(true);
                                            }}
                                            reset={isReset}
                                            filtersOpened={filtersOpened}
                                            setFiltersOpened={setFiltersOpened}
                                            name="user_status"
                                        />
                                        {getButtonApply()}
                                    </>
                                ) : col?.prop === 'region' && filters?.includes('region') ? (
                                    <>
                                        <Slicer
                                            title={'Region'}
                                            options={regions}
                                            selected={(v: any) => {
                                                const updateQuery: any = {
                                                    ...properties,
                                                    point_of_sale_region: getArrayLikeQueries(v),
                                                };
                                                setProperties(updateQuery);
                                                setApplyFilterAction(true);
                                            }}
                                        />
                                        {getButtonApply()}
                                    </>
                                ) : col?.prop === 'countryZone' &&
                                  filters?.includes('countryZone') ? (
                                    <>
                                        <Slicer
                                            title={'Country Zone'}
                                            options={zones}
                                            selected={(v: any) => {
                                                const updateQuery: any = {
                                                    ...properties,
                                                    point_of_sale_zone: getArrayLikeQueries(v),
                                                };
                                                setProperties(updateQuery);
                                                setApplyFilterAction(true);
                                            }}
                                        />
                                        {getButtonApply()}
                                    </>
                                ) : col?.prop === 'store' && filters?.includes('store') ? (
                                    <>
                                        <Slicer
                                            title={'Account'}
                                            options={stores}
                                            selected={(v: any) => {
                                                const updateQuery: any = {
                                                    ...properties,
                                                    point_of_sale_account: getArrayLikeQueries(v),
                                                };
                                                setProperties(updateQuery);
                                                setApplyFilterAction(true);
                                            }}
                                        />
                                        {getButtonApply()}
                                    </>
                                ) : col?.prop === 'division' && filters?.includes('division') ? (
                                    <>
                                        <Slicer
                                            title={'Division'}
                                            options={divisions}
                                            selected={(v: any) => {
                                                const updateQuery: any = {
                                                    ...properties,
                                                    division: getArrayLikeQueries(v),
                                                };
                                                setProperties(updateQuery);
                                                setApplyFilterAction(true);
                                            }}
                                            isOpen={false}
                                        />
                                        {getButtonApply()}
                                    </>
                                ) : col?.prop === 'product-division' &&
                                  filters?.includes('product-division') ? (
                                    <>
                                        <Slicer
                                            title={'Division'}
                                            options={productDivisions}
                                            selected={(v: any) => {
                                                const updateQuery: any = {
                                                    ...properties,
                                                    product_division: getArrayLikeQueries(v),
                                                };
                                                setProperties(updateQuery);
                                                setApplyFilterAction(true);
                                            }}
                                        />
                                        {getButtonApply()}
                                    </>
                                ) : col?.prop === 'product-group' &&
                                  filters?.includes('product-group') ? (
                                    <>
                                        <Slicer
                                            title={'Product Group'}
                                            options={productGroup}
                                            selected={(v: any) => {
                                                const updateQuery: any = {
                                                    ...properties,
                                                    group: getArrayLikeQueries(v),
                                                };
                                                setProperties(updateQuery);
                                                setApplyFilterAction(true);
                                            }}
                                            isOpen={false}
                                        />
                                        {getButtonApply()}
                                    </>
                                ) : col?.prop === 'segments' && filters?.includes('segments') ? (
                                    <>
                                        <Slicer
                                            title={'Segments'}
                                            options={segments}
                                            selected={(v: any) => {
                                                const updateQuery: any = {
                                                    ...properties,
                                                    segment: getArrayLikeQueries(v),
                                                };
                                                setProperties(updateQuery);
                                                setApplyFilterAction(true);
                                            }}
                                        />
                                        {getButtonApply()}
                                    </>
                                ) : col?.prop === 'sitefromname' &&
                                  filters?.includes('sitefromname') ? (
                                    <>
                                        <Slicer
                                            title={'Factory'}
                                            options={sitefromname}
                                            selected={(v: any) => {
                                                const updateQuery: any = {
                                                    ...properties,
                                                    factory: getArrayLikeQueries(v),
                                                };
                                                setProperties(updateQuery);
                                                setApplyFilterAction(true);
                                            }}
                                        />
                                        {getButtonApply()}
                                    </>
                                ) : col?.prop === 'product-group-glp' &&
                                  filters?.includes('product-group-glp') ? (
                                    <>
                                        <Slicer
                                            title={'Product Group'}
                                            options={productGroupGLP}
                                            selected={(v: any) => {
                                                const updateQuery: any = {
                                                    ...properties,
                                                    divisionname: getArrayLikeQueries(v),
                                                };
                                                setProperties(updateQuery);
                                                setApplyFilterAction(true);
                                            }}
                                            isOpen={arrayIncludes(
                                                filtersOpened,
                                                'product-group-glp'
                                            )}
                                            filtersOpened={filtersOpened}
                                            setFiltersOpened={setFiltersOpened}
                                            type={'product-group-glp'}
                                        />
                                        {getButtonApply()}
                                    </>
                                ) : col?.prop === 'customer-glp' &&
                                  filters?.includes('customer-glp') ? (
                                    <>
                                        <Slicer
                                            title={'Customer'}
                                            options={customerGLP}
                                            selected={(v: any) => {
                                                const updateQuery: any = {
                                                    ...properties,
                                                    shiptoname: getArrayLikeQueries(v),
                                                };
                                                setProperties(updateQuery);
                                                setApplyFilterAction(true);
                                            }}
                                            // isOpen={arrayIncludes(filtersOpened, 'customer-glp')}
                                            // filtersOpened={filtersOpened}
                                            // setFiltersOpened={setFiltersOpened}
                                            type={'customer-glp'}
                                        />
                                        {getButtonApply()}
                                    </>
                                ) : col?.prop === 'country-glp' &&
                                  filters?.includes('country-glp') ? (
                                    <>
                                        <Slicer
                                            title={'Country'}
                                            options={countryGLP}
                                            selected={(v: any) => {
                                                const updateQuery: any = {
                                                    ...properties,
                                                    finalctry: getArrayLikeQueries(v),
                                                };
                                                setProperties(updateQuery);
                                                setApplyFilterAction(true);
                                            }}
                                            isOpen={arrayIncludes(filtersOpened, 'country-glp')}
                                            filtersOpened={filtersOpened}
                                            setFiltersOpened={setFiltersOpened}
                                            type={'country-glp'}
                                            compactMode={true}
                                        />
                                        {getButtonApply()}
                                    </>
                                ) : col?.prop === 'year' && filters?.includes('year') ? (
                                    <>
                                        <Slicer
                                            title={'Year'}
                                            options={year}
                                            selected={(v: any) => {
                                                const updateQuery: any = {
                                                    ...properties,
                                                    year: getArrayLikeQueries(v),
                                                };
                                                setProperties(updateQuery);
                                                setApplyFilterAction(true);
                                            }}
                                        />
                                        {getButtonApply()}
                                    </>
                                ) : col?.prop === 'week' && filters?.includes('week') ? (
                                    <>
                                        <Slicer
                                            title={'Week'}
                                            options={week}
                                            selected={(v: any) => {
                                                const updateQuery: any = {
                                                    ...properties,
                                                    week: getArrayLikeQueries(v),
                                                };
                                                setProperties(updateQuery);
                                                setApplyFilterAction(true);
                                            }}
                                        />
                                        {getButtonApply()}
                                    </>
                                ) : (
                                    ''
                                )}
                            </>
                        )}
                    </div>
                );
            })}
        </div>
    );
};
