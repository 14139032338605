import React, { useContext, useEffect, useState } from 'react';
import styles from './glp-alerts.module.scss';
import classNames from 'classnames';
import { Table } from '@cheilca/cheil-commons';
import { Column, CustomButtonGroup } from '@cheilca/cheil-commons/src/utils/interfaces';
import { Loader } from '@cheilca/cheil-commons/src/components/loader/loader';
import { ChevronRightIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { getAlerts, getAlertsByCustomer, postActionLogs } from '../../services/python-services';
import { GlpAlertsSetting } from '../glp-alerts-setting/glp-alerts-setting';
import { RMContext } from '../../context/RMContext';
import { viewInArrayViews } from '../../utils/functions';
export interface GlpAlertsProps {
    name: string;
    customer_id?: number;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const GlpAlerts = ({ name, customer_id }: GlpAlertsProps) => {
    const [searchText, setSearchText] = useState('');
    const [moreItems, setMoreItems] = useState(true);
    const [loadingData, setLoadingData] = useState(false);
    const [recipentsSettings, setRecipentsSettings] = useState(false);
    const [loadingAlert, setLoadingALert] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertHTML, setAlertHTML] = useState<any>([]);
    const [isLoadingDataRecipents, setIsLoadingDataRecipents] = useState<any>([]);
    const [data, setData] = useState<any>([]);
    const [costumerIdToSettings, setCostumerIdToSettings] = useState(0);
    const settingsModal = document.getElementById('modal-settings') as HTMLDialogElement;
    const [typeId, setTypeId] = useState(0);
    const [customerId, setCustomerId] = useState(customer_id ? customer_id : 0);

    const currentView = window.location.href.split('/').slice(-1)[0];
    const { sitemap } = useContext(RMContext).userData;

    useEffect(() => {
        const viewD = viewInArrayViews(currentView, sitemap);
        if (viewD?.hasOwnProperty('id')) {
            postActionLogs(viewD.id);
        }
    }, [sitemap]);

    const customButton: CustomButtonGroup[] = [
        {
            onClick: () => {
                if (settingsModal) {
                    settingsModal.showModal();
                }
            },
            children: <div className={styles.addUser}>Settings</div>,
            // children: 'Settings',
        },
    ];
    const extractDateUntilColon = (dateString: any) => {
        const regex = /^(.*?):/;
        const match = dateString.match(regex);
        return match ? match[1] : dateString;
    };
    const columns: Column[] = [
        {
            id: 'id_',
            name: 'ID',
            prop: 'id',
            open: false,
            show: true,
            style: { minWidth: '50px', maxWidth: '50px' },
        },
        {
            id: 'type',
            name: 'Alert',
            prop: 'type',
            open: false,
            show: true,
            style: { minWidth: '150px', maxWidth: '150px' },
            formatting: (v: any) => {
                return v.name;
            },
            // style: { minWidth: '130px' },
        },
        {
            id: 'date_sent',
            name: 'Date Sent',
            prop: 'date_sent',
            open: false,
            show: true,
            style: { minWidth: '150px', maxWidth: '150px' },
            formatting: (v: any) => {
                return extractDateUntilColon(v);
            },
        },
        {
            id: 'number_alert',
            name: 'Alerts',
            prop: 'number_alert',
            open: false,
            show: true,
            style: { minWidth: '65px', maxWidth: '65px' },
        },
        {
            id: 'number_sent',
            name: 'Sent',
            prop: 'number_sent',
            open: false,
            show: true,
            style: { minWidth: '60px', maxWidth: '60px' },
        },
        {
            id: 'preview',
            name: 'Preview',
            prop: 'preview',
            open: false,
            show: true,
            onClick: async (_e: React.MouseEvent, _value: string, row: any) => {
                if (loadingAlert) {
                    return;
                } else {
                    setShowAlert(true);
                    setLoadingALert(true);

                    const res = await getAlertsByCustomer(row.id);
                    //console.log('alerta cargada', res);
                    //console.log(row);
                    setAlertHTML(res);

                    setLoadingALert(false);
                }
            },
            formatting: () => {
                return (
                    <div style={{ color: '#66D0AF', cursor: 'pointer', display: 'flex' }}>
                        Preview <ChevronRightIcon style={{ width: 25 }} />
                    </div>
                );
            },
            style: { minWidth: '75px', maxWidth: '75px' },
        },
    ];
    const fetchData = async () => {
        if (loadingData) {
            return;
        } else {
            setLoadingData(true);
            const res: any = await getAlerts();
            setData(res);
            setLoadingData(false);
            setMoreItems(false);
        }
    };

    const updateData = (newData: any) => {
        setData([]);
        setData(newData);
    };

    const getEmails = (emails: any[]) => {
        return (
            <>
                {emails.map((e: any, index: number) => {
                    return (
                        <div
                            key={'email-' + index}
                            style={{
                                padding: '0 15px',
                                fontSize: '15px',
                            }}
                            className="flex gap-1 bg-[#D9D9D9] rounded-full b"
                        >
                            {e}
                            {/* <XMarkIcon style={{ width: 15 }}></XMarkIcon> */}
                        </div>
                    );
                })}
            </>
        );
    };

    const setRecipentsToCustomer = (customer_id: number) => {
        setCostumerIdToSettings(customer_id);
        if (settingsModal) {
            settingsModal.showModal();
        }
    };

    const closeModal = () => {
        settingsModal.close();
    };

    const getEmailsAlert = (alert_sent: []) => {
        let emails = alert_sent.map((e: any) => {
            return e.email;
        });
        return emails;
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className={classNames(styles.root + ' p-3 h-screen grid grid-cols-3 gap-4')}>
            <div
                className="row-span-2"
                style={{ width: '100%', height: '100%', border: 'solid 1px #C4C4C4' }}
            >
                <Table
                    title={name}
                    columns={columns}
                    data={data}
                    count={data.length}
                    isSearch={true}
                    fetchData={fetchData}
                    isLoadingData={loadingData}
                    resetButton={false}
                    columnsButton={false}
                    haveMoreItems={moreItems}
                    updateData={updateData}
                    searchText={searchText}
                    customButton={customButton}
                ></Table>
            </div>
            <div
                className="h-full w-full row-span-2 col-span-2 bg-white overflow-auto"
                style={{ border: 'solid 1px #C4C4C4' }}
            >
                {!showAlert ? (
                    <div className="w-full h-full flex flex-col justify-center items-center">
                        <h1 style={{ color: '#bdbcbc', fontWeight: 'bold', fontSize: '5rem' }}>
                            Alerts Preview
                        </h1>
                        <p style={{ color: '#bdbcbc', fontSize: '2rem' }}>
                            Please click on "Preview &gt;" to open an alert's contents
                        </p>
                    </div>
                ) : (
                    <div className="p-3" style={{ height: loadingAlert ? '100%' : 'auto' }}>
                        {loadingAlert ? (
                            <Loader></Loader>
                        ) : (
                            <div className="flex flex-col gap-[35px]">
                                {alertHTML.map((e: any, index: number) => {
                                    return (
                                        <div className="mt-2 w-full" key={'alert-' + index}>
                                            <h1 style={{ fontSize: '30px' }}>
                                                {e.alert.type.name}
                                            </h1>
                                            <h1 style={{ fontSize: '35px' }}>
                                                {e.customer.code + ' - ' + e.customer.name}
                                            </h1>
                                            {
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        gap: '5px',
                                                        flexWrap: 'wrap',
                                                    }}
                                                >
                                                    {e.alert_sent.length > 0 ? (
                                                        <>
                                                            {getEmails(
                                                                getEmailsAlert(e.alert_sent)
                                                            )}
                                                        </>
                                                    ) : (
                                                        <p
                                                            style={{
                                                                color: '#D00000',
                                                                fontWeight: 'bold',
                                                            }}
                                                        >
                                                            This alert was not sent to any email
                                                            address.
                                                        </p>
                                                    )}
                                                    {e.customer?.recipents?.length === 0 ? (
                                                        <p
                                                            onClick={() =>
                                                                setRecipentsToCustomer(
                                                                    e.customer.id
                                                                )
                                                            }
                                                            style={{
                                                                color: '#D00000',
                                                                fontWeight: 'bold',
                                                                cursor: 'pointer',
                                                            }}
                                                        >
                                                            No recipents set up for this customer,
                                                            please set it up here
                                                        </p>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {
                                                        new Set(
                                                            e.customer.alert_by_customer_setting
                                                                .map((s: any) => {
                                                                    return {
                                                                        ...s,
                                                                        exist: Boolean(
                                                                            e.alert_sent.filter(
                                                                                (sent: any) =>
                                                                                    sent.email ==
                                                                                    s.recipent.email
                                                                            ).length
                                                                        ),
                                                                    };
                                                                })
                                                                .filter((e: any) => !e.exist)
                                                                .map((x: any, index: number) => {
                                                                    return (
                                                                        x.type_id ===
                                                                            e.alert.type.id && (
                                                                            <div
                                                                                key={'set-' + index}
                                                                                style={{
                                                                                    color: '#F4F4F4',
                                                                                    padding:
                                                                                        '0 15px',
                                                                                    fontSize:
                                                                                        '15px',
                                                                                }}
                                                                                className="flex gap-1 bg-[#E34234] rounded-full b"
                                                                            >
                                                                                {x.recipent.email}{' '}
                                                                            </div>
                                                                        )
                                                                    );
                                                                })
                                                        )
                                                    }
                                                </div>
                                            }

                                            {}
                                            <div
                                                className="mt-2"
                                                dangerouslySetInnerHTML={{ __html: e.html }}
                                            ></div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                )}
            </div>
            <dialog
                style={{ minWidth: '675px', width: '40%', height: '90%' }}
                id="modal-settings"
                className="rounded"
            >
                <div className=" h-full p-5">
                    <div className="w-full flex justify-end">
                        <XMarkIcon
                            onClick={closeModal}
                            style={{ width: 25, cursor: 'pointer', textAlign: 'right' }}
                        />
                    </div>
                    <GlpAlertsSetting customer_id={costumerIdToSettings} />
                </div>
            </dialog>
        </div>
    );
};
