import { Column } from '../../../utils/interfaces';

export const getColunmsWeeklySales: Function = (): Column[] => [
    {
        name: 'COUNTRY',
        id: 'COUNTRY',
        prop: 'country__name',
        open: false,
        show: true,
    },
    {
        name: 'YEAR',
        id: 'YEAR',
        prop: 'year',
        open: false,
        show: true,
        valueType: 'number',
    },
    {
        name: 'WEEK',
        id: 'WEEK',
        prop: 'week',
        open: false,
        show: true,
        valueType: 'number',
    },
    {
        name: 'ACCOUNT',
        id: 'ACCOUNT',
        prop: 'custom_account__name',
        open: false,
        show: true,
        style: { minWidth: '200px', maxWidth: '200px' },
    },
    {
        name: 'MSO NAME',
        id: 'MSO NAME',
        prop: 'mso_name',
        open: false,
        show: true,
        style: { minWidth: '175px', maxWidth: '175px' },
    },
    {
        name: 'SITE NAME',
        id: 'SITE NAME',
        prop: 'gscn_site_name',
        open: false,
        show: true,
        style: { minWidth: '350px', maxWidth: '350px' },
        // onClick: (_e: React.MouseEvent, _value: string, row: PointOfSale) => {
        //     pos = row.point_of_sale;
        //     f(pos);
        // },
        // formatting: (value: any) => (
        //     <div
        //         style={{ color: 'var(--tertiary)' }}
        //         className="text-teal-500  cursor-pointer gap-1 items-center font-medium flex"
        //     >
        //         {value} <ChevronRightIcon className="h-4 w-4" />
        //     </div>
        // ),
    },
    {
        name: 'SITE ID',
        id: 'SITE ID',
        prop: 'site_id',
        open: false,
        show: true,
    },
    {
        name: 'PRODUCT',
        id: 'PRODUCT',
        prop: 'marketing_name',
        open: false,
        show: true,
    },
    {
        name: 'SKU',
        id: 'SKU',
        prop: 'sku',
        open: false,
        show: true,
    },
    {
        name: 'INVENTORY',
        id: 'INVENTORY',
        prop: 'inventory',
        open: false,
        show: true,
        valueType: 'number',
    },
    {
        name: 'SOLD UNITS',
        id: 'SOLD UNITS',
        prop: 'sold_units',
        open: false,
        show: true,
        valueType: 'number',
    },
    {
        name: 'AMOUNT',
        id: 'AMOUNT',
        prop: 'amount',
        open: false,
        show: true,
        valueType: 'number',
    },
    {
        name: 'SEGMENT',
        id: 'SEGMENT',
        prop: 'product_range__value',
        open: false,
        show: false,
    },
    {
        name: 'PRODUCT VALUE',
        id: 'PRODUCT VALUE',
        prop: 'group__value',
        open: false,
        show: false,
    },
    {
        name: 'REGION',
        id: 'REGION',
        prop: 'region__name',
        open: false,
        show: false,
        style: { minWidth: '200px', maxWidth: '200px' },
    },
    {
        name: 'COUNTRY ZONE',
        id: 'COUNTRY ZONE',
        prop: 'zone__name',
        open: false,
        show: false,
        style: { minWidth: '200px', maxWidth: '200px' },
    },

    {
        name: 'ID',
        id: 'ID',
        prop: 'id',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'ACCOUNT NAME',
        id: 'ACCOUNT NAME',
        prop: 'account__name',
        open: false,
        show: false,
    },
    {
        name: 'POS ACCOUNT',
        id: 'POS ACCOUNT',
        prop: 'point_of_sale_account',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'PRODUCT GROUP',
        id: 'PRODUCT GROUP',
        prop: 'product_group',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'POS',
        id: 'POS',
        prop: 'point_of_sale',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'POS REGION',
        id: 'POS REGION',
        prop: 'point_of_sale_region',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'CUSTOM ACCOUNT',
        id: 'CUSTOM ACCOUNT',
        prop: 'custom_account',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'PRODUCT ID',
        id: 'PRODUCT ID',
        prop: 'product_id',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'INCHES',
        id: 'INCHES',
        prop: 'INCHES',
        open: false,
        show: false,
        style: { minWidth: '100px' },
    },
    {
        name: 'LOAD',
        id: 'LOAD',
        prop: 'LOAD',
        open: false,
        show: false,
        style: { minWidth: '100px' },
    },
    {
        name: 'PRODUCT DIVISION',
        id: 'PRODUCT DIVISION',
        prop: 'product_division',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'DIVISION VALUE',
        id: 'DIVISION VALUE',
        prop: 'division__value',
        open: false,
        show: false,
    },
    {
        name: 'PRODUCT RANGE',
        id: 'PRODUCT RANGE',
        prop: 'product_range',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'PRODUCT SEGMENT',
        id: 'PRODUCT SEGMENT',
        prop: 'product_segment',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'SEGMENT VALUE',
        id: 'SEGMENT VALUE',
        prop: 'segment__value',
        open: false,
        show: false,
    },
    {
        name: 'POS CITY',
        id: 'POS CITY',
        prop: 'point_of_sale_city',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'CITY NAME',
        id: 'CITY NAME',
        prop: 'city__name',
        open: false,
        show: false,
    },
    {
        name: 'POS ZONE',
        id: 'POS ZONE',
        prop: 'point_of_sale_zone',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'POS COUNTRY',
        id: 'POS COUNTRY',
        prop: 'point_of_sale_country',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'KIOSKO',
        id: 'KIOSKO',
        prop: 'kiosco',
        open: false,
        show: false,
        valueType: 'number',
        cellType: 'numberBoolean',
    },
    {
        name: 'GRADE IM',
        id: 'GRADE IM',
        prop: 'grade_im',
        open: false,
        show: false,
        style: { minWidth: '100px' },
    },
    {
        name: 'GRADE AV',
        id: 'GRADE AV',
        prop: 'grade_av',
        open: false,
        show: false,
        style: { minWidth: '100px' },
    },
    {
        name: 'GRADE DA',
        id: 'GRADE DA',
        prop: 'grade_da',
        open: false,
        show: false,
        style: { minWidth: '100px' },
    },
    {
        name: 'ANTENNA IM',
        id: 'ANTENNA IM',
        prop: 'antenna_im',
        open: false,
        show: false,
        valueType: 'number',
        cellType: 'numberBoolean',
    },
    {
        name: 'ANTENNA AV',
        id: 'ANTENNA AV',
        prop: 'antenna_av',
        open: false,
        show: false,
        valueType: 'number',
        cellType: 'numberBoolean',
    },
    {
        name: 'ANTENNA DA',
        id: 'ANTENNA DA',
        prop: 'antenna_da',
        open: false,
        show: false,
        valueType: 'number',
        cellType: 'numberBoolean',
    },
    {
        name: 'SEGMENT IM',
        id: 'SEGMENT IM',
        prop: 'segment_im',
        open: false,
        show: false,
        valueType: 'number',
        cellType: 'numberBoolean',
    },
    {
        name: 'SEGMENT AV',
        id: 'SEGMENT AV',
        prop: 'segment_av',
        open: false,
        show: false,
        valueType: 'number',
        cellType: 'numberBoolean',
    },
    {
        name: 'SEGMENT DA',
        id: 'SEGMENT DA',
        prop: 'segment_da',
        open: false,
        show: false,
        valueType: 'number',
        cellType: 'numberBoolean',
    },
    {
        name: 'COVERAGE IM',
        id: 'COVERAGE IM',
        prop: 'coverage_im',
        open: false,
        show: false,
        valueType: 'number',
        cellType: 'numberBoolean',
    },
    {
        name: 'COVERAGE AV',
        id: 'COVERAGE AV',
        prop: 'coverage_av',
        open: false,
        show: false,
        valueType: 'number',
        cellType: 'numberBoolean',
    },
    {
        name: 'COVERAGE DA',
        id: 'COVERAGE DA',
        prop: 'coverage_da',
        open: false,
        show: false,
        valueType: 'number',
        cellType: 'numberBoolean',
    },
    {
        name: 'MERCHANDISER COVERAGE',
        id: 'MERCHANDISER COVERAGE',
        prop: 'merchandiser_coverage',
        open: false,
        show: false,
        valueType: 'number',
        cellType: 'numberBoolean',
    },
    {
        name: 'PROMOTER COVERAGE',
        id: 'PROMOTER COVERAGE',
        prop: 'promoter_coverage',
        open: false,
        show: false,
        valueType: 'number',
        cellType: 'numberBoolean',
    },
    {
        name: 'COVERAGE',
        id: 'COVERAGE',
        prop: 'coverage',
        open: false,
        show: false,
        valueType: 'number',
        cellType: 'numberBoolean',
    },
    {
        name: 'UNIVERSE',
        id: 'UNIVERSE',
        prop: 'universe',
        open: false,
        show: false,
        valueType: 'number',
        cellType: 'numberBoolean',
    },
    {
        name: 'LATITUDE',
        id: 'LATITUDE',
        prop: 'latitude',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'LONGITUDE',
        id: 'LONGITUDE',
        prop: 'longitude',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'INTERNAL CHEIL',
        id: 'INTERNAL CHEIL',
        prop: 'internal_cheil',
        open: false,
        show: false,
        valueType: 'number',
        cellType: 'numberBoolean',
    },
    {
        name: 'STATUS',
        id: 'STATUS',
        prop: 'status',
        open: false,
        show: false,
        valueType: 'number',
        cellType: 'numberBoolean',
    },
    {
        name: 'PHOTO',
        id: 'PHOTO',
        prop: 'photo',
        open: false,
        show: false,
        valueType: 'number',
        cellType: 'numberBoolean',
    },
    {
        name: 'ANTENNA',
        id: 'ANTENNA',
        prop: 'antenna',
        open: false,
        show: false,
        valueType: 'number',
        cellType: 'numberBoolean',
    },
    {
        name: 'WEEK OBJECT ID',
        id: 'WEEK OBJECT ID',
        prop: 'week_object_id',
        open: false,
        show: false,
        valueType: 'number',
    },
    {
        name: 'ACCOUNT FLOORING',
        id: 'ACCOUNT FLOORING',
        prop: 'account_flooring',
        open: false,
        show: false,
    },
    {
        name: 'SITE FLOORING',
        id: 'SITE FLOORING',
        prop: 'site_flooring',
        open: false,
        show: false,
    },
    {
        name: 'FLOORING PLAN',
        id: 'FLOORING PLAN',
        prop: 'flooring_plan',
        open: false,
        show: false,
        cellType: 'numberBoolean',
    },
    {
        name: 'CLUSTER ',
        id: 'CLUSTER ',
        prop: 'cluster',
        open: false,
        show: false,
        style: { minWidth: '100px' },
    },
    {
        name: 'AVAILABLE DS 2023',
        id: 'AVAILABLE DS 2023',
        prop: '2023_ds_available',
        open: false,
        show: false,
        cellType: 'boolean',
    },
    {
        name: 'AVAILABLE DS 2020',
        id: 'AVAILABLE DS 2020',
        prop: '2020_ds_available',
        open: false,
        show: false,
        cellType: 'boolean',
    },
    {
        name: 'AVAILABLE DS 2019',
        id: 'AVAILABLE DS 2019',
        prop: '2019_ds_available',
        open: false,
        show: false,
        cellType: 'boolean',
    },
    {
        name: 'AVAILABLE DS 2021',
        id: 'AVAILABLE DS 2021',
        prop: '2021_ds_available',
        open: false,
        show: false,
        cellType: 'boolean',
    },
    {
        name: 'AVAILABLE DS 2022',
        id: 'AVAILABLE DS 2022',
        prop: '2022_ds_available',
        open: false,
        show: false,
        cellType: 'boolean',
    },
    {
        name: 'AVAILABLE MS 2023',
        id: 'AVAILABLE MS 2023',
        prop: '2023_ms_available',
        open: false,
        show: false,
        cellType: 'boolean',
    },
    {
        name: 'AVAILABLE MS 2020',
        id: 'AVAILABLE MS 2020',
        prop: '2020_ms_available',
        open: false,
        show: false,
        cellType: 'boolean',
    },
    {
        name: 'AVAILABLE MS 2021',
        id: 'AVAILABLE MS 2021',
        prop: '2021_ms_available',
        open: false,
        show: false,
        cellType: 'boolean',
    },
    {
        name: 'AVAILABLE MS 2017',
        id: 'AVAILABLE MS 2017',
        prop: '2017_ms_available',
        open: false,
        show: false,
        cellType: 'boolean',
    },
    {
        name: 'AVAILABLE MS 2018',
        id: 'AVAILABLE MS 2018',
        prop: '2018_ms_available',
        open: false,
        show: false,
        cellType: 'boolean',
    },
    {
        name: 'AVAILABLE MS 2019',
        id: 'AVAILABLE MS 2019',
        prop: '2019_ms_available',
        open: false,
        show: false,
        cellType: 'boolean',
    },
    {
        name: 'AVAILABLE MS 2016',
        id: 'AVAILABLE MS 2016',
        prop: '2016_ms_available',
        open: false,
        show: false,
        cellType: 'boolean',
    },
    {
        name: 'AVAILABLE MS 2022',
        id: 'AVAILABLE MS 2022',
        prop: '2022_ms_available',
        open: false,
        show: false,
        cellType: 'boolean',
    },
    {
        name: 'AVAILABLE GSCM 2020',
        id: 'AVAILABLE GSCM 2020',
        prop: '2020_gscm_available',
        open: false,
        show: false,
        cellType: 'boolean',
    },
    {
        name: 'AVAILABLE GSCM 2021',
        id: 'AVAILABLE GSCM 2021',
        prop: '2021_gscm_available',
        open: false,
        show: false,
        cellType: 'boolean',
    },
    {
        name: 'AVAILABLE GSCM 2022',
        id: 'AVAILABLE GSCM 2022',
        prop: '2022_gscm_available',
        open: false,
        show: false,
        cellType: 'boolean',
    },
    {
        name: 'AVAILABLE GSCM 2023',
        id: 'AVAILABLE GSCM 2023',
        prop: '2023_gscm_available',
        open: false,
        show: false,
        cellType: 'boolean',
    },
];
