import styles from './change-password.module.scss';
import classNames from 'classnames';
import { useState } from 'react';
import { changePassword } from '../../services/python-services';
import Notiflix from 'notiflix';
import { addAuthOptionsToContext, validatePassword } from '../../utils/functions';
import { getInterceptorError } from '../../utils/functions';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

export interface ChangePasswordProps {
    className?: string;
    user_id: string;
    setUserData: any;
    userData: any;
    handleClose: any;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const ChangePassword = ({
    className,
    user_id,
    setUserData,
    userData,
    handleClose,
}: ChangePasswordProps) => {
    const [password, setPassword] = useState('');
    const [confirm_pass, setConfirm_pass] = useState('');
    const [passValidator, setPassValidator] = useState(false);
    const [pass2Validator, setPass2Validator] = useState(false);
    const [passwordError, setPasswordError] = useState<any>([]);
    const regexError = /:\s*(.+)/;
    const validateForm = (e: any) => {
        const value = e.target.value;
        if (e.target.id === 'password') {
            setPassword(value);
            if (value.length < 7) {
                setPassValidator(false);
            } else {
                setPassValidator(true);
            }
        } else if (e.target.id === 'confirm_pass') {
            setConfirm_pass(value);
            if (value !== password) {
                setPass2Validator(false);
            } else {
                setPass2Validator(true);
            }
        }
    };
    const onChangePasswords = async (e: any) => {
        e.preventDefault();
        validateForm(e);
    };

    const savePassword = async () => {
        const passwordValidation = await validatePassword(confirm_pass);
        if (!passwordValidation.isValid) {
            setPasswordError(passwordValidation.errorMessages);
            console.log(passwordError);
            Notiflix.Notify.failure('Password validation failed.');
            return;
        }
        const res = await changePassword({ password: confirm_pass }, user_id);
        try {
            if (res?.detail.includes('succesfully')) {
                const authOption = {
                    auth_options: {
                        email: true,
                        password: true,
                    },
                };
                addAuthOptionsToContext(setUserData, userData, authOption);
                Notiflix.Notify.success('Password saved successfully');
                handleClose('changePassword');
            }
        } catch (error) {
            const interceptorError = getInterceptorError();
            const matchError: any = regexError.exec(interceptorError.response.data.detail);
            setPasswordError(matchError[1]);
            console.error(error);
        }
    };

    return (
        <div className={'modal fade slide-up ' + className}>
            <div className={classNames(styles['modal-header'])}>
                <h4 className={classNames(styles['modal-title'], styles['pull-left'])}>
                    Add a new password
                </h4>
            </div>
            <div className={classNames(styles['modal-body'])}>
                <p>Due to security policies, you must create a password.</p>

                <form>
                    <div
                        className={classNames(
                            styles['mb-3'],
                            styles['box-pass'],
                            passValidator ? styles['valid-box'] : ''
                        )}
                    >
                        <label
                            htmlFor="pass1"
                            className={classNames(
                                styles['form-label'],
                                styles['label-pass'],
                                styles['d-flex'],
                                styles['gap-pass']
                            )}
                        >
                            Password
                            <p
                                className={classNames(
                                    styles['min-pass'],
                                    passValidator ? styles['valid'] : styles['invalid']
                                )}
                            >
                                (Minimum 7 characters and a symbol)
                            </p>
                        </label>
                        <input
                            type="password"
                            className={classNames(styles['form-control'], styles['input-pass'])}
                            id="password"
                            name="password"
                            value={password}
                            onChange={(e) => onChangePasswords(e)}
                        />
                    </div>
                    {passwordError.map((x: any) => (
                        <div
                            style={{
                                color: 'red',
                                textAlign: 'left',
                                display: 'flex',
                                gap: '5px',
                                alignItems: 'center',
                            }}
                        >
                            {' '}
                            <ExclamationTriangleIcon className="w-4 h-4" /> {x}
                        </div>
                    ))}
                    <div
                        className={classNames(
                            styles['mb-3'],
                            styles['box-pass'],
                            pass2Validator
                                ? styles['valid-box']
                                : !pass2Validator && passValidator
                                ? styles['invalid-box']
                                : ''
                        )}
                    >
                        <label
                            htmlFor="pass2"
                            className={classNames(styles['form-label'], styles['label-pass'])}
                        >
                            Confirm Password
                        </label>
                        <input
                            type="password"
                            className={classNames(styles['form-control'], styles['input-pass'])}
                            id="confirm_pass"
                            name="confirm_pass"
                            value={confirm_pass}
                            onChange={(e) => onChangePasswords(e)}
                        />
                    </div>
                </form>
                <div className={classNames(styles['button-box'])}>
                    <button
                        className={classNames(
                            styles['btn'],
                            styles['btn-info'],
                            pass2Validator && passValidator ? styles['btn-enbled'] : ''
                        )}
                        disabled={!pass2Validator || !passValidator}
                        onClick={savePassword}
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};
