import styles from './master-file-comparison-item.module.scss';
import classNames from 'classnames';
import { MasterFileComparisonTable } from '../master-file-comparison-table/master-file-comparison-table';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

import { useState } from 'react';

export interface MasterFileComparisonItemProps {
    className?: string;
    title: string;
    data: object;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const MasterFileComparisonItem = ({
    className,
    title = 'default title',
    data = {
        RM: '1233',
        GSCM: 'C012345XYZ',
        MSO: '213123',
    },
}: MasterFileComparisonItemProps) => {
    const [opened, setOpened] = useState(true);
    return (
        <div className={classNames(styles.root, className)}>
            <div className={styles.ItemHeader} onClick={() => setOpened(!opened)}>
                <div>{title}</div>
                {opened ? (
                    <ChevronDownIcon className="h-6 w-6" />
                ) : (
                    <ChevronRightIcon className="h-6 w-6" />
                )}
            </div>
            {opened ? <MasterFileComparisonTable data={data} /> : <hr className={styles.hr} />}
        </div>
    );
};
