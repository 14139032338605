import classNames from 'classnames';
import styles from './table-footer.module.scss';
export interface TableFooterProps {
    count?: number | string;
    activeCompactMode?: boolean;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const TableFooter = ({ count = 0 , activeCompactMode=false}: TableFooterProps) => {
    return (
        <div
            className={classNames(
                styles.root,
                'w-full bg-white flex items-center text-teal-500 text-base font-semibold',
                !activeCompactMode?
                'py-3 px-2 ':'py-0 px-1 '
            )}
        >
            Total {count}
        </div>
    );
};
