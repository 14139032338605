import { ColumnDef } from '@tanstack/react-table';
import styles from './columns.module.scss';
import { XCircleIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { getRecipents } from '../../services/python-services';
import { FilterFn } from '@tanstack/react-table';
import { SortingFn } from '@tanstack/react-table';

type SoldTo = {
    created_date: string;
    soldto_code: string;
    soldto_name: string;
};

type GLPAlerts = {
    code: string;
    name: string;
    sold_to: SoldTo[];
    created_date: string;
    country: string;
    alert_by_customer_setting: Object[];
};

const regex = /^\d{4}-\d{2}-\d{2}/;
const customArrIncludesSome: FilterFn<GLPAlerts> = (row, columnId, filterValue) => {
    const country = row.getValue(columnId);
    const upperCountry = typeof country === 'string' ? country.toUpperCase() : 'NO COUNTRY';
    return filterValue.map((val: string) => val.toUpperCase()).includes(upperCountry);
};

const customSort: SortingFn<GLPAlerts> = (rowA, rowB, columnId) => {
    const shipToCodeA = rowA.getValue('code');
    const shipToCodeB = rowB.getValue('code');

    const soldToA = rowA.getValue(columnId) as SoldTo[];
    const soldToB = rowB.getValue(columnId) as SoldTo[];

    const matchingSoldToCodeA =
        soldToA.find((sold) => sold.soldto_code === shipToCodeA)?.soldto_code || '0';
    const matchingSoldToCodeB =
        soldToB.find((sold) => sold.soldto_code === shipToCodeB)?.soldto_code || '0';

    const codeA = parseInt(matchingSoldToCodeA, 10);
    const codeB = parseInt(matchingSoldToCodeB, 10);

    return codeA - codeB;
};

const sortBySoldToName: SortingFn<GLPAlerts> = (rowA, rowB, columnId) => {
    const soldToA = rowA.getValue(columnId) as SoldTo[];
    const soldToB = rowB.getValue(columnId) as SoldTo[];

    const nameA = soldToA[0]?.soldto_name || '';
    const nameB = soldToB[0]?.soldto_name || '';

    return nameA.localeCompare(nameB);
};

export const columnsAlerts: ColumnDef<GLPAlerts>[] = [
    {
        accessorKey: 'code',
        id: 'code',
        header: 'Ship to code',
        cell: (info: any) => info.getValue(),
        size: 50,
    },
    {
        accessorKey: 'name',
        id: 'name',
        cell: (info: any) => {
            const name = info.getValue();
            return (
                <div className={styles.shipName} title={info.getValue()}>
                    {name}
                </div>
            );
        },
        header: 'Ship to name',
        size: 200,
    },
    {
        accessorKey: 'sold_to',
        id: 'sold_to_code',
        header: 'Sold to code',
        sortingFn: customSort,
        sortDescFirst: false,
        cell: (info: any) => {
            const shipToCode = info.row.getValue('code');
            const soldTo = info.getValue() as SoldTo[];

            const matchingSoldToCode =
                soldTo.find((sold) => sold.soldto_code === shipToCode)?.soldto_code || 'N/A';

            return <div className={styles.shipName}>{matchingSoldToCode}</div>;
        },
        size: 50,
    },
    {
        accessorKey: 'sold_to',
        id: 'sold_to_name',
        header: 'Sold to name',
        sortingFn: sortBySoldToName,
        sortDescFirst: false,
        cell: (info: any) => {
            const information = info.getValue().map((x: any) => {
                return x.soldto_name;
            });
            const soldToCode = info.row.getValue('sold_to_code');
            const shipToCode = info.row.getValue('code');
            const allowInformation = soldToCode
                .map((x: any) => {
                    return x.soldto_code;
                })
                .some((y: any) => {
                    return y == shipToCode;
                });
            return (
                <div className={styles.shipName} title={information}>
                    {allowInformation && information}
                </div>
            );
        },
        size: 200,
    },
    {
        accessorKey: 'created_date',
        id: 'created_date',
        header: 'Creation date',
        cell: (info: any) => {
            const date = info.getValue().match(regex)[0];
            return <div>{date}</div>;
        },
        size: 50,
    },
    {
        accessorKey: 'country.name',
        id: 'country',
        header: 'Creation country',
        filterFn: customArrIncludesSome,
        cell: (info: any) => {
            const country = info.getValue();
            let lastCountry;
            country === undefined ? (lastCountry = 'NO COUNTRY') : (lastCountry = country);
            const allCapsCountry = lastCountry.toUpperCase();
            return <div className={styles.shipName}>{allCapsCountry}</div>;
        },
        meta: {
            filterVariant: 'select',
        },
        size: 100,
    },
    {
        accessorKey: 'alert_by_customer_setting',
        header: 'Channel Recipients',
        id: 'Channel',
        cell: (info: any) => {
            const emails: any[] = [];
            info.getValue().map((recipient: any) => {
                return emails.push(recipient.recipent.email);
            });
            return (
                <div>
                    {emails.map((email: any, index: any) => (
                        <div className={styles.emailsAdded} key={index}>
                            {email}
                        </div>
                    ))}
                </div>
            );
        },
        size: 500,
    },
    {
        accessorKey: 'alert_by_customer_setting',
        header: 'Samsung Recipients',
        id: 'Samsung',
        cell: (info: any) => {
            const emails: any[] = [];
            info.getValue().map((recipient: any) => {
                return emails.push(recipient.recipent.email);
            });
            return (
                <div>
                    {emails.map((email: any, index: any) => (
                        <div className={styles.emailsAdded} key={index}>
                            {email}
                        </div>
                    ))}
                </div>
            );
        },
        size: 500,
    },
    /*     {
        id: 'addRecipients',
        header: 'Add recipients',
        cell: () => <div className={styles.addRecipents}>{}</div>,
        enableResizing: false,
    }, */
];
