import React, { useEffect, useState } from 'react';
import styles from './date-range-picker.module.scss';

export interface DateRangePickerProps {
  className?: string;
  onSave?: any;
  reset?: boolean;
  setIsReset?: any;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const DateRangePicker = ({  onSave = () => { }, reset = false, setIsReset = () => { } }: DateRangePickerProps) => {

  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
    setEndDate(''); // Clear the end date when start date changes to ensure it's set after the start date
  };

  useEffect(() => {
    if (reset) {
      setStartDate('');
      setEndDate('');
      setIsReset(false); // Reset reset state when reset is true to avoid unnecessary re-renders
    }
  }, [reset])
  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEndDate = e.target.value;
    if (newEndDate >= startDate) {
      setEndDate(newEndDate);
      onSave({ startDate, endDate: newEndDate });
    } else {
      alert('End date should be greater than or equal to the start date.');
    }
  };

  return (
    <div className={styles.containerDataPicker}>
      <div>
        <label style={{width:'20%'}}>
          Start Date:
        </label>
        <input
          className={styles.calendarStyle}
          type="date"
          value={startDate}
          onChange={handleStartDateChange}
        />
      </div>
      <div>
        <label style={{width:'20%'}}>
          End Date:
        </label>
        <input
          className={styles.calendarStyle}
          type="date"
          value={endDate}
          min={startDate} // Ensure end date cannot be before the start date
          onChange={handleEndDateChange}
        />
      </div>
    </div>
  );
};
