import { TableHeader } from '../table-header/table-header';
import { Column, CustomButtonGroup } from '../../utils/interfaces';
import { TableRows } from '../table-rows/table-rows';
import { TableTools } from '../table-tools/table-tools';
import { TableFooter } from '../table-footer/table-footer';
import { Commas } from '../../services/python-services';
import { useState, useEffect, useRef } from 'react';
import { Loader } from '../loader/loader';
import { GenericModal } from '../generic-modal/generic-modal';
import styles from './table.module.scss';
import { FilterTable } from '../filter-table/filter-table';

export interface TableProps {
    className?: string;
    columns: Column[];
    data: object[];
    title: string;
    fetchData: (isScroll: boolean) => void;
    selectAll?: () => void;
    selectId?: (id: number) => void | undefined;
    haveMoreItems: boolean;
    toolsNative?: boolean;
    updateData: Function;
    searchFunction?: Function;
    searchText: string;
    sortFunction?: Function;
    count: number;
    exportData?: Function;
    exportLoader?: boolean;
    onReset?: Function;
    isLoadingData?: boolean;
    isLoadingDataScroll?: boolean;
    customButton?: CustomButtonGroup[];
    columnsButton: boolean;
    resetButton: boolean;
    isSearch: boolean;
    allColumnsStyle?: string;
    tableWidth?: string;
    idsArray?: number[] | undefined;
    selectAllBoolean?: boolean;
    addRole?: CustomButtonGroup[];
    openModal?: boolean;
    setOpenModal?: Function;
    activeCompactMode?: boolean;
    isExpand?: boolean;
    hasTotal?: boolean;
    hasAdditionalHeader?: boolean;
    additionalHeader?: any;
    isRolesTable?: boolean;
    initialData?: any;
    style?: any;
    lightTheme?: boolean;
    filter?: any;
    filterByArrival?: boolean;
    filterByQuery?: any;
    queries?: any;
    isFilter?: any;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const Table = ({
    columns,
    data,
    title,
    fetchData,
    selectAll,
    selectId,
    haveMoreItems,
    toolsNative,
    updateData,
    searchFunction = () => {},
    searchText,
    sortFunction = () => {},
    count,
    exportData,
    exportLoader = false,
    onReset = () => {},
    isLoadingData,
    isLoadingDataScroll,
    customButton,
    columnsButton,
    resetButton,
    isSearch,
    idsArray,
    allColumnsStyle,
    selectAllBoolean,
    addRole,
    openModal,
    setOpenModal,
    activeCompactMode = false,
    isExpand = true,
    hasTotal = true,
    hasAdditionalHeader = false,
    additionalHeader = null,
    isRolesTable = false,
    initialData = null,
    lightTheme = false,
    filter = null,
    filterByArrival,
    filterByQuery,
    queries,
    isFilter,
}: TableProps) => {
    const [columns_, setColumns] = useState(columns);
    const [searchValue, setSearchValue] = useState(searchText);
    // let scrollRef: HTMLElement | null = null;
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const [expandTable, setExpandTable] = useState(false);
    // mover a un

    const SortData = (value: any) => {
        if (toolsNative) {
            const by = value.prop as keyof {};
            const asc: any = (a: string | number | Date, b: string | number | Date): number => {
                if (a[by] > b[by]) return 1;
                else return b[by] > a[by] ? -1 : 0;
            };
            const desc: any = (a: string | number | Date, b: string | number | Date) => {
                if (a[by] < b[by]) return 1;
                else return b[by] < a[by] ? -1 : 0;
            };
            if (value.name === 'Ascending') {
                let dtoData: any = value.prop!=="last_login"?[...data]:[];
                if(value.prop==="last_login"){
                    data.forEach((element: any) => {
                    dtoData.push({ ...element, last_login: (element.last_login!=="NaN-NaN-NaN NaN:NaN"&& element.last_login!=="-" && element.last_login!==null)?element.last_login:"NaN-NaN-NaN NaN:NaN" });
                });
                }
                updateData([...dtoData].sort(asc));
            } else if (value.name === 'Descending') {
                
                let dtoData: any = value.prop!=="last_login"?[...data]:[];
                if(value.prop==="last_login"){
                    data.forEach((element: any) => {
                    dtoData.push({ ...element, last_login: (element.last_login!=="NaN-NaN-NaN NaN:NaN"&& element.last_login!=="-" && element.last_login!==null)?element.last_login:"-" });
                });
                }
                updateData([...dtoData].sort(desc));
            }
        } else {
            sortFunction(value);
        }
    };
    const typeSearch = (event: any) => {
        if (toolsNative) {
            let newData: any;
            // console.log('typeSearch', event);
            const value = event?.target?.value || event;

            if (value.length >= 2) {
                newData = arraySearch(initialData || data, value);
                updateData(newData);
            } else {
                updateData((initialData || data) as any);
            }
        } else {
            setSearchValue(event);
            searchFunction(event);
        }
    };

    const UpdateColumns = (newColumns: Column[]) => {
        setColumns(newColumns);
    };
    const handleCloseModal = () => {
        const dialog = document.getElementById('generic-dialog');
        //@ts-expect-error
        if (dialog) dialog.close();
    };

    const handleOpenModal = () => {
        const dialog = document.getElementById('generic-dialog');
        //@ts-expect-error
        if (dialog) dialog.showModal();
    };

    useEffect(() => {
        if (expandTable) {
            handleOpenModal();
        } else {
            handleCloseModal();
        }
    }, [expandTable]);

    const getTable = () => {
        return (
            <div className="inline-flex w-full h-full flex-col">
                <TableTools
                    title={title}
                    onSearch={typeSearch}
                    searchText={searchValue}
                    columns={columns_}
                    onChangeColumns={UpdateColumns}
                    exportData={exportData}
                    exportLoader={exportLoader}
                    reset={onReset}
                    customButton={customButton}
                    isSearch={isSearch}
                    columnsButton={columnsButton}
                    resetButton={resetButton}
                    setExpandTable={setExpandTable}
                    expandTable={expandTable}
                    addRole={addRole}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    activeCompactMode={activeCompactMode}
                    isExpand={isExpand}
                    hasAdditionalHeader={hasAdditionalHeader}
                    additionalHeader={additionalHeader}
                    filterByArrival={filterByArrival}
                    filterByQuery={filterByQuery}
                    queries={queries}
                    isFilter={isFilter}
                />
                {filter !== null && (
                    <FilterTable
                        applyFilters={filter?.applyFilters}
                        columns={filter?.columns}
                        customFilters={filter?.customFilters}
                        filters={filter?.filters}
                        onReset={filter?.onReset}
                        setCustomFilters={filter?.setCustomFilters}
                        setUniques={filter?.setUniques}
                        uniques={filter?.uniques}
                        isColapsed={true}
                    />
                )}
                {/* Scrollable container */}
                <div
                    className={styles.scroll}
                    style={{ overflowX: 'auto', overflowY: 'hidden', width: '100%' }}
                    ref={scrollRef}
                >
                    <div style={{ display: 'inline-block', minWidth: '100%' }}>
                        <TableHeader
                            columns={columns_}
                            sortData={SortData}
                            onOpen={UpdateColumns}
                            selectAll={selectAll}
                            allColumnsStyle={allColumnsStyle}
                            selectAllBoolean={selectAllBoolean}
                            activeCompactMode={activeCompactMode}
                            isRolesTable={isRolesTable}
                            lightTheme={lightTheme}
                        />

                        {isLoadingData && !isLoadingDataScroll ? (
                            <div className="flex h-full w-full items-center justify-center">
                                <Loader title="Loading Table Data ..." />
                            </div>
                        ) : (
                            <>
                                {data?.length === 0 || columns_.every((i: Column) => !i.show) ? (
                                    <div className="flex bg-white h-[80vh] justify-center items-center flex-col text-gray-500">
                                        <h5 className="font-bold text-xl">NO DATA</h5>
                                        <p>
                                            Check the filters. To reset the filters, click on the
                                            reset button.
                                        </p>
                                    </div>
                                ) : (
                                    <TableRows
                                        haveMore={haveMoreItems}
                                        data={data}
                                        columns={columns_}
                                        fetchData={fetchData}
                                        selectId={selectId}
                                        idsArray={idsArray}
                                        allColumnsStyle={allColumnsStyle}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </div>
                {hasTotal && (
                    <TableFooter count={Commas(count)} activeCompactMode={activeCompactMode} />
                )}
            </div>
        );
    };

    return (
        <div className={styles.containerData}>
            {getTable()}
            <GenericModal
                closeDialog={handleCloseModal}
                haveComponent={true}
                getComponent={getTable()}
            />
        </div>
    );
};

export const arraySearch = (array: any[], keyword: string) => {
    const searchTerm = keyword.toLowerCase();
    return array.filter((value) => {
        return Object.values(value).some((prop) => {
            if (typeof prop === 'string') {
                return prop.toLowerCase().match(new RegExp(searchTerm, 'g'));
            }
            return false;
        });
    });
};
