import styles from './add-pbi-report.module.scss';
import classNames from 'classnames';

import { useState, useEffect } from 'react';
import { MockUp } from '../mock-up/mock-up';
import vector from '/reports/Vector.png';
import { createPowerBiReport, updatePowerBI } from '../../services/python-services';
import { cleanString, isValidId, isValidMultiSelect, isValidString } from '../../utils/functions';
import Notiflix from 'notiflix';
import Multiselect from 'multiselect-react-dropdown';
import { stylesMultiSelect } from '../../utils/constants';

export interface AddPBIReportProps {
    className?: string;
    coverData: any;
    subViewData: any;
    divisionData: any;
    groupData: any;
    // type: any,
    CloseModalAndUpdate: any;
    OnlyClose: any;
    selectedReport?: any;
    setUpdatedData?: any;
    usersData?: any;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/kb16522
 */
export const AddPBIReport = ({
    className,
    coverData,
    subViewData,
    divisionData,
    groupData,
    // type,
    CloseModalAndUpdate,
    OnlyClose,
    selectedReport = null,
    setUpdatedData,
    usersData,
}: AddPBIReportProps) => {
    const [viewType, setViewType] = useState('');
    const [url, setUrl] = useState('');
    const [divisionId, setDivisionId] = useState(-1);
    const [userId, setUserId] = useState<any[]>([]);
    const [subViewId, setCountryId] = useState<any>(-1);
    const [groupId, setGroupId] = useState(-1);
    const [groupName, setGroupName] = useState('');
    const [workspaceId, setWorkspaceId] = useState('');
    const [reportId, setReportId] = useState('');
    //console.log('Adding PBI Report', selectedReport,selectedReport === null ? 'ADD' : 'UPDATE');
    const [method, setMethod] = useState(selectedReport === null ? 'ADD' : 'UPDATE');
    const [showGroupSelect, setShowGroupSelect] = useState(false);
    const [showMockUp, setShowMockUp] = useState(false);
    const [name, setName] = useState('');
    const [mockUpData, setMockUpData] = useState({});
    const [idItem, setIdItem] = useState(null);
    const [isUpdate, setIsUpdate] = useState(false);
    const [somethingChange, setSomethingChange] = useState(false);
    const [updatedApplied, setUpdatedApplied] = useState(false);
    const [validForm, setValidForm] = useState(false);
    const [validCover, setValidCover] = useState(true);
    const [validDivision, setValidDivision] = useState(true);
    const [validCountry, setValidCountry] = useState(true);
    const [validUser, setValidUser] = useState(true);

    // console.log("selectedReport",selectedReport);
    useEffect(() => {
        // Handle initialization or update when idItem changes
        if (selectedReport || updatedApplied) {
            readData(selectedReport);
            setUpdatedApplied(false);
        } else {
            clearFields();
        }
        if (selectedReport !== null) {
            setMethod('UPDATE');
        }
    }, [selectedReport, updatedApplied]);

    useEffect(() => {
        // Handle initialization or update when idItem changes
        if (somethingChange) onChanges();
    }, [somethingChange]);

    const readData = (data: any) => {
        const currentDivision = divisionData?.filter(
            (divisionD: any) => divisionD.id === data?.division_id
        )[0];
        const currenCountry = subViewData?.filter(
            (country: any) => country.id === parseInt(data?.parent_id!)
        )![0];
        const currentCover = coverData?.filter(
            (coverD: any) => coverD.name === data?.cover_name
        )[0];
        const person_in_charge_id = usersData.filter(
            (user: any) => user.id === data?.person_in_charge_id
        )![0];

        setIdItem(data?.id);
        setViewType(currentCover?.id || -1);
        setUrl(data?.url || '');
        const pic = person_in_charge_id !== undefined ? [{ ...person_in_charge_id }] : [];
        // console.log(data, "readData", pic);
        setUserId(pic);
        // setDivisionId(currentDivision?.id || -1);
        setDivisionId(data?.division_id);
        setCountryId(currenCountry?.id || -1);
        setWorkspaceId(data?.workspace_id);
        setReportId(data?.report_id);
        if (data?.group !== '') {
            setGroupId(data?.group);
        }
        //try to get group
        const gr = data?.name.split('_')?.pop().toUpperCase();
        const currentGroup = groupData?.filter((groupD: any) => groupD.value === gr)[0];
        setGroupId(currentGroup?.id || -1);
        setGroupName(currentGroup?.value || '');
        // console.log("currentGroup: ", currentGroup, "currentGroup !== undefined", currentGroup !== undefined)
        if (currentGroup !== undefined) {
            setShowGroupSelect(true);
        }
        setName(data?.name);
        setMockUpData({
            name: data?.name,
            division_name: currentDivision?.value || '',
            report: currentCover?.name || '',
            background_color: currentCover?.color,
        });
        setShowMockUp(true);
        setIsUpdate(true);
        setValidForm(
            isValidString(data?.name) &&
                isValidString(data?.name.split('_')![2] || -1) &&
                validateCountry(currenCountry?.id) &&
                validateUser([{ ...person_in_charge_id }])
        );
        //onChanges();
    };

    const clearFields = () => {
        setViewType('');
        setUrl('');
        setDivisionId(-1);
        setCountryId(-1);
        setUserId([]);
        setGroupId(-1);
        setGroupName('');
        setWorkspaceId('');
        setReportId('');
        setShowGroupSelect(false);
        setShowMockUp(false);
    };

    function handleCountryInput(e: any) {
        setCountryId(e);
        setSomethingChange(true);
    }
    function handleUserInput(e: any) {
        setUserId([...e]);
        setSomethingChange(true);
    }
    const validateCover = (viewTypeD: any) => {
        const isValidCover = isValidString(viewTypeD) && viewTypeD !== '-1';
        setValidCover(isValidCover);
        return isValidCover;
    };
    const validateCountry = (subViewIdD: any) => {
        const isValidCountry = isValidId(subViewIdD);
        setValidCountry(isValidCountry);
        return isValidCountry;
    };
    const validateDivision = (divisionIdD: any) => {
        const isValidDivision = isValidId(divisionIdD);
        setValidDivision(isValidDivision);
        return isValidDivision;
    };
    const validateUser = (userIdD: any) => {
        const isValidUser = isValidMultiSelect(userIdD);
        setValidUser(isValidUser);
        return isValidUser;
    };

    const validateForm = () => {
        let valid = true;

        const isValidCover = validateCover(viewType);

        const isValidCountry = validateCountry(subViewId);

        const isValidDivision = validateDivision(divisionId);

        const isValidUser = validateUser(userId);

        valid &&= isValidString(name);
        valid &&= isValidCover;
        valid &&= isValidCountry;
        valid &&= isValidDivision;
        valid &&= isValidUser;
        setValidForm(valid);
    };
    const onChanges = () => {
        validateForm();
        if (divisionId === 3 && viewType === 're') {
            setShowGroupSelect(true);
        } else {
            setShowGroupSelect(false);
        }

        if ((subViewId && viewType) || divisionId) {
            setShowMockUp(true);
            const divisionName = divisionData?.find(
                (object: any) => object.id === divisionId
            )?.value;
            const subViewName = subViewData?.filter((object: any) => object.id === subViewId)[0]
                ?.name;
            // console.log("view_type", viewType)
            let nameG = '';
            if (groupId !== -1) {
                nameG = groupData?.find((object: any) => object.id === groupId)?.value;
                setGroupName(nameG);
            }
            const groupValue = showGroupSelect ? nameG : divisionName;

            let newName = !isUpdate
                ? `${subViewName}_${isValidString(viewType) ? viewType : ''}${
                      isValidString(groupValue) ? '_' + groupValue : ''
                  }`.toLowerCase()
                : name;
            setName(newName);

            setMockUpData({
                name: newName,
                division_name: divisionName,
                report: coverData?.find((i: any) => i.id === viewType)?.name,
                background_color: coverData.find((i: any) => i.id === viewType)?.color,
            });
        } else {
            setShowMockUp(false);
        }
        setSomethingChange(false);
    };

    const modalPost = async () => {
        const newName = cleanString(name);
        const body = {
            name: newName,
            type: viewType,
            sub_view_id: subViewId,
            division_id: divisionId,
            workspace_id: workspaceId,
            report_id: reportId,
            url,
            person_in_charge_id: userId.length > 0 ? userId[0]?.id : null,
            show: selectedReport?.show || true,
            group: isValidString(groupName) ? groupName : null,
        };

        try {
            let response: any = null;
            // console.log("method: " + method)
            if (method === 'ADD') {
                response = await createPowerBiReport(body);
            } else if (method === 'UPDATE' && idItem) {
                response = await updatePowerBI(idItem, body);
            }

            if (response.outcome) {
                CloseModalAndUpdate(response.message);
                setUpdatedData(true);
                setUpdatedApplied(true);
            } else {
                Notiflix.Notify.failure(response.message);
                OnlyClose();
            }
            clearFields();
        } catch (error) {
            clearFields();
            OnlyClose.emit();
        }
    };

    const methodUpload = (method: any) => {
        if (method === 'post') {
            modalPost();
        }
    };

    const close = () => {
        OnlyClose();
    };
    console.log('user dat', usersData);
    return (
        <div
            className={
                classNames(styles.root, styles[className || '']) + ' ' + styles.containerData
            }
        >
            <h1 className={styles.header}>{selectedReport !== null ? 'EDIT' : 'ADD'} REPORT</h1>
            <div>
                <div className="form-flex">
                    <div id="" className="form-box">
                        <label>Cover name</label>
                        <select
                            name="cover_name"
                            className="test"
                            value={viewType}
                            onChange={(e) => {
                                setViewType(e.target.value);
                                setSomethingChange(true);
                            }}
                        >
                            <option key={-1} value={-1}>
                                {''}
                            </option>
                            {coverData?.map((name: any) => (
                                <option key={name.id} value={name.id}>
                                    {name.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    {!validCover && (
                        <small className="text-red-500">The Cover name field is required.</small>
                    )}
                    <div id="" className="form-box">
                        <label>Url</label>
                        <input
                            type="text"
                            name="url"
                            className="test"
                            value={url}
                            onChange={(e) => setUrl(e.target.value)}
                        />
                    </div>
                    <div id="" className="form-box">
                        <label>Division</label>
                        <select
                            name="division_name"
                            className="test"
                            value={divisionId}
                            onChange={(e) => {
                                setDivisionId(parseInt(e.target.value));
                                setSomethingChange(true);
                            }}
                        >
                            <option key={-1} value={-1}>
                                {''}
                            </option>
                            {divisionData?.map((name: any) => (
                                <option key={name.id} value={name.id}>
                                    {name.value}
                                </option>
                            ))}
                        </select>
                    </div>
                    {!validDivision && (
                        <small className="text-red-500">The Division field is required.</small>
                    )}
                    {showGroupSelect && (
                        <div id="" className="form-box">
                            <label>Group</label>
                            <select
                                name="group"
                                className="test"
                                value={groupId}
                                onChange={(e) => {
                                    setGroupId(parseInt(e.target.value));
                                    setSomethingChange(true);
                                }}
                            >
                                <option key={-1} value={-1}>
                                    {''}
                                </option>
                                {groupData?.map((name: any) => (
                                    <option key={name.id} value={name.id}>
                                        {name.value}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}

                    <div>
                        <div id="" className="form-box">
                            <label>Sub View</label>
                            {subViewData && (
                                <select
                                    id="country"
                                    name="division_name"
                                    placeholder="Select a Country"
                                    className="test"
                                    value={subViewId}
                                    onChange={(e) => {
                                        handleCountryInput(parseInt(e.target.value));
                                    }}
                                >
                                    <option key={-1} value={-1}>
                                        {''}
                                    </option>
                                    {subViewData?.map((name: any) => (
                                        <option key={name.id} value={name.id}>
                                            {name.description}
                                        </option>
                                    ))}
                                </select>
                            )}
                        </div>
                        {!validCountry && (
                            <small className="text-red-500">The Sub View field is required.</small>
                        )}
                    </div>
                </div>
                <div id="" className="form-box">
                    <label>WORKSPACE ID</label>
                    <input
                        name="workspace_id"
                        type="text"
                        className="test"
                        value={workspaceId || ''}
                        onChange={(e) => setWorkspaceId(e.target.value)}
                    />
                </div>
                <div id="" className="form-box">
                    <label>REPORT ID</label>
                    <input
                        name="report_id"
                        type="text"
                        className="test"
                        value={reportId || ''}
                        onChange={(e) => setReportId(e.target.value)}
                    />
                </div>
                <div>
                    <div id="" className="form-box">
                        <label>Person In Charge</label>
                        {usersData && usersData.length > 0 && (
                            // <select
                            //     id="person_in_charge"
                            //     name="person_in_charge"
                            //     placeholder='Select a Person in Charge'
                            //     className="test"
                            //     value={userId}
                            //     onChange={(e) => { handleUserInput(parseInt(e.target.value)); }}
                            // >
                            //     <option key={-1} value={-1}>
                            //         {""}
                            //     </option>
                            //     {usersData?.map((user: any) => (
                            //         <option key={user.id} value={user.id}>
                            //             {user.full_name}
                            //         </option>
                            //     ))}
                            // </select>

                            <Multiselect
                                id="users"
                                placeholder="Select a Person in Charge"
                                onSelect={handleUserInput}
                                displayValue="full_name"
                                options={usersData}
                                style={stylesMultiSelect}
                                showArrow={true}
                                selectedValues={[...userId]}
                                onRemove={handleUserInput}
                                selectionLimit={1}
                            />
                        )}
                    </div>
                    {!validUser && (
                        <small className="text-red-500">
                            The Person In Charge field is required.
                        </small>
                    )}
                </div>
                {!showMockUp && (
                    <div>
                        <div className={styles['bottom-seg']}>
                            <div className={styles['line']}></div>
                            <div id="cover" className={styles.cover}>
                                Cover
                            </div>
                            <div id="bottom-text" className={styles['bottom-text']}>
                                Please fill in the fields above to see a preview of the report
                                cover.
                            </div>
                        </div>

                        <div
                            id="bottom-img"
                            className={styles['bottom-img']}
                            style={{ display: !showMockUp ? 'block' : 'none' }}
                        >
                            <img src={vector} alt="" id="img-bot" className={styles['img-bot']} />
                            <div id="bottom-img-text" className={styles['bottom-img-text']}>
                                No preview available
                            </div>
                        </div>
                    </div>
                )}
                {showMockUp && (
                    <div className={styles['mock-up-box']}>
                        <MockUp data={mockUpData}></MockUp>
                    </div>
                )}

                <div className="bottom-flex-pop">
                    <button className="btn" onClick={close}>
                        CANCEL
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() => methodUpload('post')}
                        disabled={!validForm}
                    >
                        UPLOAD
                    </button>
                </div>
            </div>
        </div>
    );
};
